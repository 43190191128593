const app = window.__initialData;
const globals = {
  // Scroll
  is_scrolled: app.is_scrolled,
  // {boolean} Used to toggle dynamic header bar
  prev_scroll_pos: app.prev_scroll_pos,
  // {number} Pprevious scroll position of the page.
  show_scroll_up: app.show_scroll_up,
  // {boolean} To show the 'back to top' button
  // Audio
  click_audio: app.click_audio,
  // {string} URL for click sound
  success_audio: app.success_audio,
  // {string} URL for success sound
  enable_audio: app.enable_audio,
  // {boolean} To enable or disable audio
  // Popups
  age_popup: app.age_popup,
  // {boolean} To toggle the age popup
  filter_popup: app.filter_popup,
  // {boolean} To toggle the filter popup
  localization_popup: app.localization_popup,
  // {boolean} To toggle the localization popup
  audio_popup: app.audio_popup,
  // {boolean} To toggle audio settings popup
  cookie_popup: app.cookie_popup,
  // {boolean} To toggle the cookie compliance popup
  discount_popup: app.discount_popup,
  // {boolean} To toggle the discount popup
  guarantee_popup: app.guarantee_popup,
  // {boolean} To toggle the guarantee popup
  // Quick sections
  quick_add_popup: app.quick_add_popup,
  // {boolean} To toggle the quick add popup
  quick_edit_popup: app.quick_edit_popup,
  // {boolean} To toggle the quick edit popup
  quick_edit_handle: app.quick_edit_handle,
  // {string} The product handle of the product being edited
  quick_add_handle: app.quick_add_handle,
  // {string} The product handle of the product being added
  // Menu
  menu_drawer: app.menu_drawer,
  // {boolean} To toggle the menu drawer
  menu_nested: app.menu_nested,
  // {boolean} To check if the menu is nested
  // Header
  hide_header: app.hide_header,
  // {boolean} To hide the header
  // Errors
  error_alert: app.error_alert,
  // {boolean} To show the alert
  error_message: app.error_message,
  // {string} Error message
  // Prices
  price_format_with_currency: app.price_format_with_currency,
  // {string} Format for price with currency
  price_format_without_currency: app.price_format_without_currency,
  // {string} Format for price without currency
  price_enable_zeros: app.price_enable_zeros,
  // {Boolean} Set to false to hide '.00'
  price_enable_currency: app.price_enable_currency,
  // {Boolean} Set to false to hide 'CAD
  // Product
  recent_products: app.recent_products,
  // {array} of recently viewed products
  // Discount properties
  discount_text: app.discount_text,
  // {string} Text for the discount
  discount_code: app.discount_code,
  // {string} Code for the discount
  // Cart
  cart_alert: app.cart_alert,
  // {boolean} To show the cart alert
  cart_drawer: app.cart_drawer,
  // {boolean} To toggle the cart drawer
  cart_loading: app.cart_loading,
  // {boolean} To check if the cart is loading
  cart_behavior_desktop: app.cart_behavior_desktop,
  // {string} Behavior of the cart on desktop
  cart_behavior_mobile: app.cart_behavior_mobile,
  // {string} Behavior of the cart on mobile
  cart: app.cart,
  // {object} Object to store the cart data
  progress_bar_threshold: app.progress_bar_threshold,
  // {number} Set the threshold for the 'free shipping' progress bar
  // Search
  search_active: app.search_active,
  // {boolean} to toggle the search overlay
  search_loading: app.search_loading,
  // {boolean} To check if the search is loading
  search_term: app.search_term,
  // {string} Term for the search
  search_items: app.search_items,
  // {array} Array of search items
  search_focus_index: app.search_focus_index,
  // {string} Index of the focused search item
  search_focus_url: app.search_focus_url,
  // {string} URL of the focused search item
  search_items_pages: app.search_items_pages,
  // {array} Array of search items in pages
  search_items_collections: app.search_items_collections,
  // {array} Array of search items in collections
  search_items_articles: app.search_items_articles,
  // {array} Array of search items in articles
  search_items_queries: app.search_items_queries,
  // {array} Array of search items in queries
  // Pagination
  pagination_loading: app.pagination_loading,
  // {boolean} To show loading state in pagination
  pagination_total_pages: app.pagination_total_pages,
  // {number} Total number of pages for the current collection
  pagination_current_page: app.pagination_current_page,
  // {number} Current page number in pagination
  pagination_section: app.pagination_section,
  // {string} Points to a {{ section.id }} to paginate
  // Filter
  filter_min_price: app.filter_min_price,
  // {number} Value of the min price input
  filter_max_price: app.filter_max_price,
  // {number} Value of the max price input
  filter_min: app.filter_min,
  // {number} Min price for the current collection
  filter_max: app.filter_max,
  // {number} Max price for the current collection
  filter_min_thumb: app.filter_min_thumb,
  // {number} Sets position of min price thumb
  filter_max_thumb: app.filter_max_thumb,
  // {number} Sets position of max price thumb
  // TODO: - Remove, merge and connect. Referce Space
  show_alert: app.show_alert,
  enable_body_scrolling: app.enable_body_scrolling
};
const cart = {
  updateCartNote(note) {
    this.cart_loading = true;
    fetch(`${window.Shopify.routes.root}cart/update.js`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ note })
    }).then(async (response) => {
      let data2 = await response.json();
      if (response.status === 200) {
        this.cart.items = data2.items.map((item) => {
          return {
            ...item
          };
        });
        this.updateCart(false);
      } else {
        this.error_title = data2.message, this.error_message = data2.description, this.show_alert = true;
      }
    }).catch((error2) => {
      console.error("Error:", error2);
      this.cart_loading = false;
    });
  },
  // Update cart with fetched data
  async updateCart(openCart) {
    this.cart_loading = true;
    this.enable_body_scrolling = true;
    try {
      const response = await fetch(`${window.Shopify.routes.root}cart.js`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data2 = await response.json();
      this.cart.items = data2.items;
      this.cart.item_count = data2.item_count;
      this.cart.total_price = data2.total_price;
      this.cart.original_total_price = data2.original_total_price;
      this.cart.total_discount = data2.total_discount;
      this.cart.cart_level_discount_applications = data2.cart_level_discount_applications;
      let calcTotal;
      if (this.cart.progress_bar_calculation === "total") {
        calcTotal = this.cart.total_price;
      } else {
        calcTotal = this.cart.original_total_price;
      }
      this.cart.progress_bar_remaining = this.progress_bar_threshold * (+window.Shopify.currency.rate || 1) - calcTotal;
      this.cart.progress_bar_percent = calcTotal / (this.progress_bar_threshold * (+window.Shopify.currency.rate || 1)) * 100 + "%";
      setTimeout(() => {
        this.cart_loading = false;
      }, 200);
      const cartUpsells = document.querySelectorAll(".js-upsell");
      cartUpsells.forEach(function(target) {
        target.style.display = "flex";
      });
      this.cart.items.forEach((item) => {
        const upsellElements = document.querySelectorAll(".js-upsell-" + item.product_id);
        upsellElements.forEach((element) => {
          element.style.display = "none";
        });
      });
      if (openCart) {
        this.menu_drawer = false;
        this.age_popup = false;
        this.audio_popup = false;
        this.discount_popup = false;
        this.filter_popup = false;
        this.localization_popup = false;
        this.guarantee_popup = false;
        let cart_behavior;
        if (window.innerWidth < 768) {
          cart_behavior = this.cart_behavior_mobile;
        } else {
          cart_behavior = this.cart_behavior_desktop;
        }
        switch (cart_behavior) {
          case "alert":
            if (this.cart_drawer) {
              return;
            } else {
              this.cart_alert = true;
              this.cart.alert_delay = "0%";
              setTimeout(() => {
                this.cart.alert_delay = "100%";
              }, 100);
              setTimeout(() => {
                this.cart.alert_delay = "0%";
                this.cart_alert = false;
              }, 4100);
            }
            break;
          case "drawer":
            this.cart_drawer = true;
            break;
          case "redirect":
            window.location.href = "/cart";
            break;
        }
      }
    } catch (error2) {
      console.error("Error:", error2);
      this.cart_loading = false;
    }
  },
  // Call change.js to update cart item then use updateCart()
  async changeCartItemQuantity(key, quantity, openCart, refresh) {
    this.playAudioIfEnabled(this.click_audio);
    this.cart_loading = true;
    let formData = {
      id: key.toString(),
      quantity: quantity.toString()
    };
    try {
      const response = await fetch(`${window.Shopify.routes.root}cart/change.js`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data2 = await response.json();
      this.cart.items = data2.items.map((item) => ({ ...item }));
      if (refresh) {
        window.location.reload();
      } else {
        this.playAudioIfEnabled(this.success_audio);
        this.updateCart(openCart);
      }
    } catch (error2) {
      console.error("Error:", error2);
      this.cart_loading = false;
    }
  },
  // Load quick add with section render
  async fetchAndRenderQuickEdit(product_handle, template, variantId, quantity) {
    this.edit_variant = variantId;
    this.edit_quantity = quantity;
    this.quick_edit_handle = product_handle;
    try {
      const response = await fetch(
        `${window.Shopify.routes.root}products/${product_handle}?section_id=quick-edit`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseHtml = await response.text();
      this.enable_body_scrolling = false;
      const quickAddContainer = document.getElementById(`js-quickEdit-${template}-${product_handle}`);
      if (quickAddContainer) {
        this.quick_edit_popup = true;
        quickAddContainer.innerHTML = responseHtml;
      } else {
        console.error(`Element 'js-quickEdit-${template}-${product_handle}' not found.`);
      }
    } catch (error2) {
      console.error(error2);
    }
  },
  // Load quick add with section render
  async fetchAndRenderQuickAdd(product_handle, template) {
    this.quick_add_handle = product_handle;
    try {
      const response = await fetch(
        `${window.Shopify.routes.root}products/${product_handle}?section_id=quick-add`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseHtml = await response.text();
      this.enable_body_scrolling = false;
      const quickAddContainer = document.getElementById(`js-quickAdd-${template}-${product_handle}`);
      if (quickAddContainer) {
        this.quick_add_popup = true;
        quickAddContainer.innerHTML = responseHtml;
      } else {
        console.error(`Element 'js-quickAdd-${template}-${product_handle}' not found.`);
      }
    } catch (error2) {
      console.error(error2);
    }
  },
  // Call add.js to add cart item then use updateCart()
  async editCartItem(oldQuantity, oldVariantId, newVariantId, sellingPlanId) {
    this.cart_loading = true;
    this.enable_body_scrolling = true;
    this.playAudioIfEnabled(this.click_audio);
    let oldFormData = {
      id: oldVariantId.toString(),
      quantity: "0"
    };
    let newFormData = sellingPlanId == 0 ? {
      id: newVariantId.toString(),
      quantity: oldQuantity.toString()
    } : {
      id: newVariantId.toString(),
      quantity: oldQuantity.toString(),
      selling_plan: sellingPlanId.toString()
    };
    try {
      const oldResponse = await fetch(`${window.Shopify.routes.root}cart/change.js`, {
        method: "POST",
        body: JSON.stringify(oldFormData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!oldResponse.ok) {
        throw new Error(`HTTP error! status: ${oldResponse.status}`);
      }
      const addResponse = await fetch(`${window.Shopify.routes.root}cart/add.js`, {
        method: "POST",
        body: JSON.stringify(newFormData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (!addResponse.ok) {
        throw new Error(`HTTP error! status: ${addResponse.status}`);
      }
      const data2 = await addResponse.json();
      if (addResponse.status === 200) {
        this.playAudioIfEnabled(this.success_audio);
        this.updateCart(false);
      } else {
        this.error_message = data2.description;
        this.show_alert = true;
      }
    } catch (error2) {
      console.error("Error:", error2);
      this.cart_loading = false;
    }
  },
  // Add multiple items to cart, used for cart sharing
  async addCartItems(items) {
    this.cart_loading = true;
    this.playAudioIfEnabled(this.click_audio);
    for (const item of items) {
      await this.addCartItem(item.variantId, 0, item.quantity, false, false);
    }
    this.cart_loading = false;
    this.updateCart(true);
    this.playAudioIfEnabled(this.success_audio);
  },
  // Call add.js to add cart item then use updateCart()
  async addCartItem(variantID, sellingPlanId, quantity, openCart) {
    this.playAudioIfEnabled(this.click_audio);
    let formData;
    if (sellingPlanId == 0) {
      formData = {
        items: [
          {
            id: variantID,
            quantity
          }
        ]
      };
    } else {
      formData = {
        items: [
          {
            id: variantID,
            quantity,
            selling_plan: sellingPlanId
          }
        ]
      };
    }
    return fetch(`${window.Shopify.routes.root}cart/add.js`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    }).then(async (response) => {
      let data2 = await response.json();
      if (response.status === 200) {
        this.playAudioIfEnabled(this.success_audio);
        this.updateCart(openCart);
      } else {
        this.error_message = data2.description, this.error_alert = true;
      }
    }).catch((error2) => {
      console.error("Error:", error2);
      this.cart_loading = false;
    });
  },
  // Add cart item by submitting form
  submitCartForm(form) {
    this.cart_loading = true;
    this.enable_body_scrolling = true;
    this.playAudioIfEnabled(this.click_audio);
    let formData = new FormData(form);
    let propertiesObj = Array.from(formData.entries()).filter(([key]) => key.includes("properties")).reduce((obj, [key, value]) => {
      let name = key.replace("properties[", "").replace("]", "");
      obj[name] = value;
      return obj;
    }, {});
    if (Object.keys(propertiesObj).length > 0) {
      for (const [key, value] of Object.entries(propertiesObj)) {
        formData.append(`properties[${key}]`, value);
      }
    }
    for (let pair of formData.entries()) {
      if (pair[0] === "selling_plan" && pair[1] === "0") {
        formData.delete(pair[0]);
      }
    }
    fetch(`${window.Shopify.routes.root}cart/add.js`, {
      method: "POST",
      body: formData
    }).then(async (response) => {
      let data2 = await response.json();
      if (response.status === 200) {
        this.playAudioIfEnabled(this.success_audio);
        this.updateCart(true);
      } else {
        this.error_message = data2.description;
        this.error_alert = true;
      }
    }).catch((error2) => {
      console.error("Error:", error2);
      this.cart_loading = false;
    });
  },
  // Display discount alert if  URL parameters contain '/discount'
  // e.g. - .com/discount/13KS94BNGCS8?dt=Save+20percent+storewide
  handleSharedDiscount() {
    const discountCode = this.getCookie("discount_code");
    const urlParams = new URLSearchParams(window.location.search);
    const discountText = urlParams.get("dt");
    if (discountText) {
      this.discount_code = discountCode;
      this.discount_text = discountText;
      this.discount_popup = true;
    }
  },
  // Add items to cart if cartshare url available
  handleSharedCart() {
    if (location.search.includes("cartshare")) {
      const query = location.search.substring(1);
      const queryArray = query.split("&");
      const itemsArray = queryArray.map((item) => {
        if (item) {
          const properties = item.split(",");
          const obj = {};
          for (const property of properties) {
            const tup = property.split(":");
            obj[tup[0]] = tup[1];
          }
          return obj;
        }
        return null;
      }).filter(Boolean);
      const itemsObject = itemsArray.map((obj) => ({ variantId: Number(obj.id), quantity: Number(obj.q) || 1 }));
      this.addCartItems(itemsObject);
    }
  },
  // Generate url with query string based on cart contents
  generateUrl() {
    const serialize = (obj) => {
      return Object.entries(obj).reduce((str, [key, value]) => str.concat(`${encodeURIComponent(key)}:${encodeURIComponent(value.toString())},`), "").slice(0, -1);
    };
    const filteredCart = this.cart.items.map((item) => {
      return {
        cartshare: true,
        id: item.variant_id,
        q: item.quantity
      };
    });
    const queryString = filteredCart.map(serialize).join("&");
    return `${window.location.origin}?${queryString}`;
  }
};
const search = {
  // Fetch search suggestions and update alpine variables
  async fetchAndUpdateSearch(event, params, resources) {
    this.search_focus_index = "";
    this.search_focus_url = "";
    const buildParams = () => {
      return Object.entries(params).reduce((acc, [key, value]) => value ? acc.concat(key) : acc, []).join();
    };
    const buildResources = () => {
      let resourceArr = [];
      for (const [key, value] of Object.entries(resources)) {
        if (value) {
          resourceArr.push(key.toString());
        }
      }
      const resourcesString = resourceArr.join();
      return resourcesString;
    };
    this.search_term = event.target.value.trim();
    this.search_loading = true;
    if (!this.search_term) {
      this.search_loading = false;
      this.search_items = [];
      this.search_items_collections = [];
      this.search_items_pages = [];
      this.search_items_articles = [];
      this.search_items_queries = [];
      return;
    }
    const requestUrl = `${window.Shopify.routes.root}search/suggest.json?q=${this.search_term}&resources[type]=${buildResources()}&resources[limit]=6&[options][fields]=${buildParams()}`;
    try {
      const response = await fetch(requestUrl);
      if (!response.ok) {
        throw new Error(response.status.toString());
      }
      const data2 = await response.json();
      const { products: products2, collections: collections2, pages, articles, queries } = data2.resources.results;
      this.search_items = products2 ? products2 : [];
      this.search_items_collections = collections2 ? collections2 : [];
      this.search_items_pages = pages ? pages : [];
      this.search_items_articles = articles ? articles : [];
      this.search_items_queries = queries ? queries : [];
      this.search_loading = false;
    } catch (error2) {
      this.error_message = error2.description;
      this.error_alert = true;
    } finally {
      this.search_loading = false;
      this.search_focus_index = "";
      this.search_focus_url = "";
    }
  },
  // Get total search results
  getSearchItems() {
    const totalResults = [
      ...this.search_items_queries,
      ...this.search_items_pages,
      ...this.search_items_articles,
      ...this.search_items_collections,
      ...this.search_items
    ];
    return totalResults;
  },
  // Update selected search index when using arrow keys
  updateSelectedSearch(increment) {
    const searchItems = this.getSearchItems();
    if (this.search_focus_index === "") {
      this.search_focus_index = 0;
    } else {
      this.search_focus_index = (this.search_focus_index + increment + searchItems.length) % searchItems.length;
    }
    this.search_focus_url = searchItems[this.search_focus_index].url;
  },
  // Go to selected search item when using arrow keys
  goToSelectedItem(formElement) {
    if (this.search_focus_url === "") {
      formElement.submit();
    } else {
      window.location.href = this.search_focus_url;
    }
  }
};
const products = {
  // Update page when variant selection changes
  handleProductFormChange(enableUrlParameters, preselectedVariantId) {
    this.setOptionsFromPreselectedVariantId(preselectedVariantId);
    let selectedVariant = this.setSelectedVariant();
    this.setUnavailableOptions();
    this.setDefaultsFromSelectedVariant(selectedVariant);
    this.setallOptionsSelected();
    this.reorderProductGallery();
    this.fetchAndRefreshPickup();
    if (enableUrlParameters && this.all_options_selected) {
      this.updateUrlParameters();
    }
    this.calculated_price = this.quantity * this.current_variant_price;
  },
  // If using combined listing this loads and sets title, description and images switching between products
  async handleCombinedListing(productURL, sectionId) {
    try {
      const response = await fetch(
        `${productURL}?section_id=${sectionId}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseHtml = await response.text();
      const html = new DOMParser().parseFromString(responseHtml, "text/html");
      const oldSection = document.querySelector(".js-product");
      if (oldSection) {
        const newSection = html.querySelector(".js-product");
        oldSection.parentNode.insertBefore(newSection, oldSection);
        oldSection.remove();
      }
    } catch (error2) {
      console.error(error2);
    }
  },
  // Find options that are not available based on selected options
  setUnavailableOptions() {
    const findMatchingVariants = (excludeOptionIndex) => {
      return this.product.variants.filter((variant) => {
        if (variant.available) {
          return false;
        }
        for (let i = 1; i <= this.product.options.length; i++) {
          if (i === excludeOptionIndex) continue;
          const optionKey = `option${i}`;
          if (variant[optionKey].toLowerCase() !== this[`option${i}`].toLowerCase()) {
            return false;
          }
        }
        return true;
      });
    };
    for (let i = 1; i <= this.product.options.length; i++) {
      const matchingVariants = findMatchingVariants(i);
      const unavailableOptionsSet = /* @__PURE__ */ new Set();
      matchingVariants.forEach((variant) => {
        const optionKey = `option${i}`;
        unavailableOptionsSet.add(this.handleize(variant[optionKey]));
      });
      this[`unavailable_options${i}`] = Array.from(unavailableOptionsSet);
    }
  },
  // Set selectedVariant based on selected options
  // This will find the selectedVariant based on selected options
  setSelectedVariant() {
    let optionsSize = this.product.options.length;
    let selectedVariant;
    switch (optionsSize) {
      case 1:
        selectedVariant = this.product.variants.find(
          (variant) => this.handleize(variant.option1) === this.handleize(this.option1)
        );
        break;
      case 2:
        selectedVariant = this.product.variants.find(
          (variant) => this.handleize(variant.option1) === this.handleize(this.option1) && this.handleize(variant.option2) === this.handleize(this.option2)
        );
        break;
      case 3:
        selectedVariant = this.product.variants.find(
          (variant) => this.handleize(variant.option1) === this.handleize(this.option1) && this.handleize(variant.option2) === this.handleize(this.option2) && this.handleize(variant.option3) === this.handleize(this.option3)
        );
        break;
    }
    return selectedVariant;
  },
  // Check if preselectedVariantId exists and set options
  setOptionsFromPreselectedVariantId(preselectedVariantId) {
    let optionsSize = this.product.options.length;
    if (preselectedVariantId) {
      this.current_variant_id = preselectedVariantId;
      const selectedVariant = this.product.variants.find(
        (variant) => variant.id === preselectedVariantId
      );
      if (selectedVariant) {
        switch (optionsSize) {
          case 1:
            this.option1 = this.handleize(selectedVariant.option1);
            break;
          case 2:
            this.option1 = this.handleize(selectedVariant.option1);
            this.option2 = this.handleize(selectedVariant.option2);
            break;
          case 3:
            this.option1 = this.handleize(selectedVariant.option1);
            this.option2 = this.handleize(selectedVariant.option2);
            this.option3 = this.handleize(selectedVariant.option3);
            break;
        }
      }
    }
  },
  // Update values based on selected variant
  setDefaultsFromSelectedVariant(selectedVariant) {
    let formContainer = this.$refs.formContainer;
    if (selectedVariant) {
      this.current_variant_available = selectedVariant.available;
      this.current_variant_exists = true;
      this.current_variant_id = selectedVariant.id;
      this.current_variant_price = selectedVariant.price;
      this.current_variant_compare_price = selectedVariant.compare_at_price;
      this.current_variant_sku = selectedVariant.sku;
      this.current_variant_title = selectedVariant.title;
      const customSelectedVariant = this.variants[this.current_variant_id];
      if (customSelectedVariant && customSelectedVariant.length > 0) {
        this.current_variant_inventory_quantity = customSelectedVariant[0].inventory_quantity;
        this.current_variant_inventory_policy = customSelectedVariant[0].inventory_policy;
      }
      this.current_variant_featured_image_id = selectedVariant.featured_image ? selectedVariant.featured_image.id : null;
      this.current_variant_featured_media_id = selectedVariant.featured_media ? selectedVariant.featured_media.id : null;
      if (selectedVariant.unit_price) {
        this.current_variant_unit_price = selectedVariant.unit_price;
        this.current_variant_unit_label = selectedVariant.unit_price_measurement.reference_unit;
      }
      this.current_variant_has_selling_plan = Array.isArray(selectedVariant.selling_plan_allocations) && selectedVariant.selling_plan_allocations.length > 0;
      if (this.current_variant_has_selling_plan && this.enable_selling_plan_widget) {
        this.current_variant_requires_selling_plan = selectedVariant.requires_selling_plan;
        this.current_variant_selling_group_ids = selectedVariant.selling_plan_allocations.map((allocation) => allocation.selling_plan_group_id);
        this.current_variant_selling_group_ids.push("0");
        this.current_variant_selling_group_id = this.current_variant_selling_group_ids.includes(this.current_variant_selling_group_id) ? this.current_variant_selling_group_id : this.current_variant_selling_group_ids[0];
        let matchingAllocation = selectedVariant.selling_plan_allocations.find(
          (allocation) => allocation.selling_plan_group_id === this.current_variant_selling_group_id && allocation.selling_plan_id === parseInt(this.current_variant_selling_plan_id)
        );
        if (!matchingAllocation) {
          const firstAllocationInGroup = selectedVariant.selling_plan_allocations.find(
            (allocation) => allocation.selling_plan_group_id === this.current_variant_selling_group_id
          );
          if (firstAllocationInGroup) {
            matchingAllocation = firstAllocationInGroup;
            this.current_variant_selling_plan_id = firstAllocationInGroup.selling_plan_id;
          }
        }
        if (matchingAllocation) {
          this.defaultSellingPlanPrice = matchingAllocation.per_delivery_price;
          this.current_variant_price = matchingAllocation.per_delivery_price;
          this.current_variant_compare_price = matchingAllocation.compare_at_price;
          this.current_variant_unit_price = matchingAllocation.unit_price;
        }
        if (this.current_variant_selling_plan_id !== 0) {
          let sellingPlanInput = formContainer.querySelector(".js-" + this.current_variant_selling_plan_id);
          let sellingPlanData = JSON.parse(sellingPlanInput.getAttribute("data-selling-plan"));
          this.current_variant_selling_plan_name = sellingPlanData.name.trim() + ".";
          this.current_variant_selling_plan_description = sellingPlanData.description.trim();
          let savingSummary = "";
          let savingHighlight = "";
          sellingPlanData.price_adjustments.forEach((price_adjustment, index, array) => {
            let savingValue = price_adjustment.value;
            if (savingValue <= 0) return;
            let savingsPercentLabel = "";
            let savingsCount = price_adjustment.order_count || "ongoing";
            let punctuation = index === array.length - 1 ? ". " : "";
            let sentenceStart = "Save ";
            switch (price_adjustment.value_type) {
              case "percentage":
                savingsPercentLabel = "%";
                break;
              case "price":
                savingValue = Shopify.formatMoney(sellingPlanData.compare_at_price - savingValue);
                sentenceStart = "";
                savingHighlight = `Save ${savingValue}${savingsPercentLabel}`;
                break;
              case "fixed_amount":
                savingValue = Shopify.formatMoney(savingValue);
                break;
            }
            savingSummary += `${sentenceStart}${savingValue}${savingsPercentLabel} for ${savingsCount} orders${punctuation}`;
            if (index === 0) {
              savingHighlight = `Save ${savingValue}${savingsPercentLabel}`;
            }
          });
          this.current_variant_selling_plan_savings_description = savingSummary;
          this.current_variant_selling_plan_savings_summary = savingHighlight;
        }
        if (this.current_variant_selling_group_id == "0") {
          this.current_variant_selling_plan_id = 0;
        }
      }
    } else {
      this.current_variant_exists = false;
    }
  },
  // Update all_options_selected if all options are selected
  setallOptionsSelected() {
    let optionsSize = this.product.options.length;
    this.all_options_selected = optionsSize === 1 && this.option1 || optionsSize === 2 && this.option1 && this.option2 || optionsSize === 3 && this.option1 && this.option2 && this.option3;
    if (this.current_variant_has_only_default_variant === true) {
      this.all_options_selected = true;
    }
  },
  // Update order of product gallery images
  reorderProductGallery() {
    let formContainer = this.$refs.formContainer;
    if (this.enable_variant_images) {
      setTimeout(() => {
        this.galleryScrollToStart(0);
      }, 100);
    } else {
      const featuredImages = formContainer.querySelectorAll(".js-" + this.current_variant_featured_media_id);
      if (featuredImages.length > 0) {
        const slideIndex = featuredImages[0].getAttribute("data-slide");
        if (slideIndex) {
          this.galleryScrollToIndex(parseInt(slideIndex));
        } else {
          const parentElement = featuredImages[0].parentNode;
          if (parentElement) {
            parentElement.insertBefore(featuredImages[0], parentElement.firstChild);
          }
        }
      }
    }
  },
  // Add variant id to URL parameters
  updateUrlParameters() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("variant", this.current_variant_id);
    const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
    history.replaceState(null, "", newRelativePathQuery);
  },
  // Refresh pickup availability block
  fetchAndRefreshPickup() {
    const formContainer = this.$refs.formContainer;
    const pickupContainer = formContainer.querySelector(".js-pickup");
    if (pickupContainer) {
      fetch(window.location + "&section_id=product__pickup").then(async (response) => {
        const data2 = await response.text();
        if (response.status === 200) {
          const html = document.createElement("div");
          html.innerHTML = data2;
          const htmlCleaned = html.querySelector(".js-pickup");
          if (htmlCleaned) {
            pickupContainer.innerHTML = htmlCleaned.innerHTML;
          }
        } else {
          console.error("Error:", error);
        }
      }).catch((error2) => {
        console.error("Error:", error2);
      });
    }
  },
  // Scroll to next gallery item
  galleryScrollNext() {
    this.galleryResetZoom();
    this.gallery_next = this.gallery_index + 1;
    if (this.gallery_next > this.gallery_size) {
      this.gallery_next = 0;
    }
    this.galleryScrollToIndex(this.gallery_next);
  },
  // Scroll to previous gallery item
  galleryScrollBack() {
    this.galleryResetZoom();
    this.gallery_next = this.gallery_index - 1;
    if (this.gallery_next < 0) {
      this.gallery_next = this.gallery_size;
    }
    this.galleryScrollToIndex(this.gallery_next);
  },
  // Scroll to a specific gallery item
  galleryScrollToIndex(index) {
    this.galleryResetZoom();
    let formContainer = this.$refs.formContainer;
    let slider = formContainer.querySelector(".js-slider");
    let thumbnailSlider = formContainer.querySelector(".js-thumbnailSlider");
    let zoomSlider = formContainer.querySelector(".js-zoomSlider");
    let currentSlide = slider.querySelector('[data-slide="' + index + '"]');
    if (currentSlide) {
      let currentSlidePosition = currentSlide.offsetLeft;
      slider.scrollTo({
        top: 0,
        left: currentSlidePosition,
        behavior: "smooth"
      });
    }
    if (thumbnailSlider) {
      let currentThumb = thumbnailSlider.querySelector('[data-slide="' + index + '"]');
      if (currentThumb) {
        let currentThumbPosition = currentThumb.offsetTop;
        thumbnailSlider.scrollTo({
          top: currentThumbPosition - 200,
          left: 0,
          behavior: "smooth"
        });
      }
    }
    setTimeout(() => {
      if (zoomSlider) {
        let currentSlide2 = zoomSlider.querySelector('[data-slide="' + index + '"]');
        let currentSlidePosition = currentSlide2.offsetLeft;
        if (currentSlide2) {
          zoomSlider.scrollTo({
            top: 0,
            left: currentSlidePosition,
            behavior: "smooth"
          });
        }
      }
    }, 100);
    this.gallery_index = index;
  },
  // Scroll to start of gallery slider
  galleryScrollToStart() {
    this.galleryResetZoom();
    let formContainer = this.$refs.formContainer;
    let slider = formContainer.querySelector(".js-slider");
    let thumbnailSlider = formContainer.querySelector(".js-thumbnailSlider");
    slider.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (thumbnailSlider) {
      thumbnailSlider.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
    this.gallery_index = 0;
  },
  // Unzoom all images
  galleryResetZoom() {
    for (let i = 0; i < this.gallery_size; i++) {
      this["gallery_zoom_" + i] = false;
    }
  },
  galleryZoomIn() {
    this["gallery_zoom_" + this.gallery_index] = true;
  },
  galleryZoomOut() {
    this["gallery_zoom_" + this.gallery_index] = false;
  }
};
const collections = {
  // Call section render API with data from filter
  async fetchAndRenderCollection(filterData, closePopup) {
    let activeElement = document.activeElement;
    let parentContainer = activeElement ? activeElement.closest(".popup__content--container") : null;
    let scrollPosition = parentContainer ? parentContainer.scrollTop : 0;
    const element = document.getElementById("body");
    if (element && !closePopup) {
      element.scrollIntoView();
    }
    const filterUrl = this.buildUrlFilter(filterData);
    let searchUrl = new URL(location.href).searchParams.get("q");
    searchUrl = searchUrl ? `&q=${searchUrl}` : "";
    history.pushState(
      null,
      "",
      `${window.location.pathname}?${filterUrl}${searchUrl}`
    );
    window.addEventListener("popstate", () => {
      this.fetchAndRenderCollection(filterData);
    });
    try {
      const response = await fetch(
        `${window.location.pathname}?section_id=${this.pagination_section}${filterUrl}${searchUrl}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data2 = await response.text();
      const sectionElement = document.getElementById(`shopify-section-${this.pagination_section}`);
      if (sectionElement) {
        sectionElement.innerHTML = data2;
        setTimeout(function() {
          sectionElement.querySelector(".popup__content--container").scrollTop = scrollPosition;
        }, 0);
      }
      this.pagination_loading = false;
      if (closePopup) {
        this.filter_popup = false;
      }
    } catch (error2) {
      console.error("Error:", error2);
      this.pagination_loading = false;
    }
  },
  // Check if next page is avaible and inject more products
  async fetchAndRenderNextPage() {
    this.pagination_loading = true;
    const filter = document.getElementById("js-desktopFilter");
    const pageUrl = `&page=${this.pagination_current_page + 1}`;
    const searchUrl = new URL(location.href).searchParams.get("q") ? `&q=${new URL(location.href).searchParams.get("q")}` : "";
    let fetchUrl = `${window.location.pathname}?section_id=${this.pagination_section}${pageUrl}${searchUrl}`;
    if (filter) {
      const filterData = new FormData(filter);
      const filterUrl = this.buildUrlFilter(filterData);
      fetchUrl += filterUrl;
    }
    if (this.pagination_current_page < this.pagination_total_pages) {
      try {
        const response = await fetch(fetchUrl);
        const data2 = await response.text();
        const tempElement = document.createElement("div");
        tempElement.innerHTML = data2;
        const fetchedElement = tempElement.querySelector("#js-results");
        if (fetchedElement) {
          const resultsElement = document.getElementById("js-results");
          if (resultsElement) {
            resultsElement.insertAdjacentHTML("beforeend", fetchedElement.innerHTML);
          }
        }
        this.pagination_current_page += 1;
        this.pagination_loading = false;
      } catch (error2) {
        console.error("Error:", error2);
        this.pagination_loading = false;
      }
    } else {
      this.pagination_loading = false;
    }
  },
  // Handle filter changes on price
  handlePriceFilterChange(filterType) {
    const { filter_min_price, filter_max_price, filter_min, filter_max } = this;
    const priceRange = filter_max - filter_min;
    if (filterType === "max") {
      this.filter_max_price = Math.max(filter_max_price, filter_min_price);
      this.filter_max_thumb = 100 - (this.filter_max_price - filter_min) / priceRange * 100;
    } else if (filterType === "min") {
      this.filter_min_price = Math.min(filter_min_price, filter_max_price);
      this.filter_min_thumb = (this.filter_min_price - filter_min) / priceRange * 100;
    } else {
      console.error('Invalid filter type. Expected "min" or "max".');
    }
  },
  // Handle filter change
  handleFilterChange(id) {
    this.pagination_loading = true;
    this.pagination_current_page = 1;
    const filter = document.getElementById(id);
    if (filter) {
      const filterData = new FormData(filter);
      this.fetchAndRenderCollection(filterData);
    } else {
      console.error("Filter element not found.");
    }
  },
  // Handle deleting filters
  handleFilterDelete(filterToReset) {
    this.pagination_loading = true;
    const filter = document.getElementById("js-desktopFilter");
    if (filter) {
      const filterData = new FormData(filter);
      filterData.delete(filterToReset);
      if (filterToReset.indexOf("price") !== -1) {
        filterData.delete("filter.v.price.gte");
        filterData.delete("filter.v.price.lte");
        this.filter_min_price = this.filter_min;
        this.filter_max_price = this.filter_max;
      }
      this.fetchAndRenderCollection(filterData);
    } else {
      console.error("Filter element 'js-desktopFilter' not found.");
    }
  },
  // Handle deleting all filters
  handleFilterDeleteAll: function() {
    this.pagination_loading = true;
    let filterData = new FormData();
    this.filter_min_price = this.filter_min;
    this.filter_max_price = this.filter_max;
    this.fetchAndRenderCollection(filterData);
  },
  // Build urlFilter
  buildUrlFilter(filterData) {
    let urlFilter = "";
    for (let pair of filterData.entries()) {
      const [key, value] = pair;
      if (key.includes("price")) {
        if (key === "filter.v.price.lte" && value < this.filter_max) {
          urlFilter += `&${key}=${value}`;
        }
        if (key === "filter.v.price.gte" && value > this.filter_min) {
          urlFilter += `&${key}=${value}`;
        }
      } else {
        urlFilter += `&${key}=${encodeURIComponent(value)}`;
      }
    }
    return urlFilter;
  }
};
const utils = {
  // Initiate animation setup - classes will swap when elements scroll into view
  initAnimationObserver() {
    const observerCallback = function(entries) {
      entries.forEach((entry) => {
        let element = document.getElementById(entry.target.dataset.id);
        if (entry.isIntersecting) {
          const delay3 = entry.target.dataset.delay || "";
          let replaceClasses;
          try {
            replaceClasses = JSON.parse(
              entry.target.dataset.replace.replace(/'/g, '"')
            );
          } catch (error2) {
            console.error("Error parsing replaceClasses:", error2);
            return;
          }
          const callback = entry.target.dataset.callback;
          if (callback && window[callback] && typeof window[callback] === "function") {
            window[callback]();
          }
          Object.keys(replaceClasses).forEach(function(key) {
            setTimeout(function() {
              if (element) {
                element.classList.remove(key);
                if (replaceClasses[key]) {
                  element.classList.add(replaceClasses[key]);
                }
              } else {
                entry.target.classList.remove(key);
                if (replaceClasses[key]) {
                  entry.target.classList.add(replaceClasses[key]);
                }
              }
            }, parseInt(delay3, 10));
          });
        }
      });
    };
    const animationElements = document.querySelectorAll(".js-animation");
    if (animationElements.length > 0) {
      const animationObserver = new IntersectionObserver(observerCallback);
      animationElements.forEach(function(target) {
        animationObserver.observe(target);
      });
    }
  },
  // set query params
  // NEEDS TO GET REWORKED
  setQuery: (query) => {
    if (query === "") {
      window.history.replaceState({}, "", window.location.pathname);
      return;
    } else {
      const path = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      const hash = window.location.hash;
      params.set("edit", query);
      window.history.replaceState({}, "", `${path}?${params.toString()}${hash}`);
    }
  },
  // Refresh when using back button when history states were changed
  initPopstate() {
    window.addEventListener("popstate", async () => {
      window.location.href = location.href;
    });
  },
  debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  },
  // Match to liquid handle filter
  handleize(str) {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9\p{L}\p{N}]+/gu, "-").replace(/-+/g, "-").replace(/^-+|-+$/g, "");
  },
  // Add classes to images after loading
  loadImages() {
    const loadImage = (img) => {
      var _a, _b;
      img.classList.add("loaded");
      (_b = (_a = img.parentElement) == null ? void 0 : _a.parentElement) == null ? void 0 : _b.classList.add("loaded");
    };
    const images = document.querySelectorAll("img.js-image");
    images.forEach((img) => {
      const imageElement = img;
      if (imageElement.complete) {
        loadImage(imageElement);
      } else {
        imageElement.onload = () => {
          loadImage(imageElement);
        };
      }
      setTimeout(() => {
        loadImage(imageElement);
      }, 2e3);
    });
  },
  // Copy value of input to clipboard and focus element
  copyToClipboard(id) {
    const copyText = document.getElementById(id);
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      try {
        navigator.clipboard.writeText(copyText.value);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    } else {
      console.error("Element not found: ", id);
    }
  },
  // Get cookie by name
  getCookie(name) {
    const cookieMatch = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
    return cookieMatch ? cookieMatch.pop() : null;
  },
  // Handle audio playing
  playAudioIfEnabled(audioFile) {
    if (this.enable_audio) {
      const audio = new Audio(audioFile);
      if (window.innerWidth > 768) {
        audio.play();
      }
    }
  },
  // Open menu drawer
  openMenu() {
    this.menu_drawer = true;
    this.enable_body_scrolling = false;
    this.playAudioIfEnabled(this.click_audio);
  },
  // Open cart drawer
  openCart() {
    this.cart_drawer = true;
    this.cart_alert = false;
    this.enable_body_scrolling = false;
    this.playAudioIfEnabled(this.click_audio);
  },
  // Open search drawer
  openSearch() {
    this.menu_drawer = false;
    this.search_drawer = true;
    this.enable_body_scrolling = false;
    setTimeout(() => {
      let searchField = document.querySelector("#search-field");
      if (searchField) {
        searchField.focus();
      }
    }, 400);
  },
  // Close cart drawer
  closeCart() {
    this.cart_drawer = false;
    this.cart_alert = false;
    this.enable_body_scrolling = true;
  },
  // Close menu drawer
  closeMenu() {
    this.menu_drawer = false;
    this.enable_body_scrolling = true;
  },
  // Close menu drawer
  closeSearch() {
    this.search_drawer = false;
    this.enable_body_scrolling = true;
  },
  // Initiate header scrolling (unless in preview mode)
  initScroll: function() {
    if (!Shopify.visualPreviewMode) {
      const body = document.querySelector("body");
      body.setAttribute("x-on:scroll.window", `() => {
        if (window.pageYOffset > 400) {
          is_scrolled = window.pageYOffset > prev_scroll_pos ? true : false; 
          prev_scroll_pos = window.pageYOffset;
          $refs.header.style.transform = 'none'
        } else {
          is_scrolled = false;
          prev_scroll_pos = window.pageYOffset;
          $refs.header.style.transform = 'none'
        }
      }`);
      body.setAttribute("x-intersect.half:enter", "scroll_up = false");
      body.setAttribute("x-intersect.half:leave", "scroll_up = true");
    }
  },
  // Check line items values
  // REWORK
  checkLineItems(handle) {
    const inputs = document.querySelectorAll(
      `.custom-input_${handle}`
    );
    const inputsArr = Array.from(inputs);
    const requiredFields = inputsArr.filter((inp) => inp.required);
    if (requiredFields.some((field) => field.value === "")) {
      this.incomplete_fields = true;
    } else {
      this.incomplete_fields = false;
    }
  }
};
let Shopify$1 = window.Shopify ?? {};
function formatMoney(cents, forceEnableCurrency) {
  if (typeof cents == "string") {
    cents = cents.replace(".", "");
  }
  let value = "";
  let placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  let formatStringWithoutCurrency = globals.price_format_without_currency;
  let formatStringWithCurrency = globals.price_format_with_currency;
  let formatString = "";
  let enableZeros = globals.price_enable_zeros;
  let enableCurrency = globals.price_enable_currency;
  formatString = enableCurrency === false ? formatStringWithCurrency : formatStringWithoutCurrency;
  if (forceEnableCurrency === false) {
    formatString = formatStringWithCurrency;
  } else if (forceEnableCurrency === true) {
    formatString = formatStringWithoutCurrency;
  }
  function defaultOption(opt, def) {
    return typeof opt == "undefined" ? def : opt;
  }
  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ",");
    decimal = defaultOption(decimal, ".");
    if (isNaN(number) || number == null) {
      return 0;
    }
    number = (number / 100).toFixed(precision);
    var parts = number.split("."), dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + thousands), cents2 = parts[1] ? decimal + parts[1] : "";
    return dollars + cents2;
  }
  switch (formatString.match(placeholderRegex)[1]) {
    case "amount":
      value = formatWithDelimiters(cents, 2);
      break;
    case "amount_no_decimals":
      value = formatWithDelimiters(cents, 0);
      break;
    case "amount_with_comma_separator":
      value = formatWithDelimiters(cents, 2, ".", ",");
      break;
    case "amount_no_decimals_with_comma_separator":
      value = formatWithDelimiters(cents, 0, ".", ",");
      break;
  }
  value = formatString.replace(placeholderRegex, value);
  if (enableZeros === false) {
    value = value.replace(".00", "");
  }
  return value;
}
Shopify$1.formatMoney = formatMoney;
utils.loadImages();
window.app = function() {
  return {
    // Spread globals
    ...globals,
    ...cart,
    ...search,
    ...products,
    ...collections,
    ...utils,
    ...Shopify$1
  };
};
/*! instant.page v5.1.1 - (C) 2019-2020 Alexandre Dieulot - https://instant.page/license */
let mouseoverTimer;
let lastTouchTimestamp;
const prefetches = /* @__PURE__ */ new Set();
const prefetchElement = document.createElement("link");
const isSupported = prefetchElement.relList && prefetchElement.relList.supports && prefetchElement.relList.supports("prefetch") && window.IntersectionObserver && "isIntersecting" in IntersectionObserverEntry.prototype;
const allowQueryString = "instantAllowQueryString" in document.body.dataset;
const allowExternalLinks = "instantAllowExternalLinks" in document.body.dataset;
const useWhitelist = "instantWhitelist" in document.body.dataset;
const mousedownShortcut = "instantMousedownShortcut" in document.body.dataset;
const DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION = 1111;
let delayOnHover = 65;
let useMousedown = false;
let useMousedownOnly = false;
let useViewport = false;
if ("instantIntensity" in document.body.dataset) {
  const intensity = document.body.dataset.instantIntensity;
  if (intensity.substr(0, "mousedown".length) == "mousedown") {
    useMousedown = true;
    if (intensity == "mousedown-only") {
      useMousedownOnly = true;
    }
  } else if (intensity.substr(0, "viewport".length) == "viewport") {
    if (!(navigator.connection && (navigator.connection.saveData || navigator.connection.effectiveType && navigator.connection.effectiveType.includes("2g")))) {
      if (intensity == "viewport") {
        if (document.documentElement.clientWidth * document.documentElement.clientHeight < 45e4) {
          useViewport = true;
        }
      } else if (intensity == "viewport-all") {
        useViewport = true;
      }
    }
  } else {
    const milliseconds = parseInt(intensity);
    if (!isNaN(milliseconds)) {
      delayOnHover = milliseconds;
    }
  }
}
if (isSupported) {
  const eventListenersOptions = {
    capture: true,
    passive: true
  };
  if (!useMousedownOnly) {
    document.addEventListener("touchstart", touchstartListener, eventListenersOptions);
  }
  if (!useMousedown) {
    document.addEventListener("mouseover", mouseoverListener, eventListenersOptions);
  } else if (!mousedownShortcut) {
    document.addEventListener("mousedown", mousedownListener, eventListenersOptions);
  }
  if (mousedownShortcut) {
    document.addEventListener("mousedown", mousedownShortcutListener, eventListenersOptions);
  }
  if (useViewport) {
    let triggeringFunction;
    if (window.requestIdleCallback) {
      triggeringFunction = (callback) => {
        requestIdleCallback(callback, {
          timeout: 1500
        });
      };
    } else {
      triggeringFunction = (callback) => {
        callback();
      };
    }
    triggeringFunction(() => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const linkElement = entry.target;
            intersectionObserver.unobserve(linkElement);
            preload$1(linkElement.href);
          }
        });
      });
      document.querySelectorAll("a").forEach((linkElement) => {
        if (isPreloadable(linkElement)) {
          intersectionObserver.observe(linkElement);
        }
      });
    });
  }
}
function touchstartListener(event) {
  lastTouchTimestamp = performance.now();
  const linkElement = event.target.closest("a");
  if (!isPreloadable(linkElement)) {
    return;
  }
  preload$1(linkElement.href);
}
function mouseoverListener(event) {
  if (performance.now() - lastTouchTimestamp < DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION) {
    return;
  }
  if (!("closest" in event.target)) {
    return;
  }
  const linkElement = event.target.closest("a");
  if (!isPreloadable(linkElement)) {
    return;
  }
  linkElement.addEventListener("mouseout", mouseoutListener, { passive: true });
  mouseoverTimer = setTimeout(() => {
    preload$1(linkElement.href);
    mouseoverTimer = void 0;
  }, delayOnHover);
}
function mousedownListener(event) {
  const linkElement = event.target.closest("a");
  if (!isPreloadable(linkElement)) {
    return;
  }
  preload$1(linkElement.href);
}
function mouseoutListener(event) {
  if (event.relatedTarget && event.target.closest("a") == event.relatedTarget.closest("a")) {
    return;
  }
  if (mouseoverTimer) {
    clearTimeout(mouseoverTimer);
    mouseoverTimer = void 0;
  }
}
function mousedownShortcutListener(event) {
  if (performance.now() - lastTouchTimestamp < DELAY_TO_NOT_BE_CONSIDERED_A_TOUCH_INITIATED_ACTION) {
    return;
  }
  const linkElement = event.target.closest("a");
  if (event.which > 1 || event.metaKey || event.ctrlKey) {
    return;
  }
  if (!linkElement) {
    return;
  }
  linkElement.addEventListener("click", function(event2) {
    if (event2.detail == 1337) {
      return;
    }
    event2.preventDefault();
  }, { capture: true, passive: false, once: true });
  const customEvent = new MouseEvent("click", { view: window, bubbles: true, cancelable: false, detail: 1337 });
  linkElement.dispatchEvent(customEvent);
}
function isPreloadable(linkElement) {
  if (!linkElement || !linkElement.href) {
    return;
  }
  if (useWhitelist && !("instant" in linkElement.dataset)) {
    return;
  }
  if (!allowExternalLinks && linkElement.origin != location.origin && !("instant" in linkElement.dataset)) {
    return;
  }
  if (!["http:", "https:"].includes(linkElement.protocol)) {
    return;
  }
  if (linkElement.protocol == "http:" && location.protocol == "https:") {
    return;
  }
  if (!allowQueryString && linkElement.search && !("instant" in linkElement.dataset)) {
    return;
  }
  if (linkElement.hash && linkElement.pathname + linkElement.search == location.pathname + location.search) {
    return;
  }
  if ("noInstant" in linkElement.dataset) {
    return;
  }
  return true;
}
function preload$1(url) {
  if (prefetches.has(url)) {
    return;
  }
  const prefetcher = document.createElement("link");
  prefetcher.rel = "prefetch";
  prefetcher.href = url;
  document.head.appendChild(prefetcher);
  prefetches.add(url);
}
var flushPending = false;
var flushing = false;
var queue = [];
var lastFlushedIndex = -1;
function scheduler(callback) {
  queueJob(callback);
}
function queueJob(job) {
  if (!queue.includes(job))
    queue.push(job);
  queueFlush();
}
function dequeueJob(job) {
  let index = queue.indexOf(job);
  if (index !== -1 && index > lastFlushedIndex)
    queue.splice(index, 1);
}
function queueFlush() {
  if (!flushing && !flushPending) {
    flushPending = true;
    queueMicrotask(flushJobs);
  }
}
function flushJobs() {
  flushPending = false;
  flushing = true;
  for (let i = 0; i < queue.length; i++) {
    queue[i]();
    lastFlushedIndex = i;
  }
  queue.length = 0;
  lastFlushedIndex = -1;
  flushing = false;
}
var reactive;
var effect;
var release;
var raw;
var shouldSchedule = true;
function disableEffectScheduling(callback) {
  shouldSchedule = false;
  callback();
  shouldSchedule = true;
}
function setReactivityEngine(engine) {
  reactive = engine.reactive;
  release = engine.release;
  effect = (callback) => engine.effect(callback, { scheduler: (task) => {
    if (shouldSchedule) {
      scheduler(task);
    } else {
      task();
    }
  } });
  raw = engine.raw;
}
function overrideEffect(override) {
  effect = override;
}
function elementBoundEffect(el) {
  let cleanup2 = () => {
  };
  let wrappedEffect = (callback) => {
    let effectReference = effect(callback);
    if (!el._x_effects) {
      el._x_effects = /* @__PURE__ */ new Set();
      el._x_runEffects = () => {
        el._x_effects.forEach((i) => i());
      };
    }
    el._x_effects.add(effectReference);
    cleanup2 = () => {
      if (effectReference === void 0)
        return;
      el._x_effects.delete(effectReference);
      release(effectReference);
    };
    return effectReference;
  };
  return [wrappedEffect, () => {
    cleanup2();
  }];
}
function watch(getter, callback) {
  let firstTime = true;
  let oldValue;
  let effectReference = effect(() => {
    let value = getter();
    JSON.stringify(value);
    if (!firstTime) {
      queueMicrotask(() => {
        callback(value, oldValue);
        oldValue = value;
      });
    } else {
      oldValue = value;
    }
    firstTime = false;
  });
  return () => release(effectReference);
}
var onAttributeAddeds = [];
var onElRemoveds = [];
var onElAddeds = [];
function onElAdded(callback) {
  onElAddeds.push(callback);
}
function onElRemoved(el, callback) {
  if (typeof callback === "function") {
    if (!el._x_cleanups)
      el._x_cleanups = [];
    el._x_cleanups.push(callback);
  } else {
    callback = el;
    onElRemoveds.push(callback);
  }
}
function onAttributesAdded(callback) {
  onAttributeAddeds.push(callback);
}
function onAttributeRemoved(el, name, callback) {
  if (!el._x_attributeCleanups)
    el._x_attributeCleanups = {};
  if (!el._x_attributeCleanups[name])
    el._x_attributeCleanups[name] = [];
  el._x_attributeCleanups[name].push(callback);
}
function cleanupAttributes(el, names) {
  if (!el._x_attributeCleanups)
    return;
  Object.entries(el._x_attributeCleanups).forEach(([name, value]) => {
    if (names === void 0 || names.includes(name)) {
      value.forEach((i) => i());
      delete el._x_attributeCleanups[name];
    }
  });
}
function cleanupElement(el) {
  if (el._x_cleanups) {
    while (el._x_cleanups.length)
      el._x_cleanups.pop()();
  }
}
var observer = new MutationObserver(onMutate);
var currentlyObserving = false;
function startObservingMutations() {
  observer.observe(document, { subtree: true, childList: true, attributes: true, attributeOldValue: true });
  currentlyObserving = true;
}
function stopObservingMutations() {
  flushObserver();
  observer.disconnect();
  currentlyObserving = false;
}
var queuedMutations = [];
function flushObserver() {
  let records = observer.takeRecords();
  queuedMutations.push(() => records.length > 0 && onMutate(records));
  let queueLengthWhenTriggered = queuedMutations.length;
  queueMicrotask(() => {
    if (queuedMutations.length === queueLengthWhenTriggered) {
      while (queuedMutations.length > 0)
        queuedMutations.shift()();
    }
  });
}
function mutateDom(callback) {
  if (!currentlyObserving)
    return callback();
  stopObservingMutations();
  let result = callback();
  startObservingMutations();
  return result;
}
var isCollecting = false;
var deferredMutations = [];
function deferMutations() {
  isCollecting = true;
}
function flushAndStopDeferringMutations() {
  isCollecting = false;
  onMutate(deferredMutations);
  deferredMutations = [];
}
function onMutate(mutations) {
  if (isCollecting) {
    deferredMutations = deferredMutations.concat(mutations);
    return;
  }
  let addedNodes = /* @__PURE__ */ new Set();
  let removedNodes = /* @__PURE__ */ new Set();
  let addedAttributes = /* @__PURE__ */ new Map();
  let removedAttributes = /* @__PURE__ */ new Map();
  for (let i = 0; i < mutations.length; i++) {
    if (mutations[i].target._x_ignoreMutationObserver)
      continue;
    if (mutations[i].type === "childList") {
      mutations[i].addedNodes.forEach((node) => node.nodeType === 1 && addedNodes.add(node));
      mutations[i].removedNodes.forEach((node) => node.nodeType === 1 && removedNodes.add(node));
    }
    if (mutations[i].type === "attributes") {
      let el = mutations[i].target;
      let name = mutations[i].attributeName;
      let oldValue = mutations[i].oldValue;
      let add2 = () => {
        if (!addedAttributes.has(el))
          addedAttributes.set(el, []);
        addedAttributes.get(el).push({ name, value: el.getAttribute(name) });
      };
      let remove = () => {
        if (!removedAttributes.has(el))
          removedAttributes.set(el, []);
        removedAttributes.get(el).push(name);
      };
      if (el.hasAttribute(name) && oldValue === null) {
        add2();
      } else if (el.hasAttribute(name)) {
        remove();
        add2();
      } else {
        remove();
      }
    }
  }
  removedAttributes.forEach((attrs, el) => {
    cleanupAttributes(el, attrs);
  });
  addedAttributes.forEach((attrs, el) => {
    onAttributeAddeds.forEach((i) => i(el, attrs));
  });
  for (let node of removedNodes) {
    if (addedNodes.has(node))
      continue;
    onElRemoveds.forEach((i) => i(node));
  }
  addedNodes.forEach((node) => {
    node._x_ignoreSelf = true;
    node._x_ignore = true;
  });
  for (let node of addedNodes) {
    if (removedNodes.has(node))
      continue;
    if (!node.isConnected)
      continue;
    delete node._x_ignoreSelf;
    delete node._x_ignore;
    onElAddeds.forEach((i) => i(node));
    node._x_ignore = true;
    node._x_ignoreSelf = true;
  }
  addedNodes.forEach((node) => {
    delete node._x_ignoreSelf;
    delete node._x_ignore;
  });
  addedNodes = null;
  removedNodes = null;
  addedAttributes = null;
  removedAttributes = null;
}
function scope(node) {
  return mergeProxies(closestDataStack(node));
}
function addScopeToNode(node, data2, referenceNode) {
  node._x_dataStack = [data2, ...closestDataStack(referenceNode || node)];
  return () => {
    node._x_dataStack = node._x_dataStack.filter((i) => i !== data2);
  };
}
function closestDataStack(node) {
  if (node._x_dataStack)
    return node._x_dataStack;
  if (typeof ShadowRoot === "function" && node instanceof ShadowRoot) {
    return closestDataStack(node.host);
  }
  if (!node.parentNode) {
    return [];
  }
  return closestDataStack(node.parentNode);
}
function mergeProxies(objects) {
  return new Proxy({ objects }, mergeProxyTrap);
}
var mergeProxyTrap = {
  ownKeys({ objects }) {
    return Array.from(
      new Set(objects.flatMap((i) => Object.keys(i)))
    );
  },
  has({ objects }, name) {
    if (name == Symbol.unscopables)
      return false;
    return objects.some(
      (obj) => Object.prototype.hasOwnProperty.call(obj, name) || Reflect.has(obj, name)
    );
  },
  get({ objects }, name, thisProxy) {
    if (name == "toJSON")
      return collapseProxies;
    return Reflect.get(
      objects.find(
        (obj) => Reflect.has(obj, name)
      ) || {},
      name,
      thisProxy
    );
  },
  set({ objects }, name, value, thisProxy) {
    const target = objects.find(
      (obj) => Object.prototype.hasOwnProperty.call(obj, name)
    ) || objects[objects.length - 1];
    const descriptor = Object.getOwnPropertyDescriptor(target, name);
    if ((descriptor == null ? void 0 : descriptor.set) && (descriptor == null ? void 0 : descriptor.get))
      return descriptor.set.call(thisProxy, value) || true;
    return Reflect.set(target, name, value);
  }
};
function collapseProxies() {
  let keys = Reflect.ownKeys(this);
  return keys.reduce((acc, key) => {
    acc[key] = Reflect.get(this, key);
    return acc;
  }, {});
}
function initInterceptors(data2) {
  let isObject2 = (val) => typeof val === "object" && !Array.isArray(val) && val !== null;
  let recurse = (obj, basePath = "") => {
    Object.entries(Object.getOwnPropertyDescriptors(obj)).forEach(([key, { value, enumerable }]) => {
      if (enumerable === false || value === void 0)
        return;
      if (typeof value === "object" && value !== null && value.__v_skip)
        return;
      let path = basePath === "" ? key : `${basePath}.${key}`;
      if (typeof value === "object" && value !== null && value._x_interceptor) {
        obj[key] = value.initialize(data2, path, key);
      } else {
        if (isObject2(value) && value !== obj && !(value instanceof Element)) {
          recurse(value, path);
        }
      }
    });
  };
  return recurse(data2);
}
function interceptor(callback, mutateObj = () => {
}) {
  let obj = {
    initialValue: void 0,
    _x_interceptor: true,
    initialize(data2, path, key) {
      return callback(this.initialValue, () => get(data2, path), (value) => set(data2, path, value), path, key);
    }
  };
  mutateObj(obj);
  return (initialValue) => {
    if (typeof initialValue === "object" && initialValue !== null && initialValue._x_interceptor) {
      let initialize = obj.initialize.bind(obj);
      obj.initialize = (data2, path, key) => {
        let innerValue = initialValue.initialize(data2, path, key);
        obj.initialValue = innerValue;
        return initialize(data2, path, key);
      };
    } else {
      obj.initialValue = initialValue;
    }
    return obj;
  };
}
function get(obj, path) {
  return path.split(".").reduce((carry, segment) => carry[segment], obj);
}
function set(obj, path, value) {
  if (typeof path === "string")
    path = path.split(".");
  if (path.length === 1)
    obj[path[0]] = value;
  else if (path.length === 0)
    throw error;
  else {
    if (obj[path[0]])
      return set(obj[path[0]], path.slice(1), value);
    else {
      obj[path[0]] = {};
      return set(obj[path[0]], path.slice(1), value);
    }
  }
}
var magics = {};
function magic(name, callback) {
  magics[name] = callback;
}
function injectMagics(obj, el) {
  Object.entries(magics).forEach(([name, callback]) => {
    let memoizedUtilities = null;
    function getUtilities() {
      if (memoizedUtilities) {
        return memoizedUtilities;
      } else {
        let [utilities, cleanup2] = getElementBoundUtilities(el);
        memoizedUtilities = { interceptor, ...utilities };
        onElRemoved(el, cleanup2);
        return memoizedUtilities;
      }
    }
    Object.defineProperty(obj, `$${name}`, {
      get() {
        return callback(el, getUtilities());
      },
      enumerable: false
    });
  });
  return obj;
}
function tryCatch(el, expression, callback, ...args) {
  try {
    return callback(...args);
  } catch (e) {
    handleError(e, el, expression);
  }
}
function handleError(error2, el, expression = void 0) {
  error2 = Object.assign(
    error2 ?? { message: "No error message given." },
    { el, expression }
  );
  console.warn(`Alpine Expression Error: ${error2.message}

${expression ? 'Expression: "' + expression + '"\n\n' : ""}`, el);
  setTimeout(() => {
    throw error2;
  }, 0);
}
var shouldAutoEvaluateFunctions = true;
function dontAutoEvaluateFunctions(callback) {
  let cache = shouldAutoEvaluateFunctions;
  shouldAutoEvaluateFunctions = false;
  let result = callback();
  shouldAutoEvaluateFunctions = cache;
  return result;
}
function evaluate(el, expression, extras = {}) {
  let result;
  evaluateLater(el, expression)((value) => result = value, extras);
  return result;
}
function evaluateLater(...args) {
  return theEvaluatorFunction(...args);
}
var theEvaluatorFunction = normalEvaluator;
function setEvaluator(newEvaluator) {
  theEvaluatorFunction = newEvaluator;
}
function normalEvaluator(el, expression) {
  let overriddenMagics = {};
  injectMagics(overriddenMagics, el);
  let dataStack = [overriddenMagics, ...closestDataStack(el)];
  let evaluator = typeof expression === "function" ? generateEvaluatorFromFunction(dataStack, expression) : generateEvaluatorFromString(dataStack, expression, el);
  return tryCatch.bind(null, el, expression, evaluator);
}
function generateEvaluatorFromFunction(dataStack, func) {
  return (receiver = () => {
  }, { scope: scope2 = {}, params = [] } = {}) => {
    let result = func.apply(mergeProxies([scope2, ...dataStack]), params);
    runIfTypeOfFunction(receiver, result);
  };
}
var evaluatorMemo = {};
function generateFunctionFromString(expression, el) {
  if (evaluatorMemo[expression]) {
    return evaluatorMemo[expression];
  }
  let AsyncFunction = Object.getPrototypeOf(async function() {
  }).constructor;
  let rightSideSafeExpression = /^[\n\s]*if.*\(.*\)/.test(expression.trim()) || /^(let|const)\s/.test(expression.trim()) ? `(async()=>{ ${expression} })()` : expression;
  const safeAsyncFunction = () => {
    try {
      let func2 = new AsyncFunction(
        ["__self", "scope"],
        `with (scope) { __self.result = ${rightSideSafeExpression} }; __self.finished = true; return __self.result;`
      );
      Object.defineProperty(func2, "name", {
        value: `[Alpine] ${expression}`
      });
      return func2;
    } catch (error2) {
      handleError(error2, el, expression);
      return Promise.resolve();
    }
  };
  let func = safeAsyncFunction();
  evaluatorMemo[expression] = func;
  return func;
}
function generateEvaluatorFromString(dataStack, expression, el) {
  let func = generateFunctionFromString(expression, el);
  return (receiver = () => {
  }, { scope: scope2 = {}, params = [] } = {}) => {
    func.result = void 0;
    func.finished = false;
    let completeScope = mergeProxies([scope2, ...dataStack]);
    if (typeof func === "function") {
      let promise = func(func, completeScope).catch((error2) => handleError(error2, el, expression));
      if (func.finished) {
        runIfTypeOfFunction(receiver, func.result, completeScope, params, el);
        func.result = void 0;
      } else {
        promise.then((result) => {
          runIfTypeOfFunction(receiver, result, completeScope, params, el);
        }).catch((error2) => handleError(error2, el, expression)).finally(() => func.result = void 0);
      }
    }
  };
}
function runIfTypeOfFunction(receiver, value, scope2, params, el) {
  if (shouldAutoEvaluateFunctions && typeof value === "function") {
    let result = value.apply(scope2, params);
    if (result instanceof Promise) {
      result.then((i) => runIfTypeOfFunction(receiver, i, scope2, params)).catch((error2) => handleError(error2, el, value));
    } else {
      receiver(result);
    }
  } else if (typeof value === "object" && value instanceof Promise) {
    value.then((i) => receiver(i));
  } else {
    receiver(value);
  }
}
var prefixAsString = "x-";
function prefix(subject = "") {
  return prefixAsString + subject;
}
function setPrefix(newPrefix) {
  prefixAsString = newPrefix;
}
var directiveHandlers = {};
function directive(name, callback) {
  directiveHandlers[name] = callback;
  return {
    before(directive2) {
      if (!directiveHandlers[directive2]) {
        console.warn(String.raw`Cannot find directive \`${directive2}\`. \`${name}\` will use the default order of execution`);
        return;
      }
      const pos = directiveOrder.indexOf(directive2);
      directiveOrder.splice(pos >= 0 ? pos : directiveOrder.indexOf("DEFAULT"), 0, name);
    }
  };
}
function directiveExists(name) {
  return Object.keys(directiveHandlers).includes(name);
}
function directives(el, attributes, originalAttributeOverride) {
  attributes = Array.from(attributes);
  if (el._x_virtualDirectives) {
    let vAttributes = Object.entries(el._x_virtualDirectives).map(([name, value]) => ({ name, value }));
    let staticAttributes = attributesOnly(vAttributes);
    vAttributes = vAttributes.map((attribute) => {
      if (staticAttributes.find((attr) => attr.name === attribute.name)) {
        return {
          name: `x-bind:${attribute.name}`,
          value: `"${attribute.value}"`
        };
      }
      return attribute;
    });
    attributes = attributes.concat(vAttributes);
  }
  let transformedAttributeMap = {};
  let directives2 = attributes.map(toTransformedAttributes((newName, oldName) => transformedAttributeMap[newName] = oldName)).filter(outNonAlpineAttributes).map(toParsedDirectives(transformedAttributeMap, originalAttributeOverride)).sort(byPriority);
  return directives2.map((directive2) => {
    return getDirectiveHandler(el, directive2);
  });
}
function attributesOnly(attributes) {
  return Array.from(attributes).map(toTransformedAttributes()).filter((attr) => !outNonAlpineAttributes(attr));
}
var isDeferringHandlers = false;
var directiveHandlerStacks = /* @__PURE__ */ new Map();
var currentHandlerStackKey = Symbol();
function deferHandlingDirectives(callback) {
  isDeferringHandlers = true;
  let key = Symbol();
  currentHandlerStackKey = key;
  directiveHandlerStacks.set(key, []);
  let flushHandlers = () => {
    while (directiveHandlerStacks.get(key).length)
      directiveHandlerStacks.get(key).shift()();
    directiveHandlerStacks.delete(key);
  };
  let stopDeferring = () => {
    isDeferringHandlers = false;
    flushHandlers();
  };
  callback(flushHandlers);
  stopDeferring();
}
function getElementBoundUtilities(el) {
  let cleanups = [];
  let cleanup2 = (callback) => cleanups.push(callback);
  let [effect3, cleanupEffect] = elementBoundEffect(el);
  cleanups.push(cleanupEffect);
  let utilities = {
    Alpine: alpine_default,
    effect: effect3,
    cleanup: cleanup2,
    evaluateLater: evaluateLater.bind(evaluateLater, el),
    evaluate: evaluate.bind(evaluate, el)
  };
  let doCleanup = () => cleanups.forEach((i) => i());
  return [utilities, doCleanup];
}
function getDirectiveHandler(el, directive2) {
  let noop = () => {
  };
  let handler4 = directiveHandlers[directive2.type] || noop;
  let [utilities, cleanup2] = getElementBoundUtilities(el);
  onAttributeRemoved(el, directive2.original, cleanup2);
  let fullHandler = () => {
    if (el._x_ignore || el._x_ignoreSelf)
      return;
    handler4.inline && handler4.inline(el, directive2, utilities);
    handler4 = handler4.bind(handler4, el, directive2, utilities);
    isDeferringHandlers ? directiveHandlerStacks.get(currentHandlerStackKey).push(handler4) : handler4();
  };
  fullHandler.runCleanups = cleanup2;
  return fullHandler;
}
var startingWith = (subject, replacement) => ({ name, value }) => {
  if (name.startsWith(subject))
    name = name.replace(subject, replacement);
  return { name, value };
};
var into = (i) => i;
function toTransformedAttributes(callback = () => {
}) {
  return ({ name, value }) => {
    let { name: newName, value: newValue } = attributeTransformers.reduce((carry, transform) => {
      return transform(carry);
    }, { name, value });
    if (newName !== name)
      callback(newName, name);
    return { name: newName, value: newValue };
  };
}
var attributeTransformers = [];
function mapAttributes(callback) {
  attributeTransformers.push(callback);
}
function outNonAlpineAttributes({ name }) {
  return alpineAttributeRegex().test(name);
}
var alpineAttributeRegex = () => new RegExp(`^${prefixAsString}([^:^.]+)\\b`);
function toParsedDirectives(transformedAttributeMap, originalAttributeOverride) {
  return ({ name, value }) => {
    let typeMatch = name.match(alpineAttributeRegex());
    let valueMatch = name.match(/:([a-zA-Z0-9\-_:]+)/);
    let modifiers = name.match(/\.[^.\]]+(?=[^\]]*$)/g) || [];
    let original = originalAttributeOverride || transformedAttributeMap[name] || name;
    return {
      type: typeMatch ? typeMatch[1] : null,
      value: valueMatch ? valueMatch[1] : null,
      modifiers: modifiers.map((i) => i.replace(".", "")),
      expression: value,
      original
    };
  };
}
var DEFAULT = "DEFAULT";
var directiveOrder = [
  "ignore",
  "ref",
  "data",
  "id",
  "anchor",
  "bind",
  "init",
  "for",
  "model",
  "modelable",
  "transition",
  "show",
  "if",
  DEFAULT,
  "teleport"
];
function byPriority(a, b) {
  let typeA = directiveOrder.indexOf(a.type) === -1 ? DEFAULT : a.type;
  let typeB = directiveOrder.indexOf(b.type) === -1 ? DEFAULT : b.type;
  return directiveOrder.indexOf(typeA) - directiveOrder.indexOf(typeB);
}
function dispatch(el, name, detail = {}) {
  el.dispatchEvent(
    new CustomEvent(name, {
      detail,
      bubbles: true,
      // Allows events to pass the shadow DOM barrier.
      composed: true,
      cancelable: true
    })
  );
}
function walk(el, callback) {
  if (typeof ShadowRoot === "function" && el instanceof ShadowRoot) {
    Array.from(el.children).forEach((el2) => walk(el2, callback));
    return;
  }
  let skip = false;
  callback(el, () => skip = true);
  if (skip)
    return;
  let node = el.firstElementChild;
  while (node) {
    walk(node, callback);
    node = node.nextElementSibling;
  }
}
function warn(message, ...args) {
  console.warn(`Alpine Warning: ${message}`, ...args);
}
var started = false;
function start() {
  if (started)
    warn("Alpine has already been initialized on this page. Calling Alpine.start() more than once can cause problems.");
  started = true;
  if (!document.body)
    warn("Unable to initialize. Trying to load Alpine before `<body>` is available. Did you forget to add `defer` in Alpine's `<script>` tag?");
  dispatch(document, "alpine:init");
  dispatch(document, "alpine:initializing");
  startObservingMutations();
  onElAdded((el) => initTree(el, walk));
  onElRemoved((el) => destroyTree(el));
  onAttributesAdded((el, attrs) => {
    directives(el, attrs).forEach((handle) => handle());
  });
  let outNestedComponents = (el) => !closestRoot(el.parentElement, true);
  Array.from(document.querySelectorAll(allSelectors().join(","))).filter(outNestedComponents).forEach((el) => {
    initTree(el);
  });
  dispatch(document, "alpine:initialized");
  setTimeout(() => {
    warnAboutMissingPlugins();
  });
}
var rootSelectorCallbacks = [];
var initSelectorCallbacks = [];
function rootSelectors() {
  return rootSelectorCallbacks.map((fn) => fn());
}
function allSelectors() {
  return rootSelectorCallbacks.concat(initSelectorCallbacks).map((fn) => fn());
}
function addRootSelector(selectorCallback) {
  rootSelectorCallbacks.push(selectorCallback);
}
function addInitSelector(selectorCallback) {
  initSelectorCallbacks.push(selectorCallback);
}
function closestRoot(el, includeInitSelectors = false) {
  return findClosest(el, (element) => {
    const selectors = includeInitSelectors ? allSelectors() : rootSelectors();
    if (selectors.some((selector) => element.matches(selector)))
      return true;
  });
}
function findClosest(el, callback) {
  if (!el)
    return;
  if (callback(el))
    return el;
  if (el._x_teleportBack)
    el = el._x_teleportBack;
  if (!el.parentElement)
    return;
  return findClosest(el.parentElement, callback);
}
function isRoot(el) {
  return rootSelectors().some((selector) => el.matches(selector));
}
var initInterceptors2 = [];
function interceptInit(callback) {
  initInterceptors2.push(callback);
}
function initTree(el, walker = walk, intercept = () => {
}) {
  deferHandlingDirectives(() => {
    walker(el, (el2, skip) => {
      intercept(el2, skip);
      initInterceptors2.forEach((i) => i(el2, skip));
      directives(el2, el2.attributes).forEach((handle) => handle());
      el2._x_ignore && skip();
    });
  });
}
function destroyTree(root, walker = walk) {
  walker(root, (el) => {
    cleanupAttributes(el);
    cleanupElement(el);
  });
}
function warnAboutMissingPlugins() {
  let pluginDirectives = [
    ["ui", "dialog", ["[x-dialog], [x-popover]"]],
    ["anchor", "anchor", ["[x-anchor]"]],
    ["sort", "sort", ["[x-sort]"]]
  ];
  pluginDirectives.forEach(([plugin2, directive2, selectors]) => {
    if (directiveExists(directive2))
      return;
    selectors.some((selector) => {
      if (document.querySelector(selector)) {
        warn(`found "${selector}", but missing ${plugin2} plugin`);
        return true;
      }
    });
  });
}
var tickStack = [];
var isHolding = false;
function nextTick$1(callback = () => {
}) {
  queueMicrotask(() => {
    isHolding || setTimeout(() => {
      releaseNextTicks();
    });
  });
  return new Promise((res) => {
    tickStack.push(() => {
      callback();
      res();
    });
  });
}
function releaseNextTicks() {
  isHolding = false;
  while (tickStack.length)
    tickStack.shift()();
}
function holdNextTicks() {
  isHolding = true;
}
function setClasses(el, value) {
  if (Array.isArray(value)) {
    return setClassesFromString(el, value.join(" "));
  } else if (typeof value === "object" && value !== null) {
    return setClassesFromObject(el, value);
  } else if (typeof value === "function") {
    return setClasses(el, value());
  }
  return setClassesFromString(el, value);
}
function setClassesFromString(el, classString) {
  let missingClasses = (classString2) => classString2.split(" ").filter((i) => !el.classList.contains(i)).filter(Boolean);
  let addClassesAndReturnUndo = (classes2) => {
    el.classList.add(...classes2);
    return () => {
      el.classList.remove(...classes2);
    };
  };
  classString = classString === true ? classString = "" : classString || "";
  return addClassesAndReturnUndo(missingClasses(classString));
}
function setClassesFromObject(el, classObject) {
  let split = (classString) => classString.split(" ").filter(Boolean);
  let forAdd = Object.entries(classObject).flatMap(([classString, bool]) => bool ? split(classString) : false).filter(Boolean);
  let forRemove = Object.entries(classObject).flatMap(([classString, bool]) => !bool ? split(classString) : false).filter(Boolean);
  let added = [];
  let removed = [];
  forRemove.forEach((i) => {
    if (el.classList.contains(i)) {
      el.classList.remove(i);
      removed.push(i);
    }
  });
  forAdd.forEach((i) => {
    if (!el.classList.contains(i)) {
      el.classList.add(i);
      added.push(i);
    }
  });
  return () => {
    removed.forEach((i) => el.classList.add(i));
    added.forEach((i) => el.classList.remove(i));
  };
}
function setStyles(el, value) {
  if (typeof value === "object" && value !== null) {
    return setStylesFromObject(el, value);
  }
  return setStylesFromString(el, value);
}
function setStylesFromObject(el, value) {
  let previousStyles = {};
  Object.entries(value).forEach(([key, value2]) => {
    previousStyles[key] = el.style[key];
    if (!key.startsWith("--")) {
      key = kebabCase(key);
    }
    el.style.setProperty(key, value2);
  });
  setTimeout(() => {
    if (el.style.length === 0) {
      el.removeAttribute("style");
    }
  });
  return () => {
    setStyles(el, previousStyles);
  };
}
function setStylesFromString(el, value) {
  let cache = el.getAttribute("style", value);
  el.setAttribute("style", value);
  return () => {
    el.setAttribute("style", cache || "");
  };
}
function kebabCase(subject) {
  return subject.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}
function once(callback, fallback = () => {
}) {
  let called = false;
  return function() {
    if (!called) {
      called = true;
      callback.apply(this, arguments);
    } else {
      fallback.apply(this, arguments);
    }
  };
}
directive("transition", (el, { value, modifiers, expression }, { evaluate: evaluate2 }) => {
  if (typeof expression === "function")
    expression = evaluate2(expression);
  if (expression === false)
    return;
  if (!expression || typeof expression === "boolean") {
    registerTransitionsFromHelper(el, modifiers, value);
  } else {
    registerTransitionsFromClassString(el, expression, value);
  }
});
function registerTransitionsFromClassString(el, classString, stage) {
  registerTransitionObject(el, setClasses, "");
  let directiveStorageMap = {
    "enter": (classes2) => {
      el._x_transition.enter.during = classes2;
    },
    "enter-start": (classes2) => {
      el._x_transition.enter.start = classes2;
    },
    "enter-end": (classes2) => {
      el._x_transition.enter.end = classes2;
    },
    "leave": (classes2) => {
      el._x_transition.leave.during = classes2;
    },
    "leave-start": (classes2) => {
      el._x_transition.leave.start = classes2;
    },
    "leave-end": (classes2) => {
      el._x_transition.leave.end = classes2;
    }
  };
  directiveStorageMap[stage](classString);
}
function registerTransitionsFromHelper(el, modifiers, stage) {
  registerTransitionObject(el, setStyles);
  let doesntSpecify = !modifiers.includes("in") && !modifiers.includes("out") && !stage;
  let transitioningIn = doesntSpecify || modifiers.includes("in") || ["enter"].includes(stage);
  let transitioningOut = doesntSpecify || modifiers.includes("out") || ["leave"].includes(stage);
  if (modifiers.includes("in") && !doesntSpecify) {
    modifiers = modifiers.filter((i, index) => index < modifiers.indexOf("out"));
  }
  if (modifiers.includes("out") && !doesntSpecify) {
    modifiers = modifiers.filter((i, index) => index > modifiers.indexOf("out"));
  }
  let wantsAll = !modifiers.includes("opacity") && !modifiers.includes("scale");
  let wantsOpacity = wantsAll || modifiers.includes("opacity");
  let wantsScale = wantsAll || modifiers.includes("scale");
  let opacityValue = wantsOpacity ? 0 : 1;
  let scaleValue = wantsScale ? modifierValue(modifiers, "scale", 95) / 100 : 1;
  let delay3 = modifierValue(modifiers, "delay", 0) / 1e3;
  let origin = modifierValue(modifiers, "origin", "center");
  let property = "opacity, transform";
  let durationIn = modifierValue(modifiers, "duration", 150) / 1e3;
  let durationOut = modifierValue(modifiers, "duration", 75) / 1e3;
  let easing = `cubic-bezier(0.4, 0.0, 0.2, 1)`;
  if (transitioningIn) {
    el._x_transition.enter.during = {
      transformOrigin: origin,
      transitionDelay: `${delay3}s`,
      transitionProperty: property,
      transitionDuration: `${durationIn}s`,
      transitionTimingFunction: easing
    };
    el._x_transition.enter.start = {
      opacity: opacityValue,
      transform: `scale(${scaleValue})`
    };
    el._x_transition.enter.end = {
      opacity: 1,
      transform: `scale(1)`
    };
  }
  if (transitioningOut) {
    el._x_transition.leave.during = {
      transformOrigin: origin,
      transitionDelay: `${delay3}s`,
      transitionProperty: property,
      transitionDuration: `${durationOut}s`,
      transitionTimingFunction: easing
    };
    el._x_transition.leave.start = {
      opacity: 1,
      transform: `scale(1)`
    };
    el._x_transition.leave.end = {
      opacity: opacityValue,
      transform: `scale(${scaleValue})`
    };
  }
}
function registerTransitionObject(el, setFunction, defaultValue = {}) {
  if (!el._x_transition)
    el._x_transition = {
      enter: { during: defaultValue, start: defaultValue, end: defaultValue },
      leave: { during: defaultValue, start: defaultValue, end: defaultValue },
      in(before = () => {
      }, after = () => {
      }) {
        transition$1(el, setFunction, {
          during: this.enter.during,
          start: this.enter.start,
          end: this.enter.end
        }, before, after);
      },
      out(before = () => {
      }, after = () => {
      }) {
        transition$1(el, setFunction, {
          during: this.leave.during,
          start: this.leave.start,
          end: this.leave.end
        }, before, after);
      }
    };
}
window.Element.prototype._x_toggleAndCascadeWithTransitions = function(el, value, show, hide) {
  const nextTick2 = document.visibilityState === "visible" ? requestAnimationFrame : setTimeout;
  let clickAwayCompatibleShow = () => nextTick2(show);
  if (value) {
    if (el._x_transition && (el._x_transition.enter || el._x_transition.leave)) {
      el._x_transition.enter && (Object.entries(el._x_transition.enter.during).length || Object.entries(el._x_transition.enter.start).length || Object.entries(el._x_transition.enter.end).length) ? el._x_transition.in(show) : clickAwayCompatibleShow();
    } else {
      el._x_transition ? el._x_transition.in(show) : clickAwayCompatibleShow();
    }
    return;
  }
  el._x_hidePromise = el._x_transition ? new Promise((resolve, reject) => {
    el._x_transition.out(() => {
    }, () => resolve(hide));
    el._x_transitioning && el._x_transitioning.beforeCancel(() => reject({ isFromCancelledTransition: true }));
  }) : Promise.resolve(hide);
  queueMicrotask(() => {
    let closest = closestHide(el);
    if (closest) {
      if (!closest._x_hideChildren)
        closest._x_hideChildren = [];
      closest._x_hideChildren.push(el);
    } else {
      nextTick2(() => {
        let hideAfterChildren = (el2) => {
          let carry = Promise.all([
            el2._x_hidePromise,
            ...(el2._x_hideChildren || []).map(hideAfterChildren)
          ]).then(([i]) => i == null ? void 0 : i());
          delete el2._x_hidePromise;
          delete el2._x_hideChildren;
          return carry;
        };
        hideAfterChildren(el).catch((e) => {
          if (!e.isFromCancelledTransition)
            throw e;
        });
      });
    }
  });
};
function closestHide(el) {
  let parent = el.parentNode;
  if (!parent)
    return;
  return parent._x_hidePromise ? parent : closestHide(parent);
}
function transition$1(el, setFunction, { during, start: start2, end } = {}, before = () => {
}, after = () => {
}) {
  if (el._x_transitioning)
    el._x_transitioning.cancel();
  if (Object.keys(during).length === 0 && Object.keys(start2).length === 0 && Object.keys(end).length === 0) {
    before();
    after();
    return;
  }
  let undoStart, undoDuring, undoEnd;
  performTransition(el, {
    start() {
      undoStart = setFunction(el, start2);
    },
    during() {
      undoDuring = setFunction(el, during);
    },
    before,
    end() {
      undoStart();
      undoEnd = setFunction(el, end);
    },
    after,
    cleanup() {
      undoDuring();
      undoEnd();
    }
  });
}
function performTransition(el, stages) {
  let interrupted, reachedBefore, reachedEnd;
  let finish = once(() => {
    mutateDom(() => {
      interrupted = true;
      if (!reachedBefore)
        stages.before();
      if (!reachedEnd) {
        stages.end();
        releaseNextTicks();
      }
      stages.after();
      if (el.isConnected)
        stages.cleanup();
      delete el._x_transitioning;
    });
  });
  el._x_transitioning = {
    beforeCancels: [],
    beforeCancel(callback) {
      this.beforeCancels.push(callback);
    },
    cancel: once(function() {
      while (this.beforeCancels.length) {
        this.beforeCancels.shift()();
      }
      finish();
    }),
    finish
  };
  mutateDom(() => {
    stages.start();
    stages.during();
  });
  holdNextTicks();
  requestAnimationFrame(() => {
    if (interrupted)
      return;
    let duration = Number(getComputedStyle(el).transitionDuration.replace(/,.*/, "").replace("s", "")) * 1e3;
    let delay3 = Number(getComputedStyle(el).transitionDelay.replace(/,.*/, "").replace("s", "")) * 1e3;
    if (duration === 0)
      duration = Number(getComputedStyle(el).animationDuration.replace("s", "")) * 1e3;
    mutateDom(() => {
      stages.before();
    });
    reachedBefore = true;
    requestAnimationFrame(() => {
      if (interrupted)
        return;
      mutateDom(() => {
        stages.end();
      });
      releaseNextTicks();
      setTimeout(el._x_transitioning.finish, duration + delay3);
      reachedEnd = true;
    });
  });
}
function modifierValue(modifiers, key, fallback) {
  if (modifiers.indexOf(key) === -1)
    return fallback;
  const rawValue = modifiers[modifiers.indexOf(key) + 1];
  if (!rawValue)
    return fallback;
  if (key === "scale") {
    if (isNaN(rawValue))
      return fallback;
  }
  if (key === "duration" || key === "delay") {
    let match = rawValue.match(/([0-9]+)ms/);
    if (match)
      return match[1];
  }
  if (key === "origin") {
    if (["top", "right", "left", "center", "bottom"].includes(modifiers[modifiers.indexOf(key) + 2])) {
      return [rawValue, modifiers[modifiers.indexOf(key) + 2]].join(" ");
    }
  }
  return rawValue;
}
var isCloning = false;
function skipDuringClone(callback, fallback = () => {
}) {
  return (...args) => isCloning ? fallback(...args) : callback(...args);
}
function onlyDuringClone(callback) {
  return (...args) => isCloning && callback(...args);
}
var interceptors = [];
function interceptClone(callback) {
  interceptors.push(callback);
}
function cloneNode(from, to) {
  interceptors.forEach((i) => i(from, to));
  isCloning = true;
  dontRegisterReactiveSideEffects(() => {
    initTree(to, (el, callback) => {
      callback(el, () => {
      });
    });
  });
  isCloning = false;
}
var isCloningLegacy = false;
function clone(oldEl, newEl) {
  if (!newEl._x_dataStack)
    newEl._x_dataStack = oldEl._x_dataStack;
  isCloning = true;
  isCloningLegacy = true;
  dontRegisterReactiveSideEffects(() => {
    cloneTree(newEl);
  });
  isCloning = false;
  isCloningLegacy = false;
}
function cloneTree(el) {
  let hasRunThroughFirstEl = false;
  let shallowWalker = (el2, callback) => {
    walk(el2, (el3, skip) => {
      if (hasRunThroughFirstEl && isRoot(el3))
        return skip();
      hasRunThroughFirstEl = true;
      callback(el3, skip);
    });
  };
  initTree(el, shallowWalker);
}
function dontRegisterReactiveSideEffects(callback) {
  let cache = effect;
  overrideEffect((callback2, el) => {
    let storedEffect = cache(callback2);
    release(storedEffect);
    return () => {
    };
  });
  callback();
  overrideEffect(cache);
}
function bind(el, name, value, modifiers = []) {
  if (!el._x_bindings)
    el._x_bindings = reactive({});
  el._x_bindings[name] = value;
  name = modifiers.includes("camel") ? camelCase(name) : name;
  switch (name) {
    case "value":
      bindInputValue(el, value);
      break;
    case "style":
      bindStyles(el, value);
      break;
    case "class":
      bindClasses(el, value);
      break;
    case "selected":
    case "checked":
      bindAttributeAndProperty(el, name, value);
      break;
    default:
      bindAttribute(el, name, value);
      break;
  }
}
function bindInputValue(el, value) {
  if (el.type === "radio") {
    if (el.attributes.value === void 0) {
      el.value = value;
    }
    if (window.fromModel) {
      if (typeof value === "boolean") {
        el.checked = safeParseBoolean(el.value) === value;
      } else {
        el.checked = checkedAttrLooseCompare(el.value, value);
      }
    }
  } else if (el.type === "checkbox") {
    if (Number.isInteger(value)) {
      el.value = value;
    } else if (!Array.isArray(value) && typeof value !== "boolean" && ![null, void 0].includes(value)) {
      el.value = String(value);
    } else {
      if (Array.isArray(value)) {
        el.checked = value.some((val) => checkedAttrLooseCompare(val, el.value));
      } else {
        el.checked = !!value;
      }
    }
  } else if (el.tagName === "SELECT") {
    updateSelect(el, value);
  } else {
    if (el.value === value)
      return;
    el.value = value === void 0 ? "" : value;
  }
}
function bindClasses(el, value) {
  if (el._x_undoAddedClasses)
    el._x_undoAddedClasses();
  el._x_undoAddedClasses = setClasses(el, value);
}
function bindStyles(el, value) {
  if (el._x_undoAddedStyles)
    el._x_undoAddedStyles();
  el._x_undoAddedStyles = setStyles(el, value);
}
function bindAttributeAndProperty(el, name, value) {
  bindAttribute(el, name, value);
  setPropertyIfChanged(el, name, value);
}
function bindAttribute(el, name, value) {
  if ([null, void 0, false].includes(value) && attributeShouldntBePreservedIfFalsy(name)) {
    el.removeAttribute(name);
  } else {
    if (isBooleanAttr(name))
      value = name;
    setIfChanged(el, name, value);
  }
}
function setIfChanged(el, attrName, value) {
  if (el.getAttribute(attrName) != value) {
    el.setAttribute(attrName, value);
  }
}
function setPropertyIfChanged(el, propName, value) {
  if (el[propName] !== value) {
    el[propName] = value;
  }
}
function updateSelect(el, value) {
  const arrayWrappedValue = [].concat(value).map((value2) => {
    return value2 + "";
  });
  Array.from(el.options).forEach((option) => {
    option.selected = arrayWrappedValue.includes(option.value);
  });
}
function camelCase(subject) {
  return subject.toLowerCase().replace(/-(\w)/g, (match, char) => char.toUpperCase());
}
function checkedAttrLooseCompare(valueA, valueB) {
  return valueA == valueB;
}
function safeParseBoolean(rawValue) {
  if ([1, "1", "true", "on", "yes", true].includes(rawValue)) {
    return true;
  }
  if ([0, "0", "false", "off", "no", false].includes(rawValue)) {
    return false;
  }
  return rawValue ? Boolean(rawValue) : null;
}
function isBooleanAttr(attrName) {
  const booleanAttributes = [
    "disabled",
    "checked",
    "required",
    "readonly",
    "open",
    "selected",
    "autofocus",
    "itemscope",
    "multiple",
    "novalidate",
    "allowfullscreen",
    "allowpaymentrequest",
    "formnovalidate",
    "autoplay",
    "controls",
    "loop",
    "muted",
    "playsinline",
    "default",
    "ismap",
    "reversed",
    "async",
    "defer",
    "nomodule"
  ];
  return booleanAttributes.includes(attrName);
}
function attributeShouldntBePreservedIfFalsy(name) {
  return !["aria-pressed", "aria-checked", "aria-expanded", "aria-selected"].includes(name);
}
function getBinding(el, name, fallback) {
  if (el._x_bindings && el._x_bindings[name] !== void 0)
    return el._x_bindings[name];
  return getAttributeBinding(el, name, fallback);
}
function extractProp(el, name, fallback, extract = true) {
  if (el._x_bindings && el._x_bindings[name] !== void 0)
    return el._x_bindings[name];
  if (el._x_inlineBindings && el._x_inlineBindings[name] !== void 0) {
    let binding = el._x_inlineBindings[name];
    binding.extract = extract;
    return dontAutoEvaluateFunctions(() => {
      return evaluate(el, binding.expression);
    });
  }
  return getAttributeBinding(el, name, fallback);
}
function getAttributeBinding(el, name, fallback) {
  let attr = el.getAttribute(name);
  if (attr === null)
    return typeof fallback === "function" ? fallback() : fallback;
  if (attr === "")
    return true;
  if (isBooleanAttr(name)) {
    return !![name, "true"].includes(attr);
  }
  return attr;
}
function debounce(func, wait) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
function throttle(func, limit) {
  let inThrottle;
  return function() {
    let context = this, args = arguments;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}
function entangle({ get: outerGet, set: outerSet }, { get: innerGet, set: innerSet }) {
  let firstRun = true;
  let outerHash;
  let reference = effect(() => {
    let outer = outerGet();
    let inner = innerGet();
    if (firstRun) {
      innerSet(cloneIfObject(outer));
      firstRun = false;
    } else {
      let outerHashLatest = JSON.stringify(outer);
      let innerHashLatest = JSON.stringify(inner);
      if (outerHashLatest !== outerHash) {
        innerSet(cloneIfObject(outer));
      } else if (outerHashLatest !== innerHashLatest) {
        outerSet(cloneIfObject(inner));
      } else ;
    }
    outerHash = JSON.stringify(outerGet());
    JSON.stringify(innerGet());
  });
  return () => {
    release(reference);
  };
}
function cloneIfObject(value) {
  return typeof value === "object" ? JSON.parse(JSON.stringify(value)) : value;
}
function plugin(callback) {
  let callbacks = Array.isArray(callback) ? callback : [callback];
  callbacks.forEach((i) => i(alpine_default));
}
var stores = {};
var isReactive = false;
function store(name, value) {
  if (!isReactive) {
    stores = reactive(stores);
    isReactive = true;
  }
  if (value === void 0) {
    return stores[name];
  }
  stores[name] = value;
  if (typeof value === "object" && value !== null && value.hasOwnProperty("init") && typeof value.init === "function") {
    stores[name].init();
  }
  initInterceptors(stores[name]);
}
function getStores() {
  return stores;
}
var binds = {};
function bind2(name, bindings) {
  let getBindings = typeof bindings !== "function" ? () => bindings : bindings;
  if (name instanceof Element) {
    return applyBindingsObject(name, getBindings());
  } else {
    binds[name] = getBindings;
  }
  return () => {
  };
}
function injectBindingProviders(obj) {
  Object.entries(binds).forEach(([name, callback]) => {
    Object.defineProperty(obj, name, {
      get() {
        return (...args) => {
          return callback(...args);
        };
      }
    });
  });
  return obj;
}
function applyBindingsObject(el, obj, original) {
  let cleanupRunners = [];
  while (cleanupRunners.length)
    cleanupRunners.pop()();
  let attributes = Object.entries(obj).map(([name, value]) => ({ name, value }));
  let staticAttributes = attributesOnly(attributes);
  attributes = attributes.map((attribute) => {
    if (staticAttributes.find((attr) => attr.name === attribute.name)) {
      return {
        name: `x-bind:${attribute.name}`,
        value: `"${attribute.value}"`
      };
    }
    return attribute;
  });
  directives(el, attributes, original).map((handle) => {
    cleanupRunners.push(handle.runCleanups);
    handle();
  });
  return () => {
    while (cleanupRunners.length)
      cleanupRunners.pop()();
  };
}
var datas = {};
function data(name, callback) {
  datas[name] = callback;
}
function injectDataProviders(obj, context) {
  Object.entries(datas).forEach(([name, callback]) => {
    Object.defineProperty(obj, name, {
      get() {
        return (...args) => {
          return callback.bind(context)(...args);
        };
      },
      enumerable: false
    });
  });
  return obj;
}
var Alpine = {
  get reactive() {
    return reactive;
  },
  get release() {
    return release;
  },
  get effect() {
    return effect;
  },
  get raw() {
    return raw;
  },
  version: "3.14.1",
  flushAndStopDeferringMutations,
  dontAutoEvaluateFunctions,
  disableEffectScheduling,
  startObservingMutations,
  stopObservingMutations,
  setReactivityEngine,
  onAttributeRemoved,
  onAttributesAdded,
  closestDataStack,
  skipDuringClone,
  onlyDuringClone,
  addRootSelector,
  addInitSelector,
  interceptClone,
  addScopeToNode,
  deferMutations,
  mapAttributes,
  evaluateLater,
  interceptInit,
  setEvaluator,
  mergeProxies,
  extractProp,
  findClosest,
  onElRemoved,
  closestRoot,
  destroyTree,
  interceptor,
  // INTERNAL: not public API and is subject to change without major release.
  transition: transition$1,
  // INTERNAL
  setStyles,
  // INTERNAL
  mutateDom,
  directive,
  entangle,
  throttle,
  debounce,
  evaluate,
  initTree,
  nextTick: nextTick$1,
  prefixed: prefix,
  prefix: setPrefix,
  plugin,
  magic,
  store,
  start,
  clone,
  // INTERNAL
  cloneNode,
  // INTERNAL
  bound: getBinding,
  $data: scope,
  watch,
  walk,
  data,
  bind: bind2
};
var alpine_default = Alpine;
function makeMap(str, expectsLowerCase) {
  const map = /* @__PURE__ */ Object.create(null);
  const list = str.split(",");
  for (let i = 0; i < list.length; i++) {
    map[list[i]] = true;
  }
  return (val) => !!map[val];
}
var EMPTY_OBJ = Object.freeze({});
var hasOwnProperty = Object.prototype.hasOwnProperty;
var hasOwn = (val, key) => hasOwnProperty.call(val, key);
var isArray = Array.isArray;
var isMap = (val) => toTypeString(val) === "[object Map]";
var isString = (val) => typeof val === "string";
var isSymbol = (val) => typeof val === "symbol";
var isObject$2 = (val) => val !== null && typeof val === "object";
var objectToString = Object.prototype.toString;
var toTypeString = (value) => objectToString.call(value);
var toRawType = (value) => {
  return toTypeString(value).slice(8, -1);
};
var isIntegerKey = (key) => isString(key) && key !== "NaN" && key[0] !== "-" && "" + parseInt(key, 10) === key;
var cacheStringFunction = (fn) => {
  const cache = /* @__PURE__ */ Object.create(null);
  return (str) => {
    const hit = cache[str];
    return hit || (cache[str] = fn(str));
  };
};
var capitalize = cacheStringFunction((str) => str.charAt(0).toUpperCase() + str.slice(1));
var hasChanged = (value, oldValue) => value !== oldValue && (value === value || oldValue === oldValue);
var targetMap = /* @__PURE__ */ new WeakMap();
var effectStack = [];
var activeEffect;
var ITERATE_KEY = Symbol("iterate");
var MAP_KEY_ITERATE_KEY = Symbol("Map key iterate");
function isEffect(fn) {
  return fn && fn._isEffect === true;
}
function effect2(fn, options = EMPTY_OBJ) {
  if (isEffect(fn)) {
    fn = fn.raw;
  }
  const effect3 = createReactiveEffect(fn, options);
  if (!options.lazy) {
    effect3();
  }
  return effect3;
}
function stop(effect3) {
  if (effect3.active) {
    cleanup(effect3);
    if (effect3.options.onStop) {
      effect3.options.onStop();
    }
    effect3.active = false;
  }
}
var uid = 0;
function createReactiveEffect(fn, options) {
  const effect3 = function reactiveEffect() {
    if (!effect3.active) {
      return fn();
    }
    if (!effectStack.includes(effect3)) {
      cleanup(effect3);
      try {
        enableTracking();
        effectStack.push(effect3);
        activeEffect = effect3;
        return fn();
      } finally {
        effectStack.pop();
        resetTracking();
        activeEffect = effectStack[effectStack.length - 1];
      }
    }
  };
  effect3.id = uid++;
  effect3.allowRecurse = !!options.allowRecurse;
  effect3._isEffect = true;
  effect3.active = true;
  effect3.raw = fn;
  effect3.deps = [];
  effect3.options = options;
  return effect3;
}
function cleanup(effect3) {
  const { deps } = effect3;
  if (deps.length) {
    for (let i = 0; i < deps.length; i++) {
      deps[i].delete(effect3);
    }
    deps.length = 0;
  }
}
var shouldTrack = true;
var trackStack = [];
function pauseTracking() {
  trackStack.push(shouldTrack);
  shouldTrack = false;
}
function enableTracking() {
  trackStack.push(shouldTrack);
  shouldTrack = true;
}
function resetTracking() {
  const last = trackStack.pop();
  shouldTrack = last === void 0 ? true : last;
}
function track(target, type, key) {
  if (!shouldTrack || activeEffect === void 0) {
    return;
  }
  let depsMap = targetMap.get(target);
  if (!depsMap) {
    targetMap.set(target, depsMap = /* @__PURE__ */ new Map());
  }
  let dep = depsMap.get(key);
  if (!dep) {
    depsMap.set(key, dep = /* @__PURE__ */ new Set());
  }
  if (!dep.has(activeEffect)) {
    dep.add(activeEffect);
    activeEffect.deps.push(dep);
    if (activeEffect.options.onTrack) {
      activeEffect.options.onTrack({
        effect: activeEffect,
        target,
        type,
        key
      });
    }
  }
}
function trigger(target, type, key, newValue, oldValue, oldTarget) {
  const depsMap = targetMap.get(target);
  if (!depsMap) {
    return;
  }
  const effects = /* @__PURE__ */ new Set();
  const add2 = (effectsToAdd) => {
    if (effectsToAdd) {
      effectsToAdd.forEach((effect3) => {
        if (effect3 !== activeEffect || effect3.allowRecurse) {
          effects.add(effect3);
        }
      });
    }
  };
  if (type === "clear") {
    depsMap.forEach(add2);
  } else if (key === "length" && isArray(target)) {
    depsMap.forEach((dep, key2) => {
      if (key2 === "length" || key2 >= newValue) {
        add2(dep);
      }
    });
  } else {
    if (key !== void 0) {
      add2(depsMap.get(key));
    }
    switch (type) {
      case "add":
        if (!isArray(target)) {
          add2(depsMap.get(ITERATE_KEY));
          if (isMap(target)) {
            add2(depsMap.get(MAP_KEY_ITERATE_KEY));
          }
        } else if (isIntegerKey(key)) {
          add2(depsMap.get("length"));
        }
        break;
      case "delete":
        if (!isArray(target)) {
          add2(depsMap.get(ITERATE_KEY));
          if (isMap(target)) {
            add2(depsMap.get(MAP_KEY_ITERATE_KEY));
          }
        }
        break;
      case "set":
        if (isMap(target)) {
          add2(depsMap.get(ITERATE_KEY));
        }
        break;
    }
  }
  const run = (effect3) => {
    if (effect3.options.onTrigger) {
      effect3.options.onTrigger({
        effect: effect3,
        target,
        key,
        type,
        newValue,
        oldValue,
        oldTarget
      });
    }
    if (effect3.options.scheduler) {
      effect3.options.scheduler(effect3);
    } else {
      effect3();
    }
  };
  effects.forEach(run);
}
var isNonTrackableKeys = /* @__PURE__ */ makeMap(`__proto__,__v_isRef,__isVue`);
var builtInSymbols = new Set(Object.getOwnPropertyNames(Symbol).map((key) => Symbol[key]).filter(isSymbol));
var get2 = /* @__PURE__ */ createGetter();
var readonlyGet = /* @__PURE__ */ createGetter(true);
var arrayInstrumentations = /* @__PURE__ */ createArrayInstrumentations();
function createArrayInstrumentations() {
  const instrumentations = {};
  ["includes", "indexOf", "lastIndexOf"].forEach((key) => {
    instrumentations[key] = function(...args) {
      const arr = toRaw(this);
      for (let i = 0, l = this.length; i < l; i++) {
        track(arr, "get", i + "");
      }
      const res = arr[key](...args);
      if (res === -1 || res === false) {
        return arr[key](...args.map(toRaw));
      } else {
        return res;
      }
    };
  });
  ["push", "pop", "shift", "unshift", "splice"].forEach((key) => {
    instrumentations[key] = function(...args) {
      pauseTracking();
      const res = toRaw(this)[key].apply(this, args);
      resetTracking();
      return res;
    };
  });
  return instrumentations;
}
function createGetter(isReadonly = false, shallow = false) {
  return function get3(target, key, receiver) {
    if (key === "__v_isReactive") {
      return !isReadonly;
    } else if (key === "__v_isReadonly") {
      return isReadonly;
    } else if (key === "__v_raw" && receiver === (isReadonly ? shallow ? shallowReadonlyMap : readonlyMap : shallow ? shallowReactiveMap : reactiveMap).get(target)) {
      return target;
    }
    const targetIsArray = isArray(target);
    if (!isReadonly && targetIsArray && hasOwn(arrayInstrumentations, key)) {
      return Reflect.get(arrayInstrumentations, key, receiver);
    }
    const res = Reflect.get(target, key, receiver);
    if (isSymbol(key) ? builtInSymbols.has(key) : isNonTrackableKeys(key)) {
      return res;
    }
    if (!isReadonly) {
      track(target, "get", key);
    }
    if (shallow) {
      return res;
    }
    if (isRef(res)) {
      const shouldUnwrap = !targetIsArray || !isIntegerKey(key);
      return shouldUnwrap ? res.value : res;
    }
    if (isObject$2(res)) {
      return isReadonly ? readonly(res) : reactive2(res);
    }
    return res;
  };
}
var set2 = /* @__PURE__ */ createSetter();
function createSetter(shallow = false) {
  return function set3(target, key, value, receiver) {
    let oldValue = target[key];
    if (!shallow) {
      value = toRaw(value);
      oldValue = toRaw(oldValue);
      if (!isArray(target) && isRef(oldValue) && !isRef(value)) {
        oldValue.value = value;
        return true;
      }
    }
    const hadKey = isArray(target) && isIntegerKey(key) ? Number(key) < target.length : hasOwn(target, key);
    const result = Reflect.set(target, key, value, receiver);
    if (target === toRaw(receiver)) {
      if (!hadKey) {
        trigger(target, "add", key, value);
      } else if (hasChanged(value, oldValue)) {
        trigger(target, "set", key, value, oldValue);
      }
    }
    return result;
  };
}
function deleteProperty(target, key) {
  const hadKey = hasOwn(target, key);
  const oldValue = target[key];
  const result = Reflect.deleteProperty(target, key);
  if (result && hadKey) {
    trigger(target, "delete", key, void 0, oldValue);
  }
  return result;
}
function has(target, key) {
  const result = Reflect.has(target, key);
  if (!isSymbol(key) || !builtInSymbols.has(key)) {
    track(target, "has", key);
  }
  return result;
}
function ownKeys$1(target) {
  track(target, "iterate", isArray(target) ? "length" : ITERATE_KEY);
  return Reflect.ownKeys(target);
}
var mutableHandlers = {
  get: get2,
  set: set2,
  deleteProperty,
  has,
  ownKeys: ownKeys$1
};
var readonlyHandlers = {
  get: readonlyGet,
  set(target, key) {
    {
      console.warn(`Set operation on key "${String(key)}" failed: target is readonly.`, target);
    }
    return true;
  },
  deleteProperty(target, key) {
    {
      console.warn(`Delete operation on key "${String(key)}" failed: target is readonly.`, target);
    }
    return true;
  }
};
var toReactive = (value) => isObject$2(value) ? reactive2(value) : value;
var toReadonly = (value) => isObject$2(value) ? readonly(value) : value;
var toShallow = (value) => value;
var getProto = (v) => Reflect.getPrototypeOf(v);
function get$1(target, key, isReadonly = false, isShallow = false) {
  target = target[
    "__v_raw"
    /* RAW */
  ];
  const rawTarget = toRaw(target);
  const rawKey = toRaw(key);
  if (key !== rawKey) {
    !isReadonly && track(rawTarget, "get", key);
  }
  !isReadonly && track(rawTarget, "get", rawKey);
  const { has: has2 } = getProto(rawTarget);
  const wrap = isShallow ? toShallow : isReadonly ? toReadonly : toReactive;
  if (has2.call(rawTarget, key)) {
    return wrap(target.get(key));
  } else if (has2.call(rawTarget, rawKey)) {
    return wrap(target.get(rawKey));
  } else if (target !== rawTarget) {
    target.get(key);
  }
}
function has$1(key, isReadonly = false) {
  const target = this[
    "__v_raw"
    /* RAW */
  ];
  const rawTarget = toRaw(target);
  const rawKey = toRaw(key);
  if (key !== rawKey) {
    !isReadonly && track(rawTarget, "has", key);
  }
  !isReadonly && track(rawTarget, "has", rawKey);
  return key === rawKey ? target.has(key) : target.has(key) || target.has(rawKey);
}
function size(target, isReadonly = false) {
  target = target[
    "__v_raw"
    /* RAW */
  ];
  !isReadonly && track(toRaw(target), "iterate", ITERATE_KEY);
  return Reflect.get(target, "size", target);
}
function add(value) {
  value = toRaw(value);
  const target = toRaw(this);
  const proto = getProto(target);
  const hadKey = proto.has.call(target, value);
  if (!hadKey) {
    target.add(value);
    trigger(target, "add", value, value);
  }
  return this;
}
function set$1(key, value) {
  value = toRaw(value);
  const target = toRaw(this);
  const { has: has2, get: get3 } = getProto(target);
  let hadKey = has2.call(target, key);
  if (!hadKey) {
    key = toRaw(key);
    hadKey = has2.call(target, key);
  } else {
    checkIdentityKeys(target, has2, key);
  }
  const oldValue = get3.call(target, key);
  target.set(key, value);
  if (!hadKey) {
    trigger(target, "add", key, value);
  } else if (hasChanged(value, oldValue)) {
    trigger(target, "set", key, value, oldValue);
  }
  return this;
}
function deleteEntry(key) {
  const target = toRaw(this);
  const { has: has2, get: get3 } = getProto(target);
  let hadKey = has2.call(target, key);
  if (!hadKey) {
    key = toRaw(key);
    hadKey = has2.call(target, key);
  } else {
    checkIdentityKeys(target, has2, key);
  }
  const oldValue = get3 ? get3.call(target, key) : void 0;
  const result = target.delete(key);
  if (hadKey) {
    trigger(target, "delete", key, void 0, oldValue);
  }
  return result;
}
function clear() {
  const target = toRaw(this);
  const hadItems = target.size !== 0;
  const oldTarget = isMap(target) ? new Map(target) : new Set(target);
  const result = target.clear();
  if (hadItems) {
    trigger(target, "clear", void 0, void 0, oldTarget);
  }
  return result;
}
function createForEach(isReadonly, isShallow) {
  return function forEach(callback, thisArg) {
    const observed = this;
    const target = observed[
      "__v_raw"
      /* RAW */
    ];
    const rawTarget = toRaw(target);
    const wrap = isShallow ? toShallow : isReadonly ? toReadonly : toReactive;
    !isReadonly && track(rawTarget, "iterate", ITERATE_KEY);
    return target.forEach((value, key) => {
      return callback.call(thisArg, wrap(value), wrap(key), observed);
    });
  };
}
function createIterableMethod(method, isReadonly, isShallow) {
  return function(...args) {
    const target = this[
      "__v_raw"
      /* RAW */
    ];
    const rawTarget = toRaw(target);
    const targetIsMap = isMap(rawTarget);
    const isPair = method === "entries" || method === Symbol.iterator && targetIsMap;
    const isKeyOnly = method === "keys" && targetIsMap;
    const innerIterator = target[method](...args);
    const wrap = isShallow ? toShallow : isReadonly ? toReadonly : toReactive;
    !isReadonly && track(rawTarget, "iterate", isKeyOnly ? MAP_KEY_ITERATE_KEY : ITERATE_KEY);
    return {
      // iterator protocol
      next() {
        const { value, done } = innerIterator.next();
        return done ? { value, done } : {
          value: isPair ? [wrap(value[0]), wrap(value[1])] : wrap(value),
          done
        };
      },
      // iterable protocol
      [Symbol.iterator]() {
        return this;
      }
    };
  };
}
function createReadonlyMethod(type) {
  return function(...args) {
    {
      const key = args[0] ? `on key "${args[0]}" ` : ``;
      console.warn(`${capitalize(type)} operation ${key}failed: target is readonly.`, toRaw(this));
    }
    return type === "delete" ? false : this;
  };
}
function createInstrumentations() {
  const mutableInstrumentations2 = {
    get(key) {
      return get$1(this, key);
    },
    get size() {
      return size(this);
    },
    has: has$1,
    add,
    set: set$1,
    delete: deleteEntry,
    clear,
    forEach: createForEach(false, false)
  };
  const shallowInstrumentations2 = {
    get(key) {
      return get$1(this, key, false, true);
    },
    get size() {
      return size(this);
    },
    has: has$1,
    add,
    set: set$1,
    delete: deleteEntry,
    clear,
    forEach: createForEach(false, true)
  };
  const readonlyInstrumentations2 = {
    get(key) {
      return get$1(this, key, true);
    },
    get size() {
      return size(this, true);
    },
    has(key) {
      return has$1.call(this, key, true);
    },
    add: createReadonlyMethod(
      "add"
      /* ADD */
    ),
    set: createReadonlyMethod(
      "set"
      /* SET */
    ),
    delete: createReadonlyMethod(
      "delete"
      /* DELETE */
    ),
    clear: createReadonlyMethod(
      "clear"
      /* CLEAR */
    ),
    forEach: createForEach(true, false)
  };
  const shallowReadonlyInstrumentations2 = {
    get(key) {
      return get$1(this, key, true, true);
    },
    get size() {
      return size(this, true);
    },
    has(key) {
      return has$1.call(this, key, true);
    },
    add: createReadonlyMethod(
      "add"
      /* ADD */
    ),
    set: createReadonlyMethod(
      "set"
      /* SET */
    ),
    delete: createReadonlyMethod(
      "delete"
      /* DELETE */
    ),
    clear: createReadonlyMethod(
      "clear"
      /* CLEAR */
    ),
    forEach: createForEach(true, true)
  };
  const iteratorMethods = ["keys", "values", "entries", Symbol.iterator];
  iteratorMethods.forEach((method) => {
    mutableInstrumentations2[method] = createIterableMethod(method, false, false);
    readonlyInstrumentations2[method] = createIterableMethod(method, true, false);
    shallowInstrumentations2[method] = createIterableMethod(method, false, true);
    shallowReadonlyInstrumentations2[method] = createIterableMethod(method, true, true);
  });
  return [
    mutableInstrumentations2,
    readonlyInstrumentations2,
    shallowInstrumentations2,
    shallowReadonlyInstrumentations2
  ];
}
var [mutableInstrumentations, readonlyInstrumentations, shallowInstrumentations, shallowReadonlyInstrumentations] = /* @__PURE__ */ createInstrumentations();
function createInstrumentationGetter(isReadonly, shallow) {
  const instrumentations = isReadonly ? readonlyInstrumentations : mutableInstrumentations;
  return (target, key, receiver) => {
    if (key === "__v_isReactive") {
      return !isReadonly;
    } else if (key === "__v_isReadonly") {
      return isReadonly;
    } else if (key === "__v_raw") {
      return target;
    }
    return Reflect.get(hasOwn(instrumentations, key) && key in target ? instrumentations : target, key, receiver);
  };
}
var mutableCollectionHandlers = {
  get: /* @__PURE__ */ createInstrumentationGetter(false)
};
var readonlyCollectionHandlers = {
  get: /* @__PURE__ */ createInstrumentationGetter(true)
};
function checkIdentityKeys(target, has2, key) {
  const rawKey = toRaw(key);
  if (rawKey !== key && has2.call(target, rawKey)) {
    const type = toRawType(target);
    console.warn(`Reactive ${type} contains both the raw and reactive versions of the same object${type === `Map` ? ` as keys` : ``}, which can lead to inconsistencies. Avoid differentiating between the raw and reactive versions of an object and only use the reactive version if possible.`);
  }
}
var reactiveMap = /* @__PURE__ */ new WeakMap();
var shallowReactiveMap = /* @__PURE__ */ new WeakMap();
var readonlyMap = /* @__PURE__ */ new WeakMap();
var shallowReadonlyMap = /* @__PURE__ */ new WeakMap();
function targetTypeMap(rawType) {
  switch (rawType) {
    case "Object":
    case "Array":
      return 1;
    case "Map":
    case "Set":
    case "WeakMap":
    case "WeakSet":
      return 2;
    default:
      return 0;
  }
}
function getTargetType(value) {
  return value[
    "__v_skip"
    /* SKIP */
  ] || !Object.isExtensible(value) ? 0 : targetTypeMap(toRawType(value));
}
function reactive2(target) {
  if (target && target[
    "__v_isReadonly"
    /* IS_READONLY */
  ]) {
    return target;
  }
  return createReactiveObject(target, false, mutableHandlers, mutableCollectionHandlers, reactiveMap);
}
function readonly(target) {
  return createReactiveObject(target, true, readonlyHandlers, readonlyCollectionHandlers, readonlyMap);
}
function createReactiveObject(target, isReadonly, baseHandlers, collectionHandlers, proxyMap) {
  if (!isObject$2(target)) {
    {
      console.warn(`value cannot be made reactive: ${String(target)}`);
    }
    return target;
  }
  if (target[
    "__v_raw"
    /* RAW */
  ] && !(isReadonly && target[
    "__v_isReactive"
    /* IS_REACTIVE */
  ])) {
    return target;
  }
  const existingProxy = proxyMap.get(target);
  if (existingProxy) {
    return existingProxy;
  }
  const targetType = getTargetType(target);
  if (targetType === 0) {
    return target;
  }
  const proxy = new Proxy(target, targetType === 2 ? collectionHandlers : baseHandlers);
  proxyMap.set(target, proxy);
  return proxy;
}
function toRaw(observed) {
  return observed && toRaw(observed[
    "__v_raw"
    /* RAW */
  ]) || observed;
}
function isRef(r) {
  return Boolean(r && r.__v_isRef === true);
}
magic("nextTick", () => nextTick$1);
magic("dispatch", (el) => dispatch.bind(dispatch, el));
magic("watch", (el, { evaluateLater: evaluateLater2, cleanup: cleanup2 }) => (key, callback) => {
  let evaluate2 = evaluateLater2(key);
  let getter = () => {
    let value;
    evaluate2((i) => value = i);
    return value;
  };
  let unwatch = watch(getter, callback);
  cleanup2(unwatch);
});
magic("store", getStores);
magic("data", (el) => scope(el));
magic("root", (el) => closestRoot(el));
magic("refs", (el) => {
  if (el._x_refs_proxy)
    return el._x_refs_proxy;
  el._x_refs_proxy = mergeProxies(getArrayOfRefObject(el));
  return el._x_refs_proxy;
});
function getArrayOfRefObject(el) {
  let refObjects = [];
  findClosest(el, (i) => {
    if (i._x_refs)
      refObjects.push(i._x_refs);
  });
  return refObjects;
}
var globalIdMemo = {};
function findAndIncrementId(name) {
  if (!globalIdMemo[name])
    globalIdMemo[name] = 0;
  return ++globalIdMemo[name];
}
function closestIdRoot(el, name) {
  return findClosest(el, (element) => {
    if (element._x_ids && element._x_ids[name])
      return true;
  });
}
function setIdRoot(el, name) {
  if (!el._x_ids)
    el._x_ids = {};
  if (!el._x_ids[name])
    el._x_ids[name] = findAndIncrementId(name);
}
magic("id", (el, { cleanup: cleanup2 }) => (name, key = null) => {
  let cacheKey = `${name}${key ? `-${key}` : ""}`;
  return cacheIdByNameOnElement(el, cacheKey, cleanup2, () => {
    let root = closestIdRoot(el, name);
    let id = root ? root._x_ids[name] : findAndIncrementId(name);
    return key ? `${name}-${id}-${key}` : `${name}-${id}`;
  });
});
interceptClone((from, to) => {
  if (from._x_id) {
    to._x_id = from._x_id;
  }
});
function cacheIdByNameOnElement(el, cacheKey, cleanup2, callback) {
  if (!el._x_id)
    el._x_id = {};
  if (el._x_id[cacheKey])
    return el._x_id[cacheKey];
  let output = callback();
  el._x_id[cacheKey] = output;
  cleanup2(() => {
    delete el._x_id[cacheKey];
  });
  return output;
}
magic("el", (el) => el);
warnMissingPluginMagic("Focus", "focus", "focus");
warnMissingPluginMagic("Persist", "persist", "persist");
function warnMissingPluginMagic(name, magicName, slug) {
  magic(magicName, (el) => warn(`You can't use [$${magicName}] without first installing the "${name}" plugin here: https://alpinejs.dev/plugins/${slug}`, el));
}
directive("modelable", (el, { expression }, { effect: effect3, evaluateLater: evaluateLater2, cleanup: cleanup2 }) => {
  let func = evaluateLater2(expression);
  let innerGet = () => {
    let result;
    func((i) => result = i);
    return result;
  };
  let evaluateInnerSet = evaluateLater2(`${expression} = __placeholder`);
  let innerSet = (val) => evaluateInnerSet(() => {
  }, { scope: { "__placeholder": val } });
  let initialValue = innerGet();
  innerSet(initialValue);
  queueMicrotask(() => {
    if (!el._x_model)
      return;
    el._x_removeModelListeners["default"]();
    let outerGet = el._x_model.get;
    let outerSet = el._x_model.set;
    let releaseEntanglement = entangle(
      {
        get() {
          return outerGet();
        },
        set(value) {
          outerSet(value);
        }
      },
      {
        get() {
          return innerGet();
        },
        set(value) {
          innerSet(value);
        }
      }
    );
    cleanup2(releaseEntanglement);
  });
});
directive("teleport", (el, { modifiers, expression }, { cleanup: cleanup2 }) => {
  if (el.tagName.toLowerCase() !== "template")
    warn("x-teleport can only be used on a <template> tag", el);
  let target = getTarget(expression);
  let clone2 = el.content.cloneNode(true).firstElementChild;
  el._x_teleport = clone2;
  clone2._x_teleportBack = el;
  el.setAttribute("data-teleport-template", true);
  clone2.setAttribute("data-teleport-target", true);
  if (el._x_forwardEvents) {
    el._x_forwardEvents.forEach((eventName) => {
      clone2.addEventListener(eventName, (e) => {
        e.stopPropagation();
        el.dispatchEvent(new e.constructor(e.type, e));
      });
    });
  }
  addScopeToNode(clone2, {}, el);
  let placeInDom = (clone3, target2, modifiers2) => {
    if (modifiers2.includes("prepend")) {
      target2.parentNode.insertBefore(clone3, target2);
    } else if (modifiers2.includes("append")) {
      target2.parentNode.insertBefore(clone3, target2.nextSibling);
    } else {
      target2.appendChild(clone3);
    }
  };
  mutateDom(() => {
    placeInDom(clone2, target, modifiers);
    skipDuringClone(() => {
      initTree(clone2);
      clone2._x_ignore = true;
    })();
  });
  el._x_teleportPutBack = () => {
    let target2 = getTarget(expression);
    mutateDom(() => {
      placeInDom(el._x_teleport, target2, modifiers);
    });
  };
  cleanup2(() => clone2.remove());
});
var teleportContainerDuringClone = document.createElement("div");
function getTarget(expression) {
  let target = skipDuringClone(() => {
    return document.querySelector(expression);
  }, () => {
    return teleportContainerDuringClone;
  })();
  if (!target)
    warn(`Cannot find x-teleport element for selector: "${expression}"`);
  return target;
}
var handler = () => {
};
handler.inline = (el, { modifiers }, { cleanup: cleanup2 }) => {
  modifiers.includes("self") ? el._x_ignoreSelf = true : el._x_ignore = true;
  cleanup2(() => {
    modifiers.includes("self") ? delete el._x_ignoreSelf : delete el._x_ignore;
  });
};
directive("ignore", handler);
directive("effect", skipDuringClone((el, { expression }, { effect: effect3 }) => {
  effect3(evaluateLater(el, expression));
}));
function on(el, event, modifiers, callback) {
  let listenerTarget = el;
  let handler4 = (e) => callback(e);
  let options = {};
  let wrapHandler = (callback2, wrapper) => (e) => wrapper(callback2, e);
  if (modifiers.includes("dot"))
    event = dotSyntax(event);
  if (modifiers.includes("camel"))
    event = camelCase2(event);
  if (modifiers.includes("passive"))
    options.passive = true;
  if (modifiers.includes("capture"))
    options.capture = true;
  if (modifiers.includes("window"))
    listenerTarget = window;
  if (modifiers.includes("document"))
    listenerTarget = document;
  if (modifiers.includes("debounce")) {
    let nextModifier = modifiers[modifiers.indexOf("debounce") + 1] || "invalid-wait";
    let wait = isNumeric(nextModifier.split("ms")[0]) ? Number(nextModifier.split("ms")[0]) : 250;
    handler4 = debounce(handler4, wait);
  }
  if (modifiers.includes("throttle")) {
    let nextModifier = modifiers[modifiers.indexOf("throttle") + 1] || "invalid-wait";
    let wait = isNumeric(nextModifier.split("ms")[0]) ? Number(nextModifier.split("ms")[0]) : 250;
    handler4 = throttle(handler4, wait);
  }
  if (modifiers.includes("prevent"))
    handler4 = wrapHandler(handler4, (next, e) => {
      e.preventDefault();
      next(e);
    });
  if (modifiers.includes("stop"))
    handler4 = wrapHandler(handler4, (next, e) => {
      e.stopPropagation();
      next(e);
    });
  if (modifiers.includes("once")) {
    handler4 = wrapHandler(handler4, (next, e) => {
      next(e);
      listenerTarget.removeEventListener(event, handler4, options);
    });
  }
  if (modifiers.includes("away") || modifiers.includes("outside")) {
    listenerTarget = document;
    handler4 = wrapHandler(handler4, (next, e) => {
      if (el.contains(e.target))
        return;
      if (e.target.isConnected === false)
        return;
      if (el.offsetWidth < 1 && el.offsetHeight < 1)
        return;
      if (el._x_isShown === false)
        return;
      next(e);
    });
  }
  if (modifiers.includes("self"))
    handler4 = wrapHandler(handler4, (next, e) => {
      e.target === el && next(e);
    });
  if (isKeyEvent(event) || isClickEvent(event)) {
    handler4 = wrapHandler(handler4, (next, e) => {
      if (isListeningForASpecificKeyThatHasntBeenPressed(e, modifiers)) {
        return;
      }
      next(e);
    });
  }
  listenerTarget.addEventListener(event, handler4, options);
  return () => {
    listenerTarget.removeEventListener(event, handler4, options);
  };
}
function dotSyntax(subject) {
  return subject.replace(/-/g, ".");
}
function camelCase2(subject) {
  return subject.toLowerCase().replace(/-(\w)/g, (match, char) => char.toUpperCase());
}
function isNumeric(subject) {
  return !Array.isArray(subject) && !isNaN(subject);
}
function kebabCase2(subject) {
  if ([" ", "_"].includes(
    subject
  ))
    return subject;
  return subject.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/[_\s]/, "-").toLowerCase();
}
function isKeyEvent(event) {
  return ["keydown", "keyup"].includes(event);
}
function isClickEvent(event) {
  return ["contextmenu", "click", "mouse"].some((i) => event.includes(i));
}
function isListeningForASpecificKeyThatHasntBeenPressed(e, modifiers) {
  let keyModifiers = modifiers.filter((i) => {
    return !["window", "document", "prevent", "stop", "once", "capture", "self", "away", "outside", "passive"].includes(i);
  });
  if (keyModifiers.includes("debounce")) {
    let debounceIndex = keyModifiers.indexOf("debounce");
    keyModifiers.splice(debounceIndex, isNumeric((keyModifiers[debounceIndex + 1] || "invalid-wait").split("ms")[0]) ? 2 : 1);
  }
  if (keyModifiers.includes("throttle")) {
    let debounceIndex = keyModifiers.indexOf("throttle");
    keyModifiers.splice(debounceIndex, isNumeric((keyModifiers[debounceIndex + 1] || "invalid-wait").split("ms")[0]) ? 2 : 1);
  }
  if (keyModifiers.length === 0)
    return false;
  if (keyModifiers.length === 1 && keyToModifiers(e.key).includes(keyModifiers[0]))
    return false;
  const systemKeyModifiers = ["ctrl", "shift", "alt", "meta", "cmd", "super"];
  const selectedSystemKeyModifiers = systemKeyModifiers.filter((modifier) => keyModifiers.includes(modifier));
  keyModifiers = keyModifiers.filter((i) => !selectedSystemKeyModifiers.includes(i));
  if (selectedSystemKeyModifiers.length > 0) {
    const activelyPressedKeyModifiers = selectedSystemKeyModifiers.filter((modifier) => {
      if (modifier === "cmd" || modifier === "super")
        modifier = "meta";
      return e[`${modifier}Key`];
    });
    if (activelyPressedKeyModifiers.length === selectedSystemKeyModifiers.length) {
      if (isClickEvent(e.type))
        return false;
      if (keyToModifiers(e.key).includes(keyModifiers[0]))
        return false;
    }
  }
  return true;
}
function keyToModifiers(key) {
  if (!key)
    return [];
  key = kebabCase2(key);
  let modifierToKeyMap = {
    "ctrl": "control",
    "slash": "/",
    "space": " ",
    "spacebar": " ",
    "cmd": "meta",
    "esc": "escape",
    "up": "arrow-up",
    "down": "arrow-down",
    "left": "arrow-left",
    "right": "arrow-right",
    "period": ".",
    "comma": ",",
    "equal": "=",
    "minus": "-",
    "underscore": "_"
  };
  modifierToKeyMap[key] = key;
  return Object.keys(modifierToKeyMap).map((modifier) => {
    if (modifierToKeyMap[modifier] === key)
      return modifier;
  }).filter((modifier) => modifier);
}
directive("model", (el, { modifiers, expression }, { effect: effect3, cleanup: cleanup2 }) => {
  let scopeTarget = el;
  if (modifiers.includes("parent")) {
    scopeTarget = el.parentNode;
  }
  let evaluateGet = evaluateLater(scopeTarget, expression);
  let evaluateSet;
  if (typeof expression === "string") {
    evaluateSet = evaluateLater(scopeTarget, `${expression} = __placeholder`);
  } else if (typeof expression === "function" && typeof expression() === "string") {
    evaluateSet = evaluateLater(scopeTarget, `${expression()} = __placeholder`);
  } else {
    evaluateSet = () => {
    };
  }
  let getValue = () => {
    let result;
    evaluateGet((value) => result = value);
    return isGetterSetter(result) ? result.get() : result;
  };
  let setValue = (value) => {
    let result;
    evaluateGet((value2) => result = value2);
    if (isGetterSetter(result)) {
      result.set(value);
    } else {
      evaluateSet(() => {
      }, {
        scope: { "__placeholder": value }
      });
    }
  };
  if (typeof expression === "string" && el.type === "radio") {
    mutateDom(() => {
      if (!el.hasAttribute("name"))
        el.setAttribute("name", expression);
    });
  }
  var event = el.tagName.toLowerCase() === "select" || ["checkbox", "radio"].includes(el.type) || modifiers.includes("lazy") ? "change" : "input";
  let removeListener = isCloning ? () => {
  } : on(el, event, modifiers, (e) => {
    setValue(getInputValue(el, modifiers, e, getValue()));
  });
  if (modifiers.includes("fill")) {
    if ([void 0, null, ""].includes(getValue()) || el.type === "checkbox" && Array.isArray(getValue()) || el.tagName.toLowerCase() === "select" && el.multiple) {
      setValue(
        getInputValue(el, modifiers, { target: el }, getValue())
      );
    }
  }
  if (!el._x_removeModelListeners)
    el._x_removeModelListeners = {};
  el._x_removeModelListeners["default"] = removeListener;
  cleanup2(() => el._x_removeModelListeners["default"]());
  if (el.form) {
    let removeResetListener = on(el.form, "reset", [], (e) => {
      nextTick$1(() => el._x_model && el._x_model.set(getInputValue(el, modifiers, { target: el }, getValue())));
    });
    cleanup2(() => removeResetListener());
  }
  el._x_model = {
    get() {
      return getValue();
    },
    set(value) {
      setValue(value);
    }
  };
  el._x_forceModelUpdate = (value) => {
    if (value === void 0 && typeof expression === "string" && expression.match(/\./))
      value = "";
    window.fromModel = true;
    mutateDom(() => bind(el, "value", value));
    delete window.fromModel;
  };
  effect3(() => {
    let value = getValue();
    if (modifiers.includes("unintrusive") && document.activeElement.isSameNode(el))
      return;
    el._x_forceModelUpdate(value);
  });
});
function getInputValue(el, modifiers, event, currentValue) {
  return mutateDom(() => {
    if (event instanceof CustomEvent && event.detail !== void 0)
      return event.detail !== null && event.detail !== void 0 ? event.detail : event.target.value;
    else if (el.type === "checkbox") {
      if (Array.isArray(currentValue)) {
        let newValue = null;
        if (modifiers.includes("number")) {
          newValue = safeParseNumber(event.target.value);
        } else if (modifiers.includes("boolean")) {
          newValue = safeParseBoolean(event.target.value);
        } else {
          newValue = event.target.value;
        }
        return event.target.checked ? currentValue.includes(newValue) ? currentValue : currentValue.concat([newValue]) : currentValue.filter((el2) => !checkedAttrLooseCompare2(el2, newValue));
      } else {
        return event.target.checked;
      }
    } else if (el.tagName.toLowerCase() === "select" && el.multiple) {
      if (modifiers.includes("number")) {
        return Array.from(event.target.selectedOptions).map((option) => {
          let rawValue = option.value || option.text;
          return safeParseNumber(rawValue);
        });
      } else if (modifiers.includes("boolean")) {
        return Array.from(event.target.selectedOptions).map((option) => {
          let rawValue = option.value || option.text;
          return safeParseBoolean(rawValue);
        });
      }
      return Array.from(event.target.selectedOptions).map((option) => {
        return option.value || option.text;
      });
    } else {
      let newValue;
      if (el.type === "radio") {
        if (event.target.checked) {
          newValue = event.target.value;
        } else {
          newValue = currentValue;
        }
      } else {
        newValue = event.target.value;
      }
      if (modifiers.includes("number")) {
        return safeParseNumber(newValue);
      } else if (modifiers.includes("boolean")) {
        return safeParseBoolean(newValue);
      } else if (modifiers.includes("trim")) {
        return newValue.trim();
      } else {
        return newValue;
      }
    }
  });
}
function safeParseNumber(rawValue) {
  let number = rawValue ? parseFloat(rawValue) : null;
  return isNumeric2(number) ? number : rawValue;
}
function checkedAttrLooseCompare2(valueA, valueB) {
  return valueA == valueB;
}
function isNumeric2(subject) {
  return !Array.isArray(subject) && !isNaN(subject);
}
function isGetterSetter(value) {
  return value !== null && typeof value === "object" && typeof value.get === "function" && typeof value.set === "function";
}
directive("cloak", (el) => queueMicrotask(() => mutateDom(() => el.removeAttribute(prefix("cloak")))));
addInitSelector(() => `[${prefix("init")}]`);
directive("init", skipDuringClone((el, { expression }, { evaluate: evaluate2 }) => {
  if (typeof expression === "string") {
    return !!expression.trim() && evaluate2(expression, {}, false);
  }
  return evaluate2(expression, {}, false);
}));
directive("text", (el, { expression }, { effect: effect3, evaluateLater: evaluateLater2 }) => {
  let evaluate2 = evaluateLater2(expression);
  effect3(() => {
    evaluate2((value) => {
      mutateDom(() => {
        el.textContent = value;
      });
    });
  });
});
directive("html", (el, { expression }, { effect: effect3, evaluateLater: evaluateLater2 }) => {
  let evaluate2 = evaluateLater2(expression);
  effect3(() => {
    evaluate2((value) => {
      mutateDom(() => {
        el.innerHTML = value;
        el._x_ignoreSelf = true;
        initTree(el);
        delete el._x_ignoreSelf;
      });
    });
  });
});
mapAttributes(startingWith(":", into(prefix("bind:"))));
var handler2 = (el, { value, modifiers, expression, original }, { effect: effect3, cleanup: cleanup2 }) => {
  if (!value) {
    let bindingProviders = {};
    injectBindingProviders(bindingProviders);
    let getBindings = evaluateLater(el, expression);
    getBindings((bindings) => {
      applyBindingsObject(el, bindings, original);
    }, { scope: bindingProviders });
    return;
  }
  if (value === "key")
    return storeKeyForXFor(el, expression);
  if (el._x_inlineBindings && el._x_inlineBindings[value] && el._x_inlineBindings[value].extract) {
    return;
  }
  let evaluate2 = evaluateLater(el, expression);
  effect3(() => evaluate2((result) => {
    if (result === void 0 && typeof expression === "string" && expression.match(/\./)) {
      result = "";
    }
    mutateDom(() => bind(el, value, result, modifiers));
  }));
  cleanup2(() => {
    el._x_undoAddedClasses && el._x_undoAddedClasses();
    el._x_undoAddedStyles && el._x_undoAddedStyles();
  });
};
handler2.inline = (el, { value, modifiers, expression }) => {
  if (!value)
    return;
  if (!el._x_inlineBindings)
    el._x_inlineBindings = {};
  el._x_inlineBindings[value] = { expression, extract: false };
};
directive("bind", handler2);
function storeKeyForXFor(el, expression) {
  el._x_keyExpression = expression;
}
addRootSelector(() => `[${prefix("data")}]`);
directive("data", (el, { expression }, { cleanup: cleanup2 }) => {
  if (shouldSkipRegisteringDataDuringClone(el))
    return;
  expression = expression === "" ? "{}" : expression;
  let magicContext = {};
  injectMagics(magicContext, el);
  let dataProviderContext = {};
  injectDataProviders(dataProviderContext, magicContext);
  let data2 = evaluate(el, expression, { scope: dataProviderContext });
  if (data2 === void 0 || data2 === true)
    data2 = {};
  injectMagics(data2, el);
  let reactiveData = reactive(data2);
  initInterceptors(reactiveData);
  let undo = addScopeToNode(el, reactiveData);
  reactiveData["init"] && evaluate(el, reactiveData["init"]);
  cleanup2(() => {
    reactiveData["destroy"] && evaluate(el, reactiveData["destroy"]);
    undo();
  });
});
interceptClone((from, to) => {
  if (from._x_dataStack) {
    to._x_dataStack = from._x_dataStack;
    to.setAttribute("data-has-alpine-state", true);
  }
});
function shouldSkipRegisteringDataDuringClone(el) {
  if (!isCloning)
    return false;
  if (isCloningLegacy)
    return true;
  return el.hasAttribute("data-has-alpine-state");
}
directive("show", (el, { modifiers, expression }, { effect: effect3 }) => {
  let evaluate2 = evaluateLater(el, expression);
  if (!el._x_doHide)
    el._x_doHide = () => {
      mutateDom(() => {
        el.style.setProperty("display", "none", modifiers.includes("important") ? "important" : void 0);
      });
    };
  if (!el._x_doShow)
    el._x_doShow = () => {
      mutateDom(() => {
        if (el.style.length === 1 && el.style.display === "none") {
          el.removeAttribute("style");
        } else {
          el.style.removeProperty("display");
        }
      });
    };
  let hide = () => {
    el._x_doHide();
    el._x_isShown = false;
  };
  let show = () => {
    el._x_doShow();
    el._x_isShown = true;
  };
  let clickAwayCompatibleShow = () => setTimeout(show);
  let toggle = once(
    (value) => value ? show() : hide(),
    (value) => {
      if (typeof el._x_toggleAndCascadeWithTransitions === "function") {
        el._x_toggleAndCascadeWithTransitions(el, value, show, hide);
      } else {
        value ? clickAwayCompatibleShow() : hide();
      }
    }
  );
  let oldValue;
  let firstTime = true;
  effect3(() => evaluate2((value) => {
    if (!firstTime && value === oldValue)
      return;
    if (modifiers.includes("immediate"))
      value ? clickAwayCompatibleShow() : hide();
    toggle(value);
    oldValue = value;
    firstTime = false;
  }));
});
directive("for", (el, { expression }, { effect: effect3, cleanup: cleanup2 }) => {
  let iteratorNames = parseForExpression(expression);
  let evaluateItems = evaluateLater(el, iteratorNames.items);
  let evaluateKey = evaluateLater(
    el,
    // the x-bind:key expression is stored for our use instead of evaluated.
    el._x_keyExpression || "index"
  );
  el._x_prevKeys = [];
  el._x_lookup = {};
  effect3(() => loop$1(el, iteratorNames, evaluateItems, evaluateKey));
  cleanup2(() => {
    Object.values(el._x_lookup).forEach((el2) => el2.remove());
    delete el._x_prevKeys;
    delete el._x_lookup;
  });
});
function loop$1(el, iteratorNames, evaluateItems, evaluateKey) {
  let isObject2 = (i) => typeof i === "object" && !Array.isArray(i);
  let templateEl = el;
  evaluateItems((items) => {
    if (isNumeric3(items) && items >= 0) {
      items = Array.from(Array(items).keys(), (i) => i + 1);
    }
    if (items === void 0)
      items = [];
    let lookup = el._x_lookup;
    let prevKeys = el._x_prevKeys;
    let scopes = [];
    let keys = [];
    if (isObject2(items)) {
      items = Object.entries(items).map(([key, value]) => {
        let scope2 = getIterationScopeVariables(iteratorNames, value, key, items);
        evaluateKey((value2) => {
          if (keys.includes(value2))
            warn("Duplicate key on x-for", el);
          keys.push(value2);
        }, { scope: { index: key, ...scope2 } });
        scopes.push(scope2);
      });
    } else {
      for (let i = 0; i < items.length; i++) {
        let scope2 = getIterationScopeVariables(iteratorNames, items[i], i, items);
        evaluateKey((value) => {
          if (keys.includes(value))
            warn("Duplicate key on x-for", el);
          keys.push(value);
        }, { scope: { index: i, ...scope2 } });
        scopes.push(scope2);
      }
    }
    let adds = [];
    let moves = [];
    let removes = [];
    let sames = [];
    for (let i = 0; i < prevKeys.length; i++) {
      let key = prevKeys[i];
      if (keys.indexOf(key) === -1)
        removes.push(key);
    }
    prevKeys = prevKeys.filter((key) => !removes.includes(key));
    let lastKey = "template";
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let prevIndex = prevKeys.indexOf(key);
      if (prevIndex === -1) {
        prevKeys.splice(i, 0, key);
        adds.push([lastKey, i]);
      } else if (prevIndex !== i) {
        let keyInSpot = prevKeys.splice(i, 1)[0];
        let keyForSpot = prevKeys.splice(prevIndex - 1, 1)[0];
        prevKeys.splice(i, 0, keyForSpot);
        prevKeys.splice(prevIndex, 0, keyInSpot);
        moves.push([keyInSpot, keyForSpot]);
      } else {
        sames.push(key);
      }
      lastKey = key;
    }
    for (let i = 0; i < removes.length; i++) {
      let key = removes[i];
      if (!!lookup[key]._x_effects) {
        lookup[key]._x_effects.forEach(dequeueJob);
      }
      lookup[key].remove();
      lookup[key] = null;
      delete lookup[key];
    }
    for (let i = 0; i < moves.length; i++) {
      let [keyInSpot, keyForSpot] = moves[i];
      let elInSpot = lookup[keyInSpot];
      let elForSpot = lookup[keyForSpot];
      let marker = document.createElement("div");
      mutateDom(() => {
        if (!elForSpot)
          warn(`x-for ":key" is undefined or invalid`, templateEl, keyForSpot, lookup);
        elForSpot.after(marker);
        elInSpot.after(elForSpot);
        elForSpot._x_currentIfEl && elForSpot.after(elForSpot._x_currentIfEl);
        marker.before(elInSpot);
        elInSpot._x_currentIfEl && elInSpot.after(elInSpot._x_currentIfEl);
        marker.remove();
      });
      elForSpot._x_refreshXForScope(scopes[keys.indexOf(keyForSpot)]);
    }
    for (let i = 0; i < adds.length; i++) {
      let [lastKey2, index] = adds[i];
      let lastEl = lastKey2 === "template" ? templateEl : lookup[lastKey2];
      if (lastEl._x_currentIfEl)
        lastEl = lastEl._x_currentIfEl;
      let scope2 = scopes[index];
      let key = keys[index];
      let clone2 = document.importNode(templateEl.content, true).firstElementChild;
      let reactiveScope = reactive(scope2);
      addScopeToNode(clone2, reactiveScope, templateEl);
      clone2._x_refreshXForScope = (newScope) => {
        Object.entries(newScope).forEach(([key2, value]) => {
          reactiveScope[key2] = value;
        });
      };
      mutateDom(() => {
        lastEl.after(clone2);
        skipDuringClone(() => initTree(clone2))();
      });
      if (typeof key === "object") {
        warn("x-for key cannot be an object, it must be a string or an integer", templateEl);
      }
      lookup[key] = clone2;
    }
    for (let i = 0; i < sames.length; i++) {
      lookup[sames[i]]._x_refreshXForScope(scopes[keys.indexOf(sames[i])]);
    }
    templateEl._x_prevKeys = keys;
  });
}
function parseForExpression(expression) {
  let forIteratorRE = /,([^,\}\]]*)(?:,([^,\}\]]*))?$/;
  let stripParensRE = /^\s*\(|\)\s*$/g;
  let forAliasRE = /([\s\S]*?)\s+(?:in|of)\s+([\s\S]*)/;
  let inMatch = expression.match(forAliasRE);
  if (!inMatch)
    return;
  let res = {};
  res.items = inMatch[2].trim();
  let item = inMatch[1].replace(stripParensRE, "").trim();
  let iteratorMatch = item.match(forIteratorRE);
  if (iteratorMatch) {
    res.item = item.replace(forIteratorRE, "").trim();
    res.index = iteratorMatch[1].trim();
    if (iteratorMatch[2]) {
      res.collection = iteratorMatch[2].trim();
    }
  } else {
    res.item = item;
  }
  return res;
}
function getIterationScopeVariables(iteratorNames, item, index, items) {
  let scopeVariables = {};
  if (/^\[.*\]$/.test(iteratorNames.item) && Array.isArray(item)) {
    let names = iteratorNames.item.replace("[", "").replace("]", "").split(",").map((i) => i.trim());
    names.forEach((name, i) => {
      scopeVariables[name] = item[i];
    });
  } else if (/^\{.*\}$/.test(iteratorNames.item) && !Array.isArray(item) && typeof item === "object") {
    let names = iteratorNames.item.replace("{", "").replace("}", "").split(",").map((i) => i.trim());
    names.forEach((name) => {
      scopeVariables[name] = item[name];
    });
  } else {
    scopeVariables[iteratorNames.item] = item;
  }
  if (iteratorNames.index)
    scopeVariables[iteratorNames.index] = index;
  if (iteratorNames.collection)
    scopeVariables[iteratorNames.collection] = items;
  return scopeVariables;
}
function isNumeric3(subject) {
  return !Array.isArray(subject) && !isNaN(subject);
}
function handler3() {
}
handler3.inline = (el, { expression }, { cleanup: cleanup2 }) => {
  let root = closestRoot(el);
  if (!root._x_refs)
    root._x_refs = {};
  root._x_refs[expression] = el;
  cleanup2(() => delete root._x_refs[expression]);
};
directive("ref", handler3);
directive("if", (el, { expression }, { effect: effect3, cleanup: cleanup2 }) => {
  if (el.tagName.toLowerCase() !== "template")
    warn("x-if can only be used on a <template> tag", el);
  let evaluate2 = evaluateLater(el, expression);
  let show = () => {
    if (el._x_currentIfEl)
      return el._x_currentIfEl;
    let clone2 = el.content.cloneNode(true).firstElementChild;
    addScopeToNode(clone2, {}, el);
    mutateDom(() => {
      el.after(clone2);
      skipDuringClone(() => initTree(clone2))();
    });
    el._x_currentIfEl = clone2;
    el._x_undoIf = () => {
      walk(clone2, (node) => {
        if (!!node._x_effects) {
          node._x_effects.forEach(dequeueJob);
        }
      });
      clone2.remove();
      delete el._x_currentIfEl;
    };
    return clone2;
  };
  let hide = () => {
    if (!el._x_undoIf)
      return;
    el._x_undoIf();
    delete el._x_undoIf;
  };
  effect3(() => evaluate2((value) => {
    value ? show() : hide();
  }));
  cleanup2(() => el._x_undoIf && el._x_undoIf());
});
directive("id", (el, { expression }, { evaluate: evaluate2 }) => {
  let names = evaluate2(expression);
  names.forEach((name) => setIdRoot(el, name));
});
interceptClone((from, to) => {
  if (from._x_ids) {
    to._x_ids = from._x_ids;
  }
});
mapAttributes(startingWith("@", into(prefix("on:"))));
directive("on", skipDuringClone((el, { value, modifiers, expression }, { cleanup: cleanup2 }) => {
  let evaluate2 = expression ? evaluateLater(el, expression) : () => {
  };
  if (el.tagName.toLowerCase() === "template") {
    if (!el._x_forwardEvents)
      el._x_forwardEvents = [];
    if (!el._x_forwardEvents.includes(value))
      el._x_forwardEvents.push(value);
  }
  let removeListener = on(el, value, modifiers, (e) => {
    evaluate2(() => {
    }, { scope: { "$event": e }, params: [e] });
  });
  cleanup2(() => removeListener());
}));
warnMissingPluginDirective("Collapse", "collapse", "collapse");
warnMissingPluginDirective("Intersect", "intersect", "intersect");
warnMissingPluginDirective("Focus", "trap", "focus");
warnMissingPluginDirective("Mask", "mask", "mask");
function warnMissingPluginDirective(name, directiveName, slug) {
  directive(directiveName, (el) => warn(`You can't use [x-${directiveName}] without first installing the "${name}" plugin here: https://alpinejs.dev/plugins/${slug}`, el));
}
alpine_default.setEvaluator(normalEvaluator);
alpine_default.setReactivityEngine({ reactive: reactive2, effect: effect2, release: stop, raw: toRaw });
var src_default$3 = alpine_default;
var module_default$3 = src_default$3;
var candidateSelectors = ["input", "select", "textarea", "a[href]", "button", "[tabindex]:not(slot)", "audio[controls]", "video[controls]", '[contenteditable]:not([contenteditable="false"])', "details>summary:first-of-type", "details"];
var candidateSelector = /* @__PURE__ */ candidateSelectors.join(",");
var NoElement = typeof Element === "undefined";
var matches = NoElement ? function() {
} : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
var getRootNode = !NoElement && Element.prototype.getRootNode ? function(element) {
  return element.getRootNode();
} : function(element) {
  return element.ownerDocument;
};
var getCandidates = function getCandidates2(el, includeContainer, filter) {
  var candidates = Array.prototype.slice.apply(el.querySelectorAll(candidateSelector));
  if (includeContainer && matches.call(el, candidateSelector)) {
    candidates.unshift(el);
  }
  candidates = candidates.filter(filter);
  return candidates;
};
var getCandidatesIteratively = function getCandidatesIteratively2(elements, includeContainer, options) {
  var candidates = [];
  var elementsToCheck = Array.from(elements);
  while (elementsToCheck.length) {
    var element = elementsToCheck.shift();
    if (element.tagName === "SLOT") {
      var assigned = element.assignedElements();
      var content = assigned.length ? assigned : element.children;
      var nestedCandidates = getCandidatesIteratively2(content, true, options);
      if (options.flatten) {
        candidates.push.apply(candidates, nestedCandidates);
      } else {
        candidates.push({
          scope: element,
          candidates: nestedCandidates
        });
      }
    } else {
      var validCandidate = matches.call(element, candidateSelector);
      if (validCandidate && options.filter(element) && (includeContainer || !elements.includes(element))) {
        candidates.push(element);
      }
      var shadowRoot = element.shadowRoot || // check for an undisclosed shadow
      typeof options.getShadowRoot === "function" && options.getShadowRoot(element);
      var validShadowRoot = !options.shadowRootFilter || options.shadowRootFilter(element);
      if (shadowRoot && validShadowRoot) {
        var _nestedCandidates = getCandidatesIteratively2(shadowRoot === true ? element.children : shadowRoot.children, true, options);
        if (options.flatten) {
          candidates.push.apply(candidates, _nestedCandidates);
        } else {
          candidates.push({
            scope: element,
            candidates: _nestedCandidates
          });
        }
      } else {
        elementsToCheck.unshift.apply(elementsToCheck, element.children);
      }
    }
  }
  return candidates;
};
var getTabindex = function getTabindex2(node, isScope) {
  if (node.tabIndex < 0) {
    if ((isScope || /^(AUDIO|VIDEO|DETAILS)$/.test(node.tagName) || node.isContentEditable) && isNaN(parseInt(node.getAttribute("tabindex"), 10))) {
      return 0;
    }
  }
  return node.tabIndex;
};
var sortOrderedTabbables = function sortOrderedTabbables2(a, b) {
  return a.tabIndex === b.tabIndex ? a.documentOrder - b.documentOrder : a.tabIndex - b.tabIndex;
};
var isInput = function isInput2(node) {
  return node.tagName === "INPUT";
};
var isHiddenInput = function isHiddenInput2(node) {
  return isInput(node) && node.type === "hidden";
};
var isDetailsWithSummary = function isDetailsWithSummary2(node) {
  var r = node.tagName === "DETAILS" && Array.prototype.slice.apply(node.children).some(function(child) {
    return child.tagName === "SUMMARY";
  });
  return r;
};
var getCheckedRadio = function getCheckedRadio2(nodes, form) {
  for (var i = 0; i < nodes.length; i++) {
    if (nodes[i].checked && nodes[i].form === form) {
      return nodes[i];
    }
  }
};
var isTabbableRadio = function isTabbableRadio2(node) {
  if (!node.name) {
    return true;
  }
  var radioScope = node.form || getRootNode(node);
  var queryRadios = function queryRadios2(name) {
    return radioScope.querySelectorAll('input[type="radio"][name="' + name + '"]');
  };
  var radioSet;
  if (typeof window !== "undefined" && typeof window.CSS !== "undefined" && typeof window.CSS.escape === "function") {
    radioSet = queryRadios(window.CSS.escape(node.name));
  } else {
    try {
      radioSet = queryRadios(node.name);
    } catch (err) {
      console.error("Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s", err.message);
      return false;
    }
  }
  var checked = getCheckedRadio(radioSet, node.form);
  return !checked || checked === node;
};
var isRadio = function isRadio2(node) {
  return isInput(node) && node.type === "radio";
};
var isNonTabbableRadio = function isNonTabbableRadio2(node) {
  return isRadio(node) && !isTabbableRadio(node);
};
var isZeroArea = function isZeroArea2(node) {
  var _node$getBoundingClie = node.getBoundingClientRect(), width = _node$getBoundingClie.width, height = _node$getBoundingClie.height;
  return width === 0 && height === 0;
};
var isHidden = function isHidden2(node, _ref) {
  var displayCheck = _ref.displayCheck, getShadowRoot = _ref.getShadowRoot;
  if (getComputedStyle(node).visibility === "hidden") {
    return true;
  }
  var isDirectSummary = matches.call(node, "details>summary:first-of-type");
  var nodeUnderDetails = isDirectSummary ? node.parentElement : node;
  if (matches.call(nodeUnderDetails, "details:not([open]) *")) {
    return true;
  }
  var nodeRootHost = getRootNode(node).host;
  var nodeIsAttached = (nodeRootHost === null || nodeRootHost === void 0 ? void 0 : nodeRootHost.ownerDocument.contains(nodeRootHost)) || node.ownerDocument.contains(node);
  if (!displayCheck || displayCheck === "full") {
    if (typeof getShadowRoot === "function") {
      var originalNode = node;
      while (node) {
        var parentElement = node.parentElement;
        var rootNode = getRootNode(node);
        if (parentElement && !parentElement.shadowRoot && getShadowRoot(parentElement) === true) {
          return isZeroArea(node);
        } else if (node.assignedSlot) {
          node = node.assignedSlot;
        } else if (!parentElement && rootNode !== node.ownerDocument) {
          node = rootNode.host;
        } else {
          node = parentElement;
        }
      }
      node = originalNode;
    }
    if (nodeIsAttached) {
      return !node.getClientRects().length;
    }
  } else if (displayCheck === "non-zero-area") {
    return isZeroArea(node);
  }
  return false;
};
var isDisabledFromFieldset = function isDisabledFromFieldset2(node) {
  if (/^(INPUT|BUTTON|SELECT|TEXTAREA)$/.test(node.tagName)) {
    var parentNode = node.parentElement;
    while (parentNode) {
      if (parentNode.tagName === "FIELDSET" && parentNode.disabled) {
        for (var i = 0; i < parentNode.children.length; i++) {
          var child = parentNode.children.item(i);
          if (child.tagName === "LEGEND") {
            return matches.call(parentNode, "fieldset[disabled] *") ? true : !child.contains(node);
          }
        }
        return true;
      }
      parentNode = parentNode.parentElement;
    }
  }
  return false;
};
var isNodeMatchingSelectorFocusable = function isNodeMatchingSelectorFocusable2(options, node) {
  if (node.disabled || isHiddenInput(node) || isHidden(node, options) || // For a details element with a summary, the summary element gets the focus
  isDetailsWithSummary(node) || isDisabledFromFieldset(node)) {
    return false;
  }
  return true;
};
var isNodeMatchingSelectorTabbable = function isNodeMatchingSelectorTabbable2(options, node) {
  if (isNonTabbableRadio(node) || getTabindex(node) < 0 || !isNodeMatchingSelectorFocusable(options, node)) {
    return false;
  }
  return true;
};
var isValidShadowRootTabbable = function isValidShadowRootTabbable2(shadowHostNode) {
  var tabIndex = parseInt(shadowHostNode.getAttribute("tabindex"), 10);
  if (isNaN(tabIndex) || tabIndex >= 0) {
    return true;
  }
  return false;
};
var sortByOrder = function sortByOrder2(candidates) {
  var regularTabbables = [];
  var orderedTabbables = [];
  candidates.forEach(function(item, i) {
    var isScope = !!item.scope;
    var element = isScope ? item.scope : item;
    var candidateTabindex = getTabindex(element, isScope);
    var elements = isScope ? sortByOrder2(item.candidates) : element;
    if (candidateTabindex === 0) {
      isScope ? regularTabbables.push.apply(regularTabbables, elements) : regularTabbables.push(element);
    } else {
      orderedTabbables.push({
        documentOrder: i,
        tabIndex: candidateTabindex,
        item,
        isScope,
        content: elements
      });
    }
  });
  return orderedTabbables.sort(sortOrderedTabbables).reduce(function(acc, sortable) {
    sortable.isScope ? acc.push.apply(acc, sortable.content) : acc.push(sortable.content);
    return acc;
  }, []).concat(regularTabbables);
};
var tabbable = function tabbable2(el, options) {
  options = options || {};
  var candidates;
  if (options.getShadowRoot) {
    candidates = getCandidatesIteratively([el], options.includeContainer, {
      filter: isNodeMatchingSelectorTabbable.bind(null, options),
      flatten: false,
      getShadowRoot: options.getShadowRoot,
      shadowRootFilter: isValidShadowRootTabbable
    });
  } else {
    candidates = getCandidates(el, options.includeContainer, isNodeMatchingSelectorTabbable.bind(null, options));
  }
  return sortByOrder(candidates);
};
var focusable = function focusable2(el, options) {
  options = options || {};
  var candidates;
  if (options.getShadowRoot) {
    candidates = getCandidatesIteratively([el], options.includeContainer, {
      filter: isNodeMatchingSelectorFocusable.bind(null, options),
      flatten: true,
      getShadowRoot: options.getShadowRoot
    });
  } else {
    candidates = getCandidates(el, options.includeContainer, isNodeMatchingSelectorFocusable.bind(null, options));
  }
  return candidates;
};
var isTabbable = function isTabbable2(node, options) {
  options = options || {};
  if (!node) {
    throw new Error("No node provided");
  }
  if (matches.call(node, candidateSelector) === false) {
    return false;
  }
  return isNodeMatchingSelectorTabbable(options, node);
};
var focusableCandidateSelector = /* @__PURE__ */ candidateSelectors.concat("iframe").join(",");
var isFocusable = function isFocusable2(node, options) {
  options = options || {};
  if (!node) {
    throw new Error("No node provided");
  }
  if (matches.call(node, focusableCandidateSelector) === false) {
    return false;
  }
  return isNodeMatchingSelectorFocusable(options, node);
};
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function(sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), true).forEach(function(key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function(key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
var activeFocusTraps = /* @__PURE__ */ function() {
  var trapQueue = [];
  return {
    activateTrap: function activateTrap(trap) {
      if (trapQueue.length > 0) {
        var activeTrap = trapQueue[trapQueue.length - 1];
        if (activeTrap !== trap) {
          activeTrap.pause();
        }
      }
      var trapIndex = trapQueue.indexOf(trap);
      if (trapIndex === -1) {
        trapQueue.push(trap);
      } else {
        trapQueue.splice(trapIndex, 1);
        trapQueue.push(trap);
      }
    },
    deactivateTrap: function deactivateTrap(trap) {
      var trapIndex = trapQueue.indexOf(trap);
      if (trapIndex !== -1) {
        trapQueue.splice(trapIndex, 1);
      }
      if (trapQueue.length > 0) {
        trapQueue[trapQueue.length - 1].unpause();
      }
    }
  };
}();
var isSelectableInput = function isSelectableInput2(node) {
  return node.tagName && node.tagName.toLowerCase() === "input" && typeof node.select === "function";
};
var isEscapeEvent = function isEscapeEvent2(e) {
  return e.key === "Escape" || e.key === "Esc" || e.keyCode === 27;
};
var isTabEvent = function isTabEvent2(e) {
  return e.key === "Tab" || e.keyCode === 9;
};
var delay = function delay2(fn) {
  return setTimeout(fn, 0);
};
var findIndex = function findIndex2(arr, fn) {
  var idx = -1;
  arr.every(function(value, i) {
    if (fn(value)) {
      idx = i;
      return false;
    }
    return true;
  });
  return idx;
};
var valueOrHandler = function valueOrHandler2(value) {
  for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    params[_key - 1] = arguments[_key];
  }
  return typeof value === "function" ? value.apply(void 0, params) : value;
};
var getActualTarget = function getActualTarget2(event) {
  return event.target.shadowRoot && typeof event.composedPath === "function" ? event.composedPath()[0] : event.target;
};
var createFocusTrap = function createFocusTrap2(elements, userOptions) {
  var doc = (userOptions === null || userOptions === void 0 ? void 0 : userOptions.document) || document;
  var config = _objectSpread2({
    returnFocusOnDeactivate: true,
    escapeDeactivates: true,
    delayInitialFocus: true
  }, userOptions);
  var state = {
    // containers given to createFocusTrap()
    // @type {Array<HTMLElement>}
    containers: [],
    // list of objects identifying tabbable nodes in `containers` in the trap
    // NOTE: it's possible that a group has no tabbable nodes if nodes get removed while the trap
    //  is active, but the trap should never get to a state where there isn't at least one group
    //  with at least one tabbable node in it (that would lead to an error condition that would
    //  result in an error being thrown)
    // @type {Array<{
    //   container: HTMLElement,
    //   tabbableNodes: Array<HTMLElement>, // empty if none
    //   focusableNodes: Array<HTMLElement>, // empty if none
    //   firstTabbableNode: HTMLElement|null,
    //   lastTabbableNode: HTMLElement|null,
    //   nextTabbableNode: (node: HTMLElement, forward: boolean) => HTMLElement|undefined
    // }>}
    containerGroups: [],
    // same order/length as `containers` list
    // references to objects in `containerGroups`, but only those that actually have
    //  tabbable nodes in them
    // NOTE: same order as `containers` and `containerGroups`, but __not necessarily__
    //  the same length
    tabbableGroups: [],
    nodeFocusedBeforeActivation: null,
    mostRecentlyFocusedNode: null,
    active: false,
    paused: false,
    // timer ID for when delayInitialFocus is true and initial focus in this trap
    //  has been delayed during activation
    delayInitialFocusTimer: void 0
  };
  var trap;
  var getOption = function getOption2(configOverrideOptions, optionName, configOptionName) {
    return configOverrideOptions && configOverrideOptions[optionName] !== void 0 ? configOverrideOptions[optionName] : config[configOptionName || optionName];
  };
  var findContainerIndex = function findContainerIndex2(element) {
    return state.containerGroups.findIndex(function(_ref) {
      var container = _ref.container, tabbableNodes = _ref.tabbableNodes;
      return container.contains(element) || // fall back to explicit tabbable search which will take into consideration any
      //  web components if the `tabbableOptions.getShadowRoot` option was used for
      //  the trap, enabling shadow DOM support in tabbable (`Node.contains()` doesn't
      //  look inside web components even if open)
      tabbableNodes.find(function(node) {
        return node === element;
      });
    });
  };
  var getNodeForOption = function getNodeForOption2(optionName) {
    var optionValue = config[optionName];
    if (typeof optionValue === "function") {
      for (var _len2 = arguments.length, params = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        params[_key2 - 1] = arguments[_key2];
      }
      optionValue = optionValue.apply(void 0, params);
    }
    if (optionValue === true) {
      optionValue = void 0;
    }
    if (!optionValue) {
      if (optionValue === void 0 || optionValue === false) {
        return optionValue;
      }
      throw new Error("`".concat(optionName, "` was specified but was not a node, or did not return a node"));
    }
    var node = optionValue;
    if (typeof optionValue === "string") {
      node = doc.querySelector(optionValue);
      if (!node) {
        throw new Error("`".concat(optionName, "` as selector refers to no known node"));
      }
    }
    return node;
  };
  var getInitialFocusNode = function getInitialFocusNode2() {
    var node = getNodeForOption("initialFocus");
    if (node === false) {
      return false;
    }
    if (node === void 0) {
      if (findContainerIndex(doc.activeElement) >= 0) {
        node = doc.activeElement;
      } else {
        var firstTabbableGroup = state.tabbableGroups[0];
        var firstTabbableNode = firstTabbableGroup && firstTabbableGroup.firstTabbableNode;
        node = firstTabbableNode || getNodeForOption("fallbackFocus");
      }
    }
    if (!node) {
      throw new Error("Your focus-trap needs to have at least one focusable element");
    }
    return node;
  };
  var updateTabbableNodes = function updateTabbableNodes2() {
    state.containerGroups = state.containers.map(function(container) {
      var tabbableNodes = tabbable(container, config.tabbableOptions);
      var focusableNodes = focusable(container, config.tabbableOptions);
      return {
        container,
        tabbableNodes,
        focusableNodes,
        firstTabbableNode: tabbableNodes.length > 0 ? tabbableNodes[0] : null,
        lastTabbableNode: tabbableNodes.length > 0 ? tabbableNodes[tabbableNodes.length - 1] : null,
        /**
         * Finds the __tabbable__ node that follows the given node in the specified direction,
         *  in this container, if any.
         * @param {HTMLElement} node
         * @param {boolean} [forward] True if going in forward tab order; false if going
         *  in reverse.
         * @returns {HTMLElement|undefined} The next tabbable node, if any.
         */
        nextTabbableNode: function nextTabbableNode(node) {
          var forward = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
          var nodeIdx = focusableNodes.findIndex(function(n) {
            return n === node;
          });
          if (nodeIdx < 0) {
            return void 0;
          }
          if (forward) {
            return focusableNodes.slice(nodeIdx + 1).find(function(n) {
              return isTabbable(n, config.tabbableOptions);
            });
          }
          return focusableNodes.slice(0, nodeIdx).reverse().find(function(n) {
            return isTabbable(n, config.tabbableOptions);
          });
        }
      };
    });
    state.tabbableGroups = state.containerGroups.filter(function(group) {
      return group.tabbableNodes.length > 0;
    });
    if (state.tabbableGroups.length <= 0 && !getNodeForOption("fallbackFocus")) {
      throw new Error("Your focus-trap must have at least one container with at least one tabbable node in it at all times");
    }
  };
  var tryFocus = function tryFocus2(node) {
    if (node === false) {
      return;
    }
    if (node === doc.activeElement) {
      return;
    }
    if (!node || !node.focus) {
      tryFocus2(getInitialFocusNode());
      return;
    }
    node.focus({
      preventScroll: !!config.preventScroll
    });
    state.mostRecentlyFocusedNode = node;
    if (isSelectableInput(node)) {
      node.select();
    }
  };
  var getReturnFocusNode = function getReturnFocusNode2(previousActiveElement) {
    var node = getNodeForOption("setReturnFocus", previousActiveElement);
    return node ? node : node === false ? false : previousActiveElement;
  };
  var checkPointerDown = function checkPointerDown2(e) {
    var target = getActualTarget(e);
    if (findContainerIndex(target) >= 0) {
      return;
    }
    if (valueOrHandler(config.clickOutsideDeactivates, e)) {
      trap.deactivate({
        // if, on deactivation, we should return focus to the node originally-focused
        //  when the trap was activated (or the configured `setReturnFocus` node),
        //  then assume it's also OK to return focus to the outside node that was
        //  just clicked, causing deactivation, as long as that node is focusable;
        //  if it isn't focusable, then return focus to the original node focused
        //  on activation (or the configured `setReturnFocus` node)
        // NOTE: by setting `returnFocus: false`, deactivate() will do nothing,
        //  which will result in the outside click setting focus to the node
        //  that was clicked, whether it's focusable or not; by setting
        //  `returnFocus: true`, we'll attempt to re-focus the node originally-focused
        //  on activation (or the configured `setReturnFocus` node)
        returnFocus: config.returnFocusOnDeactivate && !isFocusable(target, config.tabbableOptions)
      });
      return;
    }
    if (valueOrHandler(config.allowOutsideClick, e)) {
      return;
    }
    e.preventDefault();
  };
  var checkFocusIn = function checkFocusIn2(e) {
    var target = getActualTarget(e);
    var targetContained = findContainerIndex(target) >= 0;
    if (targetContained || target instanceof Document) {
      if (targetContained) {
        state.mostRecentlyFocusedNode = target;
      }
    } else {
      e.stopImmediatePropagation();
      tryFocus(state.mostRecentlyFocusedNode || getInitialFocusNode());
    }
  };
  var checkTab = function checkTab2(e) {
    var target = getActualTarget(e);
    updateTabbableNodes();
    var destinationNode = null;
    if (state.tabbableGroups.length > 0) {
      var containerIndex = findContainerIndex(target);
      var containerGroup = containerIndex >= 0 ? state.containerGroups[containerIndex] : void 0;
      if (containerIndex < 0) {
        if (e.shiftKey) {
          destinationNode = state.tabbableGroups[state.tabbableGroups.length - 1].lastTabbableNode;
        } else {
          destinationNode = state.tabbableGroups[0].firstTabbableNode;
        }
      } else if (e.shiftKey) {
        var startOfGroupIndex = findIndex(state.tabbableGroups, function(_ref2) {
          var firstTabbableNode = _ref2.firstTabbableNode;
          return target === firstTabbableNode;
        });
        if (startOfGroupIndex < 0 && (containerGroup.container === target || isFocusable(target, config.tabbableOptions) && !isTabbable(target, config.tabbableOptions) && !containerGroup.nextTabbableNode(target, false))) {
          startOfGroupIndex = containerIndex;
        }
        if (startOfGroupIndex >= 0) {
          var destinationGroupIndex = startOfGroupIndex === 0 ? state.tabbableGroups.length - 1 : startOfGroupIndex - 1;
          var destinationGroup = state.tabbableGroups[destinationGroupIndex];
          destinationNode = destinationGroup.lastTabbableNode;
        }
      } else {
        var lastOfGroupIndex = findIndex(state.tabbableGroups, function(_ref3) {
          var lastTabbableNode = _ref3.lastTabbableNode;
          return target === lastTabbableNode;
        });
        if (lastOfGroupIndex < 0 && (containerGroup.container === target || isFocusable(target, config.tabbableOptions) && !isTabbable(target, config.tabbableOptions) && !containerGroup.nextTabbableNode(target))) {
          lastOfGroupIndex = containerIndex;
        }
        if (lastOfGroupIndex >= 0) {
          var _destinationGroupIndex = lastOfGroupIndex === state.tabbableGroups.length - 1 ? 0 : lastOfGroupIndex + 1;
          var _destinationGroup = state.tabbableGroups[_destinationGroupIndex];
          destinationNode = _destinationGroup.firstTabbableNode;
        }
      }
    } else {
      destinationNode = getNodeForOption("fallbackFocus");
    }
    if (destinationNode) {
      e.preventDefault();
      tryFocus(destinationNode);
    }
  };
  var checkKey = function checkKey2(e) {
    if (isEscapeEvent(e) && valueOrHandler(config.escapeDeactivates, e) !== false) {
      e.preventDefault();
      trap.deactivate();
      return;
    }
    if (isTabEvent(e)) {
      checkTab(e);
      return;
    }
  };
  var checkClick = function checkClick2(e) {
    var target = getActualTarget(e);
    if (findContainerIndex(target) >= 0) {
      return;
    }
    if (valueOrHandler(config.clickOutsideDeactivates, e)) {
      return;
    }
    if (valueOrHandler(config.allowOutsideClick, e)) {
      return;
    }
    e.preventDefault();
    e.stopImmediatePropagation();
  };
  var addListeners = function addListeners2() {
    if (!state.active) {
      return;
    }
    activeFocusTraps.activateTrap(trap);
    state.delayInitialFocusTimer = config.delayInitialFocus ? delay(function() {
      tryFocus(getInitialFocusNode());
    }) : tryFocus(getInitialFocusNode());
    doc.addEventListener("focusin", checkFocusIn, true);
    doc.addEventListener("mousedown", checkPointerDown, {
      capture: true,
      passive: false
    });
    doc.addEventListener("touchstart", checkPointerDown, {
      capture: true,
      passive: false
    });
    doc.addEventListener("click", checkClick, {
      capture: true,
      passive: false
    });
    doc.addEventListener("keydown", checkKey, {
      capture: true,
      passive: false
    });
    return trap;
  };
  var removeListeners = function removeListeners2() {
    if (!state.active) {
      return;
    }
    doc.removeEventListener("focusin", checkFocusIn, true);
    doc.removeEventListener("mousedown", checkPointerDown, true);
    doc.removeEventListener("touchstart", checkPointerDown, true);
    doc.removeEventListener("click", checkClick, true);
    doc.removeEventListener("keydown", checkKey, true);
    return trap;
  };
  trap = {
    get active() {
      return state.active;
    },
    get paused() {
      return state.paused;
    },
    activate: function activate(activateOptions) {
      if (state.active) {
        return this;
      }
      var onActivate = getOption(activateOptions, "onActivate");
      var onPostActivate = getOption(activateOptions, "onPostActivate");
      var checkCanFocusTrap = getOption(activateOptions, "checkCanFocusTrap");
      if (!checkCanFocusTrap) {
        updateTabbableNodes();
      }
      state.active = true;
      state.paused = false;
      state.nodeFocusedBeforeActivation = doc.activeElement;
      if (onActivate) {
        onActivate();
      }
      var finishActivation = function finishActivation2() {
        if (checkCanFocusTrap) {
          updateTabbableNodes();
        }
        addListeners();
        if (onPostActivate) {
          onPostActivate();
        }
      };
      if (checkCanFocusTrap) {
        checkCanFocusTrap(state.containers.concat()).then(finishActivation, finishActivation);
        return this;
      }
      finishActivation();
      return this;
    },
    deactivate: function deactivate(deactivateOptions) {
      if (!state.active) {
        return this;
      }
      var options = _objectSpread2({
        onDeactivate: config.onDeactivate,
        onPostDeactivate: config.onPostDeactivate,
        checkCanReturnFocus: config.checkCanReturnFocus
      }, deactivateOptions);
      clearTimeout(state.delayInitialFocusTimer);
      state.delayInitialFocusTimer = void 0;
      removeListeners();
      state.active = false;
      state.paused = false;
      activeFocusTraps.deactivateTrap(trap);
      var onDeactivate = getOption(options, "onDeactivate");
      var onPostDeactivate = getOption(options, "onPostDeactivate");
      var checkCanReturnFocus = getOption(options, "checkCanReturnFocus");
      var returnFocus = getOption(options, "returnFocus", "returnFocusOnDeactivate");
      if (onDeactivate) {
        onDeactivate();
      }
      var finishDeactivation = function finishDeactivation2() {
        delay(function() {
          if (returnFocus) {
            tryFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation));
          }
          if (onPostDeactivate) {
            onPostDeactivate();
          }
        });
      };
      if (returnFocus && checkCanReturnFocus) {
        checkCanReturnFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation)).then(finishDeactivation, finishDeactivation);
        return this;
      }
      finishDeactivation();
      return this;
    },
    pause: function pause() {
      if (state.paused || !state.active) {
        return this;
      }
      state.paused = true;
      removeListeners();
      return this;
    },
    unpause: function unpause() {
      if (!state.paused || !state.active) {
        return this;
      }
      state.paused = false;
      updateTabbableNodes();
      addListeners();
      return this;
    },
    updateContainerElements: function updateContainerElements(containerElements) {
      var elementsAsArray = [].concat(containerElements).filter(Boolean);
      state.containers = elementsAsArray.map(function(element) {
        return typeof element === "string" ? doc.querySelector(element) : element;
      });
      if (state.active) {
        updateTabbableNodes();
      }
      return this;
    }
  };
  trap.updateContainerElements(elements);
  return trap;
};
function src_default$2(Alpine2) {
  let lastFocused;
  let currentFocused;
  window.addEventListener("focusin", () => {
    lastFocused = currentFocused;
    currentFocused = document.activeElement;
  });
  Alpine2.magic("focus", (el) => {
    let within = el;
    return {
      __noscroll: false,
      __wrapAround: false,
      within(el2) {
        within = el2;
        return this;
      },
      withoutScrolling() {
        this.__noscroll = true;
        return this;
      },
      noscroll() {
        this.__noscroll = true;
        return this;
      },
      withWrapAround() {
        this.__wrapAround = true;
        return this;
      },
      wrap() {
        return this.withWrapAround();
      },
      focusable(el2) {
        return isFocusable(el2);
      },
      previouslyFocused() {
        return lastFocused;
      },
      lastFocused() {
        return lastFocused;
      },
      focused() {
        return currentFocused;
      },
      focusables() {
        if (Array.isArray(within))
          return within;
        return focusable(within, { displayCheck: "none" });
      },
      all() {
        return this.focusables();
      },
      isFirst(el2) {
        let els = this.all();
        return els[0] && els[0].isSameNode(el2);
      },
      isLast(el2) {
        let els = this.all();
        return els.length && els.slice(-1)[0].isSameNode(el2);
      },
      getFirst() {
        return this.all()[0];
      },
      getLast() {
        return this.all().slice(-1)[0];
      },
      getNext() {
        let list = this.all();
        let current = document.activeElement;
        if (list.indexOf(current) === -1)
          return;
        if (this.__wrapAround && list.indexOf(current) === list.length - 1) {
          return list[0];
        }
        return list[list.indexOf(current) + 1];
      },
      getPrevious() {
        let list = this.all();
        let current = document.activeElement;
        if (list.indexOf(current) === -1)
          return;
        if (this.__wrapAround && list.indexOf(current) === 0) {
          return list.slice(-1)[0];
        }
        return list[list.indexOf(current) - 1];
      },
      first() {
        this.focus(this.getFirst());
      },
      last() {
        this.focus(this.getLast());
      },
      next() {
        this.focus(this.getNext());
      },
      previous() {
        this.focus(this.getPrevious());
      },
      prev() {
        return this.previous();
      },
      focus(el2) {
        if (!el2)
          return;
        setTimeout(() => {
          if (!el2.hasAttribute("tabindex"))
            el2.setAttribute("tabindex", "0");
          el2.focus({ preventScroll: this.__noscroll });
        });
      }
    };
  });
  Alpine2.directive("trap", Alpine2.skipDuringClone(
    (el, { expression, modifiers }, { effect: effect3, evaluateLater: evaluateLater2, cleanup: cleanup2 }) => {
      let evaluator = evaluateLater2(expression);
      let oldValue = false;
      let options = {
        escapeDeactivates: false,
        allowOutsideClick: true,
        fallbackFocus: () => el
      };
      if (modifiers.includes("noautofocus")) {
        options.initialFocus = false;
      } else {
        let autofocusEl = el.querySelector("[autofocus]");
        if (autofocusEl)
          options.initialFocus = autofocusEl;
      }
      let trap = createFocusTrap(el, options);
      let undoInert = () => {
      };
      let undoDisableScrolling = () => {
      };
      const releaseFocus = () => {
        undoInert();
        undoInert = () => {
        };
        undoDisableScrolling();
        undoDisableScrolling = () => {
        };
        trap.deactivate({
          returnFocus: !modifiers.includes("noreturn")
        });
      };
      effect3(() => evaluator((value) => {
        if (oldValue === value)
          return;
        if (value && !oldValue) {
          if (modifiers.includes("noscroll"))
            undoDisableScrolling = disableScrolling();
          if (modifiers.includes("inert"))
            undoInert = setInert(el);
          setTimeout(() => {
            trap.activate();
          }, 15);
        }
        if (!value && oldValue) {
          releaseFocus();
        }
        oldValue = !!value;
      }));
      cleanup2(releaseFocus);
    },
    // When cloning, we only want to add aria-hidden attributes to the
    // DOM and not try to actually trap, as trapping can mess with the
    // live DOM and isn't just isolated to the cloned DOM.
    (el, { expression, modifiers }, { evaluate: evaluate2 }) => {
      if (modifiers.includes("inert") && evaluate2(expression))
        setInert(el);
    }
  ));
}
function setInert(el) {
  let undos = [];
  crawlSiblingsUp(el, (sibling) => {
    let cache = sibling.hasAttribute("aria-hidden");
    sibling.setAttribute("aria-hidden", "true");
    undos.push(() => cache || sibling.removeAttribute("aria-hidden"));
  });
  return () => {
    while (undos.length)
      undos.pop()();
  };
}
function crawlSiblingsUp(el, callback) {
  if (el.isSameNode(document.body) || !el.parentNode)
    return;
  Array.from(el.parentNode.children).forEach((sibling) => {
    if (sibling.isSameNode(el)) {
      crawlSiblingsUp(el.parentNode, callback);
    } else {
      callback(sibling);
    }
  });
}
function disableScrolling() {
  let overflow = document.documentElement.style.overflow;
  let paddingRight = document.documentElement.style.paddingRight;
  let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.overflow = "hidden";
  document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
  return () => {
    document.documentElement.style.overflow = overflow;
    document.documentElement.style.paddingRight = paddingRight;
  };
}
var module_default$2 = src_default$2;
/*! Bundled license information:

tabbable/dist/index.esm.js:
  (*!
  * tabbable 5.3.3
  * @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
  *)

focus-trap/dist/focus-trap.esm.js:
  (*!
  * focus-trap 6.9.4
  * @license MIT, https://github.com/focus-trap/focus-trap/blob/master/LICENSE
  *)
*/
function src_default$1(Alpine2) {
  Alpine2.directive("intersect", Alpine2.skipDuringClone((el, { value, expression, modifiers }, { evaluateLater: evaluateLater2, cleanup: cleanup2 }) => {
    let evaluate2 = evaluateLater2(expression);
    let options = {
      rootMargin: getRootMargin(modifiers),
      threshold: getThreshold(modifiers)
    };
    let observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === (value === "leave"))
          return;
        evaluate2();
        modifiers.includes("once") && observer2.disconnect();
      });
    }, options);
    observer2.observe(el);
    cleanup2(() => {
      observer2.disconnect();
    });
  }));
}
function getThreshold(modifiers) {
  if (modifiers.includes("full"))
    return 0.99;
  if (modifiers.includes("half"))
    return 0.5;
  if (!modifiers.includes("threshold"))
    return 0;
  let threshold = modifiers[modifiers.indexOf("threshold") + 1];
  if (threshold === "100")
    return 1;
  if (threshold === "0")
    return 0;
  return Number(`.${threshold}`);
}
function getLengthValue(rawValue) {
  let match = rawValue.match(/^(-?[0-9]+)(px|%)?$/);
  return match ? match[1] + (match[2] || "px") : void 0;
}
function getRootMargin(modifiers) {
  const key = "margin";
  const fallback = "0px 0px 0px 0px";
  const index = modifiers.indexOf(key);
  if (index === -1)
    return fallback;
  let values = [];
  for (let i = 1; i < 5; i++) {
    values.push(getLengthValue(modifiers[index + i] || ""));
  }
  values = values.filter((v) => v !== void 0);
  return values.length ? values.join(" ").trim() : fallback;
}
var module_default$1 = src_default$1;
function src_default(Alpine2) {
  let persist = () => {
    let alias;
    let storage;
    try {
      storage = localStorage;
    } catch (e) {
      console.error(e);
      console.warn("Alpine: $persist is using temporary storage since localStorage is unavailable.");
      let dummy = /* @__PURE__ */ new Map();
      storage = {
        getItem: dummy.get.bind(dummy),
        setItem: dummy.set.bind(dummy)
      };
    }
    return Alpine2.interceptor((initialValue, getter, setter, path, key) => {
      let lookup = alias || `_x_${path}`;
      let initial = storageHas(lookup, storage) ? storageGet(lookup, storage) : initialValue;
      setter(initial);
      Alpine2.effect(() => {
        let value = getter();
        storageSet(lookup, value, storage);
        setter(value);
      });
      return initial;
    }, (func) => {
      func.as = (key) => {
        alias = key;
        return func;
      }, func.using = (target) => {
        storage = target;
        return func;
      };
    });
  };
  Object.defineProperty(Alpine2, "$persist", { get: () => persist() });
  Alpine2.magic("persist", persist);
  Alpine2.persist = (key, { get: get3, set: set3 }, storage = localStorage) => {
    let initial = storageHas(key, storage) ? storageGet(key, storage) : get3();
    set3(initial);
    Alpine2.effect(() => {
      let value = get3();
      storageSet(key, value, storage);
      set3(value);
    });
  };
}
function storageHas(key, storage) {
  return storage.getItem(key) !== null;
}
function storageGet(key, storage) {
  let value = storage.getItem(key, storage);
  if (value === void 0)
    return;
  return JSON.parse(value);
}
function storageSet(key, value, storage) {
  storage.setItem(key, JSON.stringify(value));
}
var module_default = src_default;
function isObject$1(obj) {
  return obj !== null && typeof obj === "object" && "constructor" in obj && obj.constructor === Object;
}
function extend$1(target, src) {
  if (target === void 0) {
    target = {};
  }
  if (src === void 0) {
    src = {};
  }
  Object.keys(src).forEach((key) => {
    if (typeof target[key] === "undefined") target[key] = src[key];
    else if (isObject$1(src[key]) && isObject$1(target[key]) && Object.keys(src[key]).length > 0) {
      extend$1(target[key], src[key]);
    }
  });
}
const ssrDocument = {
  body: {},
  addEventListener() {
  },
  removeEventListener() {
  },
  activeElement: {
    blur() {
    },
    nodeName: ""
  },
  querySelector() {
    return null;
  },
  querySelectorAll() {
    return [];
  },
  getElementById() {
    return null;
  },
  createEvent() {
    return {
      initEvent() {
      }
    };
  },
  createElement() {
    return {
      children: [],
      childNodes: [],
      style: {},
      setAttribute() {
      },
      getElementsByTagName() {
        return [];
      }
    };
  },
  createElementNS() {
    return {};
  },
  importNode() {
    return null;
  },
  location: {
    hash: "",
    host: "",
    hostname: "",
    href: "",
    origin: "",
    pathname: "",
    protocol: "",
    search: ""
  }
};
function getDocument() {
  const doc = typeof document !== "undefined" ? document : {};
  extend$1(doc, ssrDocument);
  return doc;
}
const ssrWindow = {
  document: ssrDocument,
  navigator: {
    userAgent: ""
  },
  location: {
    hash: "",
    host: "",
    hostname: "",
    href: "",
    origin: "",
    pathname: "",
    protocol: "",
    search: ""
  },
  history: {
    replaceState() {
    },
    pushState() {
    },
    go() {
    },
    back() {
    }
  },
  CustomEvent: function CustomEvent2() {
    return this;
  },
  addEventListener() {
  },
  removeEventListener() {
  },
  getComputedStyle() {
    return {
      getPropertyValue() {
        return "";
      }
    };
  },
  Image() {
  },
  Date() {
  },
  screen: {},
  setTimeout() {
  },
  clearTimeout() {
  },
  matchMedia() {
    return {};
  },
  requestAnimationFrame(callback) {
    if (typeof setTimeout === "undefined") {
      callback();
      return null;
    }
    return setTimeout(callback, 0);
  },
  cancelAnimationFrame(id) {
    if (typeof setTimeout === "undefined") {
      return;
    }
    clearTimeout(id);
  }
};
function getWindow() {
  const win = typeof window !== "undefined" ? window : {};
  extend$1(win, ssrWindow);
  return win;
}
function classesToTokens(classes2) {
  if (classes2 === void 0) {
    classes2 = "";
  }
  return classes2.trim().split(" ").filter((c) => !!c.trim());
}
function deleteProps(obj) {
  const object = obj;
  Object.keys(object).forEach((key) => {
    try {
      object[key] = null;
    } catch (e) {
    }
    try {
      delete object[key];
    } catch (e) {
    }
  });
}
function nextTick(callback, delay3) {
  if (delay3 === void 0) {
    delay3 = 0;
  }
  return setTimeout(callback, delay3);
}
function now() {
  return Date.now();
}
function getComputedStyle$1(el) {
  const window2 = getWindow();
  let style;
  if (window2.getComputedStyle) {
    style = window2.getComputedStyle(el, null);
  }
  if (!style && el.currentStyle) {
    style = el.currentStyle;
  }
  if (!style) {
    style = el.style;
  }
  return style;
}
function getTranslate(el, axis) {
  if (axis === void 0) {
    axis = "x";
  }
  const window2 = getWindow();
  let matrix;
  let curTransform;
  let transformMatrix;
  const curStyle = getComputedStyle$1(el);
  if (window2.WebKitCSSMatrix) {
    curTransform = curStyle.transform || curStyle.webkitTransform;
    if (curTransform.split(",").length > 6) {
      curTransform = curTransform.split(", ").map((a) => a.replace(",", ".")).join(", ");
    }
    transformMatrix = new window2.WebKitCSSMatrix(curTransform === "none" ? "" : curTransform);
  } else {
    transformMatrix = curStyle.MozTransform || curStyle.OTransform || curStyle.MsTransform || curStyle.msTransform || curStyle.transform || curStyle.getPropertyValue("transform").replace("translate(", "matrix(1, 0, 0, 1,");
    matrix = transformMatrix.toString().split(",");
  }
  if (axis === "x") {
    if (window2.WebKitCSSMatrix) curTransform = transformMatrix.m41;
    else if (matrix.length === 16) curTransform = parseFloat(matrix[12]);
    else curTransform = parseFloat(matrix[4]);
  }
  if (axis === "y") {
    if (window2.WebKitCSSMatrix) curTransform = transformMatrix.m42;
    else if (matrix.length === 16) curTransform = parseFloat(matrix[13]);
    else curTransform = parseFloat(matrix[5]);
  }
  return curTransform || 0;
}
function isObject(o) {
  return typeof o === "object" && o !== null && o.constructor && Object.prototype.toString.call(o).slice(8, -1) === "Object";
}
function isNode(node) {
  if (typeof window !== "undefined" && typeof window.HTMLElement !== "undefined") {
    return node instanceof HTMLElement;
  }
  return node && (node.nodeType === 1 || node.nodeType === 11);
}
function extend() {
  const to = Object(arguments.length <= 0 ? void 0 : arguments[0]);
  const noExtend = ["__proto__", "constructor", "prototype"];
  for (let i = 1; i < arguments.length; i += 1) {
    const nextSource = i < 0 || arguments.length <= i ? void 0 : arguments[i];
    if (nextSource !== void 0 && nextSource !== null && !isNode(nextSource)) {
      const keysArray = Object.keys(Object(nextSource)).filter((key) => noExtend.indexOf(key) < 0);
      for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex += 1) {
        const nextKey = keysArray[nextIndex];
        const desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
        if (desc !== void 0 && desc.enumerable) {
          if (isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
            if (nextSource[nextKey].__swiper__) {
              to[nextKey] = nextSource[nextKey];
            } else {
              extend(to[nextKey], nextSource[nextKey]);
            }
          } else if (!isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
            to[nextKey] = {};
            if (nextSource[nextKey].__swiper__) {
              to[nextKey] = nextSource[nextKey];
            } else {
              extend(to[nextKey], nextSource[nextKey]);
            }
          } else {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
  }
  return to;
}
function setCSSProperty(el, varName, varValue) {
  el.style.setProperty(varName, varValue);
}
function animateCSSModeScroll(_ref) {
  let {
    swiper,
    targetPosition,
    side
  } = _ref;
  const window2 = getWindow();
  const startPosition = -swiper.translate;
  let startTime = null;
  let time;
  const duration = swiper.params.speed;
  swiper.wrapperEl.style.scrollSnapType = "none";
  window2.cancelAnimationFrame(swiper.cssModeFrameID);
  const dir = targetPosition > startPosition ? "next" : "prev";
  const isOutOfBound = (current, target) => {
    return dir === "next" && current >= target || dir === "prev" && current <= target;
  };
  const animate = () => {
    time = (/* @__PURE__ */ new Date()).getTime();
    if (startTime === null) {
      startTime = time;
    }
    const progress = Math.max(Math.min((time - startTime) / duration, 1), 0);
    const easeProgress = 0.5 - Math.cos(progress * Math.PI) / 2;
    let currentPosition = startPosition + easeProgress * (targetPosition - startPosition);
    if (isOutOfBound(currentPosition, targetPosition)) {
      currentPosition = targetPosition;
    }
    swiper.wrapperEl.scrollTo({
      [side]: currentPosition
    });
    if (isOutOfBound(currentPosition, targetPosition)) {
      swiper.wrapperEl.style.overflow = "hidden";
      swiper.wrapperEl.style.scrollSnapType = "";
      setTimeout(() => {
        swiper.wrapperEl.style.overflow = "";
        swiper.wrapperEl.scrollTo({
          [side]: currentPosition
        });
      });
      window2.cancelAnimationFrame(swiper.cssModeFrameID);
      return;
    }
    swiper.cssModeFrameID = window2.requestAnimationFrame(animate);
  };
  animate();
}
function getSlideTransformEl(slideEl) {
  return slideEl.querySelector(".swiper-slide-transform") || slideEl.shadowRoot && slideEl.shadowRoot.querySelector(".swiper-slide-transform") || slideEl;
}
function elementChildren(element, selector) {
  if (selector === void 0) {
    selector = "";
  }
  const children = [...element.children];
  if (element instanceof HTMLSlotElement) {
    children.push(...element.assignedElements());
  }
  if (!selector) {
    return children;
  }
  return children.filter((el) => el.matches(selector));
}
function elementIsChildOf(el, parent) {
  const isChild = parent.contains(el);
  if (!isChild && parent instanceof HTMLSlotElement) {
    const children = [...parent.assignedElements()];
    return children.includes(el);
  }
  return isChild;
}
function showWarning(text) {
  try {
    console.warn(text);
    return;
  } catch (err) {
  }
}
function createElement(tag, classes2) {
  if (classes2 === void 0) {
    classes2 = [];
  }
  const el = document.createElement(tag);
  el.classList.add(...Array.isArray(classes2) ? classes2 : classesToTokens(classes2));
  return el;
}
function elementPrevAll(el, selector) {
  const prevEls = [];
  while (el.previousElementSibling) {
    const prev = el.previousElementSibling;
    if (selector) {
      if (prev.matches(selector)) prevEls.push(prev);
    } else prevEls.push(prev);
    el = prev;
  }
  return prevEls;
}
function elementNextAll(el, selector) {
  const nextEls = [];
  while (el.nextElementSibling) {
    const next = el.nextElementSibling;
    if (selector) {
      if (next.matches(selector)) nextEls.push(next);
    } else nextEls.push(next);
    el = next;
  }
  return nextEls;
}
function elementStyle(el, prop) {
  const window2 = getWindow();
  return window2.getComputedStyle(el, null).getPropertyValue(prop);
}
function elementIndex(el) {
  let child = el;
  let i;
  if (child) {
    i = 0;
    while ((child = child.previousSibling) !== null) {
      if (child.nodeType === 1) i += 1;
    }
    return i;
  }
  return void 0;
}
function elementParents(el, selector) {
  const parents = [];
  let parent = el.parentElement;
  while (parent) {
    if (selector) {
      if (parent.matches(selector)) parents.push(parent);
    } else {
      parents.push(parent);
    }
    parent = parent.parentElement;
  }
  return parents;
}
function elementOuterSize(el, size2, includeMargins) {
  const window2 = getWindow();
  {
    return el[size2 === "width" ? "offsetWidth" : "offsetHeight"] + parseFloat(window2.getComputedStyle(el, null).getPropertyValue(size2 === "width" ? "margin-right" : "margin-top")) + parseFloat(window2.getComputedStyle(el, null).getPropertyValue(size2 === "width" ? "margin-left" : "margin-bottom"));
  }
}
function makeElementsArray(el) {
  return (Array.isArray(el) ? el : [el]).filter((e) => !!e);
}
function getRotateFix(swiper) {
  return (v) => {
    if (Math.abs(v) > 0 && swiper.browser && swiper.browser.need3dFix && Math.abs(v) % 90 === 0) {
      return v + 1e-3;
    }
    return v;
  };
}
let support;
function calcSupport() {
  const window2 = getWindow();
  const document2 = getDocument();
  return {
    smoothScroll: document2.documentElement && document2.documentElement.style && "scrollBehavior" in document2.documentElement.style,
    touch: !!("ontouchstart" in window2 || window2.DocumentTouch && document2 instanceof window2.DocumentTouch)
  };
}
function getSupport() {
  if (!support) {
    support = calcSupport();
  }
  return support;
}
let deviceCached;
function calcDevice(_temp) {
  let {
    userAgent
  } = _temp === void 0 ? {} : _temp;
  const support2 = getSupport();
  const window2 = getWindow();
  const platform = window2.navigator.platform;
  const ua = userAgent || window2.navigator.userAgent;
  const device = {
    ios: false,
    android: false
  };
  const screenWidth = window2.screen.width;
  const screenHeight = window2.screen.height;
  const android = ua.match(/(Android);?[\s\/]+([\d.]+)?/);
  let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  const ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
  const iphone = !ipad && ua.match(/(iPhone\sOS|iOS)\s([\d_]+)/);
  const windows = platform === "Win32";
  let macos = platform === "MacIntel";
  const iPadScreens = ["1024x1366", "1366x1024", "834x1194", "1194x834", "834x1112", "1112x834", "768x1024", "1024x768", "820x1180", "1180x820", "810x1080", "1080x810"];
  if (!ipad && macos && support2.touch && iPadScreens.indexOf(`${screenWidth}x${screenHeight}`) >= 0) {
    ipad = ua.match(/(Version)\/([\d.]+)/);
    if (!ipad) ipad = [0, 1, "13_0_0"];
    macos = false;
  }
  if (android && !windows) {
    device.os = "android";
    device.android = true;
  }
  if (ipad || iphone || ipod) {
    device.os = "ios";
    device.ios = true;
  }
  return device;
}
function getDevice(overrides) {
  if (overrides === void 0) {
    overrides = {};
  }
  if (!deviceCached) {
    deviceCached = calcDevice(overrides);
  }
  return deviceCached;
}
let browser;
function calcBrowser() {
  const window2 = getWindow();
  const device = getDevice();
  let needPerspectiveFix = false;
  function isSafari() {
    const ua = window2.navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") >= 0 && ua.indexOf("chrome") < 0 && ua.indexOf("android") < 0;
  }
  if (isSafari()) {
    const ua = String(window2.navigator.userAgent);
    if (ua.includes("Version/")) {
      const [major, minor] = ua.split("Version/")[1].split(" ")[0].split(".").map((num) => Number(num));
      needPerspectiveFix = major < 16 || major === 16 && minor < 2;
    }
  }
  const isWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(window2.navigator.userAgent);
  const isSafariBrowser = isSafari();
  const need3dFix = isSafariBrowser || isWebView && device.ios;
  return {
    isSafari: needPerspectiveFix || isSafariBrowser,
    needPerspectiveFix,
    need3dFix,
    isWebView
  };
}
function getBrowser() {
  if (!browser) {
    browser = calcBrowser();
  }
  return browser;
}
function Resize(_ref) {
  let {
    swiper,
    on: on2,
    emit
  } = _ref;
  const window2 = getWindow();
  let observer2 = null;
  let animationFrame = null;
  const resizeHandler = () => {
    if (!swiper || swiper.destroyed || !swiper.initialized) return;
    emit("beforeResize");
    emit("resize");
  };
  const createObserver = () => {
    if (!swiper || swiper.destroyed || !swiper.initialized) return;
    observer2 = new ResizeObserver((entries) => {
      animationFrame = window2.requestAnimationFrame(() => {
        const {
          width,
          height
        } = swiper;
        let newWidth = width;
        let newHeight = height;
        entries.forEach((_ref2) => {
          let {
            contentBoxSize,
            contentRect,
            target
          } = _ref2;
          if (target && target !== swiper.el) return;
          newWidth = contentRect ? contentRect.width : (contentBoxSize[0] || contentBoxSize).inlineSize;
          newHeight = contentRect ? contentRect.height : (contentBoxSize[0] || contentBoxSize).blockSize;
        });
        if (newWidth !== width || newHeight !== height) {
          resizeHandler();
        }
      });
    });
    observer2.observe(swiper.el);
  };
  const removeObserver = () => {
    if (animationFrame) {
      window2.cancelAnimationFrame(animationFrame);
    }
    if (observer2 && observer2.unobserve && swiper.el) {
      observer2.unobserve(swiper.el);
      observer2 = null;
    }
  };
  const orientationChangeHandler = () => {
    if (!swiper || swiper.destroyed || !swiper.initialized) return;
    emit("orientationchange");
  };
  on2("init", () => {
    if (swiper.params.resizeObserver && typeof window2.ResizeObserver !== "undefined") {
      createObserver();
      return;
    }
    window2.addEventListener("resize", resizeHandler);
    window2.addEventListener("orientationchange", orientationChangeHandler);
  });
  on2("destroy", () => {
    removeObserver();
    window2.removeEventListener("resize", resizeHandler);
    window2.removeEventListener("orientationchange", orientationChangeHandler);
  });
}
function Observer(_ref) {
  let {
    swiper,
    extendParams,
    on: on2,
    emit
  } = _ref;
  const observers = [];
  const window2 = getWindow();
  const attach = function(target, options) {
    if (options === void 0) {
      options = {};
    }
    const ObserverFunc = window2.MutationObserver || window2.WebkitMutationObserver;
    const observer2 = new ObserverFunc((mutations) => {
      if (swiper.__preventObserver__) return;
      if (mutations.length === 1) {
        emit("observerUpdate", mutations[0]);
        return;
      }
      const observerUpdate = function observerUpdate2() {
        emit("observerUpdate", mutations[0]);
      };
      if (window2.requestAnimationFrame) {
        window2.requestAnimationFrame(observerUpdate);
      } else {
        window2.setTimeout(observerUpdate, 0);
      }
    });
    observer2.observe(target, {
      attributes: typeof options.attributes === "undefined" ? true : options.attributes,
      childList: swiper.isElement || (typeof options.childList === "undefined" ? true : options).childList,
      characterData: typeof options.characterData === "undefined" ? true : options.characterData
    });
    observers.push(observer2);
  };
  const init = () => {
    if (!swiper.params.observer) return;
    if (swiper.params.observeParents) {
      const containerParents = elementParents(swiper.hostEl);
      for (let i = 0; i < containerParents.length; i += 1) {
        attach(containerParents[i]);
      }
    }
    attach(swiper.hostEl, {
      childList: swiper.params.observeSlideChildren
    });
    attach(swiper.wrapperEl, {
      attributes: false
    });
  };
  const destroy = () => {
    observers.forEach((observer2) => {
      observer2.disconnect();
    });
    observers.splice(0, observers.length);
  };
  extendParams({
    observer: false,
    observeParents: false,
    observeSlideChildren: false
  });
  on2("init", init);
  on2("destroy", destroy);
}
var eventsEmitter = {
  on(events2, handler4, priority) {
    const self = this;
    if (!self.eventsListeners || self.destroyed) return self;
    if (typeof handler4 !== "function") return self;
    const method = priority ? "unshift" : "push";
    events2.split(" ").forEach((event) => {
      if (!self.eventsListeners[event]) self.eventsListeners[event] = [];
      self.eventsListeners[event][method](handler4);
    });
    return self;
  },
  once(events2, handler4, priority) {
    const self = this;
    if (!self.eventsListeners || self.destroyed) return self;
    if (typeof handler4 !== "function") return self;
    function onceHandler() {
      self.off(events2, onceHandler);
      if (onceHandler.__emitterProxy) {
        delete onceHandler.__emitterProxy;
      }
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      handler4.apply(self, args);
    }
    onceHandler.__emitterProxy = handler4;
    return self.on(events2, onceHandler, priority);
  },
  onAny(handler4, priority) {
    const self = this;
    if (!self.eventsListeners || self.destroyed) return self;
    if (typeof handler4 !== "function") return self;
    const method = priority ? "unshift" : "push";
    if (self.eventsAnyListeners.indexOf(handler4) < 0) {
      self.eventsAnyListeners[method](handler4);
    }
    return self;
  },
  offAny(handler4) {
    const self = this;
    if (!self.eventsListeners || self.destroyed) return self;
    if (!self.eventsAnyListeners) return self;
    const index = self.eventsAnyListeners.indexOf(handler4);
    if (index >= 0) {
      self.eventsAnyListeners.splice(index, 1);
    }
    return self;
  },
  off(events2, handler4) {
    const self = this;
    if (!self.eventsListeners || self.destroyed) return self;
    if (!self.eventsListeners) return self;
    events2.split(" ").forEach((event) => {
      if (typeof handler4 === "undefined") {
        self.eventsListeners[event] = [];
      } else if (self.eventsListeners[event]) {
        self.eventsListeners[event].forEach((eventHandler, index) => {
          if (eventHandler === handler4 || eventHandler.__emitterProxy && eventHandler.__emitterProxy === handler4) {
            self.eventsListeners[event].splice(index, 1);
          }
        });
      }
    });
    return self;
  },
  emit() {
    const self = this;
    if (!self.eventsListeners || self.destroyed) return self;
    if (!self.eventsListeners) return self;
    let events2;
    let data2;
    let context;
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }
    if (typeof args[0] === "string" || Array.isArray(args[0])) {
      events2 = args[0];
      data2 = args.slice(1, args.length);
      context = self;
    } else {
      events2 = args[0].events;
      data2 = args[0].data;
      context = args[0].context || self;
    }
    data2.unshift(context);
    const eventsArray = Array.isArray(events2) ? events2 : events2.split(" ");
    eventsArray.forEach((event) => {
      if (self.eventsAnyListeners && self.eventsAnyListeners.length) {
        self.eventsAnyListeners.forEach((eventHandler) => {
          eventHandler.apply(context, [event, ...data2]);
        });
      }
      if (self.eventsListeners && self.eventsListeners[event]) {
        self.eventsListeners[event].forEach((eventHandler) => {
          eventHandler.apply(context, data2);
        });
      }
    });
    return self;
  }
};
function updateSize() {
  const swiper = this;
  let width;
  let height;
  const el = swiper.el;
  if (typeof swiper.params.width !== "undefined" && swiper.params.width !== null) {
    width = swiper.params.width;
  } else {
    width = el.clientWidth;
  }
  if (typeof swiper.params.height !== "undefined" && swiper.params.height !== null) {
    height = swiper.params.height;
  } else {
    height = el.clientHeight;
  }
  if (width === 0 && swiper.isHorizontal() || height === 0 && swiper.isVertical()) {
    return;
  }
  width = width - parseInt(elementStyle(el, "padding-left") || 0, 10) - parseInt(elementStyle(el, "padding-right") || 0, 10);
  height = height - parseInt(elementStyle(el, "padding-top") || 0, 10) - parseInt(elementStyle(el, "padding-bottom") || 0, 10);
  if (Number.isNaN(width)) width = 0;
  if (Number.isNaN(height)) height = 0;
  Object.assign(swiper, {
    width,
    height,
    size: swiper.isHorizontal() ? width : height
  });
}
function updateSlides() {
  const swiper = this;
  function getDirectionPropertyValue(node, label) {
    return parseFloat(node.getPropertyValue(swiper.getDirectionLabel(label)) || 0);
  }
  const params = swiper.params;
  const {
    wrapperEl,
    slidesEl,
    size: swiperSize,
    rtlTranslate: rtl,
    wrongRTL
  } = swiper;
  const isVirtual = swiper.virtual && params.virtual.enabled;
  const previousSlidesLength = isVirtual ? swiper.virtual.slides.length : swiper.slides.length;
  const slides = elementChildren(slidesEl, `.${swiper.params.slideClass}, swiper-slide`);
  const slidesLength = isVirtual ? swiper.virtual.slides.length : slides.length;
  let snapGrid = [];
  const slidesGrid = [];
  const slidesSizesGrid = [];
  let offsetBefore = params.slidesOffsetBefore;
  if (typeof offsetBefore === "function") {
    offsetBefore = params.slidesOffsetBefore.call(swiper);
  }
  let offsetAfter = params.slidesOffsetAfter;
  if (typeof offsetAfter === "function") {
    offsetAfter = params.slidesOffsetAfter.call(swiper);
  }
  const previousSnapGridLength = swiper.snapGrid.length;
  const previousSlidesGridLength = swiper.slidesGrid.length;
  let spaceBetween = params.spaceBetween;
  let slidePosition = -offsetBefore;
  let prevSlideSize = 0;
  let index = 0;
  if (typeof swiperSize === "undefined") {
    return;
  }
  if (typeof spaceBetween === "string" && spaceBetween.indexOf("%") >= 0) {
    spaceBetween = parseFloat(spaceBetween.replace("%", "")) / 100 * swiperSize;
  } else if (typeof spaceBetween === "string") {
    spaceBetween = parseFloat(spaceBetween);
  }
  swiper.virtualSize = -spaceBetween;
  slides.forEach((slideEl) => {
    if (rtl) {
      slideEl.style.marginLeft = "";
    } else {
      slideEl.style.marginRight = "";
    }
    slideEl.style.marginBottom = "";
    slideEl.style.marginTop = "";
  });
  if (params.centeredSlides && params.cssMode) {
    setCSSProperty(wrapperEl, "--swiper-centered-offset-before", "");
    setCSSProperty(wrapperEl, "--swiper-centered-offset-after", "");
  }
  const gridEnabled = params.grid && params.grid.rows > 1 && swiper.grid;
  if (gridEnabled) {
    swiper.grid.initSlides(slides);
  } else if (swiper.grid) {
    swiper.grid.unsetSlides();
  }
  let slideSize;
  const shouldResetSlideSize = params.slidesPerView === "auto" && params.breakpoints && Object.keys(params.breakpoints).filter((key) => {
    return typeof params.breakpoints[key].slidesPerView !== "undefined";
  }).length > 0;
  for (let i = 0; i < slidesLength; i += 1) {
    slideSize = 0;
    let slide2;
    if (slides[i]) slide2 = slides[i];
    if (gridEnabled) {
      swiper.grid.updateSlide(i, slide2, slides);
    }
    if (slides[i] && elementStyle(slide2, "display") === "none") continue;
    if (params.slidesPerView === "auto") {
      if (shouldResetSlideSize) {
        slides[i].style[swiper.getDirectionLabel("width")] = ``;
      }
      const slideStyles = getComputedStyle(slide2);
      const currentTransform = slide2.style.transform;
      const currentWebKitTransform = slide2.style.webkitTransform;
      if (currentTransform) {
        slide2.style.transform = "none";
      }
      if (currentWebKitTransform) {
        slide2.style.webkitTransform = "none";
      }
      if (params.roundLengths) {
        slideSize = swiper.isHorizontal() ? elementOuterSize(slide2, "width") : elementOuterSize(slide2, "height");
      } else {
        const width = getDirectionPropertyValue(slideStyles, "width");
        const paddingLeft = getDirectionPropertyValue(slideStyles, "padding-left");
        const paddingRight = getDirectionPropertyValue(slideStyles, "padding-right");
        const marginLeft = getDirectionPropertyValue(slideStyles, "margin-left");
        const marginRight = getDirectionPropertyValue(slideStyles, "margin-right");
        const boxSizing = slideStyles.getPropertyValue("box-sizing");
        if (boxSizing && boxSizing === "border-box") {
          slideSize = width + marginLeft + marginRight;
        } else {
          const {
            clientWidth,
            offsetWidth
          } = slide2;
          slideSize = width + paddingLeft + paddingRight + marginLeft + marginRight + (offsetWidth - clientWidth);
        }
      }
      if (currentTransform) {
        slide2.style.transform = currentTransform;
      }
      if (currentWebKitTransform) {
        slide2.style.webkitTransform = currentWebKitTransform;
      }
      if (params.roundLengths) slideSize = Math.floor(slideSize);
    } else {
      slideSize = (swiperSize - (params.slidesPerView - 1) * spaceBetween) / params.slidesPerView;
      if (params.roundLengths) slideSize = Math.floor(slideSize);
      if (slides[i]) {
        slides[i].style[swiper.getDirectionLabel("width")] = `${slideSize}px`;
      }
    }
    if (slides[i]) {
      slides[i].swiperSlideSize = slideSize;
    }
    slidesSizesGrid.push(slideSize);
    if (params.centeredSlides) {
      slidePosition = slidePosition + slideSize / 2 + prevSlideSize / 2 + spaceBetween;
      if (prevSlideSize === 0 && i !== 0) slidePosition = slidePosition - swiperSize / 2 - spaceBetween;
      if (i === 0) slidePosition = slidePosition - swiperSize / 2 - spaceBetween;
      if (Math.abs(slidePosition) < 1 / 1e3) slidePosition = 0;
      if (params.roundLengths) slidePosition = Math.floor(slidePosition);
      if (index % params.slidesPerGroup === 0) snapGrid.push(slidePosition);
      slidesGrid.push(slidePosition);
    } else {
      if (params.roundLengths) slidePosition = Math.floor(slidePosition);
      if ((index - Math.min(swiper.params.slidesPerGroupSkip, index)) % swiper.params.slidesPerGroup === 0) snapGrid.push(slidePosition);
      slidesGrid.push(slidePosition);
      slidePosition = slidePosition + slideSize + spaceBetween;
    }
    swiper.virtualSize += slideSize + spaceBetween;
    prevSlideSize = slideSize;
    index += 1;
  }
  swiper.virtualSize = Math.max(swiper.virtualSize, swiperSize) + offsetAfter;
  if (rtl && wrongRTL && (params.effect === "slide" || params.effect === "coverflow")) {
    wrapperEl.style.width = `${swiper.virtualSize + spaceBetween}px`;
  }
  if (params.setWrapperSize) {
    wrapperEl.style[swiper.getDirectionLabel("width")] = `${swiper.virtualSize + spaceBetween}px`;
  }
  if (gridEnabled) {
    swiper.grid.updateWrapperSize(slideSize, snapGrid);
  }
  if (!params.centeredSlides) {
    const newSlidesGrid = [];
    for (let i = 0; i < snapGrid.length; i += 1) {
      let slidesGridItem = snapGrid[i];
      if (params.roundLengths) slidesGridItem = Math.floor(slidesGridItem);
      if (snapGrid[i] <= swiper.virtualSize - swiperSize) {
        newSlidesGrid.push(slidesGridItem);
      }
    }
    snapGrid = newSlidesGrid;
    if (Math.floor(swiper.virtualSize - swiperSize) - Math.floor(snapGrid[snapGrid.length - 1]) > 1) {
      snapGrid.push(swiper.virtualSize - swiperSize);
    }
  }
  if (isVirtual && params.loop) {
    const size2 = slidesSizesGrid[0] + spaceBetween;
    if (params.slidesPerGroup > 1) {
      const groups = Math.ceil((swiper.virtual.slidesBefore + swiper.virtual.slidesAfter) / params.slidesPerGroup);
      const groupSize = size2 * params.slidesPerGroup;
      for (let i = 0; i < groups; i += 1) {
        snapGrid.push(snapGrid[snapGrid.length - 1] + groupSize);
      }
    }
    for (let i = 0; i < swiper.virtual.slidesBefore + swiper.virtual.slidesAfter; i += 1) {
      if (params.slidesPerGroup === 1) {
        snapGrid.push(snapGrid[snapGrid.length - 1] + size2);
      }
      slidesGrid.push(slidesGrid[slidesGrid.length - 1] + size2);
      swiper.virtualSize += size2;
    }
  }
  if (snapGrid.length === 0) snapGrid = [0];
  if (spaceBetween !== 0) {
    const key = swiper.isHorizontal() && rtl ? "marginLeft" : swiper.getDirectionLabel("marginRight");
    slides.filter((_, slideIndex) => {
      if (!params.cssMode || params.loop) return true;
      if (slideIndex === slides.length - 1) {
        return false;
      }
      return true;
    }).forEach((slideEl) => {
      slideEl.style[key] = `${spaceBetween}px`;
    });
  }
  if (params.centeredSlides && params.centeredSlidesBounds) {
    let allSlidesSize = 0;
    slidesSizesGrid.forEach((slideSizeValue) => {
      allSlidesSize += slideSizeValue + (spaceBetween || 0);
    });
    allSlidesSize -= spaceBetween;
    const maxSnap = allSlidesSize > swiperSize ? allSlidesSize - swiperSize : 0;
    snapGrid = snapGrid.map((snap) => {
      if (snap <= 0) return -offsetBefore;
      if (snap > maxSnap) return maxSnap + offsetAfter;
      return snap;
    });
  }
  if (params.centerInsufficientSlides) {
    let allSlidesSize = 0;
    slidesSizesGrid.forEach((slideSizeValue) => {
      allSlidesSize += slideSizeValue + (spaceBetween || 0);
    });
    allSlidesSize -= spaceBetween;
    const offsetSize = (params.slidesOffsetBefore || 0) + (params.slidesOffsetAfter || 0);
    if (allSlidesSize + offsetSize < swiperSize) {
      const allSlidesOffset = (swiperSize - allSlidesSize - offsetSize) / 2;
      snapGrid.forEach((snap, snapIndex) => {
        snapGrid[snapIndex] = snap - allSlidesOffset;
      });
      slidesGrid.forEach((snap, snapIndex) => {
        slidesGrid[snapIndex] = snap + allSlidesOffset;
      });
    }
  }
  Object.assign(swiper, {
    slides,
    snapGrid,
    slidesGrid,
    slidesSizesGrid
  });
  if (params.centeredSlides && params.cssMode && !params.centeredSlidesBounds) {
    setCSSProperty(wrapperEl, "--swiper-centered-offset-before", `${-snapGrid[0]}px`);
    setCSSProperty(wrapperEl, "--swiper-centered-offset-after", `${swiper.size / 2 - slidesSizesGrid[slidesSizesGrid.length - 1] / 2}px`);
    const addToSnapGrid = -swiper.snapGrid[0];
    const addToSlidesGrid = -swiper.slidesGrid[0];
    swiper.snapGrid = swiper.snapGrid.map((v) => v + addToSnapGrid);
    swiper.slidesGrid = swiper.slidesGrid.map((v) => v + addToSlidesGrid);
  }
  if (slidesLength !== previousSlidesLength) {
    swiper.emit("slidesLengthChange");
  }
  if (snapGrid.length !== previousSnapGridLength) {
    if (swiper.params.watchOverflow) swiper.checkOverflow();
    swiper.emit("snapGridLengthChange");
  }
  if (slidesGrid.length !== previousSlidesGridLength) {
    swiper.emit("slidesGridLengthChange");
  }
  if (params.watchSlidesProgress) {
    swiper.updateSlidesOffset();
  }
  swiper.emit("slidesUpdated");
  if (!isVirtual && !params.cssMode && (params.effect === "slide" || params.effect === "fade")) {
    const backFaceHiddenClass = `${params.containerModifierClass}backface-hidden`;
    const hasClassBackfaceClassAdded = swiper.el.classList.contains(backFaceHiddenClass);
    if (slidesLength <= params.maxBackfaceHiddenSlides) {
      if (!hasClassBackfaceClassAdded) swiper.el.classList.add(backFaceHiddenClass);
    } else if (hasClassBackfaceClassAdded) {
      swiper.el.classList.remove(backFaceHiddenClass);
    }
  }
}
function updateAutoHeight(speed) {
  const swiper = this;
  const activeSlides = [];
  const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
  let newHeight = 0;
  let i;
  if (typeof speed === "number") {
    swiper.setTransition(speed);
  } else if (speed === true) {
    swiper.setTransition(swiper.params.speed);
  }
  const getSlideByIndex = (index) => {
    if (isVirtual) {
      return swiper.slides[swiper.getSlideIndexByData(index)];
    }
    return swiper.slides[index];
  };
  if (swiper.params.slidesPerView !== "auto" && swiper.params.slidesPerView > 1) {
    if (swiper.params.centeredSlides) {
      (swiper.visibleSlides || []).forEach((slide2) => {
        activeSlides.push(slide2);
      });
    } else {
      for (i = 0; i < Math.ceil(swiper.params.slidesPerView); i += 1) {
        const index = swiper.activeIndex + i;
        if (index > swiper.slides.length && !isVirtual) break;
        activeSlides.push(getSlideByIndex(index));
      }
    }
  } else {
    activeSlides.push(getSlideByIndex(swiper.activeIndex));
  }
  for (i = 0; i < activeSlides.length; i += 1) {
    if (typeof activeSlides[i] !== "undefined") {
      const height = activeSlides[i].offsetHeight;
      newHeight = height > newHeight ? height : newHeight;
    }
  }
  if (newHeight || newHeight === 0) swiper.wrapperEl.style.height = `${newHeight}px`;
}
function updateSlidesOffset() {
  const swiper = this;
  const slides = swiper.slides;
  const minusOffset = swiper.isElement ? swiper.isHorizontal() ? swiper.wrapperEl.offsetLeft : swiper.wrapperEl.offsetTop : 0;
  for (let i = 0; i < slides.length; i += 1) {
    slides[i].swiperSlideOffset = (swiper.isHorizontal() ? slides[i].offsetLeft : slides[i].offsetTop) - minusOffset - swiper.cssOverflowAdjustment();
  }
}
const toggleSlideClasses$1 = (slideEl, condition, className) => {
  if (condition && !slideEl.classList.contains(className)) {
    slideEl.classList.add(className);
  } else if (!condition && slideEl.classList.contains(className)) {
    slideEl.classList.remove(className);
  }
};
function updateSlidesProgress(translate2) {
  if (translate2 === void 0) {
    translate2 = this && this.translate || 0;
  }
  const swiper = this;
  const params = swiper.params;
  const {
    slides,
    rtlTranslate: rtl,
    snapGrid
  } = swiper;
  if (slides.length === 0) return;
  if (typeof slides[0].swiperSlideOffset === "undefined") swiper.updateSlidesOffset();
  let offsetCenter = -translate2;
  if (rtl) offsetCenter = translate2;
  swiper.visibleSlidesIndexes = [];
  swiper.visibleSlides = [];
  let spaceBetween = params.spaceBetween;
  if (typeof spaceBetween === "string" && spaceBetween.indexOf("%") >= 0) {
    spaceBetween = parseFloat(spaceBetween.replace("%", "")) / 100 * swiper.size;
  } else if (typeof spaceBetween === "string") {
    spaceBetween = parseFloat(spaceBetween);
  }
  for (let i = 0; i < slides.length; i += 1) {
    const slide2 = slides[i];
    let slideOffset = slide2.swiperSlideOffset;
    if (params.cssMode && params.centeredSlides) {
      slideOffset -= slides[0].swiperSlideOffset;
    }
    const slideProgress = (offsetCenter + (params.centeredSlides ? swiper.minTranslate() : 0) - slideOffset) / (slide2.swiperSlideSize + spaceBetween);
    const originalSlideProgress = (offsetCenter - snapGrid[0] + (params.centeredSlides ? swiper.minTranslate() : 0) - slideOffset) / (slide2.swiperSlideSize + spaceBetween);
    const slideBefore = -(offsetCenter - slideOffset);
    const slideAfter = slideBefore + swiper.slidesSizesGrid[i];
    const isFullyVisible = slideBefore >= 0 && slideBefore <= swiper.size - swiper.slidesSizesGrid[i];
    const isVisible = slideBefore >= 0 && slideBefore < swiper.size - 1 || slideAfter > 1 && slideAfter <= swiper.size || slideBefore <= 0 && slideAfter >= swiper.size;
    if (isVisible) {
      swiper.visibleSlides.push(slide2);
      swiper.visibleSlidesIndexes.push(i);
    }
    toggleSlideClasses$1(slide2, isVisible, params.slideVisibleClass);
    toggleSlideClasses$1(slide2, isFullyVisible, params.slideFullyVisibleClass);
    slide2.progress = rtl ? -slideProgress : slideProgress;
    slide2.originalProgress = rtl ? -originalSlideProgress : originalSlideProgress;
  }
}
function updateProgress(translate2) {
  const swiper = this;
  if (typeof translate2 === "undefined") {
    const multiplier = swiper.rtlTranslate ? -1 : 1;
    translate2 = swiper && swiper.translate && swiper.translate * multiplier || 0;
  }
  const params = swiper.params;
  const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
  let {
    progress,
    isBeginning,
    isEnd,
    progressLoop
  } = swiper;
  const wasBeginning = isBeginning;
  const wasEnd = isEnd;
  if (translatesDiff === 0) {
    progress = 0;
    isBeginning = true;
    isEnd = true;
  } else {
    progress = (translate2 - swiper.minTranslate()) / translatesDiff;
    const isBeginningRounded = Math.abs(translate2 - swiper.minTranslate()) < 1;
    const isEndRounded = Math.abs(translate2 - swiper.maxTranslate()) < 1;
    isBeginning = isBeginningRounded || progress <= 0;
    isEnd = isEndRounded || progress >= 1;
    if (isBeginningRounded) progress = 0;
    if (isEndRounded) progress = 1;
  }
  if (params.loop) {
    const firstSlideIndex = swiper.getSlideIndexByData(0);
    const lastSlideIndex = swiper.getSlideIndexByData(swiper.slides.length - 1);
    const firstSlideTranslate = swiper.slidesGrid[firstSlideIndex];
    const lastSlideTranslate = swiper.slidesGrid[lastSlideIndex];
    const translateMax = swiper.slidesGrid[swiper.slidesGrid.length - 1];
    const translateAbs = Math.abs(translate2);
    if (translateAbs >= firstSlideTranslate) {
      progressLoop = (translateAbs - firstSlideTranslate) / translateMax;
    } else {
      progressLoop = (translateAbs + translateMax - lastSlideTranslate) / translateMax;
    }
    if (progressLoop > 1) progressLoop -= 1;
  }
  Object.assign(swiper, {
    progress,
    progressLoop,
    isBeginning,
    isEnd
  });
  if (params.watchSlidesProgress || params.centeredSlides && params.autoHeight) swiper.updateSlidesProgress(translate2);
  if (isBeginning && !wasBeginning) {
    swiper.emit("reachBeginning toEdge");
  }
  if (isEnd && !wasEnd) {
    swiper.emit("reachEnd toEdge");
  }
  if (wasBeginning && !isBeginning || wasEnd && !isEnd) {
    swiper.emit("fromEdge");
  }
  swiper.emit("progress", progress);
}
const toggleSlideClasses = (slideEl, condition, className) => {
  if (condition && !slideEl.classList.contains(className)) {
    slideEl.classList.add(className);
  } else if (!condition && slideEl.classList.contains(className)) {
    slideEl.classList.remove(className);
  }
};
function updateSlidesClasses() {
  const swiper = this;
  const {
    slides,
    params,
    slidesEl,
    activeIndex
  } = swiper;
  const isVirtual = swiper.virtual && params.virtual.enabled;
  const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
  const getFilteredSlide = (selector) => {
    return elementChildren(slidesEl, `.${params.slideClass}${selector}, swiper-slide${selector}`)[0];
  };
  let activeSlide;
  let prevSlide;
  let nextSlide;
  if (isVirtual) {
    if (params.loop) {
      let slideIndex = activeIndex - swiper.virtual.slidesBefore;
      if (slideIndex < 0) slideIndex = swiper.virtual.slides.length + slideIndex;
      if (slideIndex >= swiper.virtual.slides.length) slideIndex -= swiper.virtual.slides.length;
      activeSlide = getFilteredSlide(`[data-swiper-slide-index="${slideIndex}"]`);
    } else {
      activeSlide = getFilteredSlide(`[data-swiper-slide-index="${activeIndex}"]`);
    }
  } else {
    if (gridEnabled) {
      activeSlide = slides.filter((slideEl) => slideEl.column === activeIndex)[0];
      nextSlide = slides.filter((slideEl) => slideEl.column === activeIndex + 1)[0];
      prevSlide = slides.filter((slideEl) => slideEl.column === activeIndex - 1)[0];
    } else {
      activeSlide = slides[activeIndex];
    }
  }
  if (activeSlide) {
    if (!gridEnabled) {
      nextSlide = elementNextAll(activeSlide, `.${params.slideClass}, swiper-slide`)[0];
      if (params.loop && !nextSlide) {
        nextSlide = slides[0];
      }
      prevSlide = elementPrevAll(activeSlide, `.${params.slideClass}, swiper-slide`)[0];
      if (params.loop && !prevSlide === 0) {
        prevSlide = slides[slides.length - 1];
      }
    }
  }
  slides.forEach((slideEl) => {
    toggleSlideClasses(slideEl, slideEl === activeSlide, params.slideActiveClass);
    toggleSlideClasses(slideEl, slideEl === nextSlide, params.slideNextClass);
    toggleSlideClasses(slideEl, slideEl === prevSlide, params.slidePrevClass);
  });
  swiper.emitSlidesClasses();
}
const processLazyPreloader = (swiper, imageEl) => {
  if (!swiper || swiper.destroyed || !swiper.params) return;
  const slideSelector = () => swiper.isElement ? `swiper-slide` : `.${swiper.params.slideClass}`;
  const slideEl = imageEl.closest(slideSelector());
  if (slideEl) {
    let lazyEl = slideEl.querySelector(`.${swiper.params.lazyPreloaderClass}`);
    if (!lazyEl && swiper.isElement) {
      if (slideEl.shadowRoot) {
        lazyEl = slideEl.shadowRoot.querySelector(`.${swiper.params.lazyPreloaderClass}`);
      } else {
        requestAnimationFrame(() => {
          if (slideEl.shadowRoot) {
            lazyEl = slideEl.shadowRoot.querySelector(`.${swiper.params.lazyPreloaderClass}`);
            if (lazyEl) lazyEl.remove();
          }
        });
      }
    }
    if (lazyEl) lazyEl.remove();
  }
};
const unlazy = (swiper, index) => {
  if (!swiper.slides[index]) return;
  const imageEl = swiper.slides[index].querySelector('[loading="lazy"]');
  if (imageEl) imageEl.removeAttribute("loading");
};
const preload = (swiper) => {
  if (!swiper || swiper.destroyed || !swiper.params) return;
  let amount = swiper.params.lazyPreloadPrevNext;
  const len = swiper.slides.length;
  if (!len || !amount || amount < 0) return;
  amount = Math.min(amount, len);
  const slidesPerView = swiper.params.slidesPerView === "auto" ? swiper.slidesPerViewDynamic() : Math.ceil(swiper.params.slidesPerView);
  const activeIndex = swiper.activeIndex;
  if (swiper.params.grid && swiper.params.grid.rows > 1) {
    const activeColumn = activeIndex;
    const preloadColumns = [activeColumn - amount];
    preloadColumns.push(...Array.from({
      length: amount
    }).map((_, i) => {
      return activeColumn + slidesPerView + i;
    }));
    swiper.slides.forEach((slideEl, i) => {
      if (preloadColumns.includes(slideEl.column)) unlazy(swiper, i);
    });
    return;
  }
  const slideIndexLastInView = activeIndex + slidesPerView - 1;
  if (swiper.params.rewind || swiper.params.loop) {
    for (let i = activeIndex - amount; i <= slideIndexLastInView + amount; i += 1) {
      const realIndex = (i % len + len) % len;
      if (realIndex < activeIndex || realIndex > slideIndexLastInView) unlazy(swiper, realIndex);
    }
  } else {
    for (let i = Math.max(activeIndex - amount, 0); i <= Math.min(slideIndexLastInView + amount, len - 1); i += 1) {
      if (i !== activeIndex && (i > slideIndexLastInView || i < activeIndex)) {
        unlazy(swiper, i);
      }
    }
  }
};
function getActiveIndexByTranslate(swiper) {
  const {
    slidesGrid,
    params
  } = swiper;
  const translate2 = swiper.rtlTranslate ? swiper.translate : -swiper.translate;
  let activeIndex;
  for (let i = 0; i < slidesGrid.length; i += 1) {
    if (typeof slidesGrid[i + 1] !== "undefined") {
      if (translate2 >= slidesGrid[i] && translate2 < slidesGrid[i + 1] - (slidesGrid[i + 1] - slidesGrid[i]) / 2) {
        activeIndex = i;
      } else if (translate2 >= slidesGrid[i] && translate2 < slidesGrid[i + 1]) {
        activeIndex = i + 1;
      }
    } else if (translate2 >= slidesGrid[i]) {
      activeIndex = i;
    }
  }
  if (params.normalizeSlideIndex) {
    if (activeIndex < 0 || typeof activeIndex === "undefined") activeIndex = 0;
  }
  return activeIndex;
}
function updateActiveIndex(newActiveIndex) {
  const swiper = this;
  const translate2 = swiper.rtlTranslate ? swiper.translate : -swiper.translate;
  const {
    snapGrid,
    params,
    activeIndex: previousIndex,
    realIndex: previousRealIndex,
    snapIndex: previousSnapIndex
  } = swiper;
  let activeIndex = newActiveIndex;
  let snapIndex;
  const getVirtualRealIndex = (aIndex) => {
    let realIndex2 = aIndex - swiper.virtual.slidesBefore;
    if (realIndex2 < 0) {
      realIndex2 = swiper.virtual.slides.length + realIndex2;
    }
    if (realIndex2 >= swiper.virtual.slides.length) {
      realIndex2 -= swiper.virtual.slides.length;
    }
    return realIndex2;
  };
  if (typeof activeIndex === "undefined") {
    activeIndex = getActiveIndexByTranslate(swiper);
  }
  if (snapGrid.indexOf(translate2) >= 0) {
    snapIndex = snapGrid.indexOf(translate2);
  } else {
    const skip = Math.min(params.slidesPerGroupSkip, activeIndex);
    snapIndex = skip + Math.floor((activeIndex - skip) / params.slidesPerGroup);
  }
  if (snapIndex >= snapGrid.length) snapIndex = snapGrid.length - 1;
  if (activeIndex === previousIndex && !swiper.params.loop) {
    if (snapIndex !== previousSnapIndex) {
      swiper.snapIndex = snapIndex;
      swiper.emit("snapIndexChange");
    }
    return;
  }
  if (activeIndex === previousIndex && swiper.params.loop && swiper.virtual && swiper.params.virtual.enabled) {
    swiper.realIndex = getVirtualRealIndex(activeIndex);
    return;
  }
  const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
  let realIndex;
  if (swiper.virtual && params.virtual.enabled && params.loop) {
    realIndex = getVirtualRealIndex(activeIndex);
  } else if (gridEnabled) {
    const firstSlideInColumn = swiper.slides.filter((slideEl) => slideEl.column === activeIndex)[0];
    let activeSlideIndex = parseInt(firstSlideInColumn.getAttribute("data-swiper-slide-index"), 10);
    if (Number.isNaN(activeSlideIndex)) {
      activeSlideIndex = Math.max(swiper.slides.indexOf(firstSlideInColumn), 0);
    }
    realIndex = Math.floor(activeSlideIndex / params.grid.rows);
  } else if (swiper.slides[activeIndex]) {
    const slideIndex = swiper.slides[activeIndex].getAttribute("data-swiper-slide-index");
    if (slideIndex) {
      realIndex = parseInt(slideIndex, 10);
    } else {
      realIndex = activeIndex;
    }
  } else {
    realIndex = activeIndex;
  }
  Object.assign(swiper, {
    previousSnapIndex,
    snapIndex,
    previousRealIndex,
    realIndex,
    previousIndex,
    activeIndex
  });
  if (swiper.initialized) {
    preload(swiper);
  }
  swiper.emit("activeIndexChange");
  swiper.emit("snapIndexChange");
  if (swiper.initialized || swiper.params.runCallbacksOnInit) {
    if (previousRealIndex !== realIndex) {
      swiper.emit("realIndexChange");
    }
    swiper.emit("slideChange");
  }
}
function updateClickedSlide(el, path) {
  const swiper = this;
  const params = swiper.params;
  let slide2 = el.closest(`.${params.slideClass}, swiper-slide`);
  if (!slide2 && swiper.isElement && path && path.length > 1 && path.includes(el)) {
    [...path.slice(path.indexOf(el) + 1, path.length)].forEach((pathEl) => {
      if (!slide2 && pathEl.matches && pathEl.matches(`.${params.slideClass}, swiper-slide`)) {
        slide2 = pathEl;
      }
    });
  }
  let slideFound = false;
  let slideIndex;
  if (slide2) {
    for (let i = 0; i < swiper.slides.length; i += 1) {
      if (swiper.slides[i] === slide2) {
        slideFound = true;
        slideIndex = i;
        break;
      }
    }
  }
  if (slide2 && slideFound) {
    swiper.clickedSlide = slide2;
    if (swiper.virtual && swiper.params.virtual.enabled) {
      swiper.clickedIndex = parseInt(slide2.getAttribute("data-swiper-slide-index"), 10);
    } else {
      swiper.clickedIndex = slideIndex;
    }
  } else {
    swiper.clickedSlide = void 0;
    swiper.clickedIndex = void 0;
    return;
  }
  if (params.slideToClickedSlide && swiper.clickedIndex !== void 0 && swiper.clickedIndex !== swiper.activeIndex) {
    swiper.slideToClickedSlide();
  }
}
var update = {
  updateSize,
  updateSlides,
  updateAutoHeight,
  updateSlidesOffset,
  updateSlidesProgress,
  updateProgress,
  updateSlidesClasses,
  updateActiveIndex,
  updateClickedSlide
};
function getSwiperTranslate(axis) {
  if (axis === void 0) {
    axis = this.isHorizontal() ? "x" : "y";
  }
  const swiper = this;
  const {
    params,
    rtlTranslate: rtl,
    translate: translate2,
    wrapperEl
  } = swiper;
  if (params.virtualTranslate) {
    return rtl ? -translate2 : translate2;
  }
  if (params.cssMode) {
    return translate2;
  }
  let currentTranslate = getTranslate(wrapperEl, axis);
  currentTranslate += swiper.cssOverflowAdjustment();
  if (rtl) currentTranslate = -currentTranslate;
  return currentTranslate || 0;
}
function setTranslate(translate2, byController) {
  const swiper = this;
  const {
    rtlTranslate: rtl,
    params,
    wrapperEl,
    progress
  } = swiper;
  let x = 0;
  let y = 0;
  const z = 0;
  if (swiper.isHorizontal()) {
    x = rtl ? -translate2 : translate2;
  } else {
    y = translate2;
  }
  if (params.roundLengths) {
    x = Math.floor(x);
    y = Math.floor(y);
  }
  swiper.previousTranslate = swiper.translate;
  swiper.translate = swiper.isHorizontal() ? x : y;
  if (params.cssMode) {
    wrapperEl[swiper.isHorizontal() ? "scrollLeft" : "scrollTop"] = swiper.isHorizontal() ? -x : -y;
  } else if (!params.virtualTranslate) {
    if (swiper.isHorizontal()) {
      x -= swiper.cssOverflowAdjustment();
    } else {
      y -= swiper.cssOverflowAdjustment();
    }
    wrapperEl.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
  }
  let newProgress;
  const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
  if (translatesDiff === 0) {
    newProgress = 0;
  } else {
    newProgress = (translate2 - swiper.minTranslate()) / translatesDiff;
  }
  if (newProgress !== progress) {
    swiper.updateProgress(translate2);
  }
  swiper.emit("setTranslate", swiper.translate, byController);
}
function minTranslate() {
  return -this.snapGrid[0];
}
function maxTranslate() {
  return -this.snapGrid[this.snapGrid.length - 1];
}
function translateTo(translate2, speed, runCallbacks, translateBounds, internal) {
  if (translate2 === void 0) {
    translate2 = 0;
  }
  if (speed === void 0) {
    speed = this.params.speed;
  }
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (translateBounds === void 0) {
    translateBounds = true;
  }
  const swiper = this;
  const {
    params,
    wrapperEl
  } = swiper;
  if (swiper.animating && params.preventInteractionOnTransition) {
    return false;
  }
  const minTranslate2 = swiper.minTranslate();
  const maxTranslate2 = swiper.maxTranslate();
  let newTranslate;
  if (translateBounds && translate2 > minTranslate2) newTranslate = minTranslate2;
  else if (translateBounds && translate2 < maxTranslate2) newTranslate = maxTranslate2;
  else newTranslate = translate2;
  swiper.updateProgress(newTranslate);
  if (params.cssMode) {
    const isH = swiper.isHorizontal();
    if (speed === 0) {
      wrapperEl[isH ? "scrollLeft" : "scrollTop"] = -newTranslate;
    } else {
      if (!swiper.support.smoothScroll) {
        animateCSSModeScroll({
          swiper,
          targetPosition: -newTranslate,
          side: isH ? "left" : "top"
        });
        return true;
      }
      wrapperEl.scrollTo({
        [isH ? "left" : "top"]: -newTranslate,
        behavior: "smooth"
      });
    }
    return true;
  }
  if (speed === 0) {
    swiper.setTransition(0);
    swiper.setTranslate(newTranslate);
    if (runCallbacks) {
      swiper.emit("beforeTransitionStart", speed, internal);
      swiper.emit("transitionEnd");
    }
  } else {
    swiper.setTransition(speed);
    swiper.setTranslate(newTranslate);
    if (runCallbacks) {
      swiper.emit("beforeTransitionStart", speed, internal);
      swiper.emit("transitionStart");
    }
    if (!swiper.animating) {
      swiper.animating = true;
      if (!swiper.onTranslateToWrapperTransitionEnd) {
        swiper.onTranslateToWrapperTransitionEnd = function transitionEnd2(e) {
          if (!swiper || swiper.destroyed) return;
          if (e.target !== this) return;
          swiper.wrapperEl.removeEventListener("transitionend", swiper.onTranslateToWrapperTransitionEnd);
          swiper.onTranslateToWrapperTransitionEnd = null;
          delete swiper.onTranslateToWrapperTransitionEnd;
          swiper.animating = false;
          if (runCallbacks) {
            swiper.emit("transitionEnd");
          }
        };
      }
      swiper.wrapperEl.addEventListener("transitionend", swiper.onTranslateToWrapperTransitionEnd);
    }
  }
  return true;
}
var translate = {
  getTranslate: getSwiperTranslate,
  setTranslate,
  minTranslate,
  maxTranslate,
  translateTo
};
function setTransition(duration, byController) {
  const swiper = this;
  if (!swiper.params.cssMode) {
    swiper.wrapperEl.style.transitionDuration = `${duration}ms`;
    swiper.wrapperEl.style.transitionDelay = duration === 0 ? `0ms` : "";
  }
  swiper.emit("setTransition", duration, byController);
}
function transitionEmit(_ref) {
  let {
    swiper,
    runCallbacks,
    direction,
    step
  } = _ref;
  const {
    activeIndex,
    previousIndex
  } = swiper;
  let dir = direction;
  if (!dir) {
    if (activeIndex > previousIndex) dir = "next";
    else if (activeIndex < previousIndex) dir = "prev";
    else dir = "reset";
  }
  swiper.emit(`transition${step}`);
  if (runCallbacks && activeIndex !== previousIndex) {
    if (dir === "reset") {
      swiper.emit(`slideResetTransition${step}`);
      return;
    }
    swiper.emit(`slideChangeTransition${step}`);
    if (dir === "next") {
      swiper.emit(`slideNextTransition${step}`);
    } else {
      swiper.emit(`slidePrevTransition${step}`);
    }
  }
}
function transitionStart(runCallbacks, direction) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper = this;
  const {
    params
  } = swiper;
  if (params.cssMode) return;
  if (params.autoHeight) {
    swiper.updateAutoHeight();
  }
  transitionEmit({
    swiper,
    runCallbacks,
    direction,
    step: "Start"
  });
}
function transitionEnd(runCallbacks, direction) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper = this;
  const {
    params
  } = swiper;
  swiper.animating = false;
  if (params.cssMode) return;
  swiper.setTransition(0);
  transitionEmit({
    swiper,
    runCallbacks,
    direction,
    step: "End"
  });
}
var transition = {
  setTransition,
  transitionStart,
  transitionEnd
};
function slideTo(index, speed, runCallbacks, internal, initial) {
  if (index === void 0) {
    index = 0;
  }
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (typeof index === "string") {
    index = parseInt(index, 10);
  }
  const swiper = this;
  let slideIndex = index;
  if (slideIndex < 0) slideIndex = 0;
  const {
    params,
    snapGrid,
    slidesGrid,
    previousIndex,
    activeIndex,
    rtlTranslate: rtl,
    wrapperEl,
    enabled
  } = swiper;
  if (!enabled && !internal && !initial || swiper.destroyed || swiper.animating && params.preventInteractionOnTransition) {
    return false;
  }
  if (typeof speed === "undefined") {
    speed = swiper.params.speed;
  }
  const skip = Math.min(swiper.params.slidesPerGroupSkip, slideIndex);
  let snapIndex = skip + Math.floor((slideIndex - skip) / swiper.params.slidesPerGroup);
  if (snapIndex >= snapGrid.length) snapIndex = snapGrid.length - 1;
  const translate2 = -snapGrid[snapIndex];
  if (params.normalizeSlideIndex) {
    for (let i = 0; i < slidesGrid.length; i += 1) {
      const normalizedTranslate = -Math.floor(translate2 * 100);
      const normalizedGrid = Math.floor(slidesGrid[i] * 100);
      const normalizedGridNext = Math.floor(slidesGrid[i + 1] * 100);
      if (typeof slidesGrid[i + 1] !== "undefined") {
        if (normalizedTranslate >= normalizedGrid && normalizedTranslate < normalizedGridNext - (normalizedGridNext - normalizedGrid) / 2) {
          slideIndex = i;
        } else if (normalizedTranslate >= normalizedGrid && normalizedTranslate < normalizedGridNext) {
          slideIndex = i + 1;
        }
      } else if (normalizedTranslate >= normalizedGrid) {
        slideIndex = i;
      }
    }
  }
  if (swiper.initialized && slideIndex !== activeIndex) {
    if (!swiper.allowSlideNext && (rtl ? translate2 > swiper.translate && translate2 > swiper.minTranslate() : translate2 < swiper.translate && translate2 < swiper.minTranslate())) {
      return false;
    }
    if (!swiper.allowSlidePrev && translate2 > swiper.translate && translate2 > swiper.maxTranslate()) {
      if ((activeIndex || 0) !== slideIndex) {
        return false;
      }
    }
  }
  if (slideIndex !== (previousIndex || 0) && runCallbacks) {
    swiper.emit("beforeSlideChangeStart");
  }
  swiper.updateProgress(translate2);
  let direction;
  if (slideIndex > activeIndex) direction = "next";
  else if (slideIndex < activeIndex) direction = "prev";
  else direction = "reset";
  const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
  const isInitialVirtual = isVirtual && initial;
  if (!isInitialVirtual && (rtl && -translate2 === swiper.translate || !rtl && translate2 === swiper.translate)) {
    swiper.updateActiveIndex(slideIndex);
    if (params.autoHeight) {
      swiper.updateAutoHeight();
    }
    swiper.updateSlidesClasses();
    if (params.effect !== "slide") {
      swiper.setTranslate(translate2);
    }
    if (direction !== "reset") {
      swiper.transitionStart(runCallbacks, direction);
      swiper.transitionEnd(runCallbacks, direction);
    }
    return false;
  }
  if (params.cssMode) {
    const isH = swiper.isHorizontal();
    const t = rtl ? translate2 : -translate2;
    if (speed === 0) {
      if (isVirtual) {
        swiper.wrapperEl.style.scrollSnapType = "none";
        swiper._immediateVirtual = true;
      }
      if (isVirtual && !swiper._cssModeVirtualInitialSet && swiper.params.initialSlide > 0) {
        swiper._cssModeVirtualInitialSet = true;
        requestAnimationFrame(() => {
          wrapperEl[isH ? "scrollLeft" : "scrollTop"] = t;
        });
      } else {
        wrapperEl[isH ? "scrollLeft" : "scrollTop"] = t;
      }
      if (isVirtual) {
        requestAnimationFrame(() => {
          swiper.wrapperEl.style.scrollSnapType = "";
          swiper._immediateVirtual = false;
        });
      }
    } else {
      if (!swiper.support.smoothScroll) {
        animateCSSModeScroll({
          swiper,
          targetPosition: t,
          side: isH ? "left" : "top"
        });
        return true;
      }
      wrapperEl.scrollTo({
        [isH ? "left" : "top"]: t,
        behavior: "smooth"
      });
    }
    return true;
  }
  swiper.setTransition(speed);
  swiper.setTranslate(translate2);
  swiper.updateActiveIndex(slideIndex);
  swiper.updateSlidesClasses();
  swiper.emit("beforeTransitionStart", speed, internal);
  swiper.transitionStart(runCallbacks, direction);
  if (speed === 0) {
    swiper.transitionEnd(runCallbacks, direction);
  } else if (!swiper.animating) {
    swiper.animating = true;
    if (!swiper.onSlideToWrapperTransitionEnd) {
      swiper.onSlideToWrapperTransitionEnd = function transitionEnd2(e) {
        if (!swiper || swiper.destroyed) return;
        if (e.target !== this) return;
        swiper.wrapperEl.removeEventListener("transitionend", swiper.onSlideToWrapperTransitionEnd);
        swiper.onSlideToWrapperTransitionEnd = null;
        delete swiper.onSlideToWrapperTransitionEnd;
        swiper.transitionEnd(runCallbacks, direction);
      };
    }
    swiper.wrapperEl.addEventListener("transitionend", swiper.onSlideToWrapperTransitionEnd);
  }
  return true;
}
function slideToLoop(index, speed, runCallbacks, internal) {
  if (index === void 0) {
    index = 0;
  }
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (typeof index === "string") {
    const indexAsNumber = parseInt(index, 10);
    index = indexAsNumber;
  }
  const swiper = this;
  if (swiper.destroyed) return;
  if (typeof speed === "undefined") {
    speed = swiper.params.speed;
  }
  const gridEnabled = swiper.grid && swiper.params.grid && swiper.params.grid.rows > 1;
  let newIndex = index;
  if (swiper.params.loop) {
    if (swiper.virtual && swiper.params.virtual.enabled) {
      newIndex = newIndex + swiper.virtual.slidesBefore;
    } else {
      let targetSlideIndex;
      if (gridEnabled) {
        const slideIndex = newIndex * swiper.params.grid.rows;
        targetSlideIndex = swiper.slides.filter((slideEl) => slideEl.getAttribute("data-swiper-slide-index") * 1 === slideIndex)[0].column;
      } else {
        targetSlideIndex = swiper.getSlideIndexByData(newIndex);
      }
      const cols = gridEnabled ? Math.ceil(swiper.slides.length / swiper.params.grid.rows) : swiper.slides.length;
      const {
        centeredSlides
      } = swiper.params;
      let slidesPerView = swiper.params.slidesPerView;
      if (slidesPerView === "auto") {
        slidesPerView = swiper.slidesPerViewDynamic();
      } else {
        slidesPerView = Math.ceil(parseFloat(swiper.params.slidesPerView, 10));
        if (centeredSlides && slidesPerView % 2 === 0) {
          slidesPerView = slidesPerView + 1;
        }
      }
      let needLoopFix = cols - targetSlideIndex < slidesPerView;
      if (centeredSlides) {
        needLoopFix = needLoopFix || targetSlideIndex < Math.ceil(slidesPerView / 2);
      }
      if (internal && centeredSlides && swiper.params.slidesPerView !== "auto" && !gridEnabled) {
        needLoopFix = false;
      }
      if (needLoopFix) {
        const direction = centeredSlides ? targetSlideIndex < swiper.activeIndex ? "prev" : "next" : targetSlideIndex - swiper.activeIndex - 1 < swiper.params.slidesPerView ? "next" : "prev";
        swiper.loopFix({
          direction,
          slideTo: true,
          activeSlideIndex: direction === "next" ? targetSlideIndex + 1 : targetSlideIndex - cols + 1,
          slideRealIndex: direction === "next" ? swiper.realIndex : void 0
        });
      }
      if (gridEnabled) {
        const slideIndex = newIndex * swiper.params.grid.rows;
        newIndex = swiper.slides.filter((slideEl) => slideEl.getAttribute("data-swiper-slide-index") * 1 === slideIndex)[0].column;
      } else {
        newIndex = swiper.getSlideIndexByData(newIndex);
      }
    }
  }
  requestAnimationFrame(() => {
    swiper.slideTo(newIndex, speed, runCallbacks, internal);
  });
  return swiper;
}
function slideNext(speed, runCallbacks, internal) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper = this;
  const {
    enabled,
    params,
    animating
  } = swiper;
  if (!enabled || swiper.destroyed) return swiper;
  if (typeof speed === "undefined") {
    speed = swiper.params.speed;
  }
  let perGroup = params.slidesPerGroup;
  if (params.slidesPerView === "auto" && params.slidesPerGroup === 1 && params.slidesPerGroupAuto) {
    perGroup = Math.max(swiper.slidesPerViewDynamic("current", true), 1);
  }
  const increment = swiper.activeIndex < params.slidesPerGroupSkip ? 1 : perGroup;
  const isVirtual = swiper.virtual && params.virtual.enabled;
  if (params.loop) {
    if (animating && !isVirtual && params.loopPreventsSliding) return false;
    swiper.loopFix({
      direction: "next"
    });
    swiper._clientLeft = swiper.wrapperEl.clientLeft;
    if (swiper.activeIndex === swiper.slides.length - 1 && params.cssMode) {
      requestAnimationFrame(() => {
        swiper.slideTo(swiper.activeIndex + increment, speed, runCallbacks, internal);
      });
      return true;
    }
  }
  if (params.rewind && swiper.isEnd) {
    return swiper.slideTo(0, speed, runCallbacks, internal);
  }
  return swiper.slideTo(swiper.activeIndex + increment, speed, runCallbacks, internal);
}
function slidePrev(speed, runCallbacks, internal) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper = this;
  const {
    params,
    snapGrid,
    slidesGrid,
    rtlTranslate,
    enabled,
    animating
  } = swiper;
  if (!enabled || swiper.destroyed) return swiper;
  if (typeof speed === "undefined") {
    speed = swiper.params.speed;
  }
  const isVirtual = swiper.virtual && params.virtual.enabled;
  if (params.loop) {
    if (animating && !isVirtual && params.loopPreventsSliding) return false;
    swiper.loopFix({
      direction: "prev"
    });
    swiper._clientLeft = swiper.wrapperEl.clientLeft;
  }
  const translate2 = rtlTranslate ? swiper.translate : -swiper.translate;
  function normalize(val) {
    if (val < 0) return -Math.floor(Math.abs(val));
    return Math.floor(val);
  }
  const normalizedTranslate = normalize(translate2);
  const normalizedSnapGrid = snapGrid.map((val) => normalize(val));
  let prevSnap = snapGrid[normalizedSnapGrid.indexOf(normalizedTranslate) - 1];
  if (typeof prevSnap === "undefined" && params.cssMode) {
    let prevSnapIndex;
    snapGrid.forEach((snap, snapIndex) => {
      if (normalizedTranslate >= snap) {
        prevSnapIndex = snapIndex;
      }
    });
    if (typeof prevSnapIndex !== "undefined") {
      prevSnap = snapGrid[prevSnapIndex > 0 ? prevSnapIndex - 1 : prevSnapIndex];
    }
  }
  let prevIndex = 0;
  if (typeof prevSnap !== "undefined") {
    prevIndex = slidesGrid.indexOf(prevSnap);
    if (prevIndex < 0) prevIndex = swiper.activeIndex - 1;
    if (params.slidesPerView === "auto" && params.slidesPerGroup === 1 && params.slidesPerGroupAuto) {
      prevIndex = prevIndex - swiper.slidesPerViewDynamic("previous", true) + 1;
      prevIndex = Math.max(prevIndex, 0);
    }
  }
  if (params.rewind && swiper.isBeginning) {
    const lastIndex = swiper.params.virtual && swiper.params.virtual.enabled && swiper.virtual ? swiper.virtual.slides.length - 1 : swiper.slides.length - 1;
    return swiper.slideTo(lastIndex, speed, runCallbacks, internal);
  } else if (params.loop && swiper.activeIndex === 0 && params.cssMode) {
    requestAnimationFrame(() => {
      swiper.slideTo(prevIndex, speed, runCallbacks, internal);
    });
    return true;
  }
  return swiper.slideTo(prevIndex, speed, runCallbacks, internal);
}
function slideReset(speed, runCallbacks, internal) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  const swiper = this;
  if (swiper.destroyed) return;
  if (typeof speed === "undefined") {
    speed = swiper.params.speed;
  }
  return swiper.slideTo(swiper.activeIndex, speed, runCallbacks, internal);
}
function slideToClosest(speed, runCallbacks, internal, threshold) {
  if (runCallbacks === void 0) {
    runCallbacks = true;
  }
  if (threshold === void 0) {
    threshold = 0.5;
  }
  const swiper = this;
  if (swiper.destroyed) return;
  if (typeof speed === "undefined") {
    speed = swiper.params.speed;
  }
  let index = swiper.activeIndex;
  const skip = Math.min(swiper.params.slidesPerGroupSkip, index);
  const snapIndex = skip + Math.floor((index - skip) / swiper.params.slidesPerGroup);
  const translate2 = swiper.rtlTranslate ? swiper.translate : -swiper.translate;
  if (translate2 >= swiper.snapGrid[snapIndex]) {
    const currentSnap = swiper.snapGrid[snapIndex];
    const nextSnap = swiper.snapGrid[snapIndex + 1];
    if (translate2 - currentSnap > (nextSnap - currentSnap) * threshold) {
      index += swiper.params.slidesPerGroup;
    }
  } else {
    const prevSnap = swiper.snapGrid[snapIndex - 1];
    const currentSnap = swiper.snapGrid[snapIndex];
    if (translate2 - prevSnap <= (currentSnap - prevSnap) * threshold) {
      index -= swiper.params.slidesPerGroup;
    }
  }
  index = Math.max(index, 0);
  index = Math.min(index, swiper.slidesGrid.length - 1);
  return swiper.slideTo(index, speed, runCallbacks, internal);
}
function slideToClickedSlide() {
  const swiper = this;
  if (swiper.destroyed) return;
  const {
    params,
    slidesEl
  } = swiper;
  const slidesPerView = params.slidesPerView === "auto" ? swiper.slidesPerViewDynamic() : params.slidesPerView;
  let slideToIndex = swiper.clickedIndex;
  let realIndex;
  const slideSelector = swiper.isElement ? `swiper-slide` : `.${params.slideClass}`;
  if (params.loop) {
    if (swiper.animating) return;
    realIndex = parseInt(swiper.clickedSlide.getAttribute("data-swiper-slide-index"), 10);
    if (params.centeredSlides) {
      if (slideToIndex < swiper.loopedSlides - slidesPerView / 2 || slideToIndex > swiper.slides.length - swiper.loopedSlides + slidesPerView / 2) {
        swiper.loopFix();
        slideToIndex = swiper.getSlideIndex(elementChildren(slidesEl, `${slideSelector}[data-swiper-slide-index="${realIndex}"]`)[0]);
        nextTick(() => {
          swiper.slideTo(slideToIndex);
        });
      } else {
        swiper.slideTo(slideToIndex);
      }
    } else if (slideToIndex > swiper.slides.length - slidesPerView) {
      swiper.loopFix();
      slideToIndex = swiper.getSlideIndex(elementChildren(slidesEl, `${slideSelector}[data-swiper-slide-index="${realIndex}"]`)[0]);
      nextTick(() => {
        swiper.slideTo(slideToIndex);
      });
    } else {
      swiper.slideTo(slideToIndex);
    }
  } else {
    swiper.slideTo(slideToIndex);
  }
}
var slide = {
  slideTo,
  slideToLoop,
  slideNext,
  slidePrev,
  slideReset,
  slideToClosest,
  slideToClickedSlide
};
function loopCreate(slideRealIndex) {
  const swiper = this;
  const {
    params,
    slidesEl
  } = swiper;
  if (!params.loop || swiper.virtual && swiper.params.virtual.enabled) return;
  const initSlides = () => {
    const slides = elementChildren(slidesEl, `.${params.slideClass}, swiper-slide`);
    slides.forEach((el, index) => {
      el.setAttribute("data-swiper-slide-index", index);
    });
  };
  const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
  const slidesPerGroup = params.slidesPerGroup * (gridEnabled ? params.grid.rows : 1);
  const shouldFillGroup = swiper.slides.length % slidesPerGroup !== 0;
  const shouldFillGrid = gridEnabled && swiper.slides.length % params.grid.rows !== 0;
  const addBlankSlides = (amountOfSlides) => {
    for (let i = 0; i < amountOfSlides; i += 1) {
      const slideEl = swiper.isElement ? createElement("swiper-slide", [params.slideBlankClass]) : createElement("div", [params.slideClass, params.slideBlankClass]);
      swiper.slidesEl.append(slideEl);
    }
  };
  if (shouldFillGroup) {
    if (params.loopAddBlankSlides) {
      const slidesToAdd = slidesPerGroup - swiper.slides.length % slidesPerGroup;
      addBlankSlides(slidesToAdd);
      swiper.recalcSlides();
      swiper.updateSlides();
    } else {
      showWarning("Swiper Loop Warning: The number of slides is not even to slidesPerGroup, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)");
    }
    initSlides();
  } else if (shouldFillGrid) {
    if (params.loopAddBlankSlides) {
      const slidesToAdd = params.grid.rows - swiper.slides.length % params.grid.rows;
      addBlankSlides(slidesToAdd);
      swiper.recalcSlides();
      swiper.updateSlides();
    } else {
      showWarning("Swiper Loop Warning: The number of slides is not even to grid.rows, loop mode may not function properly. You need to add more slides (or make duplicates, or empty slides)");
    }
    initSlides();
  } else {
    initSlides();
  }
  swiper.loopFix({
    slideRealIndex,
    direction: params.centeredSlides ? void 0 : "next"
  });
}
function loopFix(_temp) {
  let {
    slideRealIndex,
    slideTo: slideTo2 = true,
    direction,
    setTranslate: setTranslate2,
    activeSlideIndex,
    byController,
    byMousewheel
  } = _temp === void 0 ? {} : _temp;
  const swiper = this;
  if (!swiper.params.loop) return;
  swiper.emit("beforeLoopFix");
  const {
    slides,
    allowSlidePrev,
    allowSlideNext,
    slidesEl,
    params
  } = swiper;
  const {
    centeredSlides
  } = params;
  swiper.allowSlidePrev = true;
  swiper.allowSlideNext = true;
  if (swiper.virtual && params.virtual.enabled) {
    if (slideTo2) {
      if (!params.centeredSlides && swiper.snapIndex === 0) {
        swiper.slideTo(swiper.virtual.slides.length, 0, false, true);
      } else if (params.centeredSlides && swiper.snapIndex < params.slidesPerView) {
        swiper.slideTo(swiper.virtual.slides.length + swiper.snapIndex, 0, false, true);
      } else if (swiper.snapIndex === swiper.snapGrid.length - 1) {
        swiper.slideTo(swiper.virtual.slidesBefore, 0, false, true);
      }
    }
    swiper.allowSlidePrev = allowSlidePrev;
    swiper.allowSlideNext = allowSlideNext;
    swiper.emit("loopFix");
    return;
  }
  let slidesPerView = params.slidesPerView;
  if (slidesPerView === "auto") {
    slidesPerView = swiper.slidesPerViewDynamic();
  } else {
    slidesPerView = Math.ceil(parseFloat(params.slidesPerView, 10));
    if (centeredSlides && slidesPerView % 2 === 0) {
      slidesPerView = slidesPerView + 1;
    }
  }
  const slidesPerGroup = params.slidesPerGroupAuto ? slidesPerView : params.slidesPerGroup;
  let loopedSlides = slidesPerGroup;
  if (loopedSlides % slidesPerGroup !== 0) {
    loopedSlides += slidesPerGroup - loopedSlides % slidesPerGroup;
  }
  loopedSlides += params.loopAdditionalSlides;
  swiper.loopedSlides = loopedSlides;
  const gridEnabled = swiper.grid && params.grid && params.grid.rows > 1;
  if (slides.length < slidesPerView + loopedSlides) {
    showWarning("Swiper Loop Warning: The number of slides is not enough for loop mode, it will be disabled and not function properly. You need to add more slides (or make duplicates) or lower the values of slidesPerView and slidesPerGroup parameters");
  } else if (gridEnabled && params.grid.fill === "row") {
    showWarning("Swiper Loop Warning: Loop mode is not compatible with grid.fill = `row`");
  }
  const prependSlidesIndexes = [];
  const appendSlidesIndexes = [];
  let activeIndex = swiper.activeIndex;
  if (typeof activeSlideIndex === "undefined") {
    activeSlideIndex = swiper.getSlideIndex(slides.filter((el) => el.classList.contains(params.slideActiveClass))[0]);
  } else {
    activeIndex = activeSlideIndex;
  }
  const isNext = direction === "next" || !direction;
  const isPrev = direction === "prev" || !direction;
  let slidesPrepended = 0;
  let slidesAppended = 0;
  const cols = gridEnabled ? Math.ceil(slides.length / params.grid.rows) : slides.length;
  const activeColIndex = gridEnabled ? slides[activeSlideIndex].column : activeSlideIndex;
  const activeColIndexWithShift = activeColIndex + (centeredSlides && typeof setTranslate2 === "undefined" ? -slidesPerView / 2 + 0.5 : 0);
  if (activeColIndexWithShift < loopedSlides) {
    slidesPrepended = Math.max(loopedSlides - activeColIndexWithShift, slidesPerGroup);
    for (let i = 0; i < loopedSlides - activeColIndexWithShift; i += 1) {
      const index = i - Math.floor(i / cols) * cols;
      if (gridEnabled) {
        const colIndexToPrepend = cols - index - 1;
        for (let i2 = slides.length - 1; i2 >= 0; i2 -= 1) {
          if (slides[i2].column === colIndexToPrepend) prependSlidesIndexes.push(i2);
        }
      } else {
        prependSlidesIndexes.push(cols - index - 1);
      }
    }
  } else if (activeColIndexWithShift + slidesPerView > cols - loopedSlides) {
    slidesAppended = Math.max(activeColIndexWithShift - (cols - loopedSlides * 2), slidesPerGroup);
    for (let i = 0; i < slidesAppended; i += 1) {
      const index = i - Math.floor(i / cols) * cols;
      if (gridEnabled) {
        slides.forEach((slide2, slideIndex) => {
          if (slide2.column === index) appendSlidesIndexes.push(slideIndex);
        });
      } else {
        appendSlidesIndexes.push(index);
      }
    }
  }
  swiper.__preventObserver__ = true;
  requestAnimationFrame(() => {
    swiper.__preventObserver__ = false;
  });
  if (isPrev) {
    prependSlidesIndexes.forEach((index) => {
      slides[index].swiperLoopMoveDOM = true;
      slidesEl.prepend(slides[index]);
      slides[index].swiperLoopMoveDOM = false;
    });
  }
  if (isNext) {
    appendSlidesIndexes.forEach((index) => {
      slides[index].swiperLoopMoveDOM = true;
      slidesEl.append(slides[index]);
      slides[index].swiperLoopMoveDOM = false;
    });
  }
  swiper.recalcSlides();
  if (params.slidesPerView === "auto") {
    swiper.updateSlides();
  } else if (gridEnabled && (prependSlidesIndexes.length > 0 && isPrev || appendSlidesIndexes.length > 0 && isNext)) {
    swiper.slides.forEach((slide2, slideIndex) => {
      swiper.grid.updateSlide(slideIndex, slide2, swiper.slides);
    });
  }
  if (params.watchSlidesProgress) {
    swiper.updateSlidesOffset();
  }
  if (slideTo2) {
    if (prependSlidesIndexes.length > 0 && isPrev) {
      if (typeof slideRealIndex === "undefined") {
        const currentSlideTranslate = swiper.slidesGrid[activeIndex];
        const newSlideTranslate = swiper.slidesGrid[activeIndex + slidesPrepended];
        const diff = newSlideTranslate - currentSlideTranslate;
        if (byMousewheel) {
          swiper.setTranslate(swiper.translate - diff);
        } else {
          swiper.slideTo(activeIndex + Math.ceil(slidesPrepended), 0, false, true);
          if (setTranslate2) {
            swiper.touchEventsData.startTranslate = swiper.touchEventsData.startTranslate - diff;
            swiper.touchEventsData.currentTranslate = swiper.touchEventsData.currentTranslate - diff;
          }
        }
      } else {
        if (setTranslate2) {
          const shift = gridEnabled ? prependSlidesIndexes.length / params.grid.rows : prependSlidesIndexes.length;
          swiper.slideTo(swiper.activeIndex + shift, 0, false, true);
          swiper.touchEventsData.currentTranslate = swiper.translate;
        }
      }
    } else if (appendSlidesIndexes.length > 0 && isNext) {
      if (typeof slideRealIndex === "undefined") {
        const currentSlideTranslate = swiper.slidesGrid[activeIndex];
        const newSlideTranslate = swiper.slidesGrid[activeIndex - slidesAppended];
        const diff = newSlideTranslate - currentSlideTranslate;
        if (byMousewheel) {
          swiper.setTranslate(swiper.translate - diff);
        } else {
          swiper.slideTo(activeIndex - slidesAppended, 0, false, true);
          if (setTranslate2) {
            swiper.touchEventsData.startTranslate = swiper.touchEventsData.startTranslate - diff;
            swiper.touchEventsData.currentTranslate = swiper.touchEventsData.currentTranslate - diff;
          }
        }
      } else {
        const shift = gridEnabled ? appendSlidesIndexes.length / params.grid.rows : appendSlidesIndexes.length;
        swiper.slideTo(swiper.activeIndex - shift, 0, false, true);
      }
    }
  }
  swiper.allowSlidePrev = allowSlidePrev;
  swiper.allowSlideNext = allowSlideNext;
  if (swiper.controller && swiper.controller.control && !byController) {
    const loopParams = {
      slideRealIndex,
      direction,
      setTranslate: setTranslate2,
      activeSlideIndex,
      byController: true
    };
    if (Array.isArray(swiper.controller.control)) {
      swiper.controller.control.forEach((c) => {
        if (!c.destroyed && c.params.loop) c.loopFix({
          ...loopParams,
          slideTo: c.params.slidesPerView === params.slidesPerView ? slideTo2 : false
        });
      });
    } else if (swiper.controller.control instanceof swiper.constructor && swiper.controller.control.params.loop) {
      swiper.controller.control.loopFix({
        ...loopParams,
        slideTo: swiper.controller.control.params.slidesPerView === params.slidesPerView ? slideTo2 : false
      });
    }
  }
  swiper.emit("loopFix");
}
function loopDestroy() {
  const swiper = this;
  const {
    params,
    slidesEl
  } = swiper;
  if (!params.loop || swiper.virtual && swiper.params.virtual.enabled) return;
  swiper.recalcSlides();
  const newSlidesOrder = [];
  swiper.slides.forEach((slideEl) => {
    const index = typeof slideEl.swiperSlideIndex === "undefined" ? slideEl.getAttribute("data-swiper-slide-index") * 1 : slideEl.swiperSlideIndex;
    newSlidesOrder[index] = slideEl;
  });
  swiper.slides.forEach((slideEl) => {
    slideEl.removeAttribute("data-swiper-slide-index");
  });
  newSlidesOrder.forEach((slideEl) => {
    slidesEl.append(slideEl);
  });
  swiper.recalcSlides();
  swiper.slideTo(swiper.realIndex, 0);
}
var loop = {
  loopCreate,
  loopFix,
  loopDestroy
};
function setGrabCursor(moving) {
  const swiper = this;
  if (!swiper.params.simulateTouch || swiper.params.watchOverflow && swiper.isLocked || swiper.params.cssMode) return;
  const el = swiper.params.touchEventsTarget === "container" ? swiper.el : swiper.wrapperEl;
  if (swiper.isElement) {
    swiper.__preventObserver__ = true;
  }
  el.style.cursor = "move";
  el.style.cursor = moving ? "grabbing" : "grab";
  if (swiper.isElement) {
    requestAnimationFrame(() => {
      swiper.__preventObserver__ = false;
    });
  }
}
function unsetGrabCursor() {
  const swiper = this;
  if (swiper.params.watchOverflow && swiper.isLocked || swiper.params.cssMode) {
    return;
  }
  if (swiper.isElement) {
    swiper.__preventObserver__ = true;
  }
  swiper[swiper.params.touchEventsTarget === "container" ? "el" : "wrapperEl"].style.cursor = "";
  if (swiper.isElement) {
    requestAnimationFrame(() => {
      swiper.__preventObserver__ = false;
    });
  }
}
var grabCursor = {
  setGrabCursor,
  unsetGrabCursor
};
function closestElement(selector, base) {
  if (base === void 0) {
    base = this;
  }
  function __closestFrom(el) {
    if (!el || el === getDocument() || el === getWindow()) return null;
    if (el.assignedSlot) el = el.assignedSlot;
    const found = el.closest(selector);
    if (!found && !el.getRootNode) {
      return null;
    }
    return found || __closestFrom(el.getRootNode().host);
  }
  return __closestFrom(base);
}
function preventEdgeSwipe(swiper, event, startX) {
  const window2 = getWindow();
  const {
    params
  } = swiper;
  const edgeSwipeDetection = params.edgeSwipeDetection;
  const edgeSwipeThreshold = params.edgeSwipeThreshold;
  if (edgeSwipeDetection && (startX <= edgeSwipeThreshold || startX >= window2.innerWidth - edgeSwipeThreshold)) {
    if (edgeSwipeDetection === "prevent") {
      event.preventDefault();
      return true;
    }
    return false;
  }
  return true;
}
function onTouchStart(event) {
  const swiper = this;
  const document2 = getDocument();
  let e = event;
  if (e.originalEvent) e = e.originalEvent;
  const data2 = swiper.touchEventsData;
  if (e.type === "pointerdown") {
    if (data2.pointerId !== null && data2.pointerId !== e.pointerId) {
      return;
    }
    data2.pointerId = e.pointerId;
  } else if (e.type === "touchstart" && e.targetTouches.length === 1) {
    data2.touchId = e.targetTouches[0].identifier;
  }
  if (e.type === "touchstart") {
    preventEdgeSwipe(swiper, e, e.targetTouches[0].pageX);
    return;
  }
  const {
    params,
    touches,
    enabled
  } = swiper;
  if (!enabled) return;
  if (!params.simulateTouch && e.pointerType === "mouse") return;
  if (swiper.animating && params.preventInteractionOnTransition) {
    return;
  }
  if (!swiper.animating && params.cssMode && params.loop) {
    swiper.loopFix();
  }
  let targetEl = e.target;
  if (params.touchEventsTarget === "wrapper") {
    if (!elementIsChildOf(targetEl, swiper.wrapperEl)) return;
  }
  if ("which" in e && e.which === 3) return;
  if ("button" in e && e.button > 0) return;
  if (data2.isTouched && data2.isMoved) return;
  const swipingClassHasValue = !!params.noSwipingClass && params.noSwipingClass !== "";
  const eventPath = e.composedPath ? e.composedPath() : e.path;
  if (swipingClassHasValue && e.target && e.target.shadowRoot && eventPath) {
    targetEl = eventPath[0];
  }
  const noSwipingSelector = params.noSwipingSelector ? params.noSwipingSelector : `.${params.noSwipingClass}`;
  const isTargetShadow = !!(e.target && e.target.shadowRoot);
  if (params.noSwiping && (isTargetShadow ? closestElement(noSwipingSelector, targetEl) : targetEl.closest(noSwipingSelector))) {
    swiper.allowClick = true;
    return;
  }
  if (params.swipeHandler) {
    if (!targetEl.closest(params.swipeHandler)) return;
  }
  touches.currentX = e.pageX;
  touches.currentY = e.pageY;
  const startX = touches.currentX;
  const startY = touches.currentY;
  if (!preventEdgeSwipe(swiper, e, startX)) {
    return;
  }
  Object.assign(data2, {
    isTouched: true,
    isMoved: false,
    allowTouchCallbacks: true,
    isScrolling: void 0,
    startMoving: void 0
  });
  touches.startX = startX;
  touches.startY = startY;
  data2.touchStartTime = now();
  swiper.allowClick = true;
  swiper.updateSize();
  swiper.swipeDirection = void 0;
  if (params.threshold > 0) data2.allowThresholdMove = false;
  let preventDefault = true;
  if (targetEl.matches(data2.focusableElements)) {
    preventDefault = false;
    if (targetEl.nodeName === "SELECT") {
      data2.isTouched = false;
    }
  }
  if (document2.activeElement && document2.activeElement.matches(data2.focusableElements) && document2.activeElement !== targetEl) {
    document2.activeElement.blur();
  }
  const shouldPreventDefault = preventDefault && swiper.allowTouchMove && params.touchStartPreventDefault;
  if ((params.touchStartForcePreventDefault || shouldPreventDefault) && !targetEl.isContentEditable) {
    e.preventDefault();
  }
  if (params.freeMode && params.freeMode.enabled && swiper.freeMode && swiper.animating && !params.cssMode) {
    swiper.freeMode.onTouchStart();
  }
  swiper.emit("touchStart", e);
}
function onTouchMove(event) {
  const document2 = getDocument();
  const swiper = this;
  const data2 = swiper.touchEventsData;
  const {
    params,
    touches,
    rtlTranslate: rtl,
    enabled
  } = swiper;
  if (!enabled) return;
  if (!params.simulateTouch && event.pointerType === "mouse") return;
  let e = event;
  if (e.originalEvent) e = e.originalEvent;
  if (e.type === "pointermove") {
    if (data2.touchId !== null) return;
    const id = e.pointerId;
    if (id !== data2.pointerId) return;
  }
  let targetTouch;
  if (e.type === "touchmove") {
    targetTouch = [...e.changedTouches].filter((t) => t.identifier === data2.touchId)[0];
    if (!targetTouch || targetTouch.identifier !== data2.touchId) return;
  } else {
    targetTouch = e;
  }
  if (!data2.isTouched) {
    if (data2.startMoving && data2.isScrolling) {
      swiper.emit("touchMoveOpposite", e);
    }
    return;
  }
  const pageX = targetTouch.pageX;
  const pageY = targetTouch.pageY;
  if (e.preventedByNestedSwiper) {
    touches.startX = pageX;
    touches.startY = pageY;
    return;
  }
  if (!swiper.allowTouchMove) {
    if (!e.target.matches(data2.focusableElements)) {
      swiper.allowClick = false;
    }
    if (data2.isTouched) {
      Object.assign(touches, {
        startX: pageX,
        startY: pageY,
        currentX: pageX,
        currentY: pageY
      });
      data2.touchStartTime = now();
    }
    return;
  }
  if (params.touchReleaseOnEdges && !params.loop) {
    if (swiper.isVertical()) {
      if (pageY < touches.startY && swiper.translate <= swiper.maxTranslate() || pageY > touches.startY && swiper.translate >= swiper.minTranslate()) {
        data2.isTouched = false;
        data2.isMoved = false;
        return;
      }
    } else if (pageX < touches.startX && swiper.translate <= swiper.maxTranslate() || pageX > touches.startX && swiper.translate >= swiper.minTranslate()) {
      return;
    }
  }
  if (document2.activeElement) {
    if (e.target === document2.activeElement && e.target.matches(data2.focusableElements)) {
      data2.isMoved = true;
      swiper.allowClick = false;
      return;
    }
  }
  if (data2.allowTouchCallbacks) {
    swiper.emit("touchMove", e);
  }
  touches.previousX = touches.currentX;
  touches.previousY = touches.currentY;
  touches.currentX = pageX;
  touches.currentY = pageY;
  const diffX = touches.currentX - touches.startX;
  const diffY = touches.currentY - touches.startY;
  if (swiper.params.threshold && Math.sqrt(diffX ** 2 + diffY ** 2) < swiper.params.threshold) return;
  if (typeof data2.isScrolling === "undefined") {
    let touchAngle;
    if (swiper.isHorizontal() && touches.currentY === touches.startY || swiper.isVertical() && touches.currentX === touches.startX) {
      data2.isScrolling = false;
    } else {
      if (diffX * diffX + diffY * diffY >= 25) {
        touchAngle = Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180 / Math.PI;
        data2.isScrolling = swiper.isHorizontal() ? touchAngle > params.touchAngle : 90 - touchAngle > params.touchAngle;
      }
    }
  }
  if (data2.isScrolling) {
    swiper.emit("touchMoveOpposite", e);
  }
  if (typeof data2.startMoving === "undefined") {
    if (touches.currentX !== touches.startX || touches.currentY !== touches.startY) {
      data2.startMoving = true;
    }
  }
  if (data2.isScrolling || e.type === "touchmove" && data2.preventTouchMoveFromPointerMove) {
    data2.isTouched = false;
    return;
  }
  if (!data2.startMoving) {
    return;
  }
  swiper.allowClick = false;
  if (!params.cssMode && e.cancelable) {
    e.preventDefault();
  }
  if (params.touchMoveStopPropagation && !params.nested) {
    e.stopPropagation();
  }
  let diff = swiper.isHorizontal() ? diffX : diffY;
  let touchesDiff = swiper.isHorizontal() ? touches.currentX - touches.previousX : touches.currentY - touches.previousY;
  if (params.oneWayMovement) {
    diff = Math.abs(diff) * (rtl ? 1 : -1);
    touchesDiff = Math.abs(touchesDiff) * (rtl ? 1 : -1);
  }
  touches.diff = diff;
  diff *= params.touchRatio;
  if (rtl) {
    diff = -diff;
    touchesDiff = -touchesDiff;
  }
  const prevTouchesDirection = swiper.touchesDirection;
  swiper.swipeDirection = diff > 0 ? "prev" : "next";
  swiper.touchesDirection = touchesDiff > 0 ? "prev" : "next";
  const isLoop = swiper.params.loop && !params.cssMode;
  const allowLoopFix = swiper.touchesDirection === "next" && swiper.allowSlideNext || swiper.touchesDirection === "prev" && swiper.allowSlidePrev;
  if (!data2.isMoved) {
    if (isLoop && allowLoopFix) {
      swiper.loopFix({
        direction: swiper.swipeDirection
      });
    }
    data2.startTranslate = swiper.getTranslate();
    swiper.setTransition(0);
    if (swiper.animating) {
      const evt = new window.CustomEvent("transitionend", {
        bubbles: true,
        cancelable: true,
        detail: {
          bySwiperTouchMove: true
        }
      });
      swiper.wrapperEl.dispatchEvent(evt);
    }
    data2.allowMomentumBounce = false;
    if (params.grabCursor && (swiper.allowSlideNext === true || swiper.allowSlidePrev === true)) {
      swiper.setGrabCursor(true);
    }
    swiper.emit("sliderFirstMove", e);
  }
  let loopFixed;
  (/* @__PURE__ */ new Date()).getTime();
  if (data2.isMoved && data2.allowThresholdMove && prevTouchesDirection !== swiper.touchesDirection && isLoop && allowLoopFix && Math.abs(diff) >= 1) {
    Object.assign(touches, {
      startX: pageX,
      startY: pageY,
      currentX: pageX,
      currentY: pageY,
      startTranslate: data2.currentTranslate
    });
    data2.loopSwapReset = true;
    data2.startTranslate = data2.currentTranslate;
    return;
  }
  swiper.emit("sliderMove", e);
  data2.isMoved = true;
  data2.currentTranslate = diff + data2.startTranslate;
  let disableParentSwiper = true;
  let resistanceRatio = params.resistanceRatio;
  if (params.touchReleaseOnEdges) {
    resistanceRatio = 0;
  }
  if (diff > 0) {
    if (isLoop && allowLoopFix && !loopFixed && data2.allowThresholdMove && data2.currentTranslate > (params.centeredSlides ? swiper.minTranslate() - swiper.slidesSizesGrid[swiper.activeIndex + 1] - (params.slidesPerView !== "auto" && swiper.slides.length - params.slidesPerView >= 2 ? swiper.slidesSizesGrid[swiper.activeIndex + 1] + swiper.params.spaceBetween : 0) - swiper.params.spaceBetween : swiper.minTranslate())) {
      swiper.loopFix({
        direction: "prev",
        setTranslate: true,
        activeSlideIndex: 0
      });
    }
    if (data2.currentTranslate > swiper.minTranslate()) {
      disableParentSwiper = false;
      if (params.resistance) {
        data2.currentTranslate = swiper.minTranslate() - 1 + (-swiper.minTranslate() + data2.startTranslate + diff) ** resistanceRatio;
      }
    }
  } else if (diff < 0) {
    if (isLoop && allowLoopFix && !loopFixed && data2.allowThresholdMove && data2.currentTranslate < (params.centeredSlides ? swiper.maxTranslate() + swiper.slidesSizesGrid[swiper.slidesSizesGrid.length - 1] + swiper.params.spaceBetween + (params.slidesPerView !== "auto" && swiper.slides.length - params.slidesPerView >= 2 ? swiper.slidesSizesGrid[swiper.slidesSizesGrid.length - 1] + swiper.params.spaceBetween : 0) : swiper.maxTranslate())) {
      swiper.loopFix({
        direction: "next",
        setTranslate: true,
        activeSlideIndex: swiper.slides.length - (params.slidesPerView === "auto" ? swiper.slidesPerViewDynamic() : Math.ceil(parseFloat(params.slidesPerView, 10)))
      });
    }
    if (data2.currentTranslate < swiper.maxTranslate()) {
      disableParentSwiper = false;
      if (params.resistance) {
        data2.currentTranslate = swiper.maxTranslate() + 1 - (swiper.maxTranslate() - data2.startTranslate - diff) ** resistanceRatio;
      }
    }
  }
  if (disableParentSwiper) {
    e.preventedByNestedSwiper = true;
  }
  if (!swiper.allowSlideNext && swiper.swipeDirection === "next" && data2.currentTranslate < data2.startTranslate) {
    data2.currentTranslate = data2.startTranslate;
  }
  if (!swiper.allowSlidePrev && swiper.swipeDirection === "prev" && data2.currentTranslate > data2.startTranslate) {
    data2.currentTranslate = data2.startTranslate;
  }
  if (!swiper.allowSlidePrev && !swiper.allowSlideNext) {
    data2.currentTranslate = data2.startTranslate;
  }
  if (params.threshold > 0) {
    if (Math.abs(diff) > params.threshold || data2.allowThresholdMove) {
      if (!data2.allowThresholdMove) {
        data2.allowThresholdMove = true;
        touches.startX = touches.currentX;
        touches.startY = touches.currentY;
        data2.currentTranslate = data2.startTranslate;
        touches.diff = swiper.isHorizontal() ? touches.currentX - touches.startX : touches.currentY - touches.startY;
        return;
      }
    } else {
      data2.currentTranslate = data2.startTranslate;
      return;
    }
  }
  if (!params.followFinger || params.cssMode) return;
  if (params.freeMode && params.freeMode.enabled && swiper.freeMode || params.watchSlidesProgress) {
    swiper.updateActiveIndex();
    swiper.updateSlidesClasses();
  }
  if (params.freeMode && params.freeMode.enabled && swiper.freeMode) {
    swiper.freeMode.onTouchMove();
  }
  swiper.updateProgress(data2.currentTranslate);
  swiper.setTranslate(data2.currentTranslate);
}
function onTouchEnd(event) {
  const swiper = this;
  const data2 = swiper.touchEventsData;
  let e = event;
  if (e.originalEvent) e = e.originalEvent;
  let targetTouch;
  const isTouchEvent = e.type === "touchend" || e.type === "touchcancel";
  if (!isTouchEvent) {
    if (data2.touchId !== null) return;
    if (e.pointerId !== data2.pointerId) return;
    targetTouch = e;
  } else {
    targetTouch = [...e.changedTouches].filter((t) => t.identifier === data2.touchId)[0];
    if (!targetTouch || targetTouch.identifier !== data2.touchId) return;
  }
  if (["pointercancel", "pointerout", "pointerleave", "contextmenu"].includes(e.type)) {
    const proceed = ["pointercancel", "contextmenu"].includes(e.type) && (swiper.browser.isSafari || swiper.browser.isWebView);
    if (!proceed) {
      return;
    }
  }
  data2.pointerId = null;
  data2.touchId = null;
  const {
    params,
    touches,
    rtlTranslate: rtl,
    slidesGrid,
    enabled
  } = swiper;
  if (!enabled) return;
  if (!params.simulateTouch && e.pointerType === "mouse") return;
  if (data2.allowTouchCallbacks) {
    swiper.emit("touchEnd", e);
  }
  data2.allowTouchCallbacks = false;
  if (!data2.isTouched) {
    if (data2.isMoved && params.grabCursor) {
      swiper.setGrabCursor(false);
    }
    data2.isMoved = false;
    data2.startMoving = false;
    return;
  }
  if (params.grabCursor && data2.isMoved && data2.isTouched && (swiper.allowSlideNext === true || swiper.allowSlidePrev === true)) {
    swiper.setGrabCursor(false);
  }
  const touchEndTime = now();
  const timeDiff = touchEndTime - data2.touchStartTime;
  if (swiper.allowClick) {
    const pathTree = e.path || e.composedPath && e.composedPath();
    swiper.updateClickedSlide(pathTree && pathTree[0] || e.target, pathTree);
    swiper.emit("tap click", e);
    if (timeDiff < 300 && touchEndTime - data2.lastClickTime < 300) {
      swiper.emit("doubleTap doubleClick", e);
    }
  }
  data2.lastClickTime = now();
  nextTick(() => {
    if (!swiper.destroyed) swiper.allowClick = true;
  });
  if (!data2.isTouched || !data2.isMoved || !swiper.swipeDirection || touches.diff === 0 && !data2.loopSwapReset || data2.currentTranslate === data2.startTranslate && !data2.loopSwapReset) {
    data2.isTouched = false;
    data2.isMoved = false;
    data2.startMoving = false;
    return;
  }
  data2.isTouched = false;
  data2.isMoved = false;
  data2.startMoving = false;
  let currentPos;
  if (params.followFinger) {
    currentPos = rtl ? swiper.translate : -swiper.translate;
  } else {
    currentPos = -data2.currentTranslate;
  }
  if (params.cssMode) {
    return;
  }
  if (params.freeMode && params.freeMode.enabled) {
    swiper.freeMode.onTouchEnd({
      currentPos
    });
    return;
  }
  const swipeToLast = currentPos >= -swiper.maxTranslate() && !swiper.params.loop;
  let stopIndex = 0;
  let groupSize = swiper.slidesSizesGrid[0];
  for (let i = 0; i < slidesGrid.length; i += i < params.slidesPerGroupSkip ? 1 : params.slidesPerGroup) {
    const increment2 = i < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
    if (typeof slidesGrid[i + increment2] !== "undefined") {
      if (swipeToLast || currentPos >= slidesGrid[i] && currentPos < slidesGrid[i + increment2]) {
        stopIndex = i;
        groupSize = slidesGrid[i + increment2] - slidesGrid[i];
      }
    } else if (swipeToLast || currentPos >= slidesGrid[i]) {
      stopIndex = i;
      groupSize = slidesGrid[slidesGrid.length - 1] - slidesGrid[slidesGrid.length - 2];
    }
  }
  let rewindFirstIndex = null;
  let rewindLastIndex = null;
  if (params.rewind) {
    if (swiper.isBeginning) {
      rewindLastIndex = params.virtual && params.virtual.enabled && swiper.virtual ? swiper.virtual.slides.length - 1 : swiper.slides.length - 1;
    } else if (swiper.isEnd) {
      rewindFirstIndex = 0;
    }
  }
  const ratio = (currentPos - slidesGrid[stopIndex]) / groupSize;
  const increment = stopIndex < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
  if (timeDiff > params.longSwipesMs) {
    if (!params.longSwipes) {
      swiper.slideTo(swiper.activeIndex);
      return;
    }
    if (swiper.swipeDirection === "next") {
      if (ratio >= params.longSwipesRatio) swiper.slideTo(params.rewind && swiper.isEnd ? rewindFirstIndex : stopIndex + increment);
      else swiper.slideTo(stopIndex);
    }
    if (swiper.swipeDirection === "prev") {
      if (ratio > 1 - params.longSwipesRatio) {
        swiper.slideTo(stopIndex + increment);
      } else if (rewindLastIndex !== null && ratio < 0 && Math.abs(ratio) > params.longSwipesRatio) {
        swiper.slideTo(rewindLastIndex);
      } else {
        swiper.slideTo(stopIndex);
      }
    }
  } else {
    if (!params.shortSwipes) {
      swiper.slideTo(swiper.activeIndex);
      return;
    }
    const isNavButtonTarget = swiper.navigation && (e.target === swiper.navigation.nextEl || e.target === swiper.navigation.prevEl);
    if (!isNavButtonTarget) {
      if (swiper.swipeDirection === "next") {
        swiper.slideTo(rewindFirstIndex !== null ? rewindFirstIndex : stopIndex + increment);
      }
      if (swiper.swipeDirection === "prev") {
        swiper.slideTo(rewindLastIndex !== null ? rewindLastIndex : stopIndex);
      }
    } else if (e.target === swiper.navigation.nextEl) {
      swiper.slideTo(stopIndex + increment);
    } else {
      swiper.slideTo(stopIndex);
    }
  }
}
function onResize() {
  const swiper = this;
  const {
    params,
    el
  } = swiper;
  if (el && el.offsetWidth === 0) return;
  if (params.breakpoints) {
    swiper.setBreakpoint();
  }
  const {
    allowSlideNext,
    allowSlidePrev,
    snapGrid
  } = swiper;
  const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
  swiper.allowSlideNext = true;
  swiper.allowSlidePrev = true;
  swiper.updateSize();
  swiper.updateSlides();
  swiper.updateSlidesClasses();
  const isVirtualLoop = isVirtual && params.loop;
  if ((params.slidesPerView === "auto" || params.slidesPerView > 1) && swiper.isEnd && !swiper.isBeginning && !swiper.params.centeredSlides && !isVirtualLoop) {
    swiper.slideTo(swiper.slides.length - 1, 0, false, true);
  } else {
    if (swiper.params.loop && !isVirtual) {
      swiper.slideToLoop(swiper.realIndex, 0, false, true);
    } else {
      swiper.slideTo(swiper.activeIndex, 0, false, true);
    }
  }
  if (swiper.autoplay && swiper.autoplay.running && swiper.autoplay.paused) {
    clearTimeout(swiper.autoplay.resizeTimeout);
    swiper.autoplay.resizeTimeout = setTimeout(() => {
      if (swiper.autoplay && swiper.autoplay.running && swiper.autoplay.paused) {
        swiper.autoplay.resume();
      }
    }, 500);
  }
  swiper.allowSlidePrev = allowSlidePrev;
  swiper.allowSlideNext = allowSlideNext;
  if (swiper.params.watchOverflow && snapGrid !== swiper.snapGrid) {
    swiper.checkOverflow();
  }
}
function onClick(e) {
  const swiper = this;
  if (!swiper.enabled) return;
  if (!swiper.allowClick) {
    if (swiper.params.preventClicks) e.preventDefault();
    if (swiper.params.preventClicksPropagation && swiper.animating) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  }
}
function onScroll() {
  const swiper = this;
  const {
    wrapperEl,
    rtlTranslate,
    enabled
  } = swiper;
  if (!enabled) return;
  swiper.previousTranslate = swiper.translate;
  if (swiper.isHorizontal()) {
    swiper.translate = -wrapperEl.scrollLeft;
  } else {
    swiper.translate = -wrapperEl.scrollTop;
  }
  if (swiper.translate === 0) swiper.translate = 0;
  swiper.updateActiveIndex();
  swiper.updateSlidesClasses();
  let newProgress;
  const translatesDiff = swiper.maxTranslate() - swiper.minTranslate();
  if (translatesDiff === 0) {
    newProgress = 0;
  } else {
    newProgress = (swiper.translate - swiper.minTranslate()) / translatesDiff;
  }
  if (newProgress !== swiper.progress) {
    swiper.updateProgress(rtlTranslate ? -swiper.translate : swiper.translate);
  }
  swiper.emit("setTranslate", swiper.translate, false);
}
function onLoad(e) {
  const swiper = this;
  processLazyPreloader(swiper, e.target);
  if (swiper.params.cssMode || swiper.params.slidesPerView !== "auto" && !swiper.params.autoHeight) {
    return;
  }
  swiper.update();
}
function onDocumentTouchStart() {
  const swiper = this;
  if (swiper.documentTouchHandlerProceeded) return;
  swiper.documentTouchHandlerProceeded = true;
  if (swiper.params.touchReleaseOnEdges) {
    swiper.el.style.touchAction = "auto";
  }
}
const events = (swiper, method) => {
  const document2 = getDocument();
  const {
    params,
    el,
    wrapperEl,
    device
  } = swiper;
  const capture = !!params.nested;
  const domMethod = method === "on" ? "addEventListener" : "removeEventListener";
  const swiperMethod = method;
  if (!el || typeof el === "string") return;
  document2[domMethod]("touchstart", swiper.onDocumentTouchStart, {
    passive: false,
    capture
  });
  el[domMethod]("touchstart", swiper.onTouchStart, {
    passive: false
  });
  el[domMethod]("pointerdown", swiper.onTouchStart, {
    passive: false
  });
  document2[domMethod]("touchmove", swiper.onTouchMove, {
    passive: false,
    capture
  });
  document2[domMethod]("pointermove", swiper.onTouchMove, {
    passive: false,
    capture
  });
  document2[domMethod]("touchend", swiper.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointerup", swiper.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointercancel", swiper.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("touchcancel", swiper.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointerout", swiper.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("pointerleave", swiper.onTouchEnd, {
    passive: true
  });
  document2[domMethod]("contextmenu", swiper.onTouchEnd, {
    passive: true
  });
  if (params.preventClicks || params.preventClicksPropagation) {
    el[domMethod]("click", swiper.onClick, true);
  }
  if (params.cssMode) {
    wrapperEl[domMethod]("scroll", swiper.onScroll);
  }
  if (params.updateOnWindowResize) {
    swiper[swiperMethod](device.ios || device.android ? "resize orientationchange observerUpdate" : "resize observerUpdate", onResize, true);
  } else {
    swiper[swiperMethod]("observerUpdate", onResize, true);
  }
  el[domMethod]("load", swiper.onLoad, {
    capture: true
  });
};
function attachEvents() {
  const swiper = this;
  const {
    params
  } = swiper;
  swiper.onTouchStart = onTouchStart.bind(swiper);
  swiper.onTouchMove = onTouchMove.bind(swiper);
  swiper.onTouchEnd = onTouchEnd.bind(swiper);
  swiper.onDocumentTouchStart = onDocumentTouchStart.bind(swiper);
  if (params.cssMode) {
    swiper.onScroll = onScroll.bind(swiper);
  }
  swiper.onClick = onClick.bind(swiper);
  swiper.onLoad = onLoad.bind(swiper);
  events(swiper, "on");
}
function detachEvents() {
  const swiper = this;
  events(swiper, "off");
}
var events$1 = {
  attachEvents,
  detachEvents
};
const isGridEnabled = (swiper, params) => {
  return swiper.grid && params.grid && params.grid.rows > 1;
};
function setBreakpoint() {
  const swiper = this;
  const {
    realIndex,
    initialized,
    params,
    el
  } = swiper;
  const breakpoints2 = params.breakpoints;
  if (!breakpoints2 || breakpoints2 && Object.keys(breakpoints2).length === 0) return;
  const breakpoint = swiper.getBreakpoint(breakpoints2, swiper.params.breakpointsBase, swiper.el);
  if (!breakpoint || swiper.currentBreakpoint === breakpoint) return;
  const breakpointOnlyParams = breakpoint in breakpoints2 ? breakpoints2[breakpoint] : void 0;
  const breakpointParams = breakpointOnlyParams || swiper.originalParams;
  const wasMultiRow = isGridEnabled(swiper, params);
  const isMultiRow = isGridEnabled(swiper, breakpointParams);
  const wasGrabCursor = swiper.params.grabCursor;
  const isGrabCursor = breakpointParams.grabCursor;
  const wasEnabled = params.enabled;
  if (wasMultiRow && !isMultiRow) {
    el.classList.remove(`${params.containerModifierClass}grid`, `${params.containerModifierClass}grid-column`);
    swiper.emitContainerClasses();
  } else if (!wasMultiRow && isMultiRow) {
    el.classList.add(`${params.containerModifierClass}grid`);
    if (breakpointParams.grid.fill && breakpointParams.grid.fill === "column" || !breakpointParams.grid.fill && params.grid.fill === "column") {
      el.classList.add(`${params.containerModifierClass}grid-column`);
    }
    swiper.emitContainerClasses();
  }
  if (wasGrabCursor && !isGrabCursor) {
    swiper.unsetGrabCursor();
  } else if (!wasGrabCursor && isGrabCursor) {
    swiper.setGrabCursor();
  }
  ["navigation", "pagination", "scrollbar"].forEach((prop) => {
    if (typeof breakpointParams[prop] === "undefined") return;
    const wasModuleEnabled = params[prop] && params[prop].enabled;
    const isModuleEnabled = breakpointParams[prop] && breakpointParams[prop].enabled;
    if (wasModuleEnabled && !isModuleEnabled) {
      swiper[prop].disable();
    }
    if (!wasModuleEnabled && isModuleEnabled) {
      swiper[prop].enable();
    }
  });
  const directionChanged = breakpointParams.direction && breakpointParams.direction !== params.direction;
  const needsReLoop = params.loop && (breakpointParams.slidesPerView !== params.slidesPerView || directionChanged);
  const wasLoop = params.loop;
  if (directionChanged && initialized) {
    swiper.changeDirection();
  }
  extend(swiper.params, breakpointParams);
  const isEnabled = swiper.params.enabled;
  const hasLoop = swiper.params.loop;
  Object.assign(swiper, {
    allowTouchMove: swiper.params.allowTouchMove,
    allowSlideNext: swiper.params.allowSlideNext,
    allowSlidePrev: swiper.params.allowSlidePrev
  });
  if (wasEnabled && !isEnabled) {
    swiper.disable();
  } else if (!wasEnabled && isEnabled) {
    swiper.enable();
  }
  swiper.currentBreakpoint = breakpoint;
  swiper.emit("_beforeBreakpoint", breakpointParams);
  if (initialized) {
    if (needsReLoop) {
      swiper.loopDestroy();
      swiper.loopCreate(realIndex);
      swiper.updateSlides();
    } else if (!wasLoop && hasLoop) {
      swiper.loopCreate(realIndex);
      swiper.updateSlides();
    } else if (wasLoop && !hasLoop) {
      swiper.loopDestroy();
    }
  }
  swiper.emit("breakpoint", breakpointParams);
}
function getBreakpoint(breakpoints2, base, containerEl) {
  if (base === void 0) {
    base = "window";
  }
  if (!breakpoints2 || base === "container" && !containerEl) return void 0;
  let breakpoint = false;
  const window2 = getWindow();
  const currentHeight = base === "window" ? window2.innerHeight : containerEl.clientHeight;
  const points = Object.keys(breakpoints2).map((point) => {
    if (typeof point === "string" && point.indexOf("@") === 0) {
      const minRatio = parseFloat(point.substr(1));
      const value = currentHeight * minRatio;
      return {
        value,
        point
      };
    }
    return {
      value: point,
      point
    };
  });
  points.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
  for (let i = 0; i < points.length; i += 1) {
    const {
      point,
      value
    } = points[i];
    if (base === "window") {
      if (window2.matchMedia(`(min-width: ${value}px)`).matches) {
        breakpoint = point;
      }
    } else if (value <= containerEl.clientWidth) {
      breakpoint = point;
    }
  }
  return breakpoint || "max";
}
var breakpoints = {
  setBreakpoint,
  getBreakpoint
};
function prepareClasses(entries, prefix2) {
  const resultClasses = [];
  entries.forEach((item) => {
    if (typeof item === "object") {
      Object.keys(item).forEach((classNames) => {
        if (item[classNames]) {
          resultClasses.push(prefix2 + classNames);
        }
      });
    } else if (typeof item === "string") {
      resultClasses.push(prefix2 + item);
    }
  });
  return resultClasses;
}
function addClasses() {
  const swiper = this;
  const {
    classNames,
    params,
    rtl,
    el,
    device
  } = swiper;
  const suffixes = prepareClasses(["initialized", params.direction, {
    "free-mode": swiper.params.freeMode && params.freeMode.enabled
  }, {
    "autoheight": params.autoHeight
  }, {
    "rtl": rtl
  }, {
    "grid": params.grid && params.grid.rows > 1
  }, {
    "grid-column": params.grid && params.grid.rows > 1 && params.grid.fill === "column"
  }, {
    "android": device.android
  }, {
    "ios": device.ios
  }, {
    "css-mode": params.cssMode
  }, {
    "centered": params.cssMode && params.centeredSlides
  }, {
    "watch-progress": params.watchSlidesProgress
  }], params.containerModifierClass);
  classNames.push(...suffixes);
  el.classList.add(...classNames);
  swiper.emitContainerClasses();
}
function removeClasses() {
  const swiper = this;
  const {
    el,
    classNames
  } = swiper;
  if (!el || typeof el === "string") return;
  el.classList.remove(...classNames);
  swiper.emitContainerClasses();
}
var classes = {
  addClasses,
  removeClasses
};
function checkOverflow() {
  const swiper = this;
  const {
    isLocked: wasLocked,
    params
  } = swiper;
  const {
    slidesOffsetBefore
  } = params;
  if (slidesOffsetBefore) {
    const lastSlideIndex = swiper.slides.length - 1;
    const lastSlideRightEdge = swiper.slidesGrid[lastSlideIndex] + swiper.slidesSizesGrid[lastSlideIndex] + slidesOffsetBefore * 2;
    swiper.isLocked = swiper.size > lastSlideRightEdge;
  } else {
    swiper.isLocked = swiper.snapGrid.length === 1;
  }
  if (params.allowSlideNext === true) {
    swiper.allowSlideNext = !swiper.isLocked;
  }
  if (params.allowSlidePrev === true) {
    swiper.allowSlidePrev = !swiper.isLocked;
  }
  if (wasLocked && wasLocked !== swiper.isLocked) {
    swiper.isEnd = false;
  }
  if (wasLocked !== swiper.isLocked) {
    swiper.emit(swiper.isLocked ? "lock" : "unlock");
  }
}
var checkOverflow$1 = {
  checkOverflow
};
var defaults = {
  init: true,
  direction: "horizontal",
  oneWayMovement: false,
  swiperElementNodeName: "SWIPER-CONTAINER",
  touchEventsTarget: "wrapper",
  initialSlide: 0,
  speed: 300,
  cssMode: false,
  updateOnWindowResize: true,
  resizeObserver: true,
  nested: false,
  createElements: false,
  eventsPrefix: "swiper",
  enabled: true,
  focusableElements: "input, select, option, textarea, button, video, label",
  // Overrides
  width: null,
  height: null,
  //
  preventInteractionOnTransition: false,
  // ssr
  userAgent: null,
  url: null,
  // To support iOS's swipe-to-go-back gesture (when being used in-app).
  edgeSwipeDetection: false,
  edgeSwipeThreshold: 20,
  // Autoheight
  autoHeight: false,
  // Set wrapper width
  setWrapperSize: false,
  // Virtual Translate
  virtualTranslate: false,
  // Effects
  effect: "slide",
  // 'slide' or 'fade' or 'cube' or 'coverflow' or 'flip'
  // Breakpoints
  breakpoints: void 0,
  breakpointsBase: "window",
  // Slides grid
  spaceBetween: 0,
  slidesPerView: 1,
  slidesPerGroup: 1,
  slidesPerGroupSkip: 0,
  slidesPerGroupAuto: false,
  centeredSlides: false,
  centeredSlidesBounds: false,
  slidesOffsetBefore: 0,
  // in px
  slidesOffsetAfter: 0,
  // in px
  normalizeSlideIndex: true,
  centerInsufficientSlides: false,
  // Disable swiper and hide navigation when container not overflow
  watchOverflow: true,
  // Round length
  roundLengths: false,
  // Touches
  touchRatio: 1,
  touchAngle: 45,
  simulateTouch: true,
  shortSwipes: true,
  longSwipes: true,
  longSwipesRatio: 0.5,
  longSwipesMs: 300,
  followFinger: true,
  allowTouchMove: true,
  threshold: 5,
  touchMoveStopPropagation: false,
  touchStartPreventDefault: true,
  touchStartForcePreventDefault: false,
  touchReleaseOnEdges: false,
  // Unique Navigation Elements
  uniqueNavElements: true,
  // Resistance
  resistance: true,
  resistanceRatio: 0.85,
  // Progress
  watchSlidesProgress: false,
  // Cursor
  grabCursor: false,
  // Clicks
  preventClicks: true,
  preventClicksPropagation: true,
  slideToClickedSlide: false,
  // loop
  loop: false,
  loopAddBlankSlides: true,
  loopAdditionalSlides: 0,
  loopPreventsSliding: true,
  // rewind
  rewind: false,
  // Swiping/no swiping
  allowSlidePrev: true,
  allowSlideNext: true,
  swipeHandler: null,
  // '.swipe-handler',
  noSwiping: true,
  noSwipingClass: "swiper-no-swiping",
  noSwipingSelector: null,
  // Passive Listeners
  passiveListeners: true,
  maxBackfaceHiddenSlides: 10,
  // NS
  containerModifierClass: "swiper-",
  // NEW
  slideClass: "swiper-slide",
  slideBlankClass: "swiper-slide-blank",
  slideActiveClass: "swiper-slide-active",
  slideVisibleClass: "swiper-slide-visible",
  slideFullyVisibleClass: "swiper-slide-fully-visible",
  slideNextClass: "swiper-slide-next",
  slidePrevClass: "swiper-slide-prev",
  wrapperClass: "swiper-wrapper",
  lazyPreloaderClass: "swiper-lazy-preloader",
  lazyPreloadPrevNext: 0,
  // Callbacks
  runCallbacksOnInit: true,
  // Internals
  _emitClasses: false
};
function moduleExtendParams(params, allModulesParams) {
  return function extendParams(obj) {
    if (obj === void 0) {
      obj = {};
    }
    const moduleParamName = Object.keys(obj)[0];
    const moduleParams = obj[moduleParamName];
    if (typeof moduleParams !== "object" || moduleParams === null) {
      extend(allModulesParams, obj);
      return;
    }
    if (params[moduleParamName] === true) {
      params[moduleParamName] = {
        enabled: true
      };
    }
    if (moduleParamName === "navigation" && params[moduleParamName] && params[moduleParamName].enabled && !params[moduleParamName].prevEl && !params[moduleParamName].nextEl) {
      params[moduleParamName].auto = true;
    }
    if (["pagination", "scrollbar"].indexOf(moduleParamName) >= 0 && params[moduleParamName] && params[moduleParamName].enabled && !params[moduleParamName].el) {
      params[moduleParamName].auto = true;
    }
    if (!(moduleParamName in params && "enabled" in moduleParams)) {
      extend(allModulesParams, obj);
      return;
    }
    if (typeof params[moduleParamName] === "object" && !("enabled" in params[moduleParamName])) {
      params[moduleParamName].enabled = true;
    }
    if (!params[moduleParamName]) params[moduleParamName] = {
      enabled: false
    };
    extend(allModulesParams, obj);
  };
}
const prototypes = {
  eventsEmitter,
  update,
  translate,
  transition,
  slide,
  loop,
  grabCursor,
  events: events$1,
  breakpoints,
  checkOverflow: checkOverflow$1,
  classes
};
const extendedDefaults = {};
class Swiper {
  constructor() {
    let el;
    let params;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (args.length === 1 && args[0].constructor && Object.prototype.toString.call(args[0]).slice(8, -1) === "Object") {
      params = args[0];
    } else {
      [el, params] = args;
    }
    if (!params) params = {};
    params = extend({}, params);
    if (el && !params.el) params.el = el;
    const document2 = getDocument();
    if (params.el && typeof params.el === "string" && document2.querySelectorAll(params.el).length > 1) {
      const swipers = [];
      document2.querySelectorAll(params.el).forEach((containerEl) => {
        const newParams = extend({}, params, {
          el: containerEl
        });
        swipers.push(new Swiper(newParams));
      });
      return swipers;
    }
    const swiper = this;
    swiper.__swiper__ = true;
    swiper.support = getSupport();
    swiper.device = getDevice({
      userAgent: params.userAgent
    });
    swiper.browser = getBrowser();
    swiper.eventsListeners = {};
    swiper.eventsAnyListeners = [];
    swiper.modules = [...swiper.__modules__];
    if (params.modules && Array.isArray(params.modules)) {
      swiper.modules.push(...params.modules);
    }
    const allModulesParams = {};
    swiper.modules.forEach((mod) => {
      mod({
        params,
        swiper,
        extendParams: moduleExtendParams(params, allModulesParams),
        on: swiper.on.bind(swiper),
        once: swiper.once.bind(swiper),
        off: swiper.off.bind(swiper),
        emit: swiper.emit.bind(swiper)
      });
    });
    const swiperParams = extend({}, defaults, allModulesParams);
    swiper.params = extend({}, swiperParams, extendedDefaults, params);
    swiper.originalParams = extend({}, swiper.params);
    swiper.passedParams = extend({}, params);
    if (swiper.params && swiper.params.on) {
      Object.keys(swiper.params.on).forEach((eventName) => {
        swiper.on(eventName, swiper.params.on[eventName]);
      });
    }
    if (swiper.params && swiper.params.onAny) {
      swiper.onAny(swiper.params.onAny);
    }
    Object.assign(swiper, {
      enabled: swiper.params.enabled,
      el,
      // Classes
      classNames: [],
      // Slides
      slides: [],
      slidesGrid: [],
      snapGrid: [],
      slidesSizesGrid: [],
      // isDirection
      isHorizontal() {
        return swiper.params.direction === "horizontal";
      },
      isVertical() {
        return swiper.params.direction === "vertical";
      },
      // Indexes
      activeIndex: 0,
      realIndex: 0,
      //
      isBeginning: true,
      isEnd: false,
      // Props
      translate: 0,
      previousTranslate: 0,
      progress: 0,
      velocity: 0,
      animating: false,
      cssOverflowAdjustment() {
        return Math.trunc(this.translate / 2 ** 23) * 2 ** 23;
      },
      // Locks
      allowSlideNext: swiper.params.allowSlideNext,
      allowSlidePrev: swiper.params.allowSlidePrev,
      // Touch Events
      touchEventsData: {
        isTouched: void 0,
        isMoved: void 0,
        allowTouchCallbacks: void 0,
        touchStartTime: void 0,
        isScrolling: void 0,
        currentTranslate: void 0,
        startTranslate: void 0,
        allowThresholdMove: void 0,
        // Form elements to match
        focusableElements: swiper.params.focusableElements,
        // Last click time
        lastClickTime: 0,
        clickTimeout: void 0,
        // Velocities
        velocities: [],
        allowMomentumBounce: void 0,
        startMoving: void 0,
        pointerId: null,
        touchId: null
      },
      // Clicks
      allowClick: true,
      // Touches
      allowTouchMove: swiper.params.allowTouchMove,
      touches: {
        startX: 0,
        startY: 0,
        currentX: 0,
        currentY: 0,
        diff: 0
      },
      // Images
      imagesToLoad: [],
      imagesLoaded: 0
    });
    swiper.emit("_swiper");
    if (swiper.params.init) {
      swiper.init();
    }
    return swiper;
  }
  getDirectionLabel(property) {
    if (this.isHorizontal()) {
      return property;
    }
    return {
      "width": "height",
      "margin-top": "margin-left",
      "margin-bottom ": "margin-right",
      "margin-left": "margin-top",
      "margin-right": "margin-bottom",
      "padding-left": "padding-top",
      "padding-right": "padding-bottom",
      "marginRight": "marginBottom"
    }[property];
  }
  getSlideIndex(slideEl) {
    const {
      slidesEl,
      params
    } = this;
    const slides = elementChildren(slidesEl, `.${params.slideClass}, swiper-slide`);
    const firstSlideIndex = elementIndex(slides[0]);
    return elementIndex(slideEl) - firstSlideIndex;
  }
  getSlideIndexByData(index) {
    return this.getSlideIndex(this.slides.filter((slideEl) => slideEl.getAttribute("data-swiper-slide-index") * 1 === index)[0]);
  }
  recalcSlides() {
    const swiper = this;
    const {
      slidesEl,
      params
    } = swiper;
    swiper.slides = elementChildren(slidesEl, `.${params.slideClass}, swiper-slide`);
  }
  enable() {
    const swiper = this;
    if (swiper.enabled) return;
    swiper.enabled = true;
    if (swiper.params.grabCursor) {
      swiper.setGrabCursor();
    }
    swiper.emit("enable");
  }
  disable() {
    const swiper = this;
    if (!swiper.enabled) return;
    swiper.enabled = false;
    if (swiper.params.grabCursor) {
      swiper.unsetGrabCursor();
    }
    swiper.emit("disable");
  }
  setProgress(progress, speed) {
    const swiper = this;
    progress = Math.min(Math.max(progress, 0), 1);
    const min = swiper.minTranslate();
    const max = swiper.maxTranslate();
    const current = (max - min) * progress + min;
    swiper.translateTo(current, typeof speed === "undefined" ? 0 : speed);
    swiper.updateActiveIndex();
    swiper.updateSlidesClasses();
  }
  emitContainerClasses() {
    const swiper = this;
    if (!swiper.params._emitClasses || !swiper.el) return;
    const cls = swiper.el.className.split(" ").filter((className) => {
      return className.indexOf("swiper") === 0 || className.indexOf(swiper.params.containerModifierClass) === 0;
    });
    swiper.emit("_containerClasses", cls.join(" "));
  }
  getSlideClasses(slideEl) {
    const swiper = this;
    if (swiper.destroyed) return "";
    return slideEl.className.split(" ").filter((className) => {
      return className.indexOf("swiper-slide") === 0 || className.indexOf(swiper.params.slideClass) === 0;
    }).join(" ");
  }
  emitSlidesClasses() {
    const swiper = this;
    if (!swiper.params._emitClasses || !swiper.el) return;
    const updates = [];
    swiper.slides.forEach((slideEl) => {
      const classNames = swiper.getSlideClasses(slideEl);
      updates.push({
        slideEl,
        classNames
      });
      swiper.emit("_slideClass", slideEl, classNames);
    });
    swiper.emit("_slideClasses", updates);
  }
  slidesPerViewDynamic(view, exact) {
    if (view === void 0) {
      view = "current";
    }
    if (exact === void 0) {
      exact = false;
    }
    const swiper = this;
    const {
      params,
      slides,
      slidesGrid,
      slidesSizesGrid,
      size: swiperSize,
      activeIndex
    } = swiper;
    let spv = 1;
    if (typeof params.slidesPerView === "number") return params.slidesPerView;
    if (params.centeredSlides) {
      let slideSize = slides[activeIndex] ? Math.ceil(slides[activeIndex].swiperSlideSize) : 0;
      let breakLoop;
      for (let i = activeIndex + 1; i < slides.length; i += 1) {
        if (slides[i] && !breakLoop) {
          slideSize += Math.ceil(slides[i].swiperSlideSize);
          spv += 1;
          if (slideSize > swiperSize) breakLoop = true;
        }
      }
      for (let i = activeIndex - 1; i >= 0; i -= 1) {
        if (slides[i] && !breakLoop) {
          slideSize += slides[i].swiperSlideSize;
          spv += 1;
          if (slideSize > swiperSize) breakLoop = true;
        }
      }
    } else {
      if (view === "current") {
        for (let i = activeIndex + 1; i < slides.length; i += 1) {
          const slideInView = exact ? slidesGrid[i] + slidesSizesGrid[i] - slidesGrid[activeIndex] < swiperSize : slidesGrid[i] - slidesGrid[activeIndex] < swiperSize;
          if (slideInView) {
            spv += 1;
          }
        }
      } else {
        for (let i = activeIndex - 1; i >= 0; i -= 1) {
          const slideInView = slidesGrid[activeIndex] - slidesGrid[i] < swiperSize;
          if (slideInView) {
            spv += 1;
          }
        }
      }
    }
    return spv;
  }
  update() {
    const swiper = this;
    if (!swiper || swiper.destroyed) return;
    const {
      snapGrid,
      params
    } = swiper;
    if (params.breakpoints) {
      swiper.setBreakpoint();
    }
    [...swiper.el.querySelectorAll('[loading="lazy"]')].forEach((imageEl) => {
      if (imageEl.complete) {
        processLazyPreloader(swiper, imageEl);
      }
    });
    swiper.updateSize();
    swiper.updateSlides();
    swiper.updateProgress();
    swiper.updateSlidesClasses();
    function setTranslate2() {
      const translateValue = swiper.rtlTranslate ? swiper.translate * -1 : swiper.translate;
      const newTranslate = Math.min(Math.max(translateValue, swiper.maxTranslate()), swiper.minTranslate());
      swiper.setTranslate(newTranslate);
      swiper.updateActiveIndex();
      swiper.updateSlidesClasses();
    }
    let translated;
    if (params.freeMode && params.freeMode.enabled && !params.cssMode) {
      setTranslate2();
      if (params.autoHeight) {
        swiper.updateAutoHeight();
      }
    } else {
      if ((params.slidesPerView === "auto" || params.slidesPerView > 1) && swiper.isEnd && !params.centeredSlides) {
        const slides = swiper.virtual && params.virtual.enabled ? swiper.virtual.slides : swiper.slides;
        translated = swiper.slideTo(slides.length - 1, 0, false, true);
      } else {
        translated = swiper.slideTo(swiper.activeIndex, 0, false, true);
      }
      if (!translated) {
        setTranslate2();
      }
    }
    if (params.watchOverflow && snapGrid !== swiper.snapGrid) {
      swiper.checkOverflow();
    }
    swiper.emit("update");
  }
  changeDirection(newDirection, needUpdate) {
    if (needUpdate === void 0) {
      needUpdate = true;
    }
    const swiper = this;
    const currentDirection = swiper.params.direction;
    if (!newDirection) {
      newDirection = currentDirection === "horizontal" ? "vertical" : "horizontal";
    }
    if (newDirection === currentDirection || newDirection !== "horizontal" && newDirection !== "vertical") {
      return swiper;
    }
    swiper.el.classList.remove(`${swiper.params.containerModifierClass}${currentDirection}`);
    swiper.el.classList.add(`${swiper.params.containerModifierClass}${newDirection}`);
    swiper.emitContainerClasses();
    swiper.params.direction = newDirection;
    swiper.slides.forEach((slideEl) => {
      if (newDirection === "vertical") {
        slideEl.style.width = "";
      } else {
        slideEl.style.height = "";
      }
    });
    swiper.emit("changeDirection");
    if (needUpdate) swiper.update();
    return swiper;
  }
  changeLanguageDirection(direction) {
    const swiper = this;
    if (swiper.rtl && direction === "rtl" || !swiper.rtl && direction === "ltr") return;
    swiper.rtl = direction === "rtl";
    swiper.rtlTranslate = swiper.params.direction === "horizontal" && swiper.rtl;
    if (swiper.rtl) {
      swiper.el.classList.add(`${swiper.params.containerModifierClass}rtl`);
      swiper.el.dir = "rtl";
    } else {
      swiper.el.classList.remove(`${swiper.params.containerModifierClass}rtl`);
      swiper.el.dir = "ltr";
    }
    swiper.update();
  }
  mount(element) {
    const swiper = this;
    if (swiper.mounted) return true;
    let el = element || swiper.params.el;
    if (typeof el === "string") {
      el = document.querySelector(el);
    }
    if (!el) {
      return false;
    }
    el.swiper = swiper;
    if (el.parentNode && el.parentNode.host && el.parentNode.host.nodeName === swiper.params.swiperElementNodeName.toUpperCase()) {
      swiper.isElement = true;
    }
    const getWrapperSelector = () => {
      return `.${(swiper.params.wrapperClass || "").trim().split(" ").join(".")}`;
    };
    const getWrapper = () => {
      if (el && el.shadowRoot && el.shadowRoot.querySelector) {
        const res = el.shadowRoot.querySelector(getWrapperSelector());
        return res;
      }
      return elementChildren(el, getWrapperSelector())[0];
    };
    let wrapperEl = getWrapper();
    if (!wrapperEl && swiper.params.createElements) {
      wrapperEl = createElement("div", swiper.params.wrapperClass);
      el.append(wrapperEl);
      elementChildren(el, `.${swiper.params.slideClass}`).forEach((slideEl) => {
        wrapperEl.append(slideEl);
      });
    }
    Object.assign(swiper, {
      el,
      wrapperEl,
      slidesEl: swiper.isElement && !el.parentNode.host.slideSlots ? el.parentNode.host : wrapperEl,
      hostEl: swiper.isElement ? el.parentNode.host : el,
      mounted: true,
      // RTL
      rtl: el.dir.toLowerCase() === "rtl" || elementStyle(el, "direction") === "rtl",
      rtlTranslate: swiper.params.direction === "horizontal" && (el.dir.toLowerCase() === "rtl" || elementStyle(el, "direction") === "rtl"),
      wrongRTL: elementStyle(wrapperEl, "display") === "-webkit-box"
    });
    return true;
  }
  init(el) {
    const swiper = this;
    if (swiper.initialized) return swiper;
    const mounted = swiper.mount(el);
    if (mounted === false) return swiper;
    swiper.emit("beforeInit");
    if (swiper.params.breakpoints) {
      swiper.setBreakpoint();
    }
    swiper.addClasses();
    swiper.updateSize();
    swiper.updateSlides();
    if (swiper.params.watchOverflow) {
      swiper.checkOverflow();
    }
    if (swiper.params.grabCursor && swiper.enabled) {
      swiper.setGrabCursor();
    }
    if (swiper.params.loop && swiper.virtual && swiper.params.virtual.enabled) {
      swiper.slideTo(swiper.params.initialSlide + swiper.virtual.slidesBefore, 0, swiper.params.runCallbacksOnInit, false, true);
    } else {
      swiper.slideTo(swiper.params.initialSlide, 0, swiper.params.runCallbacksOnInit, false, true);
    }
    if (swiper.params.loop) {
      swiper.loopCreate();
    }
    swiper.attachEvents();
    const lazyElements = [...swiper.el.querySelectorAll('[loading="lazy"]')];
    if (swiper.isElement) {
      lazyElements.push(...swiper.hostEl.querySelectorAll('[loading="lazy"]'));
    }
    lazyElements.forEach((imageEl) => {
      if (imageEl.complete) {
        processLazyPreloader(swiper, imageEl);
      } else {
        imageEl.addEventListener("load", (e) => {
          processLazyPreloader(swiper, e.target);
        });
      }
    });
    preload(swiper);
    swiper.initialized = true;
    preload(swiper);
    swiper.emit("init");
    swiper.emit("afterInit");
    return swiper;
  }
  destroy(deleteInstance, cleanStyles) {
    if (deleteInstance === void 0) {
      deleteInstance = true;
    }
    if (cleanStyles === void 0) {
      cleanStyles = true;
    }
    const swiper = this;
    const {
      params,
      el,
      wrapperEl,
      slides
    } = swiper;
    if (typeof swiper.params === "undefined" || swiper.destroyed) {
      return null;
    }
    swiper.emit("beforeDestroy");
    swiper.initialized = false;
    swiper.detachEvents();
    if (params.loop) {
      swiper.loopDestroy();
    }
    if (cleanStyles) {
      swiper.removeClasses();
      if (el && typeof el !== "string") {
        el.removeAttribute("style");
      }
      if (wrapperEl) {
        wrapperEl.removeAttribute("style");
      }
      if (slides && slides.length) {
        slides.forEach((slideEl) => {
          slideEl.classList.remove(params.slideVisibleClass, params.slideFullyVisibleClass, params.slideActiveClass, params.slideNextClass, params.slidePrevClass);
          slideEl.removeAttribute("style");
          slideEl.removeAttribute("data-swiper-slide-index");
        });
      }
    }
    swiper.emit("destroy");
    Object.keys(swiper.eventsListeners).forEach((eventName) => {
      swiper.off(eventName);
    });
    if (deleteInstance !== false) {
      if (swiper.el && typeof swiper.el !== "string") {
        swiper.el.swiper = null;
      }
      deleteProps(swiper);
    }
    swiper.destroyed = true;
    return null;
  }
  static extendDefaults(newDefaults) {
    extend(extendedDefaults, newDefaults);
  }
  static get extendedDefaults() {
    return extendedDefaults;
  }
  static get defaults() {
    return defaults;
  }
  static installModule(mod) {
    if (!Swiper.prototype.__modules__) Swiper.prototype.__modules__ = [];
    const modules = Swiper.prototype.__modules__;
    if (typeof mod === "function" && modules.indexOf(mod) < 0) {
      modules.push(mod);
    }
  }
  static use(module) {
    if (Array.isArray(module)) {
      module.forEach((m) => Swiper.installModule(m));
      return Swiper;
    }
    Swiper.installModule(module);
    return Swiper;
  }
}
Object.keys(prototypes).forEach((prototypeGroup) => {
  Object.keys(prototypes[prototypeGroup]).forEach((protoMethod) => {
    Swiper.prototype[protoMethod] = prototypes[prototypeGroup][protoMethod];
  });
});
Swiper.use([Resize, Observer]);
function createElementIfNotDefined(swiper, originalParams, params, checkProps) {
  if (swiper.params.createElements) {
    Object.keys(checkProps).forEach((key) => {
      if (!params[key] && params.auto === true) {
        let element = elementChildren(swiper.el, `.${checkProps[key]}`)[0];
        if (!element) {
          element = createElement("div", checkProps[key]);
          element.className = checkProps[key];
          swiper.el.append(element);
        }
        params[key] = element;
        originalParams[key] = element;
      }
    });
  }
  return params;
}
function classesToSelector(classes2) {
  if (classes2 === void 0) {
    classes2 = "";
  }
  return `.${classes2.trim().replace(/([\.:!+\/])/g, "\\$1").replace(/ /g, ".")}`;
}
function Pagination(_ref) {
  let {
    swiper,
    extendParams,
    on: on2,
    emit
  } = _ref;
  const pfx = "swiper-pagination";
  extendParams({
    pagination: {
      el: null,
      bulletElement: "span",
      clickable: false,
      hideOnClick: false,
      renderBullet: null,
      renderProgressbar: null,
      renderFraction: null,
      renderCustom: null,
      progressbarOpposite: false,
      type: "bullets",
      // 'bullets' or 'progressbar' or 'fraction' or 'custom'
      dynamicBullets: false,
      dynamicMainBullets: 1,
      formatFractionCurrent: (number) => number,
      formatFractionTotal: (number) => number,
      bulletClass: `${pfx}-bullet`,
      bulletActiveClass: `${pfx}-bullet-active`,
      modifierClass: `${pfx}-`,
      currentClass: `${pfx}-current`,
      totalClass: `${pfx}-total`,
      hiddenClass: `${pfx}-hidden`,
      progressbarFillClass: `${pfx}-progressbar-fill`,
      progressbarOppositeClass: `${pfx}-progressbar-opposite`,
      clickableClass: `${pfx}-clickable`,
      lockClass: `${pfx}-lock`,
      horizontalClass: `${pfx}-horizontal`,
      verticalClass: `${pfx}-vertical`,
      paginationDisabledClass: `${pfx}-disabled`
    }
  });
  swiper.pagination = {
    el: null,
    bullets: []
  };
  let bulletSize;
  let dynamicBulletIndex = 0;
  function isPaginationDisabled() {
    return !swiper.params.pagination.el || !swiper.pagination.el || Array.isArray(swiper.pagination.el) && swiper.pagination.el.length === 0;
  }
  function setSideBullets(bulletEl, position) {
    const {
      bulletActiveClass
    } = swiper.params.pagination;
    if (!bulletEl) return;
    bulletEl = bulletEl[`${position === "prev" ? "previous" : "next"}ElementSibling`];
    if (bulletEl) {
      bulletEl.classList.add(`${bulletActiveClass}-${position}`);
      bulletEl = bulletEl[`${position === "prev" ? "previous" : "next"}ElementSibling`];
      if (bulletEl) {
        bulletEl.classList.add(`${bulletActiveClass}-${position}-${position}`);
      }
    }
  }
  function getMoveDirection(prevIndex, nextIndex, length) {
    prevIndex = prevIndex % length;
    nextIndex = nextIndex % length;
    if (nextIndex === prevIndex + 1) {
      return "next";
    } else if (nextIndex === prevIndex - 1) {
      return "previous";
    }
    return;
  }
  function onBulletClick(e) {
    const bulletEl = e.target.closest(classesToSelector(swiper.params.pagination.bulletClass));
    if (!bulletEl) {
      return;
    }
    e.preventDefault();
    const index = elementIndex(bulletEl) * swiper.params.slidesPerGroup;
    if (swiper.params.loop) {
      if (swiper.realIndex === index) return;
      const moveDirection = getMoveDirection(swiper.realIndex, index, swiper.slides.length);
      if (moveDirection === "next") {
        swiper.slideNext();
      } else if (moveDirection === "previous") {
        swiper.slidePrev();
      } else {
        swiper.slideToLoop(index);
      }
    } else {
      swiper.slideTo(index);
    }
  }
  function update2() {
    const rtl = swiper.rtl;
    const params = swiper.params.pagination;
    if (isPaginationDisabled()) return;
    let el = swiper.pagination.el;
    el = makeElementsArray(el);
    let current;
    let previousIndex;
    const slidesLength = swiper.virtual && swiper.params.virtual.enabled ? swiper.virtual.slides.length : swiper.slides.length;
    const total = swiper.params.loop ? Math.ceil(slidesLength / swiper.params.slidesPerGroup) : swiper.snapGrid.length;
    if (swiper.params.loop) {
      previousIndex = swiper.previousRealIndex || 0;
      current = swiper.params.slidesPerGroup > 1 ? Math.floor(swiper.realIndex / swiper.params.slidesPerGroup) : swiper.realIndex;
    } else if (typeof swiper.snapIndex !== "undefined") {
      current = swiper.snapIndex;
      previousIndex = swiper.previousSnapIndex;
    } else {
      previousIndex = swiper.previousIndex || 0;
      current = swiper.activeIndex || 0;
    }
    if (params.type === "bullets" && swiper.pagination.bullets && swiper.pagination.bullets.length > 0) {
      const bullets = swiper.pagination.bullets;
      let firstIndex;
      let lastIndex;
      let midIndex;
      if (params.dynamicBullets) {
        bulletSize = elementOuterSize(bullets[0], swiper.isHorizontal() ? "width" : "height");
        el.forEach((subEl) => {
          subEl.style[swiper.isHorizontal() ? "width" : "height"] = `${bulletSize * (params.dynamicMainBullets + 4)}px`;
        });
        if (params.dynamicMainBullets > 1 && previousIndex !== void 0) {
          dynamicBulletIndex += current - (previousIndex || 0);
          if (dynamicBulletIndex > params.dynamicMainBullets - 1) {
            dynamicBulletIndex = params.dynamicMainBullets - 1;
          } else if (dynamicBulletIndex < 0) {
            dynamicBulletIndex = 0;
          }
        }
        firstIndex = Math.max(current - dynamicBulletIndex, 0);
        lastIndex = firstIndex + (Math.min(bullets.length, params.dynamicMainBullets) - 1);
        midIndex = (lastIndex + firstIndex) / 2;
      }
      bullets.forEach((bulletEl) => {
        const classesToRemove = [...["", "-next", "-next-next", "-prev", "-prev-prev", "-main"].map((suffix) => `${params.bulletActiveClass}${suffix}`)].map((s) => typeof s === "string" && s.includes(" ") ? s.split(" ") : s).flat();
        bulletEl.classList.remove(...classesToRemove);
      });
      if (el.length > 1) {
        bullets.forEach((bullet) => {
          const bulletIndex = elementIndex(bullet);
          if (bulletIndex === current) {
            bullet.classList.add(...params.bulletActiveClass.split(" "));
          } else if (swiper.isElement) {
            bullet.setAttribute("part", "bullet");
          }
          if (params.dynamicBullets) {
            if (bulletIndex >= firstIndex && bulletIndex <= lastIndex) {
              bullet.classList.add(...`${params.bulletActiveClass}-main`.split(" "));
            }
            if (bulletIndex === firstIndex) {
              setSideBullets(bullet, "prev");
            }
            if (bulletIndex === lastIndex) {
              setSideBullets(bullet, "next");
            }
          }
        });
      } else {
        const bullet = bullets[current];
        if (bullet) {
          bullet.classList.add(...params.bulletActiveClass.split(" "));
        }
        if (swiper.isElement) {
          bullets.forEach((bulletEl, bulletIndex) => {
            bulletEl.setAttribute("part", bulletIndex === current ? "bullet-active" : "bullet");
          });
        }
        if (params.dynamicBullets) {
          const firstDisplayedBullet = bullets[firstIndex];
          const lastDisplayedBullet = bullets[lastIndex];
          for (let i = firstIndex; i <= lastIndex; i += 1) {
            if (bullets[i]) {
              bullets[i].classList.add(...`${params.bulletActiveClass}-main`.split(" "));
            }
          }
          setSideBullets(firstDisplayedBullet, "prev");
          setSideBullets(lastDisplayedBullet, "next");
        }
      }
      if (params.dynamicBullets) {
        const dynamicBulletsLength = Math.min(bullets.length, params.dynamicMainBullets + 4);
        const bulletsOffset = (bulletSize * dynamicBulletsLength - bulletSize) / 2 - midIndex * bulletSize;
        const offsetProp = rtl ? "right" : "left";
        bullets.forEach((bullet) => {
          bullet.style[swiper.isHorizontal() ? offsetProp : "top"] = `${bulletsOffset}px`;
        });
      }
    }
    el.forEach((subEl, subElIndex) => {
      if (params.type === "fraction") {
        subEl.querySelectorAll(classesToSelector(params.currentClass)).forEach((fractionEl) => {
          fractionEl.textContent = params.formatFractionCurrent(current + 1);
        });
        subEl.querySelectorAll(classesToSelector(params.totalClass)).forEach((totalEl) => {
          totalEl.textContent = params.formatFractionTotal(total);
        });
      }
      if (params.type === "progressbar") {
        let progressbarDirection;
        if (params.progressbarOpposite) {
          progressbarDirection = swiper.isHorizontal() ? "vertical" : "horizontal";
        } else {
          progressbarDirection = swiper.isHorizontal() ? "horizontal" : "vertical";
        }
        const scale = (current + 1) / total;
        let scaleX = 1;
        let scaleY = 1;
        if (progressbarDirection === "horizontal") {
          scaleX = scale;
        } else {
          scaleY = scale;
        }
        subEl.querySelectorAll(classesToSelector(params.progressbarFillClass)).forEach((progressEl) => {
          progressEl.style.transform = `translate3d(0,0,0) scaleX(${scaleX}) scaleY(${scaleY})`;
          progressEl.style.transitionDuration = `${swiper.params.speed}ms`;
        });
      }
      if (params.type === "custom" && params.renderCustom) {
        subEl.innerHTML = params.renderCustom(swiper, current + 1, total);
        if (subElIndex === 0) emit("paginationRender", subEl);
      } else {
        if (subElIndex === 0) emit("paginationRender", subEl);
        emit("paginationUpdate", subEl);
      }
      if (swiper.params.watchOverflow && swiper.enabled) {
        subEl.classList[swiper.isLocked ? "add" : "remove"](params.lockClass);
      }
    });
  }
  function render() {
    const params = swiper.params.pagination;
    if (isPaginationDisabled()) return;
    const slidesLength = swiper.virtual && swiper.params.virtual.enabled ? swiper.virtual.slides.length : swiper.grid && swiper.params.grid.rows > 1 ? swiper.slides.length / Math.ceil(swiper.params.grid.rows) : swiper.slides.length;
    let el = swiper.pagination.el;
    el = makeElementsArray(el);
    let paginationHTML = "";
    if (params.type === "bullets") {
      let numberOfBullets = swiper.params.loop ? Math.ceil(slidesLength / swiper.params.slidesPerGroup) : swiper.snapGrid.length;
      if (swiper.params.freeMode && swiper.params.freeMode.enabled && numberOfBullets > slidesLength) {
        numberOfBullets = slidesLength;
      }
      for (let i = 0; i < numberOfBullets; i += 1) {
        if (params.renderBullet) {
          paginationHTML += params.renderBullet.call(swiper, i, params.bulletClass);
        } else {
          paginationHTML += `<${params.bulletElement} ${swiper.isElement ? 'part="bullet"' : ""} class="${params.bulletClass}"></${params.bulletElement}>`;
        }
      }
    }
    if (params.type === "fraction") {
      if (params.renderFraction) {
        paginationHTML = params.renderFraction.call(swiper, params.currentClass, params.totalClass);
      } else {
        paginationHTML = `<span class="${params.currentClass}"></span> / <span class="${params.totalClass}"></span>`;
      }
    }
    if (params.type === "progressbar") {
      if (params.renderProgressbar) {
        paginationHTML = params.renderProgressbar.call(swiper, params.progressbarFillClass);
      } else {
        paginationHTML = `<span class="${params.progressbarFillClass}"></span>`;
      }
    }
    swiper.pagination.bullets = [];
    el.forEach((subEl) => {
      if (params.type !== "custom") {
        subEl.innerHTML = paginationHTML || "";
      }
      if (params.type === "bullets") {
        swiper.pagination.bullets.push(...subEl.querySelectorAll(classesToSelector(params.bulletClass)));
      }
    });
    if (params.type !== "custom") {
      emit("paginationRender", el[0]);
    }
  }
  function init() {
    swiper.params.pagination = createElementIfNotDefined(swiper, swiper.originalParams.pagination, swiper.params.pagination, {
      el: "swiper-pagination"
    });
    const params = swiper.params.pagination;
    if (!params.el) return;
    let el;
    if (typeof params.el === "string" && swiper.isElement) {
      el = swiper.el.querySelector(params.el);
    }
    if (!el && typeof params.el === "string") {
      el = [...document.querySelectorAll(params.el)];
    }
    if (!el) {
      el = params.el;
    }
    if (!el || el.length === 0) return;
    if (swiper.params.uniqueNavElements && typeof params.el === "string" && Array.isArray(el) && el.length > 1) {
      el = [...swiper.el.querySelectorAll(params.el)];
      if (el.length > 1) {
        el = el.filter((subEl) => {
          if (elementParents(subEl, ".swiper")[0] !== swiper.el) return false;
          return true;
        })[0];
      }
    }
    if (Array.isArray(el) && el.length === 1) el = el[0];
    Object.assign(swiper.pagination, {
      el
    });
    el = makeElementsArray(el);
    el.forEach((subEl) => {
      if (params.type === "bullets" && params.clickable) {
        subEl.classList.add(...(params.clickableClass || "").split(" "));
      }
      subEl.classList.add(params.modifierClass + params.type);
      subEl.classList.add(swiper.isHorizontal() ? params.horizontalClass : params.verticalClass);
      if (params.type === "bullets" && params.dynamicBullets) {
        subEl.classList.add(`${params.modifierClass}${params.type}-dynamic`);
        dynamicBulletIndex = 0;
        if (params.dynamicMainBullets < 1) {
          params.dynamicMainBullets = 1;
        }
      }
      if (params.type === "progressbar" && params.progressbarOpposite) {
        subEl.classList.add(params.progressbarOppositeClass);
      }
      if (params.clickable) {
        subEl.addEventListener("click", onBulletClick);
      }
      if (!swiper.enabled) {
        subEl.classList.add(params.lockClass);
      }
    });
  }
  function destroy() {
    const params = swiper.params.pagination;
    if (isPaginationDisabled()) return;
    let el = swiper.pagination.el;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => {
        subEl.classList.remove(params.hiddenClass);
        subEl.classList.remove(params.modifierClass + params.type);
        subEl.classList.remove(swiper.isHorizontal() ? params.horizontalClass : params.verticalClass);
        if (params.clickable) {
          subEl.classList.remove(...(params.clickableClass || "").split(" "));
          subEl.removeEventListener("click", onBulletClick);
        }
      });
    }
    if (swiper.pagination.bullets) swiper.pagination.bullets.forEach((subEl) => subEl.classList.remove(...params.bulletActiveClass.split(" ")));
  }
  on2("changeDirection", () => {
    if (!swiper.pagination || !swiper.pagination.el) return;
    const params = swiper.params.pagination;
    let {
      el
    } = swiper.pagination;
    el = makeElementsArray(el);
    el.forEach((subEl) => {
      subEl.classList.remove(params.horizontalClass, params.verticalClass);
      subEl.classList.add(swiper.isHorizontal() ? params.horizontalClass : params.verticalClass);
    });
  });
  on2("init", () => {
    if (swiper.params.pagination.enabled === false) {
      disable();
    } else {
      init();
      render();
      update2();
    }
  });
  on2("activeIndexChange", () => {
    if (typeof swiper.snapIndex === "undefined") {
      update2();
    }
  });
  on2("snapIndexChange", () => {
    update2();
  });
  on2("snapGridLengthChange", () => {
    render();
    update2();
  });
  on2("destroy", () => {
    destroy();
  });
  on2("enable disable", () => {
    let {
      el
    } = swiper.pagination;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => subEl.classList[swiper.enabled ? "remove" : "add"](swiper.params.pagination.lockClass));
    }
  });
  on2("lock unlock", () => {
    update2();
  });
  on2("click", (_s, e) => {
    const targetEl = e.target;
    const el = makeElementsArray(swiper.pagination.el);
    if (swiper.params.pagination.el && swiper.params.pagination.hideOnClick && el && el.length > 0 && !targetEl.classList.contains(swiper.params.pagination.bulletClass)) {
      if (swiper.navigation && (swiper.navigation.nextEl && targetEl === swiper.navigation.nextEl || swiper.navigation.prevEl && targetEl === swiper.navigation.prevEl)) return;
      const isHidden3 = el[0].classList.contains(swiper.params.pagination.hiddenClass);
      if (isHidden3 === true) {
        emit("paginationShow");
      } else {
        emit("paginationHide");
      }
      el.forEach((subEl) => subEl.classList.toggle(swiper.params.pagination.hiddenClass));
    }
  });
  const enable = () => {
    swiper.el.classList.remove(swiper.params.pagination.paginationDisabledClass);
    let {
      el
    } = swiper.pagination;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => subEl.classList.remove(swiper.params.pagination.paginationDisabledClass));
    }
    init();
    render();
    update2();
  };
  const disable = () => {
    swiper.el.classList.add(swiper.params.pagination.paginationDisabledClass);
    let {
      el
    } = swiper.pagination;
    if (el) {
      el = makeElementsArray(el);
      el.forEach((subEl) => subEl.classList.add(swiper.params.pagination.paginationDisabledClass));
    }
    destroy();
  };
  Object.assign(swiper.pagination, {
    enable,
    disable,
    render,
    update: update2,
    init,
    destroy
  });
}
function Parallax(_ref) {
  let {
    swiper,
    extendParams,
    on: on2
  } = _ref;
  extendParams({
    parallax: {
      enabled: false
    }
  });
  const elementsSelector = "[data-swiper-parallax], [data-swiper-parallax-x], [data-swiper-parallax-y], [data-swiper-parallax-opacity], [data-swiper-parallax-scale]";
  const setTransform = (el, progress) => {
    const {
      rtl
    } = swiper;
    const rtlFactor = rtl ? -1 : 1;
    const p = el.getAttribute("data-swiper-parallax") || "0";
    let x = el.getAttribute("data-swiper-parallax-x");
    let y = el.getAttribute("data-swiper-parallax-y");
    const scale = el.getAttribute("data-swiper-parallax-scale");
    const opacity = el.getAttribute("data-swiper-parallax-opacity");
    const rotate = el.getAttribute("data-swiper-parallax-rotate");
    if (x || y) {
      x = x || "0";
      y = y || "0";
    } else if (swiper.isHorizontal()) {
      x = p;
      y = "0";
    } else {
      y = p;
      x = "0";
    }
    if (x.indexOf("%") >= 0) {
      x = `${parseInt(x, 10) * progress * rtlFactor}%`;
    } else {
      x = `${x * progress * rtlFactor}px`;
    }
    if (y.indexOf("%") >= 0) {
      y = `${parseInt(y, 10) * progress}%`;
    } else {
      y = `${y * progress}px`;
    }
    if (typeof opacity !== "undefined" && opacity !== null) {
      const currentOpacity = opacity - (opacity - 1) * (1 - Math.abs(progress));
      el.style.opacity = currentOpacity;
    }
    let transform = `translate3d(${x}, ${y}, 0px)`;
    if (typeof scale !== "undefined" && scale !== null) {
      const currentScale = scale - (scale - 1) * (1 - Math.abs(progress));
      transform += ` scale(${currentScale})`;
    }
    if (rotate && typeof rotate !== "undefined" && rotate !== null) {
      const currentRotate = rotate * progress * -1;
      transform += ` rotate(${currentRotate}deg)`;
    }
    el.style.transform = transform;
  };
  const setTranslate2 = () => {
    const {
      el,
      slides,
      progress,
      snapGrid,
      isElement
    } = swiper;
    const elements = elementChildren(el, elementsSelector);
    if (swiper.isElement) {
      elements.push(...elementChildren(swiper.hostEl, elementsSelector));
    }
    elements.forEach((subEl) => {
      setTransform(subEl, progress);
    });
    slides.forEach((slideEl, slideIndex) => {
      let slideProgress = slideEl.progress;
      if (swiper.params.slidesPerGroup > 1 && swiper.params.slidesPerView !== "auto") {
        slideProgress += Math.ceil(slideIndex / 2) - progress * (snapGrid.length - 1);
      }
      slideProgress = Math.min(Math.max(slideProgress, -1), 1);
      slideEl.querySelectorAll(`${elementsSelector}, [data-swiper-parallax-rotate]`).forEach((subEl) => {
        setTransform(subEl, slideProgress);
      });
    });
  };
  const setTransition2 = function(duration) {
    if (duration === void 0) {
      duration = swiper.params.speed;
    }
    const {
      el,
      hostEl
    } = swiper;
    const elements = [...el.querySelectorAll(elementsSelector)];
    if (swiper.isElement) {
      elements.push(...hostEl.querySelectorAll(elementsSelector));
    }
    elements.forEach((parallaxEl) => {
      let parallaxDuration = parseInt(parallaxEl.getAttribute("data-swiper-parallax-duration"), 10) || duration;
      if (duration === 0) parallaxDuration = 0;
      parallaxEl.style.transitionDuration = `${parallaxDuration}ms`;
    });
  };
  on2("beforeInit", () => {
    if (!swiper.params.parallax.enabled) return;
    swiper.params.watchSlidesProgress = true;
    swiper.originalParams.watchSlidesProgress = true;
  });
  on2("init", () => {
    if (!swiper.params.parallax.enabled) return;
    setTranslate2();
  });
  on2("setTranslate", () => {
    if (!swiper.params.parallax.enabled) return;
    setTranslate2();
  });
  on2("setTransition", (_swiper, duration) => {
    if (!swiper.params.parallax.enabled) return;
    setTransition2(duration);
  });
}
function effectInit(params) {
  const {
    effect: effect3,
    swiper,
    on: on2,
    setTranslate: setTranslate2,
    setTransition: setTransition2,
    overwriteParams,
    perspective,
    recreateShadows,
    getEffectParams
  } = params;
  on2("beforeInit", () => {
    if (swiper.params.effect !== effect3) return;
    swiper.classNames.push(`${swiper.params.containerModifierClass}${effect3}`);
    if (perspective && perspective()) {
      swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
    }
    const overwriteParamsResult = overwriteParams ? overwriteParams() : {};
    Object.assign(swiper.params, overwriteParamsResult);
    Object.assign(swiper.originalParams, overwriteParamsResult);
  });
  on2("setTranslate", () => {
    if (swiper.params.effect !== effect3) return;
    setTranslate2();
  });
  on2("setTransition", (_s, duration) => {
    if (swiper.params.effect !== effect3) return;
    setTransition2(duration);
  });
  on2("transitionEnd", () => {
    if (swiper.params.effect !== effect3) return;
    if (recreateShadows) {
      if (!getEffectParams || !getEffectParams().slideShadows) return;
      swiper.slides.forEach((slideEl) => {
        slideEl.querySelectorAll(".swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left").forEach((shadowEl) => shadowEl.remove());
      });
      recreateShadows();
    }
  });
  let requireUpdateOnVirtual;
  on2("virtualUpdate", () => {
    if (swiper.params.effect !== effect3) return;
    if (!swiper.slides.length) {
      requireUpdateOnVirtual = true;
    }
    requestAnimationFrame(() => {
      if (requireUpdateOnVirtual && swiper.slides && swiper.slides.length) {
        setTranslate2();
        requireUpdateOnVirtual = false;
      }
    });
  });
}
function effectTarget(effectParams, slideEl) {
  const transformEl = getSlideTransformEl(slideEl);
  if (transformEl !== slideEl) {
    transformEl.style.backfaceVisibility = "hidden";
    transformEl.style["-webkit-backface-visibility"] = "hidden";
  }
  return transformEl;
}
function createShadow(suffix, slideEl, side) {
  const shadowClass = `swiper-slide-shadow${side ? `-${side}` : ""}${` swiper-slide-shadow-${suffix}`}`;
  const shadowContainer = getSlideTransformEl(slideEl);
  let shadowEl = shadowContainer.querySelector(`.${shadowClass.split(" ").join(".")}`);
  if (!shadowEl) {
    shadowEl = createElement("div", shadowClass.split(" "));
    shadowContainer.append(shadowEl);
  }
  return shadowEl;
}
function EffectCoverflow(_ref) {
  let {
    swiper,
    extendParams,
    on: on2
  } = _ref;
  extendParams({
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      scale: 1,
      modifier: 1,
      slideShadows: true
    }
  });
  const setTranslate2 = () => {
    const {
      width: swiperWidth,
      height: swiperHeight,
      slides,
      slidesSizesGrid
    } = swiper;
    const params = swiper.params.coverflowEffect;
    const isHorizontal = swiper.isHorizontal();
    const transform = swiper.translate;
    const center = isHorizontal ? -transform + swiperWidth / 2 : -transform + swiperHeight / 2;
    const rotate = isHorizontal ? params.rotate : -params.rotate;
    const translate2 = params.depth;
    const r = getRotateFix(swiper);
    for (let i = 0, length = slides.length; i < length; i += 1) {
      const slideEl = slides[i];
      const slideSize = slidesSizesGrid[i];
      const slideOffset = slideEl.swiperSlideOffset;
      const centerOffset = (center - slideOffset - slideSize / 2) / slideSize;
      const offsetMultiplier = typeof params.modifier === "function" ? params.modifier(centerOffset) : centerOffset * params.modifier;
      let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
      let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
      let translateZ = -translate2 * Math.abs(offsetMultiplier);
      let stretch = params.stretch;
      if (typeof stretch === "string" && stretch.indexOf("%") !== -1) {
        stretch = parseFloat(params.stretch) / 100 * slideSize;
      }
      let translateY = isHorizontal ? 0 : stretch * offsetMultiplier;
      let translateX = isHorizontal ? stretch * offsetMultiplier : 0;
      let scale = 1 - (1 - params.scale) * Math.abs(offsetMultiplier);
      if (Math.abs(translateX) < 1e-3) translateX = 0;
      if (Math.abs(translateY) < 1e-3) translateY = 0;
      if (Math.abs(translateZ) < 1e-3) translateZ = 0;
      if (Math.abs(rotateY) < 1e-3) rotateY = 0;
      if (Math.abs(rotateX) < 1e-3) rotateX = 0;
      if (Math.abs(scale) < 1e-3) scale = 0;
      const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${r(rotateX)}deg) rotateY(${r(rotateY)}deg) scale(${scale})`;
      const targetEl = effectTarget(params, slideEl);
      targetEl.style.transform = slideTransform;
      slideEl.style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
      if (params.slideShadows) {
        let shadowBeforeEl = isHorizontal ? slideEl.querySelector(".swiper-slide-shadow-left") : slideEl.querySelector(".swiper-slide-shadow-top");
        let shadowAfterEl = isHorizontal ? slideEl.querySelector(".swiper-slide-shadow-right") : slideEl.querySelector(".swiper-slide-shadow-bottom");
        if (!shadowBeforeEl) {
          shadowBeforeEl = createShadow("coverflow", slideEl, isHorizontal ? "left" : "top");
        }
        if (!shadowAfterEl) {
          shadowAfterEl = createShadow("coverflow", slideEl, isHorizontal ? "right" : "bottom");
        }
        if (shadowBeforeEl) shadowBeforeEl.style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
        if (shadowAfterEl) shadowAfterEl.style.opacity = -offsetMultiplier > 0 ? -offsetMultiplier : 0;
      }
    }
  };
  const setTransition2 = (duration) => {
    const transformElements = swiper.slides.map((slideEl) => getSlideTransformEl(slideEl));
    transformElements.forEach((el) => {
      el.style.transitionDuration = `${duration}ms`;
      el.querySelectorAll(".swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left").forEach((shadowEl) => {
        shadowEl.style.transitionDuration = `${duration}ms`;
      });
    });
  };
  effectInit({
    effect: "coverflow",
    swiper,
    on: on2,
    setTranslate: setTranslate2,
    setTransition: setTransition2,
    perspective: () => true,
    overwriteParams: () => ({
      watchSlidesProgress: true
    })
  });
}
document.querySelectorAll(".swiper").forEach(function(el, i) {
  new Swiper(el, {
    modules: [Pagination, Parallax, EffectCoverflow],
    spaceBetween: 20,
    // effect: 'coverflow',
    pagination: {
      el: ".swiper-pagination"
    },
    parallax: {
      enabled: true
    },
    breakpoints: {
      1024: {
        spaceBetween: 30,
        slidesPerView: 3,
        //enabled: false,
        parallax: {
          enabled: false
        },
        effect: false
      }
    }
  });
});
/*!
 * Virtual Select v1.0.45
 * https://sa-si-dev.github.io/virtual-select
 * Licensed under MIT (https://github.com/sa-si-dev/virtual-select/blob/master/LICENSE)
 */
(function() {
  !function() {
    function _toConsumableArray(arr) {
      return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
    }
    function _nonIterableSpread() {
      throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    function _unsupportedIterableToArray(o, minLen) {
      if (!o) return;
      if (typeof o === "string") return _arrayLikeToArray(o, minLen);
      var n = Object.prototype.toString.call(o).slice(8, -1);
      if (n === "Object" && o.constructor) n = o.constructor.name;
      if (n === "Map" || n === "Set") return Array.from(o);
      if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }
    function _iterableToArray(iter) {
      if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
    }
    function _arrayWithoutHoles(arr) {
      if (Array.isArray(arr)) return _arrayLikeToArray(arr);
    }
    function _arrayLikeToArray(arr, len) {
      if (len == null || len > arr.length) len = arr.length;
      for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
      }
      return arr2;
    }
    function _typeof(obj) {
      "@babel/helpers - typeof";
      return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
        return typeof obj2;
      } : function(obj2) {
        return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
      }, _typeof(obj);
    }
    function _classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    function _defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
      }
    }
    function _createClass(Constructor, protoProps, staticProps) {
      if (staticProps) _defineProperties(Constructor, staticProps);
      Object.defineProperty(Constructor, "prototype", { writable: false });
      return Constructor;
    }
    function _toPropertyKey(arg) {
      var key = _toPrimitive(arg, "string");
      return _typeof(key) === "symbol" ? key : String(key);
    }
    function _toPrimitive(input, hint) {
      if (_typeof(input) !== "object" || input === null) return input;
      var prim = input[Symbol.toPrimitive];
      if (prim !== void 0) {
        var res = prim.call(input, hint);
        if (_typeof(res) !== "object") return res;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(input);
    }
    var Utils = /* @__PURE__ */ function() {
      function Utils2() {
        _classCallCheck(this, Utils2);
      }
      _createClass(Utils2, null, [{
        key: "getString",
        value: (
          /**
           * @param {any} text
           * @returns {string}
           * @memberof Utils
           */
          function getString(text) {
            return text || text === 0 ? text.toString() : "";
          }
        )
        /**
         * @param {any} value
         * @param {boolean} defaultValue
         * @returns {boolean}
         * @memberof Utils
         */
      }, {
        key: "convertToBoolean",
        value: function convertToBoolean(value) {
          var defaultValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          var result;
          if (value === true || value === "true") {
            result = true;
          } else if (value === false || value === "false") {
            result = false;
          } else {
            result = defaultValue;
          }
          return result;
        }
        /**
         * @param {any} value
         * @returns {boolean}
         * @memberof Utils
         */
      }, {
        key: "isEmpty",
        value: function isEmpty(value) {
          var result = false;
          if (!value) {
            result = true;
          } else if (Array.isArray(value)) {
            if (value.length === 0) {
              result = true;
            }
          } else if (_typeof(value) === "object" && Object.keys(value).length === 0) {
            result = true;
          }
          return result;
        }
        /**
         * @param {any} value
         * @returns {boolean}
         * @memberof Utils
         */
      }, {
        key: "isNotEmpty",
        value: function isNotEmpty(value) {
          return !this.isEmpty(value);
        }
        /**
         * @param {any[]} array
         * @param {any} value
         * @param {boolean} cloneArray
         * @returns {any[]}
         * @memberof Utils
         */
      }, {
        key: "removeItemFromArray",
        value: function removeItemFromArray(array, value) {
          var cloneArray = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
          if (!Array.isArray(array) || !array.length) {
            return array;
          }
          var inputArr = cloneArray ? _toConsumableArray(array) : array;
          var index = inputArr.indexOf(value);
          if (index !== -1) {
            inputArr.splice(index, 1);
          }
          return inputArr;
        }
        /**
         * @param {any[]} array
         * @returns {any[]}
         * @memberof Utils
         */
      }, {
        key: "removeArrayEmpty",
        value: function removeArrayEmpty(array) {
          if (!Array.isArray(array) || !array.length) {
            return [];
          }
          return array.filter(function(d) {
            return !!d;
          });
        }
        /**
         * @param {number} max
         * @param {number} max
         * @returns {number}
         * @memberof Utils
         */
      }, {
        key: "getRandomInt",
        value: function getRandomInt(max) {
          var min = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
          var minN = Math.ceil(min);
          var maxN = Math.floor(max);
          return Math.floor(Math.random() * (maxN - minN - 1)) + minN;
        }
        /**
         * @param {string} text
         * @return {string}
         * @memberof Utils
         */
      }, {
        key: "regexEscape",
        value: function regexEscape(text) {
          var ESC_REGEX = /[-/\\^$*+?.()|[\]{}]/g;
          return text.replace(ESC_REGEX, "\\$&");
        }
        /**
         * @param {string} text
         * @return {string}
         * @memberof Utils
         */
      }, {
        key: "normalizeString",
        value: function normalizeString(text) {
          var NON_WORD_REGEX = /[^\w]/g;
          return text.normalize("NFD").replace(NON_WORD_REGEX, "");
        }
        /**
         * @static
         * @param {*} container
         * @param {string} text
         * @return {boolean}
         * @memberof Utils
         */
      }, {
        key: "willTextOverflow",
        value: function willTextOverflow(container, text) {
          var tempElement = document.createElement("div");
          tempElement.style.position = "absolute";
          tempElement.style.visibility = "hidden";
          tempElement.style.whiteSpace = "nowrap";
          tempElement.style.fontSize = window.getComputedStyle(container).fontSize;
          tempElement.style.fontFamily = window.getComputedStyle(container).fontFamily;
          tempElement.textContent = text;
          document.body.appendChild(tempElement);
          var textWidth = tempElement.clientWidth;
          document.body.removeChild(tempElement);
          return textWidth > container.clientWidth;
        }
        /**
         * @static
         * @param {string} text
         * @return {string}
         * @memberof Utils
         */
      }, {
        key: "replaceDoubleQuotesWithHTML",
        value: function replaceDoubleQuotesWithHTML(text) {
          return text.replace(/"/g, "&quot;");
        }
        /**
         * @static
         * @param {string} text
         * @return {boolean}
         * @memberof Utils
         */
      }, {
        key: "containsHTML",
        value: function containsHTML(text) {
          return /<[a-z][\s\S]*>/i.test(text);
        }
      }]);
      return Utils2;
    }();
    function dom_utils_typeof(obj) {
      "@babel/helpers - typeof";
      return dom_utils_typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
        return typeof obj2;
      } : function(obj2) {
        return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
      }, dom_utils_typeof(obj);
    }
    function _slicedToArray(arr, i) {
      return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || dom_utils_unsupportedIterableToArray(arr, i) || _nonIterableRest();
    }
    function _nonIterableRest() {
      throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    function _iterableToArrayLimit(arr, i) {
      var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"];
      if (null != _i) {
        var _s, _e, _x, _r, _arr = [], _n = true, _d = false;
        try {
          if (_x = (_i = _i.call(arr)).next, 0 === i) ;
          else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = true) {
            ;
          }
        } catch (err) {
          _d = true, _e = err;
        } finally {
          try {
            if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return;
          } finally {
            if (_d) throw _e;
          }
        }
        return _arr;
      }
    }
    function _arrayWithHoles(arr) {
      if (Array.isArray(arr)) return arr;
    }
    function dom_utils_toConsumableArray(arr) {
      return dom_utils_arrayWithoutHoles(arr) || dom_utils_iterableToArray(arr) || dom_utils_unsupportedIterableToArray(arr) || dom_utils_nonIterableSpread();
    }
    function dom_utils_nonIterableSpread() {
      throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    function dom_utils_unsupportedIterableToArray(o, minLen) {
      if (!o) return;
      if (typeof o === "string") return dom_utils_arrayLikeToArray(o, minLen);
      var n = Object.prototype.toString.call(o).slice(8, -1);
      if (n === "Object" && o.constructor) n = o.constructor.name;
      if (n === "Map" || n === "Set") return Array.from(o);
      if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return dom_utils_arrayLikeToArray(o, minLen);
    }
    function dom_utils_iterableToArray(iter) {
      if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
    }
    function dom_utils_arrayWithoutHoles(arr) {
      if (Array.isArray(arr)) return dom_utils_arrayLikeToArray(arr);
    }
    function dom_utils_arrayLikeToArray(arr, len) {
      if (len == null || len > arr.length) len = arr.length;
      for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
      }
      return arr2;
    }
    function dom_utils_classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    function dom_utils_defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, dom_utils_toPropertyKey(descriptor.key), descriptor);
      }
    }
    function dom_utils_createClass(Constructor, protoProps, staticProps) {
      if (staticProps) dom_utils_defineProperties(Constructor, staticProps);
      Object.defineProperty(Constructor, "prototype", { writable: false });
      return Constructor;
    }
    function dom_utils_toPropertyKey(arg) {
      var key = dom_utils_toPrimitive(arg, "string");
      return dom_utils_typeof(key) === "symbol" ? key : String(key);
    }
    function dom_utils_toPrimitive(input, hint) {
      if (dom_utils_typeof(input) !== "object" || input === null) return input;
      var prim = input[Symbol.toPrimitive];
      if (prim !== void 0) {
        var res = prim.call(input, hint);
        if (dom_utils_typeof(res) !== "object") return res;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(input);
    }
    var DomUtils = /* @__PURE__ */ function() {
      function DomUtils2() {
        dom_utils_classCallCheck(this, DomUtils2);
      }
      dom_utils_createClass(DomUtils2, null, [{
        key: "addClass",
        value: (
          /**
           * @param {HTMLElement | NodeListOf<HTMLElement>} $ele
           * @param {string} classNames
           */
          function addClass($ele, classNames) {
            if (!$ele) {
              return;
            }
            var classNamesArr = classNames.split(" ");
            DomUtils2.getElements($ele).forEach(function($this) {
              var _$this$classList;
              (_$this$classList = $this.classList).add.apply(_$this$classList, dom_utils_toConsumableArray(classNamesArr));
            });
          }
        )
        /**
         * @param {HTMLElement | NodeListOf<HTMLElement>} $ele
         * @param {string} classNames
         */
      }, {
        key: "removeClass",
        value: function removeClass($ele, classNames) {
          if (!$ele) {
            return;
          }
          var classNamesArr = classNames.split(" ");
          DomUtils2.getElements($ele).forEach(function($this) {
            var _$this$classList2;
            (_$this$classList2 = $this.classList).remove.apply(_$this$classList2, dom_utils_toConsumableArray(classNamesArr));
          });
        }
        /**
         * @param {HTMLElement | NodeListOf<HTMLElement>} $ele
         * @param {string} classNames
         * @param {boolean} [isAdd]
         */
      }, {
        key: "toggleClass",
        value: function toggleClass($ele, classNames, isAdd) {
          if (!$ele) {
            return;
          }
          var isAdding;
          if (isAdd !== void 0) {
            isAdding = Boolean(isAdd);
          }
          DomUtils2.getElements($ele).forEach(function($this) {
            $this.classList.toggle(classNames, isAdding);
          });
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} className
         * @returns {boolean}
         */
      }, {
        key: "hasClass",
        value: function hasClass($ele, className) {
          if (!$ele) {
            return false;
          }
          return $ele.classList.contains(className);
        }
        /**
         * @param {HTMLElement} $ele
         * @returns {boolean}
         */
      }, {
        key: "hasEllipsis",
        value: function hasEllipsis($ele) {
          if (!$ele) {
            return false;
          }
          return $ele.scrollWidth > $ele.offsetWidth;
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} name
         * @param {string} [type]
         * @returns {any}
         */
      }, {
        key: "getData",
        value: function getData($ele, name, type) {
          if (!$ele) {
            return void 0;
          }
          var value = $ele ? $ele.dataset[name] : "";
          if (type === "number") {
            value = parseFloat(value) || 0;
          } else if (value === "true") {
            value = true;
          } else if (value === "false") {
            value = false;
          }
          return value;
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} name
         * @param {string} value
         */
      }, {
        key: "setData",
        value: function setData($ele, name, value) {
          if (!$ele) {
            return;
          }
          $ele.dataset[name] = value;
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} name
         * @param {string} value
         */
      }, {
        key: "setAttr",
        value: function setAttr($ele, name, value) {
          if (!$ele) {
            return;
          }
          $ele.setAttribute(name, value);
        }
        /**
         * @param {HTMLElement} $from
         * @param {HTMLElement} $to
         * @param {string[]} attrList
         * @param {string[]} valueLessProps
         */
      }, {
        key: "setAttrFromEle",
        value: function setAttrFromEle($from, $to, attrList, valueLessProps2) {
          var values = {};
          attrList.forEach(function(attr) {
            values[attr] = $from.getAttribute(attr);
          });
          attrList.forEach(function(attr) {
            var value = values[attr];
            if (value || valueLessProps2.indexOf(attr) !== -1 && value === "") {
              $to.setAttribute(attr, value);
            }
          });
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} name
         * @param {string} value
         */
      }, {
        key: "setStyle",
        value: function setStyle($ele, name, value) {
          if (!$ele) {
            return;
          }
          $ele.style[name] = value;
        }
        /**
         * @param {HTMLElement} $ele
         * @param {any} props
         */
      }, {
        key: "setStyles",
        value: function setStyles2($ele, props) {
          if (!$ele || !props) {
            return;
          }
          Object.keys(props).forEach(function(name) {
            $ele.style[name] = props[name];
          });
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} name
         * @param {string} value
         */
      }, {
        key: "setAria",
        value: function setAria($ele, name, value) {
          var attrName = name;
          if (attrName !== "role") {
            attrName = "aria-".concat(attrName);
          }
          $ele.setAttribute(attrName, value);
        }
        /**
         * @param {any} $ele
         * @returns {any[]}
         */
      }, {
        key: "getElements",
        value: function getElements($ele) {
          if (!$ele) {
            return [];
          }
          return $ele.forEach === void 0 ? [$ele] : $ele;
        }
        /**
         * @static
         * @param {string} [$selector='']
         * @param {*} [$parentEle=undefined]
         * @return {*}
         * @memberof DomUtils
         */
      }, {
        key: "getElementsBySelector",
        value: function getElementsBySelector() {
          var $selector = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "";
          var $parentEle = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : void 0;
          var elements;
          var parent = $parentEle !== void 0 ? $parentEle : document;
          if ($selector !== "") {
            elements = parent.querySelectorAll($selector);
          }
          return elements !== void 0 ? Array.from(elements) : [];
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} events
         * @param {Function} callback
         */
      }, {
        key: "addEvent",
        value: function addEvent($ele, events2, callback) {
          if (!$ele) {
            return;
          }
          var eventsArray = Utils.removeArrayEmpty(events2.split(" "));
          eventsArray.forEach(function(event) {
            var $eleArray = DomUtils2.getElements($ele);
            $eleArray.forEach(function($this) {
              $this.addEventListener(event, callback);
            });
          });
        }
        /**
         * @param {HTMLElement} $ele
         * @param {string} eventName
         * @param {boolean} [bubbles]
         */
      }, {
        key: "dispatchEvent",
        value: function dispatchEvent($ele, eventName) {
          var bubbles = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
          if (!$ele) {
            return;
          }
          var $eleArray = DomUtils2.getElements($ele);
          setTimeout(function() {
            $eleArray.forEach(function($this) {
              $this.dispatchEvent(new CustomEvent(eventName, {
                bubbles
              }));
            });
          }, 0);
        }
        /**
         * convert object to dom attributes
         * @param {ObjectString} data
         */
      }, {
        key: "getAttributesText",
        value: function getAttributesText(data2) {
          var html = "";
          if (!data2) {
            return html;
          }
          Object.entries(data2).forEach(function(_ref) {
            var _ref2 = _slicedToArray(_ref, 2), k = _ref2[0], v = _ref2[1];
            if (v !== void 0) {
              html += " ".concat(k, '="').concat(v, '" ');
            }
          });
          return html;
        }
        /**
         * convert "maxValue" to "data-max-value"
         * @param {string} prop
         */
      }, {
        key: "convertPropToDataAttr",
        value: function convertPropToDataAttr(prop) {
          return prop ? "data-".concat(prop).replace(/([A-Z])/g, "-$1").toLowerCase() : "";
        }
        /**
         * Changes the tab index of an element passed as an input
         * @static
         * @param {HTMLElement | NodeListOf<HTMLElement>} $ele
         * @param {number} newTabIndex
         * @memberof DomUtils
         */
      }, {
        key: "changeTabIndex",
        value: function changeTabIndex($ele, newTabIndex) {
          if (!$ele) {
            console.log($ele, "Invalid element provided.");
            return;
          }
          DomUtils2.getElements($ele).forEach(function($this) {
            $this.tabIndex = newTabIndex;
          });
        }
        /**
        * @param {HTMLElement} $ele
        * @param {string} event
        * @param {Function} callback
        */
      }, {
        key: "removeEvent",
        value: function removeEvent($ele, event, callback) {
          if (!$ele) {
            return;
          }
          var $eleArray = DomUtils2.getElements($ele);
          $eleArray.forEach(function($this) {
            $this.removeEventListener(event, callback);
          });
        }
      }]);
      return DomUtils2;
    }();
    function ownKeys2(object, enumerableOnly) {
      var keys = Object.keys(object);
      if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        enumerableOnly && (symbols = symbols.filter(function(sym) {
          return Object.getOwnPropertyDescriptor(object, sym).enumerable;
        })), keys.push.apply(keys, symbols);
      }
      return keys;
    }
    function _objectSpread(target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys2(Object(source), true).forEach(function(key) {
          _defineProperty2(target, key, source[key]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys2(Object(source)).forEach(function(key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
      return target;
    }
    function _defineProperty2(obj, key, value) {
      key = virtual_select_toPropertyKey(key);
      if (key in obj) {
        Object.defineProperty(obj, key, { value, enumerable: true, configurable: true, writable: true });
      } else {
        obj[key] = value;
      }
      return obj;
    }
    function virtual_select_typeof(obj) {
      "@babel/helpers - typeof";
      return virtual_select_typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
        return typeof obj2;
      } : function(obj2) {
        return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
      }, virtual_select_typeof(obj);
    }
    function virtual_select_slicedToArray(arr, i) {
      return virtual_select_arrayWithHoles(arr) || virtual_select_iterableToArrayLimit(arr, i) || virtual_select_unsupportedIterableToArray(arr, i) || virtual_select_nonIterableRest();
    }
    function virtual_select_nonIterableRest() {
      throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    function virtual_select_iterableToArrayLimit(arr, i) {
      var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"];
      if (null != _i) {
        var _s, _e, _x, _r, _arr = [], _n = true, _d = false;
        try {
          if (_x = (_i = _i.call(arr)).next, 0 === i) ;
          else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = true) {
            ;
          }
        } catch (err) {
          _d = true, _e = err;
        } finally {
          try {
            if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return;
          } finally {
            if (_d) throw _e;
          }
        }
        return _arr;
      }
    }
    function virtual_select_arrayWithHoles(arr) {
      if (Array.isArray(arr)) return arr;
    }
    function virtual_select_toConsumableArray(arr) {
      return virtual_select_arrayWithoutHoles(arr) || virtual_select_iterableToArray(arr) || virtual_select_unsupportedIterableToArray(arr) || virtual_select_nonIterableSpread();
    }
    function virtual_select_nonIterableSpread() {
      throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }
    function virtual_select_unsupportedIterableToArray(o, minLen) {
      if (!o) return;
      if (typeof o === "string") return virtual_select_arrayLikeToArray(o, minLen);
      var n = Object.prototype.toString.call(o).slice(8, -1);
      if (n === "Object" && o.constructor) n = o.constructor.name;
      if (n === "Map" || n === "Set") return Array.from(o);
      if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return virtual_select_arrayLikeToArray(o, minLen);
    }
    function virtual_select_iterableToArray(iter) {
      if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
    }
    function virtual_select_arrayWithoutHoles(arr) {
      if (Array.isArray(arr)) return virtual_select_arrayLikeToArray(arr);
    }
    function virtual_select_arrayLikeToArray(arr, len) {
      if (len == null || len > arr.length) len = arr.length;
      for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
      }
      return arr2;
    }
    function virtual_select_classCallCheck(instance, Constructor) {
      if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
      }
    }
    function virtual_select_defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, virtual_select_toPropertyKey(descriptor.key), descriptor);
      }
    }
    function virtual_select_createClass(Constructor, protoProps, staticProps) {
      if (protoProps) virtual_select_defineProperties(Constructor.prototype, protoProps);
      if (staticProps) virtual_select_defineProperties(Constructor, staticProps);
      Object.defineProperty(Constructor, "prototype", { writable: false });
      return Constructor;
    }
    function virtual_select_toPropertyKey(arg) {
      var key = virtual_select_toPrimitive(arg, "string");
      return virtual_select_typeof(key) === "symbol" ? key : String(key);
    }
    function virtual_select_toPrimitive(input, hint) {
      if (virtual_select_typeof(input) !== "object" || input === null) return input;
      var prim = input[Symbol.toPrimitive];
      if (prim !== void 0) {
        var res = prim.call(input, hint || "default");
        if (virtual_select_typeof(res) !== "object") return res;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return (hint === "string" ? String : Number)(input);
    }
    var dropboxCloseButtonFullHeight = 48;
    var searchHeight = 40;
    var keyDownMethodMapping = {
      13: "onEnterPress",
      38: "onUpArrowPress",
      40: "onDownArrowPress",
      46: "onBackspaceOrDeletePress",
      // Delete
      8: "onBackspaceOrDeletePress"
      // Backspace
    };
    var valueLessProps = ["autofocus", "disabled", "multiple", "required"];
    var nativeProps = ["autofocus", "class", "disabled", "id", "multiple", "name", "placeholder", "required"];
    var attrPropsMapping;
    var dataProps = ["additionalClasses", "additionalDropboxClasses", "additionalDropboxContainerClasses", "additionalToggleButtonClasses", "aliasKey", "allOptionsSelectedText", "allowNewOption", "alwaysShowSelectedOptionsCount", "alwaysShowSelectedOptionsLabel", "ariaLabelledby", "ariaLabelText", "ariaLabelClearButtonText", "autoSelectFirstOption", "clearButtonText", "descriptionKey", "disableAllOptionsSelectedText", "disableOptionGroupCheckbox", "disableSelectAll", "disableValidation", "dropboxWidth", "dropboxWrapper", "emptyValue", "enableSecureText", "focusSelectedOptionOnOpen", "hasOptionDescription", "hideClearButton", "hideValueTooltipOnSelectAll", "keepAlwaysOpen", "labelKey", "markSearchResults", "maxValues", "maxWidth", "minValues", "moreText", "noOfDisplayValues", "noOptionsText", "noSearchResultsText", "optionHeight", "optionSelectedText", "optionsCount", "optionsSelectedText", "popupDropboxBreakpoint", "popupPosition", "position", "search", "searchByStartsWith", "searchDelay", "searchFormLabel", "searchGroup", "searchNormalize", "searchPlaceholderText", "selectAllOnlyVisible", "selectAllText", "setValueAsArray", "showDropboxAsPopup", "showOptionsOnlyOnSearch", "showSelectedOptionsFirst", "showValueAsTags", "silentInitialValueSet", "textDirection", "tooltipAlignment", "tooltipFontSize", "tooltipMaxWidth", "updatePositionThrottle", "useGroupValue", "valueKey", "zIndex"];
    var VirtualSelect = /* @__PURE__ */ function() {
      function VirtualSelect2(options) {
        virtual_select_classCallCheck(this, VirtualSelect2);
        try {
          this.createSecureTextElements();
          this.setProps(options);
          this.setDisabledOptions(options.disabledOptions);
          this.setOptions(options.options);
          this.render();
        } catch (e) {
          console.warn("Couldn't initiate Virtual Select");
          console.error(e);
        }
      }
      virtual_select_createClass(VirtualSelect2, [{
        key: "render",
        value: function render() {
          if (!this.$ele) {
            return;
          }
          var uniqueId = this.uniqueId;
          var wrapperClasses = "vscomp-wrapper";
          var toggleButtonClasses = "vscomp-toggle-button";
          var valueTooltip = this.showValueAsTags ? "" : this.getTooltipAttrText(this.placeholder, true, true);
          var clearButtonTooltip = this.getTooltipAttrText(this.clearButtonText);
          var ariaLabelledbyText = this.ariaLabelledby ? 'aria-labelledby="'.concat(this.ariaLabelledby, '"') : "";
          var ariaLabelText = this.ariaLabelText ? 'aria-label="'.concat(this.ariaLabelText, '"') : "";
          var ariaLabelClearBtnTxt = this.ariaLabelClearButtonText ? 'aria-label="'.concat(this.ariaLabelClearButtonText, '"') : "";
          var isExpanded = false;
          if (this.additionalClasses) {
            wrapperClasses += " ".concat(this.additionalClasses);
          }
          if (this.additionalToggleButtonClasses) {
            toggleButtonClasses += " ".concat(this.additionalToggleButtonClasses);
          }
          if (this.multiple) {
            wrapperClasses += " multiple";
            if (!this.disableSelectAll) {
              wrapperClasses += " has-select-all";
            }
          }
          if (!this.hideClearButton) {
            wrapperClasses += " has-clear-button";
          }
          if (this.keepAlwaysOpen) {
            wrapperClasses += " keep-always-open";
            isExpanded = true;
          } else {
            wrapperClasses += " closed";
          }
          if (this.showAsPopup) {
            wrapperClasses += " show-as-popup";
          }
          if (this.hasSearch) {
            wrapperClasses += " has-search-input";
          }
          if (this.showValueAsTags) {
            wrapperClasses += " show-value-as-tags";
          }
          if (this.textDirection) {
            wrapperClasses += " text-direction-".concat(this.textDirection);
          }
          if (this.popupPosition) {
            wrapperClasses += " popup-position-".concat(this.popupPosition.toLowerCase());
          }
          var html = '<div id="vscomp-ele-wrapper-'.concat(uniqueId, '" class="vscomp-ele-wrapper ').concat(wrapperClasses, '" tabindex="0"\n        role="combobox" aria-haspopup="listbox" aria-controls="vscomp-dropbox-container-').concat(uniqueId, '"\n        aria-expanded="').concat(isExpanded, '" ').concat(ariaLabelledbyText, " ").concat(ariaLabelText, '>\n        <input type="hidden" name="').concat(this.name, '" class="vscomp-hidden-input">\n        <div class="').concat(toggleButtonClasses, '">\n          <div class="vscomp-value" ').concat(valueTooltip, ">\n            ").concat(this.placeholder, '\n          </div>\n          <div class="vscomp-arrow"></div>\n          <div class="vscomp-clear-button toggle-button-child" ').concat(clearButtonTooltip, ' \n          tabindex="0" ').concat(ariaLabelClearBtnTxt, '>\n            <i class="vscomp-clear-icon"></i>\n          </div>\n        </div>\n\n        ').concat(this.renderDropbox({
            wrapperClasses
          }), "\n      </div>");
          this.$ele.innerHTML = html;
          this.$body = document.querySelector("body");
          this.$wrapper = this.$ele.querySelector(".vscomp-wrapper");
          if (this.hasDropboxWrapper) {
            this.$allWrappers = [this.$wrapper, this.$dropboxWrapper];
            this.$dropboxContainer = this.$dropboxWrapper.querySelector(".vscomp-dropbox-container");
            DomUtils.addClass(this.$dropboxContainer, "pop-comp-wrapper");
          } else {
            this.$allWrappers = [this.$wrapper];
            this.$dropboxContainer = this.$wrapper.querySelector(".vscomp-dropbox-container");
          }
          this.$toggleButton = this.$ele.querySelector(".vscomp-toggle-button");
          this.$clearButton = this.$ele.querySelector(".vscomp-clear-button");
          this.$valueText = this.$ele.querySelector(".vscomp-value");
          this.$hiddenInput = this.$ele.querySelector(".vscomp-hidden-input");
          this.$dropbox = this.$dropboxContainer.querySelector(".vscomp-dropbox");
          this.$dropboxCloseButton = this.$dropboxContainer.querySelector(".vscomp-dropbox-close-button");
          this.$dropboxContainerBottom = this.$dropboxContainer.querySelector(".vscomp-dropbox-container-bottom");
          this.$dropboxContainerTop = this.$dropboxContainer.querySelector(".vscomp-dropbox-container-top");
          this.$search = this.$dropboxContainer.querySelector(".vscomp-search-wrapper");
          this.$optionsContainer = this.$dropboxContainer.querySelector(".vscomp-options-container");
          this.$optionsList = this.$dropboxContainer.querySelector(".vscomp-options-list");
          this.$options = this.$dropboxContainer.querySelector(".vscomp-options");
          this.$noOptions = this.$dropboxContainer.querySelector(".vscomp-no-options");
          this.$noSearchResults = this.$dropboxContainer.querySelector(".vscomp-no-search-results");
          this.afterRenderWrapper();
        }
      }, {
        key: "renderDropbox",
        value: function renderDropbox(_ref) {
          var wrapperClasses = _ref.wrapperClasses;
          var $wrapper = this.dropboxWrapper !== "self" ? document.querySelector(this.dropboxWrapper) : null;
          var dropboxClasses = "vscomp-dropbox";
          if (this.additionalDropboxClasses) {
            dropboxClasses += " ".concat(this.additionalDropboxClasses);
          }
          var dropboxContainerClasses = "vscomp-dropbox-container";
          if (this.additionalDropboxContainerClasses) {
            dropboxContainerClasses += " ".concat(this.additionalDropboxContainerClasses);
          }
          var html = '<div id="vscomp-dropbox-container-'.concat(this.uniqueId, '" role="listbox" class="').concat(dropboxContainerClasses, '">\n        <div class="vscomp-dropbox-container-top" aria-hidden="true" tabindex="0">&nbsp;</div>\n        <div class="').concat(dropboxClasses, '">\n          <div class="vscomp-search-wrapper"></div>\n\n          <div class="vscomp-options-container">\n            <div class="vscomp-options-loader"></div>\n\n            <div class="vscomp-options-list">\n              <div class="vscomp-options"></div>\n            </div>\n          </div>\n\n          <div class="vscomp-options-bottom-freezer"></div>\n          <div class="vscomp-no-options">').concat(this.noOptionsText, '</div>\n          <div class="vscomp-no-search-results">').concat(this.noSearchResultsText, '</div>\n\n          <span class="vscomp-dropbox-close-button"><i class="vscomp-clear-icon"></i></span>\n        </div>\n        <div class="vscomp-dropbox-container-bottom" aria-hidden="true" tabindex="0">&nbsp;</div>\n      </div>');
          if ($wrapper) {
            var $dropboxWrapper = document.createElement("div");
            this.$dropboxWrapper = $dropboxWrapper;
            this.hasDropboxWrapper = true;
            $dropboxWrapper.innerHTML = html;
            $wrapper.appendChild($dropboxWrapper);
            DomUtils.addClass($dropboxWrapper, "vscomp-dropbox-wrapper ".concat(wrapperClasses));
            return "";
          }
          this.hasDropboxWrapper = false;
          return html;
        }
      }, {
        key: "renderOptions",
        value: function renderOptions() {
          var _this = this;
          var html = "";
          var visibleOptions = this.getVisibleOptions();
          var checkboxHtml = "";
          var newOptionIconHtml = "";
          var markSearchResults = !!(this.markSearchResults && this.searchValue);
          var searchRegex;
          var labelRenderer = this.labelRenderer, disableOptionGroupCheckbox = this.disableOptionGroupCheckbox, uniqueId = this.uniqueId, searchGroup = this.searchGroup;
          var hasLabelRenderer = typeof labelRenderer === "function";
          var convertToBoolean = Utils.convertToBoolean;
          var groupName = "";
          if (markSearchResults) {
            searchRegex = new RegExp("(".concat(Utils.regexEscape(this.searchValue), ")(?!([^<]+)?>)"), "gi");
          }
          if (this.multiple) {
            checkboxHtml = '<span class="checkbox-icon"></span>';
          }
          if (this.allowNewOption) {
            var newOptionTooltip = this.getTooltipAttrText("New Option");
            newOptionIconHtml = '<span class="vscomp-new-option-icon" '.concat(newOptionTooltip, "></span>");
          }
          visibleOptions.forEach(function(d) {
            var index = d.index;
            var optionLabel;
            var optionClasses = "vscomp-option";
            var optionTooltip = _this.getTooltipAttrText("", true, true);
            var leftSection = checkboxHtml;
            var rightSection = "";
            var description = "";
            var groupIndexText = "";
            var ariaLabel = "";
            var tabIndexValue = "-1";
            var isSelected2 = convertToBoolean(d.isSelected);
            var ariaDisabledText = "";
            if (d.classNames) {
              optionClasses += " ".concat(d.classNames);
            }
            if (d.isFocused) {
              tabIndexValue = "0";
              optionClasses += " focused";
            }
            if (d.isDisabled) {
              optionClasses += " disabled";
              ariaDisabledText = 'aria-disabled="true"';
            }
            if (d.isGroupTitle) {
              groupName = d.label;
              optionClasses += " group-title";
              if (disableOptionGroupCheckbox) {
                leftSection = "";
              }
            }
            if (isSelected2) {
              optionClasses += " selected";
            }
            if (d.isGroupOption) {
              var optionDesc = "";
              optionClasses += " group-option";
              groupIndexText = 'data-group-index="'.concat(d.groupIndex, '"');
              if (d.customData) {
                groupName = d.customData.group_name !== void 0 ? "".concat(d.customData.group_name, ", ") : "";
                optionDesc = d.customData.description !== void 0 ? " ".concat(d.customData.description, ",") : "";
                ariaLabel = 'aria-label="'.concat(groupName, " ").concat(d.label, ", ").concat(optionDesc, '"');
              } else {
                ariaLabel = 'aria-label="'.concat(groupName, ", ").concat(d.label, '"');
              }
            }
            if (hasLabelRenderer) {
              optionLabel = labelRenderer(d);
            } else {
              optionLabel = d.label;
            }
            if (d.description) {
              description = '<div class="vscomp-option-description" '.concat(optionTooltip, ">").concat(d.description, "</div>");
            }
            if (d.isCurrentNew) {
              optionClasses += " current-new";
              rightSection += newOptionIconHtml;
            } else if (markSearchResults && (!d.isGroupTitle || searchGroup)) {
              optionLabel = optionLabel.replace(searchRegex, "<mark>$1</mark>");
            }
            html += '<div role="option" aria-selected="'.concat(isSelected2, '" id="vscomp-option-').concat(uniqueId, "-").concat(index, '"\n          class="').concat(optionClasses, '" data-value="').concat(d.value, '" data-index="').concat(index, '" data-visible-index="').concat(d.visibleIndex, '"\n          tabindex=').concat(tabIndexValue, " ").concat(groupIndexText, " ").concat(ariaDisabledText, " ").concat(ariaLabel, "\n        >\n          ").concat(leftSection, '\n          <span class="vscomp-option-text" ').concat(optionTooltip, ">\n            ").concat(optionLabel, "\n          </span>\n          ").concat(description, "\n          ").concat(rightSection, "\n        </div>");
          });
          groupName = "";
          this.$options.innerHTML = html;
          this.$visibleOptions = this.$options.querySelectorAll(".vscomp-option");
          this.afterRenderOptions();
        }
      }, {
        key: "renderSearch",
        value: function renderSearch() {
          if (!this.hasSearchContainer) {
            return;
          }
          var checkboxHtml = "";
          var searchInput = "";
          if (this.multiple && !this.disableSelectAll) {
            checkboxHtml = '<span class="vscomp-toggle-all-button">\n          <span class="checkbox-icon vscomp-toggle-all-checkbox"></span>\n          <span class="vscomp-toggle-all-label">'.concat(this.selectAllText, "</span>\n        </span>");
          }
          if (this.hasSearch) {
            searchInput = '<label for="vscomp-search-input-'.concat(this.uniqueId, '" class="vscomp-search-label"\n        id="vscomp-search-label-').concat(this.uniqueId, '"\n      >\n        ').concat(this.searchFormLabel, '\n      </label>\n      <input type="text" class="vscomp-search-input" placeholder="').concat(this.searchPlaceholderText, '"\n        id="vscomp-search-input-').concat(this.uniqueId, '">\n      <span class="vscomp-search-clear">&times;</span>');
          }
          var html = '<div class="vscomp-search-container">\n        '.concat(checkboxHtml, "\n        ").concat(searchInput, "\n      </div>");
          this.$search.innerHTML = html;
          this.$searchInput = this.$dropboxContainer.querySelector(".vscomp-search-input");
          this.$searchClear = this.$dropboxContainer.querySelector(".vscomp-search-clear");
          this.$toggleAllButton = this.$dropboxContainer.querySelector(".vscomp-toggle-all-button");
          this.$toggleAllCheckbox = this.$dropboxContainer.querySelector(".vscomp-toggle-all-checkbox");
          this.addEvent(this.$searchInput, "input", "onSearch");
          this.addEvent(this.$searchClear, "click", "onSearchClear");
          this.addEvent(this.$toggleAllButton, "click", "onToggleAllOptions");
          this.addEvent(this.$dropboxContainerBottom, "focus", "onDropboxContainerTopOrBottomFocus");
          this.addEvent(this.$dropboxContainerTop, "focus", "onDropboxContainerTopOrBottomFocus");
        }
        /** render methods - end */
        /** dom event methods - start */
      }, {
        key: "addEvents",
        value: function addEvents() {
          this.addEvent(document, "click", "onDocumentClick");
          this.addEvent(this.$allWrappers, "keydown", "onKeyDown");
          this.addEvent(this.$toggleButton, "click", "onToggleButtonClick");
          this.addEvent(this.$clearButton, "click keydown", "onClearButtonClick");
          this.addEvent(this.$dropboxContainer, "click", "onDropboxContainerClick");
          this.addEvent(this.$dropboxCloseButton, "click", "onDropboxCloseButtonClick");
          this.addEvent(this.$optionsContainer, "scroll", "onOptionsScroll");
          this.addEvent(this.$options, "click", "onOptionsClick");
          this.addEvent(this.$options, "mouseover", "onOptionsMouseOver");
          this.addEvent(this.$options, "touchmove", "onOptionsTouchMove");
          this.addMutationObserver();
        }
      }, {
        key: "addEvent",
        value: function addEvent($ele, events2, method) {
          var _this2 = this;
          if (!$ele) {
            return;
          }
          var eventsArray = Utils.removeArrayEmpty(events2.split(" "));
          eventsArray.forEach(function(event) {
            var eventsKey = "".concat(method, "-").concat(event);
            var callback = _this2.events[eventsKey];
            if (!callback) {
              callback = _this2[method].bind(_this2);
              _this2.events[eventsKey] = callback;
            }
            DomUtils.addEvent($ele, event, callback);
          });
        }
        /** dom event methods - start */
      }, {
        key: "removeEvents",
        value: function removeEvents() {
          this.removeEvent(document, "click", "onDocumentClick");
          this.removeEvent(this.$allWrappers, "keydown", "onKeyDown");
          this.removeEvent(this.$toggleButton, "click", "onToggleButtonClick");
          this.removeEvent(this.$clearButton, "click keydown", "onClearButtonClick");
          this.removeEvent(this.$dropboxContainer, "click", "onDropboxContainerClick");
          this.removeEvent(this.$dropboxCloseButton, "click", "onDropboxCloseButtonClick");
          this.removeEvent(this.$optionsContainer, "scroll", "onOptionsScroll");
          this.removeEvent(this.$options, "click", "onOptionsClick");
          this.removeEvent(this.$options, "mouseover", "onOptionsMouseOver");
          this.removeEvent(this.$options, "touchmove", "onOptionsTouchMove");
          this.removeMutationObserver();
        }
      }, {
        key: "removeEvent",
        value: function removeEvent($ele, events2, method) {
          var _this3 = this;
          if (!$ele) {
            return;
          }
          var eventsArray = Utils.removeArrayEmpty(events2.split(" "));
          eventsArray.forEach(function(event) {
            var eventsKey = "".concat(method, "-").concat(event);
            var callback = _this3.events[eventsKey];
            if (callback) {
              DomUtils.removeEvent($ele, event, callback);
            }
          });
        }
      }, {
        key: "onDocumentClick",
        value: function onDocumentClick(e) {
          var $eleToKeepOpen = e.target.closest(".vscomp-wrapper");
          if ($eleToKeepOpen !== this.$wrapper && $eleToKeepOpen !== this.$dropboxWrapper && this.isOpened()) {
            this.closeDropbox();
          }
        }
      }, {
        key: "onKeyDown",
        value: function onKeyDown(e) {
          var key = e.which || e.keyCode;
          var method = keyDownMethodMapping[key];
          if (document.activeElement === this.$searchInput && e.shiftKey && key === 9) {
            e.preventDefault();
            if (this.keepAlwaysOpen) {
              this.$dropboxContainerTop.focus();
            } else {
              this.closeDropbox();
            }
            return;
          }
          if (document.activeElement === this.$searchInput && key === 9) {
            e.preventDefault();
            this.focusFirstVisibleOption();
            return;
          }
          if (key === 27 || e.key === "Escape") {
            var wrapper = this.showAsPopup ? this.$wrapper : this.$dropboxWrapper;
            if ((document.activeElement === wrapper || wrapper.contains(document.activeElement)) && !this.keepAlwaysOpen) {
              this.closeDropbox();
              return;
            }
          }
          if (method) {
            this[method](e);
          }
        }
      }, {
        key: "onEnterPress",
        value: function onEnterPress(e) {
          e.preventDefault();
          if (this.isOpened()) {
            this.selectFocusedOption();
          } else if (this.$ele.disabled === false) {
            this.openDropbox();
          }
        }
      }, {
        key: "onDownArrowPress",
        value: function onDownArrowPress(e) {
          e.preventDefault();
          if (this.isOpened()) {
            this.focusOption({
              direction: "next"
            });
          } else {
            this.openDropbox();
          }
        }
      }, {
        key: "onUpArrowPress",
        value: function onUpArrowPress(e) {
          e.preventDefault();
          if (this.isOpened()) {
            this.focusOption({
              direction: "previous"
            });
          } else {
            this.openDropbox();
          }
        }
      }, {
        key: "onBackspaceOrDeletePress",
        value: function onBackspaceOrDeletePress(e) {
          if (e.target === this.$wrapper) {
            e.preventDefault();
            if (this.selectedValues.length > 0) {
              this.reset();
            }
          }
        }
      }, {
        key: "onToggleButtonClick",
        value: function onToggleButtonClick(e) {
          var $target = e.target;
          if ($target.closest(".vscomp-value-tag-clear-button")) {
            this.removeValue($target.closest(".vscomp-value-tag"));
          } else if (!$target.closest(".toggle-button-child")) {
            this.toggleDropbox();
          }
        }
      }, {
        key: "onClearButtonClick",
        value: function onClearButtonClick(e) {
          if (e.type === "click") {
            this.reset();
          } else if (e.type === "keydown" && (e.code === "Enter" || e.code === "Space")) {
            e.stopPropagation();
            this.reset();
          }
        }
      }, {
        key: "onOptionsScroll",
        value: function onOptionsScroll() {
          this.setVisibleOptions();
        }
      }, {
        key: "onOptionsClick",
        value: function onOptionsClick(e) {
          var $option = e.target.closest(".vscomp-option");
          if ($option && !DomUtils.hasClass($option, "disabled")) {
            if (DomUtils.hasClass($option, "group-title")) {
              this.onGroupTitleClick($option);
            } else {
              this.selectOption($option, {
                event: e
              });
            }
          }
        }
      }, {
        key: "onGroupTitleClick",
        value: function onGroupTitleClick($ele) {
          if (!$ele || !this.multiple || this.disableOptionGroupCheckbox) {
            return;
          }
          var isAdding = !DomUtils.hasClass($ele, "selected");
          this.toggleGroupTitleCheckbox($ele, isAdding);
          this.toggleGroupOptions($ele, isAdding);
        }
      }, {
        key: "onDropboxContainerClick",
        value: function onDropboxContainerClick(e) {
          if (!e.target.closest(".vscomp-dropbox")) {
            this.closeDropbox();
          }
        }
      }, {
        key: "onDropboxCloseButtonClick",
        value: function onDropboxCloseButtonClick() {
          this.closeDropbox();
        }
      }, {
        key: "onOptionsMouseOver",
        value: function onOptionsMouseOver(e) {
          var $ele = e.target.closest(".vscomp-option");
          if ($ele && this.isOpened()) {
            if (DomUtils.hasClass($ele, "disabled") || DomUtils.hasClass($ele, "group-title")) {
              this.removeOptionFocus();
            } else {
              this.focusOption({
                $option: $ele
              });
            }
          }
        }
      }, {
        key: "onOptionsTouchMove",
        value: function onOptionsTouchMove() {
          this.removeOptionFocus();
        }
      }, {
        key: "onSearch",
        value: function onSearch(e) {
          e.stopPropagation();
          this.setSearchValue(e.target.value, true);
        }
      }, {
        key: "onSearchClear",
        value: function onSearchClear() {
          this.setSearchValue("");
          this.focusSearchInput();
        }
      }, {
        key: "onToggleAllOptions",
        value: function onToggleAllOptions() {
          this.toggleAllOptions();
        }
      }, {
        key: "onDropboxContainerTopOrBottomFocus",
        value: function onDropboxContainerTopOrBottomFocus() {
          this.closeDropbox();
        }
      }, {
        key: "onResize",
        value: function onResize2() {
          this.setOptionsContainerHeight(true);
        }
        /** to remove dropboxWrapper on removing vscomp-ele when it is rendered outside of vscomp-ele */
      }, {
        key: "addMutationObserver",
        value: function addMutationObserver() {
          var _this4 = this;
          if (!this.hasDropboxWrapper) {
            return;
          }
          var $vscompEle = this.$ele;
          this.mutationObserver = new MutationObserver(function(mutations) {
            var isAdded = false;
            var isRemoved = false;
            mutations.forEach(function(mutation) {
              if (!isAdded) {
                isAdded = virtual_select_toConsumableArray(mutation.addedNodes).some(function($ele) {
                  return !!($ele === $vscompEle || $ele.contains($vscompEle));
                });
              }
              if (!isRemoved) {
                isRemoved = virtual_select_toConsumableArray(mutation.removedNodes).some(function($ele) {
                  return !!($ele === $vscompEle || $ele.contains($vscompEle));
                });
              }
            });
            if (isRemoved && !isAdded) {
              _this4.destroy();
            }
          });
          this.mutationObserver.observe(document.querySelector("body"), {
            childList: true,
            subtree: true
          });
        }
      }, {
        key: "removeMutationObserver",
        value: function removeMutationObserver() {
          this.mutationObserver.disconnect();
        }
        /** dom event methods - end */
        /** before event methods - start */
      }, {
        key: "beforeValueSet",
        value: function beforeValueSet(isReset) {
          this.toggleAllOptionsClass(isReset ? false : void 0);
        }
      }, {
        key: "beforeSelectNewValue",
        value: function beforeSelectNewValue() {
          var _this5 = this;
          var newOption = this.getNewOption();
          var newIndex = newOption.index;
          this.newValues.push(newOption.value);
          this.setOptionProp(newIndex, "isCurrentNew", false);
          this.setOptionProp(newIndex, "isNew", true);
          setTimeout(function() {
            _this5.setSearchValue("");
            _this5.focusSearchInput();
          }, 0);
        }
        /** before event methods - end */
        /** after event methods - start */
      }, {
        key: "afterRenderWrapper",
        value: function afterRenderWrapper() {
          DomUtils.addClass(this.$ele, "vscomp-ele");
          this.renderSearch();
          this.setEleStyles();
          this.setDropboxStyles();
          this.setOptionsHeight();
          this.setVisibleOptions();
          this.setOptionsContainerHeight();
          this.addEvents();
          this.setEleProps();
          if (!this.keepAlwaysOpen && !this.showAsPopup) {
            this.initDropboxPopover();
          }
          if (this.initialSelectedValue) {
            this.setValueMethod(this.initialSelectedValue, this.silentInitialValueSet);
          } else if (this.autoSelectFirstOption && this.visibleOptions.length) {
            this.setValueMethod(this.visibleOptions[0].value, this.silentInitialValueSet);
          }
          if (this.showOptionsOnlyOnSearch) {
            this.setSearchValue("", false, true);
          }
          if (this.initialDisabled) {
            this.disable();
          }
          if (this.autofocus) {
            this.focus();
          }
        }
      }, {
        key: "afterRenderOptions",
        value: function afterRenderOptions() {
          var visibleOptions = this.getVisibleOptions();
          var hasNoOptions = !this.options.length && !this.hasServerSearch;
          var hasNoSearchResults = !hasNoOptions && !visibleOptions.length;
          if (!this.allowNewOption || this.hasServerSearch || this.showOptionsOnlyOnSearch) {
            DomUtils.toggleClass(this.$allWrappers, "has-no-search-results", hasNoSearchResults);
            if (hasNoSearchResults) {
              DomUtils.setAttr(this.$noSearchResults, "tabindex", "0");
              DomUtils.setAttr(this.$noSearchResults, "aria-hidden", "false");
            } else {
              DomUtils.setAttr(this.$noSearchResults, "tabindex", "-1");
              DomUtils.setAttr(this.$noSearchResults, "aria-hidden", "true");
            }
          }
          DomUtils.toggleClass(this.$allWrappers, "has-no-options", hasNoOptions);
          if (hasNoOptions) {
            DomUtils.setAttr(this.$noOptions, "tabindex", "0");
            DomUtils.setAttr(this.$noOptions, "aria-hidden", "false");
          } else {
            DomUtils.setAttr(this.$noOptions, "tabindex", "-1");
            DomUtils.setAttr(this.$noOptions, "aria-hidden", "true");
          }
          this.setOptionAttr();
          this.setOptionsPosition();
          this.setOptionsTooltip();
          if (document.activeElement !== this.$searchInput) {
            var focusedOption = DomUtils.getElementsBySelector(".focused", this.$dropboxContainer)[0];
            if (focusedOption !== void 0) {
              focusedOption.focus();
            }
          }
        }
      }, {
        key: "afterSetOptionsContainerHeight",
        value: function afterSetOptionsContainerHeight(reset) {
          if (reset && this.showAsPopup) {
            this.setVisibleOptions();
          }
        }
      }, {
        key: "afterSetSearchValue",
        value: function afterSetSearchValue() {
          var _this6 = this;
          if (this.hasServerSearch) {
            clearInterval(this.serverSearchTimeout);
            this.serverSearchTimeout = setTimeout(function() {
              _this6.serverSearch();
            }, this.searchDelay);
          } else {
            this.setVisibleOptionsCount();
          }
          if (this.selectAllOnlyVisible) {
            this.toggleAllOptionsClass();
          }
          this.focusOption({
            focusFirst: true
          });
        }
      }, {
        key: "afterSetVisibleOptionsCount",
        value: function afterSetVisibleOptionsCount() {
          this.scrollToTop();
          this.setOptionsHeight();
          this.setVisibleOptions();
          this.updatePosition();
        }
      }, {
        key: "afterValueSet",
        value: function afterValueSet() {
          this.scrollToTop();
          this.setSearchValue("");
          this.renderOptions();
        }
      }, {
        key: "afterSetOptions",
        value: function afterSetOptions(keepValue) {
          if (keepValue) {
            this.setSelectedProp();
          }
          this.setOptionsHeight();
          this.setVisibleOptions();
          if (this.showOptionsOnlyOnSearch) {
            this.setSearchValue("", false, true);
          }
          if (!keepValue) {
            this.reset();
          }
        }
        /** after event methods - end */
        /** set methods - start */
        /**
         * @param {virtualSelectOptions} params
         */
      }, {
        key: "setProps",
        value: function setProps(params) {
          var options = this.setDefaultProps(params);
          this.setPropsFromElementAttr(options);
          var convertToBoolean = Utils.convertToBoolean;
          this.$ele = options.ele;
          this.dropboxWrapper = options.dropboxWrapper;
          this.valueKey = options.valueKey;
          this.labelKey = options.labelKey;
          this.descriptionKey = options.descriptionKey;
          this.aliasKey = options.aliasKey;
          this.optionHeightText = options.optionHeight;
          this.optionHeight = parseFloat(this.optionHeightText);
          this.multiple = convertToBoolean(options.multiple);
          this.hasSearch = convertToBoolean(options.search);
          this.searchByStartsWith = convertToBoolean(options.searchByStartsWith);
          this.searchGroup = convertToBoolean(options.searchGroup);
          this.hideClearButton = convertToBoolean(options.hideClearButton);
          this.autoSelectFirstOption = convertToBoolean(options.autoSelectFirstOption);
          this.hasOptionDescription = convertToBoolean(options.hasOptionDescription);
          this.silentInitialValueSet = convertToBoolean(options.silentInitialValueSet);
          this.allowNewOption = convertToBoolean(options.allowNewOption);
          this.markSearchResults = convertToBoolean(options.markSearchResults);
          this.showSelectedOptionsFirst = convertToBoolean(options.showSelectedOptionsFirst);
          this.disableSelectAll = convertToBoolean(options.disableSelectAll);
          this.keepAlwaysOpen = convertToBoolean(options.keepAlwaysOpen);
          this.showDropboxAsPopup = convertToBoolean(options.showDropboxAsPopup);
          this.hideValueTooltipOnSelectAll = convertToBoolean(options.hideValueTooltipOnSelectAll);
          this.showOptionsOnlyOnSearch = convertToBoolean(options.showOptionsOnlyOnSearch);
          this.selectAllOnlyVisible = convertToBoolean(options.selectAllOnlyVisible);
          this.alwaysShowSelectedOptionsCount = convertToBoolean(options.alwaysShowSelectedOptionsCount);
          this.alwaysShowSelectedOptionsLabel = convertToBoolean(options.alwaysShowSelectedOptionsLabel);
          this.disableAllOptionsSelectedText = convertToBoolean(options.disableAllOptionsSelectedText);
          this.showValueAsTags = convertToBoolean(options.showValueAsTags);
          this.disableOptionGroupCheckbox = convertToBoolean(options.disableOptionGroupCheckbox);
          this.enableSecureText = convertToBoolean(options.enableSecureText);
          this.setValueAsArray = convertToBoolean(options.setValueAsArray);
          this.disableValidation = convertToBoolean(options.disableValidation);
          this.initialDisabled = convertToBoolean(options.disabled);
          this.required = convertToBoolean(options.required);
          this.autofocus = convertToBoolean(options.autofocus);
          this.useGroupValue = convertToBoolean(options.useGroupValue);
          this.focusSelectedOptionOnOpen = convertToBoolean(options.focusSelectedOptionOnOpen);
          this.noOptionsText = options.noOptionsText;
          this.noSearchResultsText = options.noSearchResultsText;
          this.selectAllText = options.selectAllText;
          this.searchNormalize = options.searchNormalize;
          this.searchPlaceholderText = options.searchPlaceholderText;
          this.searchFormLabel = options.searchFormLabel;
          this.optionsSelectedText = options.optionsSelectedText;
          this.optionSelectedText = options.optionSelectedText;
          this.allOptionsSelectedText = options.allOptionsSelectedText;
          this.clearButtonText = options.clearButtonText;
          this.moreText = options.moreText;
          this.placeholder = options.placeholder;
          this.position = options.position;
          this.textDirection = options.textDirection;
          this.dropboxWidth = options.dropboxWidth;
          this.tooltipFontSize = options.tooltipFontSize;
          this.tooltipAlignment = options.tooltipAlignment;
          this.tooltipMaxWidth = options.tooltipMaxWidth;
          this.updatePositionThrottle = options.updatePositionThrottle;
          this.noOfDisplayValues = parseInt(options.noOfDisplayValues);
          this.zIndex = parseInt(options.zIndex);
          this.maxValues = parseInt(options.maxValues);
          this.minValues = parseInt(options.minValues);
          this.name = this.secureText(options.name);
          this.additionalClasses = options.additionalClasses;
          this.additionalDropboxClasses = options.additionalDropboxClasses;
          this.additionalDropboxContainerClasses = options.additionalDropboxContainerClasses;
          this.additionalToggleButtonClasses = options.additionalToggleButtonClasses;
          this.popupDropboxBreakpoint = options.popupDropboxBreakpoint;
          this.popupPosition = options.popupPosition;
          this.onServerSearch = options.onServerSearch;
          this.labelRenderer = options.labelRenderer;
          this.selectedLabelRenderer = options.selectedLabelRenderer;
          this.initialSelectedValue = options.selectedValue === 0 ? "0" : options.selectedValue;
          this.emptyValue = options.emptyValue;
          this.ariaLabelledby = options.ariaLabelledby;
          this.ariaLabelText = options.ariaLabelText;
          this.ariaLabelClearButtonText = options.ariaLabelClearButtonText;
          this.maxWidth = options.maxWidth;
          this.searchDelay = options.searchDelay;
          this.selectedValues = [];
          this.selectedOptions = [];
          this.newValues = [];
          this.events = {};
          this.tooltipEnterDelay = 200;
          this.searchValue = "";
          this.searchValueOriginal = "";
          this.isAllSelected = false;
          if (options.search === void 0 && this.multiple || this.allowNewOption || this.showOptionsOnlyOnSearch) {
            this.hasSearch = true;
          }
          this.hasServerSearch = typeof this.onServerSearch === "function";
          if (this.maxValues || this.hasServerSearch || this.showOptionsOnlyOnSearch) {
            this.disableSelectAll = true;
            this.disableOptionGroupCheckbox = true;
          }
          if (this.keepAlwaysOpen) {
            this.dropboxWrapper = "self";
          }
          this.showAsPopup = this.showDropboxAsPopup && !this.keepAlwaysOpen && window.innerWidth <= parseFloat(this.popupDropboxBreakpoint);
          this.hasSearchContainer = this.hasSearch || this.multiple && !this.disableSelectAll;
          this.optionsCount = this.getOptionsCount(options.optionsCount);
          this.halfOptionsCount = Math.ceil(this.optionsCount / 2);
          this.optionsHeight = this.getOptionsHeight();
          this.uniqueId = this.getUniqueId();
        }
        /**
         * @param {virtualSelectOptions} options
         */
      }, {
        key: "setDefaultProps",
        value: function setDefaultProps(options) {
          var defaultOptions = {
            dropboxWrapper: "self",
            valueKey: "value",
            labelKey: "label",
            descriptionKey: "description",
            aliasKey: "alias",
            ariaLabelText: "Options list",
            ariaLabelClearButtonText: "Clear button",
            optionsCount: 5,
            noOfDisplayValues: 50,
            optionHeight: "40px",
            noOptionsText: "No options found",
            noSearchResultsText: "No results found",
            selectAllText: "Select All",
            searchNormalize: false,
            searchPlaceholderText: "Search...",
            searchFormLabel: "Search",
            clearButtonText: "Clear",
            moreText: "more...",
            optionsSelectedText: "options selected",
            optionSelectedText: "option selected",
            allOptionsSelectedText: "All",
            placeholder: "Select",
            position: "bottom left",
            zIndex: options.keepAlwaysOpen ? 1 : 2,
            tooltipFontSize: "14px",
            tooltipAlignment: "center",
            tooltipMaxWidth: "300px",
            updatePositionThrottle: 100,
            name: "",
            additionalClasses: "",
            additionalDropboxClasses: "",
            additionalDropboxContainerClasses: "",
            additionalToggleButtonClasses: "",
            maxValues: 0,
            showDropboxAsPopup: true,
            popupDropboxBreakpoint: "576px",
            popupPosition: "center",
            hideValueTooltipOnSelectAll: true,
            emptyValue: "",
            searchDelay: 300,
            focusSelectedOptionOnOpen: true
          };
          if (options.hasOptionDescription) {
            defaultOptions.optionsCount = 4;
            defaultOptions.optionHeight = "50px";
          }
          return Object.assign(defaultOptions, options);
        }
      }, {
        key: "setPropsFromElementAttr",
        value: function setPropsFromElementAttr(options) {
          var $ele = options.ele;
          Object.keys(attrPropsMapping).forEach(function(k) {
            var value = $ele.getAttribute(k);
            if (valueLessProps.indexOf(k) !== -1 && (value === "" || value === "true")) {
              value = true;
            }
            if (value) {
              options[attrPropsMapping[k]] = value;
            }
          });
        }
      }, {
        key: "setEleProps",
        value: function setEleProps() {
          var $ele = this.$ele;
          $ele.virtualSelect = this;
          $ele.value = this.multiple ? [] : "";
          $ele.name = this.name;
          $ele.disabled = false;
          $ele.required = this.required;
          $ele.autofocus = this.autofocus;
          $ele.multiple = this.multiple;
          $ele.form = $ele.closest("form");
          $ele.reset = VirtualSelect2.reset;
          $ele.setValue = VirtualSelect2.setValueMethod;
          $ele.setOptions = VirtualSelect2.setOptionsMethod;
          $ele.setDisabledOptions = VirtualSelect2.setDisabledOptionsMethod;
          $ele.setEnabledOptions = VirtualSelect2.setEnabledOptionsMethod;
          $ele.toggleSelectAll = VirtualSelect2.toggleSelectAll;
          $ele.isAllSelected = VirtualSelect2.isAllSelected;
          $ele.addOption = VirtualSelect2.addOptionMethod;
          $ele.getNewValue = VirtualSelect2.getNewValueMethod;
          $ele.getDisplayValue = VirtualSelect2.getDisplayValueMethod;
          $ele.getSelectedOptions = VirtualSelect2.getSelectedOptionsMethod;
          $ele.getDisabledOptions = VirtualSelect2.getDisabledOptionsMethod;
          $ele.open = VirtualSelect2.openMethod;
          $ele.close = VirtualSelect2.closeMethod;
          $ele.focus = VirtualSelect2.focusMethod;
          $ele.enable = VirtualSelect2.enableMethod;
          $ele.disable = VirtualSelect2.disableMethod;
          $ele.destroy = VirtualSelect2.destroyMethod;
          $ele.validate = VirtualSelect2.validateMethod;
          $ele.toggleRequired = VirtualSelect2.toggleRequiredMethod;
          if (this.hasDropboxWrapper) {
            this.$dropboxWrapper.virtualSelect = this;
          }
        }
      }, {
        key: "setValueMethod",
        value: function setValueMethod(newValue, silentChange) {
          var valuesMapping = {};
          var valuesOrder = {};
          var validValues = [];
          var isMultiSelect = this.multiple;
          var value = newValue;
          if (value) {
            if (!Array.isArray(value)) {
              value = [value];
            }
            if (isMultiSelect) {
              var maxValues = this.maxValues;
              if (maxValues && value.length > maxValues) {
                value.splice(maxValues);
              }
            } else if (value.length > 1) {
              value = [value[0]];
            }
            value = value.map(function(v) {
              return v || v === 0 ? v.toString() : "";
            });
            if (this.useGroupValue) {
              value = this.setGroupOptionsValue(value);
            }
            value.forEach(function(d, i) {
              valuesMapping[d] = true;
              valuesOrder[d] = i;
            });
            if (this.allowNewOption && value) {
              this.setNewOptionsFromValue(value);
            }
          }
          this.options.forEach(function(d) {
            if (valuesMapping[d.value] === true && !d.isDisabled && !d.isGroupTitle) {
              d.isSelected = true;
              validValues.push(d.value);
            } else {
              d.isSelected = false;
            }
          });
          if (isMultiSelect) {
            if (this.hasOptionGroup) {
              this.setGroupsSelectedProp();
            }
            validValues.sort(function(a, b) {
              return valuesOrder[a] - valuesOrder[b];
            });
          } else {
            var _validValues = validValues;
            var _validValues2 = virtual_select_slicedToArray(_validValues, 1);
            validValues = _validValues2[0];
          }
          this.beforeValueSet();
          this.setValue(validValues, {
            disableEvent: silentChange
          });
          this.afterValueSet();
        }
      }, {
        key: "setGroupOptionsValue",
        value: function setGroupOptionsValue(preparedValues) {
          var selectedValues = [];
          var selectedGroups = {};
          var valuesMapping = {};
          preparedValues.forEach(function(d) {
            valuesMapping[d] = true;
          });
          this.options.forEach(function(d) {
            var value = d.value;
            var isSelected2 = valuesMapping[value] === true;
            if (d.isGroupTitle) {
              if (isSelected2) {
                selectedGroups[d.index] = true;
              }
            } else if (isSelected2 || selectedGroups[d.groupIndex]) {
              selectedValues.push(value);
            }
          });
          return selectedValues;
        }
      }, {
        key: "setGroupsSelectedProp",
        value: function setGroupsSelectedProp() {
          var isAllGroupOptionsSelected = this.isAllGroupOptionsSelected.bind(this);
          this.options.forEach(function(d) {
            if (d.isGroupTitle) {
              d.isSelected = isAllGroupOptionsSelected(d.index);
            }
          });
        }
      }, {
        key: "setOptionsMethod",
        value: function setOptionsMethod(options, keepValue) {
          this.setOptions(options);
          this.afterSetOptions(keepValue);
        }
      }, {
        key: "setDisabledOptionsMethod",
        value: function setDisabledOptionsMethod(disabledOptions) {
          var keepValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          this.setDisabledOptions(disabledOptions, true);
          if (!keepValue) {
            this.setValueMethod(null);
            this.toggleAllOptionsClass();
          }
          this.setVisibleOptions();
        }
      }, {
        key: "setDisabledOptions",
        value: function setDisabledOptions(disabledOptions) {
          var setOptionsProp = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          var disabledOptionsArr = [];
          if (!disabledOptions) {
            if (setOptionsProp) {
              this.options.forEach(function(d) {
                d.isDisabled = false;
                return d;
              });
            }
          } else if (disabledOptions === true) {
            if (setOptionsProp) {
              this.options.forEach(function(d) {
                d.isDisabled = true;
                disabledOptionsArr.push(d.value);
                return d;
              });
            }
          } else {
            disabledOptionsArr = disabledOptions.map(function(d) {
              return d.toString();
            });
            var disabledOptionsMapping = {};
            disabledOptionsArr.forEach(function(d) {
              disabledOptionsMapping[d] = true;
            });
            if (setOptionsProp) {
              this.options.forEach(function(d) {
                d.isDisabled = disabledOptionsMapping[d.value] === true;
                return d;
              });
            }
          }
          this.disabledOptions = disabledOptionsArr;
        }
      }, {
        key: "setEnabledOptionsMethod",
        value: function setEnabledOptionsMethod(disabledOptions) {
          var keepValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          this.setEnabledOptions(disabledOptions);
          if (!keepValue) {
            this.setValueMethod(null);
            this.toggleAllOptionsClass();
          }
          this.setVisibleOptions();
        }
      }, {
        key: "setEnabledOptions",
        value: function setEnabledOptions(enabledOptions) {
          if (enabledOptions === void 0) {
            return;
          }
          var disabledOptionsArr = [];
          if (enabledOptions === true) {
            this.options.forEach(function(d) {
              d.isDisabled = false;
              return d;
            });
          } else {
            var enabledOptionsMapping = {};
            enabledOptions.forEach(function(d) {
              enabledOptionsMapping[d] = true;
            });
            this.options.forEach(function(d) {
              var isDisabled = enabledOptionsMapping[d.value] !== true;
              d.isDisabled = isDisabled;
              if (isDisabled) {
                disabledOptionsArr.push(d.value);
              }
              return d;
            });
          }
          this.disabledOptions = disabledOptionsArr;
        }
      }, {
        key: "setOptions",
        value: function setOptions() {
          var _this7 = this;
          var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
          var preparedOptions = [];
          var hasDisabledOptions = this.disabledOptions.length;
          var valueKey = this.valueKey, labelKey = this.labelKey, descriptionKey = this.descriptionKey, aliasKey = this.aliasKey, hasOptionDescription = this.hasOptionDescription;
          var getString = Utils.getString, convertToBoolean = Utils.convertToBoolean;
          var secureText = this.secureText.bind(this);
          var getAlias = this.getAlias.bind(this);
          var index = 0;
          var hasOptionGroup = false;
          var disabledOptionsMapping = {};
          var hasEmptyValueOption = false;
          this.disabledOptions.forEach(function(d) {
            disabledOptionsMapping[d] = true;
          });
          var prepareOption = function prepareOption2(d) {
            if (virtual_select_typeof(d) !== "object") {
              var _d2;
              d = (_d2 = {}, _defineProperty2(_d2, valueKey, d), _defineProperty2(_d2, labelKey, d), _d2);
            }
            var value = secureText(getString(d[valueKey]));
            var label = secureText(getString(d[labelKey]));
            var childOptions = d.options;
            var isGroupTitle = !!childOptions;
            var option = {
              index,
              value,
              label,
              labelNormalized: _this7.searchNormalize ? Utils.normalizeString(label).toLowerCase() : label.toLowerCase(),
              alias: getAlias(d[aliasKey]),
              isVisible: convertToBoolean(d.isVisible, true),
              isNew: d.isNew || false,
              isGroupTitle,
              classNames: d.classNames
            };
            if (!hasEmptyValueOption && value === "") {
              hasEmptyValueOption = true;
            }
            if (hasDisabledOptions) {
              option.isDisabled = disabledOptionsMapping[value] === true;
            }
            if (d.isGroupOption) {
              option.isGroupOption = true;
              option.groupIndex = d.groupIndex;
            }
            if (hasOptionDescription) {
              option.description = secureText(getString(d[descriptionKey]));
            }
            if (d.customData) {
              option.customData = d.customData;
            }
            preparedOptions.push(option);
            index += 1;
            if (isGroupTitle) {
              var groupIndex = option.index;
              hasOptionGroup = true;
              childOptions.forEach(function(childData) {
                childData.isGroupOption = true;
                childData.groupIndex = groupIndex;
                prepareOption2(childData);
              });
            }
          };
          if (Array.isArray(options)) {
            options.forEach(prepareOption);
          }
          var optionsLength = preparedOptions.length;
          var $ele = this.$ele;
          $ele.options = preparedOptions;
          $ele.length = optionsLength;
          this.options = preparedOptions;
          this.visibleOptionsCount = optionsLength;
          this.lastOptionIndex = optionsLength - 1;
          this.newValues = [];
          this.hasOptionGroup = hasOptionGroup;
          this.hasEmptyValueOption = hasEmptyValueOption;
          this.setSortedOptions();
        }
      }, {
        key: "setServerOptions",
        value: function setServerOptions() {
          var _this8 = this;
          var options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
          this.setOptionsMethod(options, true);
          var selectedOptions = this.selectedOptions;
          var newOptions = this.options;
          var optionsUpdated = false;
          if (selectedOptions.length) {
            var newOptionsValueMapping = {};
            optionsUpdated = true;
            newOptions.forEach(function(d) {
              newOptionsValueMapping[d.value] = true;
            });
            selectedOptions.forEach(function(d) {
              if (newOptionsValueMapping[d.value] !== true) {
                d.isVisible = false;
                newOptions.push(d);
              }
            });
            this.setOptionsMethod(newOptions, true);
          }
          if (this.allowNewOption && this.searchValue) {
            var hasExactOption = newOptions.some(function(d) {
              return d.label.toLowerCase() === _this8.searchValue;
            });
            if (!hasExactOption) {
              optionsUpdated = true;
              this.setNewOption();
            }
          }
          if (optionsUpdated) {
            this.setVisibleOptionsCount();
            if (this.multiple) {
              this.toggleAllOptionsClass();
            }
            this.setValueText();
          } else {
            this.updatePosition();
          }
          this.setVisibleOptionsCount();
          DomUtils.removeClass(this.$allWrappers, "server-searching");
        }
      }, {
        key: "setSelectedOptions",
        value: function setSelectedOptions() {
          this.selectedOptions = this.options.filter(function(d) {
            return d.isSelected;
          });
        }
      }, {
        key: "setSortedOptions",
        value: function setSortedOptions() {
          var sortedOptions = virtual_select_toConsumableArray(this.options);
          if (this.showSelectedOptionsFirst && this.selectedValues.length) {
            if (this.hasOptionGroup) {
              sortedOptions = this.sortOptionsGroup(sortedOptions);
            } else {
              sortedOptions = this.sortOptions(sortedOptions);
            }
          }
          this.sortedOptions = sortedOptions;
        }
      }, {
        key: "setVisibleOptions",
        value: function setVisibleOptions() {
          var visibleOptions = virtual_select_toConsumableArray(this.sortedOptions);
          var maxOptionsToShow = this.optionsCount * 2;
          var startIndex = this.getVisibleStartIndex();
          var newOption = this.getNewOption();
          var endIndex = startIndex + maxOptionsToShow - 1;
          var i = 0;
          if (newOption) {
            newOption.visibleIndex = i;
            i += 1;
          }
          visibleOptions = visibleOptions.filter(function(d) {
            var inView = false;
            if (d.isVisible && !d.isCurrentNew) {
              inView = i >= startIndex && i <= endIndex;
              d.visibleIndex = i;
              i += 1;
            }
            return inView;
          });
          if (newOption) {
            visibleOptions = [newOption].concat(virtual_select_toConsumableArray(visibleOptions));
          }
          this.visibleOptions = visibleOptions;
          this.visibleOptionsCount = visibleOptions.length;
          this.renderOptions();
        }
      }, {
        key: "setOptionsPosition",
        value: function setOptionsPosition(startIndex) {
          var top = parseInt((startIndex || this.getVisibleStartIndex()) * this.optionHeight);
          this.$options.style.transform = "translate3d(0, ".concat(top, "px, 0)");
          DomUtils.setData(this.$options, "top", top);
        }
      }, {
        key: "setOptionsTooltip",
        value: function setOptionsTooltip() {
          var _this9 = this;
          var visibleOptions = this.getVisibleOptions();
          var hasOptionDescription = this.hasOptionDescription;
          visibleOptions.forEach(function(d) {
            var $optionEle = _this9.$dropboxContainer.querySelector('.vscomp-option[data-index="'.concat(d.index, '"]'));
            DomUtils.setData($optionEle.querySelector(".vscomp-option-text"), "tooltip", d.label);
            if (hasOptionDescription) {
              DomUtils.setData($optionEle.querySelector(".vscomp-option-description"), "tooltip", d.description);
            }
          });
        }
      }, {
        key: "setValue",
        value: function setValue(value) {
          var _ref2 = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, _ref2$disableEvent = _ref2.disableEvent, disableEvent = _ref2$disableEvent === void 0 ? false : _ref2$disableEvent, _ref2$disableValidati = _ref2.disableValidation, disableValidation = _ref2$disableValidati === void 0 ? false : _ref2$disableValidati;
          var isValidValue = this.hasEmptyValueOption && value === "" || value;
          if (!isValidValue) {
            this.selectedValues = [];
          } else if (Array.isArray(value)) {
            this.selectedValues = virtual_select_toConsumableArray(value);
          } else {
            this.selectedValues = [value];
          }
          var newValue = this.getValue();
          this.$ele.value = newValue;
          this.$hiddenInput.value = this.getInputValue(newValue);
          this.isMaxValuesSelected = !!(this.maxValues && this.maxValues <= this.selectedValues.length);
          this.toggleAllOptionsClass();
          this.setValueText();
          DomUtils.toggleClass(this.$allWrappers, "has-value", Utils.isNotEmpty(this.selectedValues));
          DomUtils.toggleClass(this.$allWrappers, "max-value-selected", this.isMaxValuesSelected);
          if (!disableValidation) {
            this.validate();
          }
          if (!disableEvent) {
            DomUtils.dispatchEvent(this.$ele, "change", true);
          }
        }
      }, {
        key: "setValueText",
        value: function setValueText() {
          var _this10 = this;
          var multiple = this.multiple, selectedValues = this.selectedValues, noOfDisplayValues = this.noOfDisplayValues, showValueAsTags = this.showValueAsTags, $valueText = this.$valueText, selectedLabelRenderer = this.selectedLabelRenderer;
          var valueText = [];
          var valueTooltip = [];
          var selectedLength = selectedValues.length;
          var selectedValuesCount = 0;
          var showAllText = this.isAllSelected && !this.hasServerSearch && !this.disableAllOptionsSelectedText && !showValueAsTags;
          if (showAllText && this.hideValueTooltipOnSelectAll) {
            $valueText.innerHTML = "".concat(this.allOptionsSelectedText, " (").concat(selectedLength, ")");
          } else {
            var selectedOptions = this.getSelectedOptions({
              fullDetails: true,
              keepSelectionOrder: true
            });
            selectedOptions.some(function(d) {
              if (d.isCurrentNew) {
                return false;
              }
              if (selectedValuesCount >= noOfDisplayValues) {
                return true;
              }
              var label = d.label;
              if (typeof selectedLabelRenderer === "function") {
                label = selectedLabelRenderer(d);
              }
              valueText.push(label);
              selectedValuesCount += 1;
              if (showValueAsTags) {
                var valueTooltipForTags = Utils.willTextOverflow($valueText.parentElement, label) ? _this10.getTooltipAttrText(label, false, true) : "";
                var valueTagHtml = '<span class="vscomp-value-tag" data-index="'.concat(d.index, '" ').concat(valueTooltipForTags, '>\n                  <span class="vscomp-value-tag-content">').concat(label, '</span>\n                  <span class="vscomp-value-tag-clear-button">\n                    <i class="vscomp-clear-icon"></i>\n                  </span>\n                </span>');
                valueTooltip.push(valueTagHtml);
              } else {
                valueTooltip.push(label);
              }
              return false;
            });
            var moreSelectedOptions = selectedLength - noOfDisplayValues;
            if (moreSelectedOptions > 0) {
              valueTooltip.push('<span class="vscomp-value-tag more-value-count">+ '.concat(moreSelectedOptions, " ").concat(this.moreText, "</span>"));
            }
            var aggregatedValueText = valueText.join(", ");
            if (aggregatedValueText === "") {
              $valueText.innerHTML = this.placeholder;
            } else {
              $valueText.innerHTML = aggregatedValueText;
              if (multiple) {
                var maxValues = this.maxValues;
                var showSelectedCount = this.alwaysShowSelectedOptionsCount || DomUtils.hasEllipsis($valueText);
                if (showSelectedCount || maxValues || showValueAsTags) {
                  var countText = '<span class="vscomp-selected-value-count">'.concat(selectedLength, "</span>");
                  if (maxValues) {
                    countText += ' / <span class="vscomp-max-value-count">'.concat(maxValues, "</span>");
                  }
                  if (showAllText) {
                    $valueText.innerHTML = "".concat(this.allOptionsSelectedText, " (").concat(selectedLength, ")");
                  } else if (showValueAsTags) {
                    $valueText.innerHTML = valueTooltip.join("");
                    this.$valueTags = $valueText.querySelectorAll(".vscomp-value-tag");
                    this.setValueTagAttr();
                  } else if (!this.alwaysShowSelectedOptionsLabel) {
                    var optionsSelectedText = selectedLength === 1 ? this.optionSelectedText : this.optionsSelectedText;
                    $valueText.innerHTML = "".concat(countText, " ").concat(optionsSelectedText);
                  }
                } else {
                  valueTooltip = [];
                }
              }
            }
          }
          var tooltipText = "";
          if (selectedLength === 0) {
            tooltipText = this.placeholder;
          } else if (!showValueAsTags) {
            tooltipText = valueTooltip.join(", ");
          }
          if (!showValueAsTags) {
            DomUtils.setData($valueText, "tooltip", tooltipText);
          }
          if (multiple) {
            if (!showValueAsTags) {
              DomUtils.setData($valueText, "tooltipEllipsisOnly", selectedLength === 0);
            } else {
              this.updatePosition();
            }
          }
        }
      }, {
        key: "setSearchValue",
        value: function setSearchValue(value) {
          var skipInputSet = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          var forceSet = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
          if (value === this.searchValueOriginal && !forceSet) {
            return;
          }
          if (!skipInputSet) {
            this.$searchInput.value = value;
          }
          var searchValue = value.replace(/\\/g, "").toLowerCase().trim();
          this.searchValue = searchValue;
          this.searchValueOriginal = value;
          DomUtils.toggleClass(this.$allWrappers, "has-search-value", value);
          this.afterSetSearchValue();
        }
      }, {
        key: "setVisibleOptionsCount",
        value: function setVisibleOptionsCount() {
          var visibleOptionsCount = 0;
          var hasExactOption = false;
          var visibleOptionGroupsMapping;
          var searchGroup = this.searchGroup, showOptionsOnlyOnSearch = this.showOptionsOnlyOnSearch, searchByStartsWith = this.searchByStartsWith;
          var searchValue = this.searchValue;
          searchValue = this.searchNormalize ? Utils.normalizeString(searchValue) : searchValue;
          var isOptionVisible = this.isOptionVisible.bind(this);
          if (this.hasOptionGroup) {
            visibleOptionGroupsMapping = this.getVisibleOptionGroupsMapping(searchValue);
          }
          this.options.forEach(function(d) {
            if (d.isCurrentNew) {
              return;
            }
            var result;
            if (showOptionsOnlyOnSearch && !searchValue) {
              d.isVisible = false;
              result = {
                isVisible: false,
                hasExactOption: false
              };
            } else {
              result = isOptionVisible({
                data: d,
                searchValue,
                hasExactOption,
                visibleOptionGroupsMapping,
                searchGroup,
                searchByStartsWith
              });
            }
            if (result.isVisible) {
              visibleOptionsCount += 1;
            }
            if (!hasExactOption) {
              hasExactOption = result.hasExactOption;
            }
          });
          if (this.allowNewOption) {
            if (searchValue && !hasExactOption) {
              this.setNewOption();
              visibleOptionsCount += 1;
            } else {
              this.removeNewOption();
            }
          }
          this.visibleOptionsCount = visibleOptionsCount;
          this.afterSetVisibleOptionsCount();
        }
      }, {
        key: "setOptionProp",
        value: function setOptionProp(index, key, value) {
          if (!this.options[index]) {
            return;
          }
          this.options[index][key] = value;
        }
      }, {
        key: "setOptionsHeight",
        value: function setOptionsHeight() {
          this.$optionsList.style.height = "".concat(this.optionHeight * this.visibleOptionsCount, "px");
        }
      }, {
        key: "setOptionsContainerHeight",
        value: function setOptionsContainerHeight(reset) {
          var optionsHeight;
          if (reset) {
            if (this.showAsPopup) {
              this.optionsCount = this.getOptionsCount();
              this.halfOptionsCount = Math.ceil(this.optionsCount / 2);
              optionsHeight = this.getOptionsHeight();
              this.optionsHeight = optionsHeight;
            }
          } else {
            optionsHeight = this.optionsHeight;
            if (this.keepAlwaysOpen) {
              DomUtils.setStyle(this.$noOptions, "height", optionsHeight);
              DomUtils.setStyle(this.$noSearchResults, "height", optionsHeight);
            }
          }
          DomUtils.setStyle(this.$optionsContainer, "max-height", optionsHeight);
          this.afterSetOptionsContainerHeight(reset);
        }
      }, {
        key: "setNewOption",
        value: function setNewOption(newValue) {
          var value = newValue || this.searchValueOriginal.trim();
          if (!value) {
            return;
          }
          var newOption = this.getNewOption();
          if (newOption) {
            var newIndex = newOption.index;
            this.setOptionProp(newIndex, "value", this.secureText(value));
            this.setOptionProp(newIndex, "label", this.secureText(value));
          } else {
            var data2 = {
              value,
              label: value
            };
            if (newValue) {
              data2.isNew = true;
              this.newValues.push(value);
            } else {
              data2.isCurrentNew = true;
            }
            this.addOption(data2);
          }
        }
      }, {
        key: "setSelectedProp",
        value: function setSelectedProp() {
          var valuesMapping = {};
          this.selectedValues.forEach(function(d) {
            valuesMapping[d] = true;
          });
          this.options.forEach(function(d) {
            if (valuesMapping[d.value] === true) {
              d.isSelected = true;
            }
          });
        }
      }, {
        key: "setNewOptionsFromValue",
        value: function setNewOptionsFromValue(values) {
          if (!values) {
            return;
          }
          var setNewOption = this.setNewOption.bind(this);
          var availableValuesMapping = {};
          this.options.forEach(function(d) {
            availableValuesMapping[d.value] = true;
          });
          values.forEach(function(d) {
            if (d && availableValuesMapping[d] !== true) {
              setNewOption(d);
            }
          });
        }
      }, {
        key: "setDropboxWrapperWidth",
        value: function setDropboxWrapperWidth() {
          if (this.showAsPopup) {
            return;
          }
          var width = this.dropboxWidth || "".concat(this.$wrapper.offsetWidth, "px");
          DomUtils.setStyle(this.$dropboxContainer, "max-width", width);
        }
      }, {
        key: "setEleStyles",
        value: function setEleStyles() {
          var maxWidth = this.maxWidth;
          var styles = {};
          if (maxWidth) {
            styles["max-width"] = maxWidth;
          }
          DomUtils.setStyles(this.$ele, styles);
        }
      }, {
        key: "setDropboxStyles",
        value: function setDropboxStyles() {
          var dropboxWidth = this.dropboxWidth;
          var styles = {};
          var containerStyles = {
            "z-index": this.zIndex
          };
          if (dropboxWidth) {
            if (this.showAsPopup) {
              styles["max-width"] = dropboxWidth;
            } else {
              containerStyles.width = dropboxWidth;
            }
          }
          DomUtils.setStyles(this.$dropboxContainer, containerStyles);
          DomUtils.setStyles(this.$dropbox, styles);
        }
      }, {
        key: "setOptionAttr",
        value: function setOptionAttr() {
          var $visibleOptions = this.$visibleOptions;
          var options = this.options;
          var optionHeight = "".concat(this.optionHeight, "px");
          var setStyle = DomUtils.setStyle, getData = DomUtils.getData, setData = DomUtils.setData;
          if ($visibleOptions && $visibleOptions.length) {
            $visibleOptions.forEach(function($option) {
              var optionDetails = options[getData($option, "index")];
              setStyle($option, "height", optionHeight);
              setData($option, "value", optionDetails.value);
            });
          }
        }
      }, {
        key: "setValueTagAttr",
        value: function setValueTagAttr() {
          var $valueTags = this.$valueTags;
          if (!$valueTags || !$valueTags.length) {
            return;
          }
          var getData = DomUtils.getData, setData = DomUtils.setData;
          var options = this.options;
          $valueTags.forEach(function($valueTag) {
            var index = getData($valueTag, "index");
            if (typeof index !== "undefined") {
              var optionDetails = options[index];
              setData($valueTag, "value", optionDetails.value);
            }
          });
        }
      }, {
        key: "setScrollTop",
        value: function setScrollTop() {
          var selectedValues = this.selectedValues;
          if (this.showSelectedOptionsFirst || !this.focusSelectedOptionOnOpen || selectedValues.length === 0) {
            return;
          }
          var valuesMapping = {};
          var selectedOptionIndex;
          selectedValues.forEach(function(d) {
            valuesMapping[d] = true;
          });
          this.options.some(function(d) {
            if (valuesMapping[d.value]) {
              selectedOptionIndex = d.visibleIndex;
              return true;
            }
            return false;
          });
          if (selectedOptionIndex) {
            this.$optionsContainer.scrollTop = this.optionHeight * selectedOptionIndex;
          }
        }
        /** set methods - end */
        /** get methods - start */
      }, {
        key: "getVisibleOptions",
        value: function getVisibleOptions() {
          return this.visibleOptions || [];
        }
      }, {
        key: "getValue",
        value: function getValue() {
          var value;
          if (this.multiple) {
            if (this.useGroupValue) {
              value = this.getGroupValue();
            } else {
              value = this.selectedValues;
            }
          } else {
            value = this.selectedValues[0] || "";
          }
          return value;
        }
      }, {
        key: "getGroupValue",
        value: function getGroupValue() {
          var selectedValues = [];
          var selectedGroups = {};
          this.options.forEach(function(d) {
            if (!d.isSelected) {
              return;
            }
            var value = d.value;
            if (d.isGroupTitle) {
              if (value) {
                selectedGroups[d.index] = true;
                selectedValues.push(value);
              }
            } else if (selectedGroups[d.groupIndex] !== true) {
              selectedValues.push(value);
            }
          });
          return selectedValues;
        }
      }, {
        key: "getInputValue",
        value: function getInputValue2(preparedValue) {
          var value = preparedValue;
          if (value && value.length) {
            if (this.setValueAsArray && this.multiple) {
              value = JSON.stringify(value);
            }
          } else {
            value = this.emptyValue;
          }
          return value;
        }
      }, {
        key: "getFirstVisibleOptionIndex",
        value: function getFirstVisibleOptionIndex() {
          return Math.ceil(this.$optionsContainer.scrollTop / this.optionHeight);
        }
      }, {
        key: "getVisibleStartIndex",
        value: function getVisibleStartIndex() {
          var firstVisibleOptionIndex = this.getFirstVisibleOptionIndex();
          var startIndex = firstVisibleOptionIndex - this.halfOptionsCount;
          if (startIndex < 0) {
            startIndex = 0;
          }
          return startIndex;
        }
      }, {
        key: "getTooltipAttrText",
        value: function getTooltipAttrText(text) {
          var ellipsisOnly = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          var allowHtml = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
          var tootltipText = Utils.containsHTML(text) ? Utils.replaceDoubleQuotesWithHTML(text) : text;
          var data2 = {
            "data-tooltip": tootltipText || "",
            "data-tooltip-enter-delay": this.tooltipEnterDelay,
            "data-tooltip-z-index": this.zIndex,
            "data-tooltip-font-size": this.tooltipFontSize,
            "data-tooltip-alignment": this.tooltipAlignment,
            "data-tooltip-max-width": this.tooltipMaxWidth,
            "data-tooltip-ellipsis-only": ellipsisOnly,
            "data-tooltip-allow-html": allowHtml
          };
          return DomUtils.getAttributesText(data2);
        }
        /**
         * @param {any} data
         * @returns {any}
         */
      }, {
        key: "getOptionObj",
        value: function getOptionObj(data2) {
          if (!data2) {
            return void 0;
          }
          var getString = Utils.getString;
          var secureText = this.secureText.bind(this);
          return {
            index: data2.index,
            value: secureText(getString(data2.value)),
            label: secureText(getString(data2.label)),
            description: secureText(getString(data2.description)),
            alias: this.getAlias(data2.alias),
            isCurrentNew: data2.isCurrentNew || false,
            isNew: data2.isNew || false,
            isVisible: true
          };
        }
      }, {
        key: "getNewOption",
        value: function getNewOption() {
          var lastOption = this.options[this.lastOptionIndex];
          if (!lastOption || !lastOption.isCurrentNew) {
            return void 0;
          }
          return lastOption;
        }
      }, {
        key: "getOptionIndex",
        value: function getOptionIndex(value) {
          var index;
          this.options.some(function(d) {
            if (d.value === value) {
              index = d.index;
              return true;
            }
            return false;
          });
          return index;
        }
      }, {
        key: "getNewValue",
        value: function getNewValue() {
          var valuesMapping = {};
          this.newValues.forEach(function(d) {
            valuesMapping[d] = true;
          });
          var result = this.selectedValues.filter(function(d) {
            return valuesMapping[d] === true;
          });
          return this.multiple ? result : result[0];
        }
      }, {
        key: "getAlias",
        value: function getAlias(alias) {
          var result = alias;
          if (result) {
            if (Array.isArray(result)) {
              result = result.join(",");
            } else {
              result = result.toString().trim();
            }
            result = result.toLowerCase();
          }
          return result || "";
        }
      }, {
        key: "getDisplayValue",
        value: function getDisplayValue() {
          var displayValues = [];
          this.options.forEach(function(d) {
            if (d.isSelected) {
              displayValues.push(d.label);
            }
          });
          return this.multiple ? displayValues : displayValues[0] || "";
        }
      }, {
        key: "getSelectedOptions",
        value: function getSelectedOptions() {
          var _ref3 = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, _ref3$fullDetails = _ref3.fullDetails, fullDetails = _ref3$fullDetails === void 0 ? false : _ref3$fullDetails, _ref3$keepSelectionOr = _ref3.keepSelectionOrder, keepSelectionOrder = _ref3$keepSelectionOr === void 0 ? false : _ref3$keepSelectionOr;
          var valueKey = this.valueKey, labelKey = this.labelKey, selectedValues = this.selectedValues;
          var selectedOptions = [];
          this.options.forEach(function(d) {
            if (d.isSelected && !d.isGroupTitle) {
              if (fullDetails) {
                selectedOptions.push(d);
              } else {
                var _data;
                var data2 = (_data = {}, _defineProperty2(_data, valueKey, d.value), _defineProperty2(_data, labelKey, d.label), _data);
                if (d.isNew) {
                  data2.isNew = true;
                }
                if (d.customData) {
                  data2.customData = d.customData;
                }
                selectedOptions.push(data2);
              }
            }
          });
          if (keepSelectionOrder) {
            var valuesOrder = {};
            selectedValues.forEach(function(d, i) {
              valuesOrder[d] = i;
            });
            selectedOptions.sort(function(a, b) {
              return valuesOrder[a.value] - valuesOrder[b.value];
            });
          }
          return this.multiple || fullDetails ? selectedOptions : selectedOptions[0];
        }
      }, {
        key: "getDisabledOptions",
        value: function getDisabledOptions() {
          var valueKey = this.valueKey, labelKey = this.labelKey, disabledOptions = this.disabledOptions;
          var disabledOptionsValueMapping = {};
          var result = [];
          disabledOptions.forEach(function(value) {
            disabledOptionsValueMapping[value] = true;
          });
          this.options.forEach(function(_ref4) {
            var value = _ref4.value, label = _ref4.label;
            if (disabledOptionsValueMapping[value]) {
              var _result$push;
              result.push((_result$push = {}, _defineProperty2(_result$push, valueKey, value), _defineProperty2(_result$push, labelKey, label), _result$push));
            }
          });
          return result;
        }
      }, {
        key: "getVisibleOptionGroupsMapping",
        value: function getVisibleOptionGroupsMapping(searchValue) {
          var options = this.options;
          var result = {};
          var isOptionVisible = this.isOptionVisible.bind(this);
          options = this.structureOptionGroup(options);
          options.forEach(function(d) {
            result[d.index] = d.options.some(function(e) {
              return isOptionVisible({
                data: e,
                searchValue
              }).isVisible;
            });
          });
          return result;
        }
      }, {
        key: "getOptionsCount",
        value: function getOptionsCount(count) {
          var result;
          if (this.showAsPopup) {
            var availableHeight = window.innerHeight * 80 / 100 - dropboxCloseButtonFullHeight;
            if (this.hasSearchContainer) {
              availableHeight -= searchHeight;
            }
            result = Math.floor(availableHeight / this.optionHeight);
          } else {
            result = parseInt(count);
          }
          return result;
        }
      }, {
        key: "getOptionsHeight",
        value: function getOptionsHeight() {
          return "".concat(this.optionsCount * this.optionHeight, "px");
        }
        /** getting next/prev valid option element */
      }, {
        key: "getSibling",
        value: function getSibling($ele, direction) {
          var propName = direction === "next" ? "nextElementSibling" : "previousElementSibling";
          var $sibling = $ele;
          do {
            if ($sibling) {
              $sibling = $sibling[propName];
            }
          } while (DomUtils.hasClass($sibling, "disabled") || DomUtils.hasClass($sibling, "group-title"));
          return $sibling;
        }
      }, {
        key: "getUniqueId",
        value: function getUniqueId() {
          var uniqueId = Utils.getRandomInt(1e4);
          var isAlreadyUsed = document.querySelector("#vscomp-ele-wrapper-".concat(uniqueId));
          if (isAlreadyUsed) {
            return this.getUniqueId();
          }
          return uniqueId;
        }
        /** get methods - end */
      }, {
        key: "initDropboxPopover",
        value: function initDropboxPopover() {
          var data2 = {
            ele: this.$ele,
            target: this.$dropboxContainer,
            position: this.position,
            zIndex: this.zIndex,
            margin: 4,
            transitionDistance: 30,
            hideArrowIcon: true,
            disableManualAction: true,
            disableUpdatePosition: !this.hasDropboxWrapper,
            updatePositionThrottle: this.updatePositionThrottle,
            afterShow: this.afterShowPopper.bind(this),
            afterHide: this.afterHidePopper.bind(this)
          };
          this.dropboxPopover = new PopoverComponent(data2);
        }
      }, {
        key: "openDropbox",
        value: function openDropbox(isSilent) {
          this.isSilentOpen = isSilent;
          if (isSilent) {
            DomUtils.setStyle(this.$dropboxContainer, "display", "inline-flex");
          } else {
            DomUtils.dispatchEvent(this.$ele, "beforeOpen");
            DomUtils.setAria(this.$wrapper, "expanded", true);
          }
          this.setDropboxWrapperWidth();
          DomUtils.removeClass(this.$allWrappers, "closed");
          DomUtils.changeTabIndex(this.$allWrappers, 0);
          if (this.dropboxPopover && !isSilent) {
            this.dropboxPopover.show();
          } else {
            this.afterShowPopper();
          }
        }
      }, {
        key: "afterShowPopper",
        value: function afterShowPopper() {
          var isSilent = this.isSilentOpen;
          this.isSilentOpen = false;
          if (!isSilent) {
            this.moveSelectedOptionsFirst();
            this.setScrollTop();
            DomUtils.addClass(this.$allWrappers, "focused");
            if (this.showAsPopup) {
              DomUtils.addClass(this.$body, "vscomp-popup-active");
              this.isPopupActive = true;
            } else {
              this.focusElementOnOpen();
            }
            DomUtils.dispatchEvent(this.$ele, "afterOpen");
          }
        }
      }, {
        key: "closeDropbox",
        value: function closeDropbox(isSilent) {
          this.isSilentClose = isSilent;
          if (this.keepAlwaysOpen) {
            this.removeOptionFocus();
            return;
          }
          if (isSilent) {
            DomUtils.setStyle(this.$dropboxContainer, "display", "");
          } else {
            DomUtils.dispatchEvent(this.$ele, "beforeClose");
            DomUtils.setAria(this.$wrapper, "expanded", false);
            DomUtils.setAria(this.$wrapper, "activedescendant", "");
          }
          this.$wrapper.focus();
          if (this.dropboxPopover && !isSilent) {
            this.dropboxPopover.hide();
          } else {
            this.afterHidePopper();
          }
        }
      }, {
        key: "afterHidePopper",
        value: function afterHidePopper() {
          var isSilent = this.isSilentClose;
          this.isSilentClose = false;
          DomUtils.removeClass(this.$allWrappers, "focused");
          this.removeOptionFocus();
          if (!isSilent && this.isPopupActive) {
            DomUtils.removeClass(this.$body, "vscomp-popup-active");
            this.isPopupActive = false;
          }
          DomUtils.addClass(this.$allWrappers, "closed");
          if (!isSilent) {
            DomUtils.dispatchEvent(this.$ele, "afterClose");
            if (this.initialSelectedValue && this.initialSelectedValue.length === 0 || this.selectedValues.length > 0) {
              this.focus();
            }
          }
        }
      }, {
        key: "moveSelectedOptionsFirst",
        value: function moveSelectedOptionsFirst() {
          if (!this.showSelectedOptionsFirst) {
            return;
          }
          this.setSortedOptions();
          if (!this.$optionsContainer.scrollTop || !this.selectedValues.length) {
            this.setVisibleOptions();
          } else {
            this.scrollToTop();
          }
        }
      }, {
        key: "toggleDropbox",
        value: function toggleDropbox() {
          if (this.isOpened()) {
            this.closeDropbox();
          } else {
            this.openDropbox();
          }
        }
      }, {
        key: "updatePosition",
        value: function updatePosition() {
          if (!this.dropboxPopover || !this.isOpened()) {
            return;
          }
          this.$ele.updatePosition();
        }
      }, {
        key: "isOpened",
        value: function isOpened() {
          return !DomUtils.hasClass(this.$wrapper, "closed");
        }
      }, {
        key: "focusSearchInput",
        value: function focusSearchInput() {
          var $ele = this.$searchInput;
          if ($ele) {
            $ele.focus();
          }
        }
      }, {
        key: "focusElementOnOpen",
        value: function focusElementOnOpen() {
          var $ele = this.$searchInput;
          var hasNoOptions = !this.options.length && !this.hasServerSearch;
          if ($ele) {
            if (hasNoOptions && !this.allowNewOption) {
              DomUtils.setAttr($ele, "disabled", "");
              this.$noOptions.focus();
            } else {
              $ele.removeAttribute("disabled");
              $ele.focus();
            }
          } else {
            var $focusableEle = this.$dropbox.querySelector('[tabindex="0"]');
            var optIndex = DomUtils.getData($focusableEle, "index");
            if (optIndex !== void 0) {
              this.focusOption({
                direction: "next"
              });
            } else if ($focusableEle) {
              $focusableEle.focus();
            } else {
              this.focusFirstVisibleOption();
            }
          }
        }
      }, {
        key: "focusFirstVisibleOption",
        value: function focusFirstVisibleOption() {
          var $focusableEle = this.$optionsContainer.querySelector("[data-index='".concat(this.getFirstVisibleOptionIndex(), "']"));
          if ($focusableEle) {
            if (DomUtils.hasClass($focusableEle, "group-title")) {
              $focusableEle = this.getSibling($focusableEle, "next");
            }
            DomUtils.setAttr($focusableEle, "tabindex", "0");
            this.$optionsContainer.scrollTop = this.optionHeight * this.getFirstVisibleOptionIndex();
            this.focusOption({
              focusFirst: true
            });
            $focusableEle.focus();
          } else {
            $focusableEle = this.$dropbox.querySelector('[tabindex="0"]');
            if ($focusableEle) {
              $focusableEle.focus();
            }
          }
        }
      }, {
        key: "focusOption",
        value: function focusOption() {
          var _ref5 = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, direction = _ref5.direction, $option = _ref5.$option, focusFirst = _ref5.focusFirst;
          var $focusedEle = this.$dropboxContainer.querySelector(".vscomp-option.focused");
          var $newFocusedEle;
          if ($option) {
            $newFocusedEle = $option;
          } else if (!$focusedEle || focusFirst) {
            var firstVisibleOptionIndex = this.getFirstVisibleOptionIndex();
            $newFocusedEle = this.$dropboxContainer.querySelector('.vscomp-option[data-visible-index="'.concat(firstVisibleOptionIndex, '"]'));
            if (DomUtils.hasClass($newFocusedEle, "disabled") || DomUtils.hasClass($newFocusedEle, "group-title")) {
              $newFocusedEle = this.getSibling($newFocusedEle, "next");
            }
          } else {
            $newFocusedEle = this.getSibling($focusedEle, direction);
          }
          if ($newFocusedEle && $newFocusedEle !== $focusedEle) {
            if ($focusedEle) {
              this.toggleOptionFocusedState($focusedEle, false);
            }
            this.toggleOptionFocusedState($newFocusedEle, true);
            this.toggleFocusedProp(DomUtils.getData($newFocusedEle, "index"), true);
            this.moveFocusedOptionToView($newFocusedEle);
          }
        }
      }, {
        key: "moveFocusedOptionToView",
        value: function moveFocusedOptionToView($ele) {
          var $focusedEle = $ele || this.$dropboxContainer.querySelector(".vscomp-option.focused");
          if (!$focusedEle) {
            return;
          }
          var newScrollTop;
          var containerRect = this.$optionsContainer.getBoundingClientRect();
          var optionRect = $focusedEle.getBoundingClientRect();
          var containerTop = containerRect.top;
          var containerBottom = containerRect.bottom;
          var containerHeight = containerRect.height;
          var optionTop = optionRect.top;
          var optionBottom = optionRect.bottom;
          var optionHeight = optionRect.height;
          var optionOffsetTop = $focusedEle.offsetTop;
          var optionsTop = DomUtils.getData(this.$options, "top", "number");
          if (containerTop > optionTop) {
            newScrollTop = optionOffsetTop + optionsTop;
          } else if (containerBottom < optionBottom) {
            newScrollTop = optionOffsetTop - containerHeight + optionHeight + optionsTop;
          }
          if (newScrollTop !== void 0) {
            this.$optionsContainer.scrollTop = newScrollTop;
          }
        }
      }, {
        key: "removeOptionFocus",
        value: function removeOptionFocus() {
          var $focusedEle = this.$dropboxContainer.querySelector(".vscomp-option.focused");
          if (!$focusedEle) {
            return;
          }
          this.toggleOptionFocusedState($focusedEle, false);
          this.toggleFocusedProp(null);
        }
      }, {
        key: "selectOption",
        value: function selectOption($ele) {
          var _ref6 = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, event = _ref6.event;
          if (!$ele) {
            return;
          }
          var isAdding = !DomUtils.hasClass($ele, "selected");
          if (isAdding) {
            if (this.multiple && this.isMaxValuesSelected) {
              return;
            }
          } else if (!this.multiple) {
            this.closeDropbox();
            return;
          }
          var selectedValues = this.selectedValues;
          var selectedValue = DomUtils.getData($ele, "value");
          var selectedIndex = DomUtils.getData($ele, "index", "number");
          var isNewOption = DomUtils.hasClass($ele, "current-new");
          var shouldSelectRange = false;
          var lastSelectedOptionIndex = this.lastSelectedOptionIndex;
          this.lastSelectedOptionIndex = null;
          this.toggleSelectedProp(selectedIndex, isAdding);
          if (isAdding) {
            if (this.multiple) {
              selectedValues.push(selectedValue);
              this.toggleAllOptionsClass();
              this.toggleGroupOptionsParent($ele);
              if (event && event.shiftKey) {
                shouldSelectRange = true;
              }
            } else {
              if (selectedValues.length) {
                this.toggleSelectedProp(this.getOptionIndex(selectedValues[0]), false);
              }
              selectedValues = [selectedValue];
              var $prevSelectedOption = this.$dropboxContainer.querySelector(".vscomp-option.selected");
              if ($prevSelectedOption) {
                this.toggleOptionSelectedState($prevSelectedOption, false);
              }
              this.closeDropbox();
              if (!isNewOption) {
                this.setSearchValue("");
              }
            }
            this.lastSelectedOptionIndex = selectedIndex;
            this.toggleOptionSelectedState($ele);
          } else if (this.multiple) {
            this.toggleOptionSelectedState($ele);
            Utils.removeItemFromArray(selectedValues, selectedValue);
            this.toggleAllOptionsClass(false);
            this.toggleGroupOptionsParent($ele, false);
          }
          if (isNewOption) {
            this.beforeSelectNewValue();
          }
          this.setValue(selectedValues);
          if (shouldSelectRange) {
            this.selectRangeOptions(lastSelectedOptionIndex, selectedIndex);
          }
        }
      }, {
        key: "selectFocusedOption",
        value: function selectFocusedOption() {
          this.selectOption(this.$dropboxContainer.querySelector(".vscomp-option.focused"));
        }
      }, {
        key: "selectRangeOptions",
        value: function selectRangeOptions(lastSelectedOptionIndex, selectedIndex) {
          var _this11 = this;
          if (typeof lastSelectedOptionIndex !== "number" || this.maxValues) {
            return;
          }
          var selectedValues = this.selectedValues, hasOptionGroup = this.hasOptionGroup;
          var groupIndexes = {};
          var startIndex;
          var endIndex;
          if (lastSelectedOptionIndex < selectedIndex) {
            startIndex = lastSelectedOptionIndex;
            endIndex = selectedIndex;
          } else {
            startIndex = selectedIndex;
            endIndex = lastSelectedOptionIndex;
          }
          this.options.forEach(function(d) {
            if (d.isDisabled || d.isGroupTitle || !d.isVisible || d.isSelected) {
              return;
            }
            var index = d.index;
            if (index > startIndex && index < endIndex) {
              if (hasOptionGroup) {
                var groupIndex = d.groupIndex;
                if (typeof groupIndex === "number") {
                  groupIndexes[groupIndex] = true;
                }
              }
              d.isSelected = true;
              selectedValues.push(d.value);
            }
          });
          this.toggleAllOptionsClass();
          this.setValue(selectedValues);
          groupIndexes = Object.keys(groupIndexes);
          if (groupIndexes.length) {
            var toggleGroupTitleProp = this.toggleGroupTitleProp.bind(this);
            groupIndexes.forEach(function(i) {
              toggleGroupTitleProp(parseInt(i));
            });
          }
          setTimeout(function() {
            _this11.renderOptions();
          }, 0);
        }
      }, {
        key: "toggleAllOptions",
        value: function toggleAllOptions(selectAll) {
          if (!this.multiple || this.disableSelectAll) {
            return;
          }
          var selectingAll = typeof isSelected === "boolean" ? selectAll : !DomUtils.hasClass(this.$toggleAllCheckbox, "checked");
          var selectedValues = [];
          var selectAllOnlyVisible = this.selectAllOnlyVisible;
          this.options.forEach(function(d) {
            var option = d;
            if (option.isDisabled || option.isCurrentNew) {
              return;
            }
            var isVisible = option.isVisible, isSelected2 = option.isSelected;
            if (
              /** when unselecting all, selectAllOnlyVisible feature disabled or visible items or already unselected items */
              !selectingAll && (!selectAllOnlyVisible || isVisible || !isSelected2) || /** when selecting all, selectAllOnlyVisible feature enabled and hidden items those are not already selected */
              selectingAll && selectAllOnlyVisible && !isVisible && !isSelected2
            ) {
              option.isSelected = false;
            } else {
              option.isSelected = true;
              if (!option.isGroupTitle) {
                selectedValues.push(option.value);
              }
            }
          });
          this.toggleAllOptionsClass(selectingAll);
          this.setValue(selectedValues);
          this.renderOptions();
        }
      }, {
        key: "toggleAllOptionsClass",
        value: function toggleAllOptionsClass(isAllSelected) {
          if (!this.multiple) {
            return;
          }
          var valuePassed = typeof isAllSelected === "boolean";
          var isAllVisibleSelected = false;
          if (!valuePassed) {
            isAllSelected = this.isAllOptionsSelected();
          }
          if (!isAllSelected && this.selectAllOnlyVisible && this.searchValue !== "" && (this.visibleOptionsCount > 0 || this.searchValue === "")) {
            isAllVisibleSelected = this.isAllOptionsSelected(true);
          }
          DomUtils.toggleClass(this.$toggleAllCheckbox, "checked", isAllSelected || isAllVisibleSelected);
          this.isAllSelected = isAllSelected;
        }
      }, {
        key: "isAllOptionsSelected",
        value: function isAllOptionsSelected(visibleOnly) {
          var isAllSelected = false;
          if (this.options.length && this.selectedValues.length) {
            isAllSelected = !this.options.some(
              /**
               * stop looping if any option is not selected
               * for selectAllOnlyVisible case hidden option need not to be selected
               */
              function(d) {
                return !d.isSelected && !d.isDisabled && !d.isGroupTitle && (!visibleOnly || d.isVisible);
              }
            );
          }
          return isAllSelected;
        }
      }, {
        key: "isAllGroupOptionsSelected",
        value: function isAllGroupOptionsSelected(groupIndex) {
          var isAllSelected = false;
          if (this.options.length) {
            isAllSelected = !this.options.some(function(d) {
              return !d.isSelected && !d.isDisabled && !d.isGroupTitle && d.groupIndex === groupIndex;
            });
          }
          return isAllSelected;
        }
      }, {
        key: "toggleGroupOptionsParent",
        value: function toggleGroupOptionsParent($option, isSelected2) {
          if (!this.hasOptionGroup || this.disableOptionGroupCheckbox || !$option) {
            return;
          }
          var groupIndex = DomUtils.getData($option, "groupIndex");
          if (groupIndex !== void 0) {
            groupIndex = parseInt(groupIndex);
          }
          var $group = this.$options.querySelector('.vscomp-option[data-index="'.concat(groupIndex, '"]'));
          var isAllSelected = typeof isSelected2 === "boolean" ? isSelected2 : this.isAllGroupOptionsSelected(groupIndex);
          this.toggleGroupTitleCheckbox($group, isAllSelected);
          this.toggleGroupTitleProp(groupIndex, isAllSelected);
        }
      }, {
        key: "toggleGroupTitleProp",
        value: function toggleGroupTitleProp(groupIndex, isSelected2) {
          var isAllSelected = typeof isSelected2 === "boolean" ? isSelected2 : this.isAllGroupOptionsSelected(groupIndex);
          this.toggleSelectedProp(groupIndex, isAllSelected);
        }
      }, {
        key: "toggleGroupOptions",
        value: function toggleGroupOptions($ele, isSelected2) {
          var _this12 = this;
          if (!this.hasOptionGroup || this.disableOptionGroupCheckbox || !$ele) {
            return;
          }
          var groupIndex = DomUtils.getData($ele, "index", "number");
          var selectedValues = this.selectedValues, selectAllOnlyVisible = this.selectAllOnlyVisible;
          var valuesMapping = {};
          var removeItemFromArray = Utils.removeItemFromArray;
          selectedValues.forEach(function(d) {
            valuesMapping[d] = true;
          });
          this.options.forEach(function(d) {
            if (d.isDisabled || d.groupIndex !== groupIndex) {
              return;
            }
            var value = d.value;
            if (!isSelected2 || selectAllOnlyVisible && !d.isVisible) {
              d.isSelected = false;
              if (valuesMapping[value]) {
                removeItemFromArray(selectedValues, value);
              }
            } else {
              d.isSelected = true;
              if (!valuesMapping[value]) {
                selectedValues.push(value);
              }
            }
          });
          this.toggleAllOptionsClass(isSelected2 ? null : false);
          this.setValue(selectedValues);
          setTimeout(function() {
            _this12.renderOptions();
          }, 0);
        }
      }, {
        key: "toggleGroupTitleCheckbox",
        value: function toggleGroupTitleCheckbox($ele, isSelected2) {
          if (!$ele) {
            return;
          }
          var selectedIndex = DomUtils.getData($ele, "index", "number");
          this.toggleSelectedProp(selectedIndex, isSelected2);
          this.toggleOptionSelectedState($ele, isSelected2);
        }
      }, {
        key: "toggleFocusedProp",
        value: function toggleFocusedProp(index) {
          var isFocused = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          if (this.focusedOptionIndex) {
            this.setOptionProp(this.focusedOptionIndex, "isFocused", false);
          }
          this.setOptionProp(index, "isFocused", isFocused);
          this.focusedOptionIndex = index;
        }
      }, {
        key: "toggleSelectedProp",
        value: function toggleSelectedProp(index) {
          var isSelected2 = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          this.setOptionProp(index, "isSelected", isSelected2);
        }
      }, {
        key: "scrollToTop",
        value: function scrollToTop() {
          var isClosed = !this.isOpened();
          if (isClosed) {
            this.openDropbox(true);
          }
          var scrollTop = this.$optionsContainer.scrollTop;
          if (scrollTop > 0) {
            this.$optionsContainer.scrollTop = 0;
          }
          if (isClosed) {
            this.closeDropbox(true);
          }
        }
      }, {
        key: "reset",
        value: function reset() {
          var formReset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
          var disableChangeEvent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          this.options.forEach(function(d) {
            d.isSelected = false;
          });
          this.beforeValueSet(true);
          this.setValue(null, {
            disableEvent: disableChangeEvent,
            disableValidation: formReset
          });
          this.afterValueSet();
          if (formReset) {
            DomUtils.removeClass(this.$allWrappers, "has-error");
          }
          DomUtils.dispatchEvent(this.$ele, "reset");
        }
      }, {
        key: "addOption",
        value: function addOption(data2, rerender) {
          if (!data2) {
            return;
          }
          this.lastOptionIndex += 1;
          var newOption = this.getOptionObj(_objectSpread(_objectSpread({}, data2), {}, {
            index: this.lastOptionIndex
          }));
          this.options.push(newOption);
          this.sortedOptions.push(newOption);
          if (rerender) {
            this.visibleOptionsCount += 1;
            this.afterSetOptions();
          }
        }
      }, {
        key: "removeOption",
        value: function removeOption(index) {
          if (!index && index !== 0) {
            return;
          }
          this.options.splice(index, 1);
          this.lastOptionIndex -= 1;
        }
      }, {
        key: "removeNewOption",
        value: function removeNewOption() {
          var newOption = this.getNewOption();
          if (newOption) {
            this.removeOption(newOption.index);
          }
        }
      }, {
        key: "sortOptions",
        value: function sortOptions(options) {
          return options.sort(function(a, b) {
            var aIsSelected = a.isSelected || a.isAnySelected;
            var bIsSelected = b.isSelected || b.isAnySelected;
            if (!aIsSelected && !bIsSelected) {
              return 0;
            }
            if (aIsSelected && (!bIsSelected || a.index < b.index)) {
              return -1;
            }
            return 1;
          });
        }
      }, {
        key: "sortOptionsGroup",
        value: function sortOptionsGroup(options) {
          var sortOptions = this.sortOptions.bind(this);
          var structuredOptions = this.structureOptionGroup(options);
          structuredOptions.forEach(function(d) {
            var childOptions = d.options;
            d.isAnySelected = childOptions.some(function(e) {
              return e.isSelected;
            });
            if (d.isAnySelected) {
              sortOptions(childOptions);
            }
          });
          sortOptions(structuredOptions);
          return this.destructureOptionGroup(structuredOptions);
        }
      }, {
        key: "isOptionVisible",
        value: function isOptionVisible(_ref7) {
          var data2 = _ref7.data, searchValue = _ref7.searchValue, hasExactOption = _ref7.hasExactOption, visibleOptionGroupsMapping = _ref7.visibleOptionGroupsMapping, searchGroup = _ref7.searchGroup, searchByStartsWith = _ref7.searchByStartsWith;
          var value = data2.value.toLowerCase();
          var label = this.searchNormalize ? data2.labelNormalized : data2.label.toLowerCase();
          var description = data2.description, alias = data2.alias;
          var isVisible = searchByStartsWith ? label.startsWith(searchValue) : label.includes(searchValue);
          if (data2.isGroupTitle && (!searchGroup || !isVisible)) {
            isVisible = visibleOptionGroupsMapping[data2.index];
          }
          if (!searchByStartsWith && alias && !isVisible) {
            isVisible = alias.includes(searchValue);
          }
          if (!searchByStartsWith && description && !isVisible) {
            isVisible = description.toLowerCase().includes(searchValue);
          }
          data2.isVisible = isVisible;
          if (!hasExactOption) {
            hasExactOption = label === searchValue || value === searchValue;
          }
          return {
            isVisible,
            hasExactOption
          };
        }
      }, {
        key: "structureOptionGroup",
        value: function structureOptionGroup(options) {
          var result = [];
          var childOptions = {};
          options.forEach(function(d) {
            if (d.isGroupTitle) {
              var childArray = [];
              d.options = childArray;
              childOptions[d.index] = childArray;
              result.push(d);
            }
          });
          options.forEach(function(d) {
            if (d.isGroupOption) {
              childOptions[d.groupIndex].push(d);
            }
          });
          return result;
        }
      }, {
        key: "destructureOptionGroup",
        value: function destructureOptionGroup(options) {
          var result = [];
          options.forEach(function(d) {
            result.push(d);
            result = result.concat(d.options);
          });
          return result;
        }
      }, {
        key: "serverSearch",
        value: function serverSearch() {
          DomUtils.removeClass(this.$allWrappers, "has-no-search-results");
          DomUtils.addClass(this.$allWrappers, "server-searching");
          this.setSelectedOptions();
          this.onServerSearch(this.searchValue, this);
        }
      }, {
        key: "removeValue",
        value: function removeValue($ele) {
          var selectedValues = this.selectedValues;
          var selectedValue = DomUtils.getData($ele, "value");
          Utils.removeItemFromArray(selectedValues, selectedValue);
          this.setValueMethod(selectedValues);
        }
      }, {
        key: "focus",
        value: function focus() {
          this.$wrapper.focus();
        }
      }, {
        key: "enable",
        value: function enable() {
          this.$ele.disabled = false;
          this.$ele.removeAttribute("disabled");
          this.$hiddenInput.removeAttribute("disabled");
          DomUtils.setAria(this.$wrapper, "disabled", false);
          DomUtils.changeTabIndex(this.$wrapper, 0);
        }
      }, {
        key: "disable",
        value: function disable() {
          this.$ele.disabled = true;
          this.$ele.setAttribute("disabled", "");
          this.$hiddenInput.setAttribute("disabled", "");
          DomUtils.setAria(this.$wrapper, "disabled", true);
          DomUtils.changeTabIndex(this.$wrapper, -1);
          this.$wrapper.blur();
        }
      }, {
        key: "validate",
        value: function validate() {
          if (this.disableValidation) {
            return true;
          }
          var hasError = false;
          var selectedValues = this.selectedValues, minValues = this.minValues;
          if (this.required && (Utils.isEmpty(selectedValues) || /** required minium options not selected */
          this.multiple && minValues && selectedValues.length < minValues)) {
            hasError = true;
          }
          DomUtils.toggleClass(this.$allWrappers, "has-error", hasError);
          return !hasError;
        }
      }, {
        key: "destroy",
        value: function destroy() {
          var $ele = this.$ele;
          $ele.virtualSelect = void 0;
          $ele.value = void 0;
          $ele.innerHTML = "";
          if (this.hasDropboxWrapper) {
            this.$dropboxWrapper.remove();
            this.removeEvents();
          }
          if (this.dropboxPopover) {
            this.dropboxPopover.destroy();
          }
          DomUtils.removeClass($ele, "vscomp-ele");
        }
      }, {
        key: "createSecureTextElements",
        value: function createSecureTextElements() {
          this.$secureDiv = document.createElement("div");
          this.$secureText = document.createTextNode("");
          this.$secureDiv.appendChild(this.$secureText);
        }
      }, {
        key: "secureText",
        value: function secureText(text) {
          if (!text || !this.enableSecureText) {
            return text;
          }
          this.$secureText.nodeValue = text;
          return this.$secureDiv.innerHTML;
        }
      }, {
        key: "toggleRequired",
        value: function toggleRequired(isRequired) {
          this.required = Utils.convertToBoolean(isRequired);
          this.$ele.required = this.required;
        }
      }, {
        key: "toggleOptionSelectedState",
        value: function toggleOptionSelectedState($ele, value) {
          var isSelected2 = value;
          if (typeof isSelected2 === "undefined") {
            isSelected2 = !DomUtils.hasClass($ele, "selected");
          }
          DomUtils.toggleClass($ele, "selected", isSelected2);
          DomUtils.setAria($ele, "selected", isSelected2);
        }
      }, {
        key: "toggleOptionFocusedState",
        value: function toggleOptionFocusedState($ele, isFocused) {
          if (!$ele) {
            return;
          }
          DomUtils.toggleClass($ele, "focused", isFocused);
          DomUtils.setAttr($ele, "tabindex", isFocused ? "0" : "-1");
          if (document.activeElement !== this.$searchInput) {
            $ele.focus();
          }
          if (isFocused) {
            DomUtils.setAria(this.$wrapper, "activedescendant", $ele.id);
          }
        }
        /** static methods - start */
      }], [{
        key: "init",
        value: function init(options) {
          var $eleArray = options.ele;
          if (!$eleArray) {
            return void 0;
          }
          var singleEle = false;
          if (typeof $eleArray === "string") {
            $eleArray = document.querySelectorAll($eleArray);
            var eleLength = $eleArray.length;
            if (eleLength === 0) {
              return void 0;
            }
            if (eleLength === 1) {
              singleEle = true;
            }
          }
          if ($eleArray.length === void 0 || $eleArray.forEach === void 0) {
            $eleArray = [$eleArray];
            singleEle = true;
          }
          var instances = [];
          $eleArray.forEach(function($ele) {
            if ($ele.virtualSelect) {
              instances.push($ele.virtualSelect);
              return;
            }
            options.ele = $ele;
            if ($ele.tagName === "SELECT") {
              VirtualSelect2.setPropsFromSelect(options);
            }
            instances.push(new VirtualSelect2(options));
          });
          return singleEle ? instances[0] : instances;
        }
      }, {
        key: "getAttrProps",
        value: function getAttrProps() {
          var convertPropToDataAttr = DomUtils.convertPropToDataAttr;
          var result = {};
          nativeProps.forEach(function(d) {
            result[d] = d;
          });
          dataProps.forEach(function(d) {
            result[convertPropToDataAttr(d)] = d;
          });
          return result;
        }
      }, {
        key: "setPropsFromSelect",
        value: function setPropsFromSelect(props) {
          var $ele = props.ele;
          var disabledOptions = [];
          var selectedValue = [];
          var getNativeOptions = function getNativeOptions2($container) {
            var options = [];
            var $options = Array.from($container.children);
            $options.forEach(function($option) {
              var value = $option.value;
              var option = {
                value
              };
              if ($option.tagName === "OPTGROUP") {
                option.label = $option.getAttribute("label");
                option.options = getNativeOptions2($option);
              } else {
                option.label = $option.innerHTML;
              }
              options.push(option);
              if ($option.disabled) {
                disabledOptions.push(value);
              }
              if ($option.selected) {
                selectedValue.push(value);
              }
            });
            return options;
          };
          var optionsList = getNativeOptions($ele);
          var $newEle = document.createElement("div");
          DomUtils.setAttrFromEle($ele, $newEle, Object.keys(attrPropsMapping), valueLessProps);
          $ele.parentNode.insertBefore($newEle, $ele);
          $ele.remove();
          props.ele = $newEle;
          props.options = optionsList;
          props.disabledOptions = disabledOptions;
          props.selectedValue = selectedValue;
        }
      }, {
        key: "onFormReset",
        value: function onFormReset(e) {
          var $form = e.target.closest("form");
          if (!$form) {
            return;
          }
          $form.querySelectorAll(".vscomp-ele-wrapper").forEach(function($ele) {
            $ele.parentElement.virtualSelect.reset(true);
          });
        }
      }, {
        key: "onFormSubmit",
        value: function onFormSubmit(e) {
          if (!VirtualSelect2.validate(e.target.closest("form"))) {
            e.preventDefault();
          }
        }
      }, {
        key: "validate",
        value: function validate($container) {
          if (!$container) {
            return true;
          }
          var hasError = false;
          $container.querySelectorAll(".vscomp-ele-wrapper").forEach(function($ele) {
            var result = $ele.parentElement.virtualSelect.validate();
            if (!hasError && !result) {
              hasError = true;
            }
          });
          return !hasError;
        }
      }, {
        key: "reset",
        value: function reset() {
          var formReset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
          var disableChangeEvent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
          this.virtualSelect.reset(formReset, disableChangeEvent);
        }
      }, {
        key: "setValueMethod",
        value: function setValueMethod() {
          var _this$virtualSelect;
          (_this$virtualSelect = this.virtualSelect).setValueMethod.apply(_this$virtualSelect, arguments);
        }
      }, {
        key: "setOptionsMethod",
        value: function setOptionsMethod() {
          var _this$virtualSelect2;
          (_this$virtualSelect2 = this.virtualSelect).setOptionsMethod.apply(_this$virtualSelect2, arguments);
        }
      }, {
        key: "setDisabledOptionsMethod",
        value: function setDisabledOptionsMethod() {
          var _this$virtualSelect3;
          (_this$virtualSelect3 = this.virtualSelect).setDisabledOptionsMethod.apply(_this$virtualSelect3, arguments);
        }
      }, {
        key: "setEnabledOptionsMethod",
        value: function setEnabledOptionsMethod() {
          var _this$virtualSelect4;
          (_this$virtualSelect4 = this.virtualSelect).setEnabledOptionsMethod.apply(_this$virtualSelect4, arguments);
        }
      }, {
        key: "toggleSelectAll",
        value: function toggleSelectAll(isSelected2) {
          this.virtualSelect.toggleAllOptions(isSelected2);
        }
      }, {
        key: "isAllSelected",
        value: function isAllSelected() {
          return this.virtualSelect.isAllSelected;
        }
      }, {
        key: "addOptionMethod",
        value: function addOptionMethod(data2) {
          this.virtualSelect.addOption(data2, true);
        }
      }, {
        key: "getNewValueMethod",
        value: function getNewValueMethod() {
          return this.virtualSelect.getNewValue();
        }
      }, {
        key: "getDisplayValueMethod",
        value: function getDisplayValueMethod() {
          return this.virtualSelect.getDisplayValue();
        }
      }, {
        key: "getSelectedOptionsMethod",
        value: function getSelectedOptionsMethod(params) {
          return this.virtualSelect.getSelectedOptions(params);
        }
      }, {
        key: "getDisabledOptionsMethod",
        value: function getDisabledOptionsMethod() {
          return this.virtualSelect.getDisabledOptions();
        }
      }, {
        key: "openMethod",
        value: function openMethod() {
          return this.virtualSelect.openDropbox();
        }
      }, {
        key: "closeMethod",
        value: function closeMethod() {
          return this.virtualSelect.closeDropbox();
        }
      }, {
        key: "focusMethod",
        value: function focusMethod() {
          return this.virtualSelect.focus();
        }
      }, {
        key: "enableMethod",
        value: function enableMethod() {
          return this.virtualSelect.enable();
        }
      }, {
        key: "disableMethod",
        value: function disableMethod() {
          return this.virtualSelect.disable();
        }
      }, {
        key: "destroyMethod",
        value: function destroyMethod() {
          return this.virtualSelect.destroy();
        }
      }, {
        key: "validateMethod",
        value: function validateMethod() {
          return this.virtualSelect.validate();
        }
      }, {
        key: "toggleRequiredMethod",
        value: function toggleRequiredMethod(isRequired) {
          return this.virtualSelect.toggleRequired(isRequired);
        }
      }, {
        key: "onResizeMethod",
        value: function onResizeMethod() {
          document.querySelectorAll(".vscomp-ele-wrapper").forEach(function($ele) {
            $ele.parentElement.virtualSelect.onResize();
          });
        }
        /** static methods - end */
      }]);
      return VirtualSelect2;
    }();
    document.addEventListener("reset", VirtualSelect.onFormReset);
    document.addEventListener("submit", VirtualSelect.onFormSubmit);
    window.addEventListener("resize", VirtualSelect.onResizeMethod);
    attrPropsMapping = VirtualSelect.getAttrProps();
    window.VirtualSelect = VirtualSelect;
    if (typeof NodeList !== "undefined" && NodeList.prototype && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
  }();
  !function() {
    /*!
     * Popover v1.0.13
     * https://sa-si-dev.github.io/popover
     * Licensed under MIT (https://github.com/sa-si-dev/popover/blob/master/LICENSE)
     */
    !function() {
      function e(e2) {
        return function(e3) {
          if (Array.isArray(e3)) return t(e3);
        }(e2) || function(e3) {
          if ("undefined" != typeof Symbol && null != e3[Symbol.iterator] || null != e3["@@iterator"]) return Array.from(e3);
        }(e2) || function(e3, o2) {
          if (e3) {
            if ("string" == typeof e3) return t(e3, o2);
            var i2 = Object.prototype.toString.call(e3).slice(8, -1);
            return "Object" === i2 && e3.constructor && (i2 = e3.constructor.name), "Map" === i2 || "Set" === i2 ? Array.from(e3) : "Arguments" === i2 || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(i2) ? t(e3, o2) : void 0;
          }
        }(e2) || function() {
          throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
        }();
      }
      function t(e2, t2) {
        (null == t2 || t2 > e2.length) && (t2 = e2.length);
        for (var o2 = 0, i2 = new Array(t2); o2 < t2; o2++) i2[o2] = e2[o2];
        return i2;
      }
      function o(e2, t2) {
        for (var o2 = 0; o2 < t2.length; o2++) {
          var i2 = t2[o2];
          i2.enumerable = i2.enumerable || false, i2.configurable = true, "value" in i2 && (i2.writable = true), Object.defineProperty(e2, i2.key, i2);
        }
      }
      var i = function() {
        function t2() {
          !function(e2, t3) {
            if (!(e2 instanceof t3)) throw new TypeError("Cannot call a class as a function");
          }(this, t2);
        }
        var i2, r2;
        return i2 = t2, r2 = [{ key: "addClass", value: function(o2, i3) {
          o2 && (i3 = i3.split(" "), t2.getElements(o2).forEach(function(t3) {
            var o3;
            (o3 = t3.classList).add.apply(o3, e(i3));
          }));
        } }, { key: "removeClass", value: function(o2, i3) {
          o2 && (i3 = i3.split(" "), t2.getElements(o2).forEach(function(t3) {
            var o3;
            (o3 = t3.classList).remove.apply(o3, e(i3));
          }));
        } }, { key: "getElements", value: function(e2) {
          if (e2) return void 0 === e2.forEach && (e2 = [e2]), e2;
        } }, { key: "getMoreVisibleSides", value: function(e2) {
          if (!e2) return {};
          var t3 = e2.getBoundingClientRect(), o2 = window.innerWidth, i3 = window.innerHeight, r3 = t3.left, n2 = t3.top;
          return { horizontal: r3 > o2 - r3 - t3.width ? "left" : "right", vertical: n2 > i3 - n2 - t3.height ? "top" : "bottom" };
        } }, { key: "getAbsoluteCoords", value: function(e2) {
          if (e2) {
            var t3 = e2.getBoundingClientRect(), o2 = window.pageXOffset, i3 = window.pageYOffset;
            return { width: t3.width, height: t3.height, top: t3.top + i3, right: t3.right + o2, bottom: t3.bottom + i3, left: t3.left + o2 };
          }
        } }, { key: "getCoords", value: function(e2) {
          return e2 ? e2.getBoundingClientRect() : {};
        } }, { key: "getData", value: function(e2, t3, o2) {
          if (e2) {
            var i3 = e2 ? e2.dataset[t3] : "";
            return "number" === o2 ? i3 = parseFloat(i3) || 0 : "true" === i3 ? i3 = true : "false" === i3 && (i3 = false), i3;
          }
        } }, { key: "setData", value: function(e2, t3, o2) {
          e2 && (e2.dataset[t3] = o2);
        } }, { key: "setStyle", value: function(e2, t3, o2) {
          e2 && (e2.style[t3] = o2);
        } }, { key: "show", value: function(e2) {
          var o2 = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "block";
          t2.setStyle(e2, "display", o2);
        } }, { key: "hide", value: function(e2) {
          t2.setStyle(e2, "display", "none");
        } }, { key: "getHideableParent", value: function(e2) {
          for (var t3, o2 = e2.parentElement; o2; ) {
            var i3 = getComputedStyle(o2).overflow;
            if (-1 !== i3.indexOf("scroll") || -1 !== i3.indexOf("auto")) {
              t3 = o2;
              break;
            }
            o2 = o2.parentElement;
          }
          return t3;
        } }], r2 && o(i2, r2), t2;
      }();
      function r(e2, t2) {
        for (var o2 = 0; o2 < t2.length; o2++) {
          var i2 = t2[o2];
          i2.enumerable = i2.enumerable || false, i2.configurable = true, "value" in i2 && (i2.writable = true), Object.defineProperty(e2, i2.key, i2);
        }
      }
      var n = ["top", "bottom", "left", "right"].map(function(e2) {
        return "position-".concat(e2);
      }), a = { top: "rotate(180deg)", left: "rotate(90deg)", right: "rotate(-90deg)" }, s = function() {
        function e2(t3) {
          !function(e3, t4) {
            if (!(e3 instanceof t4)) throw new TypeError("Cannot call a class as a function");
          }(this, e2);
          try {
            this.setProps(t3), this.init();
          } catch (e3) {
            console.warn("Couldn't initiate popper"), console.error(e3);
          }
        }
        var t2, o2;
        return t2 = e2, o2 = [{ key: "init", value: function() {
          var e3 = this.$popperEle;
          e3 && this.$triggerEle && (i.setStyle(e3, "zIndex", this.zIndex), this.setPosition());
        } }, { key: "setProps", value: function(e3) {
          var t3 = (e3 = this.setDefaultProps(e3)).position ? e3.position.toLowerCase() : "auto";
          if (this.$popperEle = e3.$popperEle, this.$triggerEle = e3.$triggerEle, this.$arrowEle = e3.$arrowEle, this.margin = parseFloat(e3.margin), this.offset = parseFloat(e3.offset), this.enterDelay = parseFloat(e3.enterDelay), this.exitDelay = parseFloat(e3.exitDelay), this.showDuration = parseFloat(e3.showDuration), this.hideDuration = parseFloat(e3.hideDuration), this.transitionDistance = parseFloat(e3.transitionDistance), this.zIndex = parseFloat(e3.zIndex), this.afterShowCallback = e3.afterShow, this.afterHideCallback = e3.afterHide, this.hasArrow = !!this.$arrowEle, -1 !== t3.indexOf(" ")) {
            var o3 = t3.split(" ");
            this.position = o3[0], this.secondaryPosition = o3[1];
          } else this.position = t3;
        } }, { key: "setDefaultProps", value: function(e3) {
          return Object.assign({ position: "auto", margin: 8, offset: 5, enterDelay: 0, exitDelay: 0, showDuration: 300, hideDuration: 200, transitionDistance: 10, zIndex: 1 }, e3);
        } }, { key: "setPosition", value: function() {
          i.show(this.$popperEle, "inline-flex");
          var e3, t3, o3, r2 = window.innerWidth, s2 = window.innerHeight, l = i.getAbsoluteCoords(this.$popperEle), u = i.getAbsoluteCoords(this.$triggerEle), p = l.width, h = l.height, c = l.top, f = l.right, v = l.bottom, d = l.left, y = u.width, m = u.height, g = u.top, b = u.right, w = u.bottom, E = u.left, k = g - c, $ = E - d, D = $, S = k, C = this.position, P = this.secondaryPosition, O = y / 2 - p / 2, T = m / 2 - h / 2, A = this.margin, x = this.transitionDistance, I = window.scrollY - c, H = s2 + I, j = window.scrollX - d, L = r2 + j, R = this.offset;
          R && (I += R, H -= R, j += R, L -= R), "auto" === C && (C = i.getMoreVisibleSides(this.$triggerEle).vertical);
          var z = { top: { top: S - h - A, left: D + O }, bottom: { top: S + m + A, left: D + O }, right: { top: S + T, left: D + y + A }, left: { top: S + T, left: D - p - A } }, F = z[C];
          if (S = F.top, D = F.left, P && ("top" === P ? S = k : "bottom" === P ? S = k + m - h : "left" === P ? D = $ : "right" === P && (D = $ + y - p)), D < j ? "left" === C ? o3 = "right" : D = j + d > b ? b - d : j : D + p > L && ("right" === C ? o3 = "left" : D = L + d < E ? E - f : L - p), S < I ? "top" === C ? o3 = "bottom" : S = I + c > w ? w - c : I : S + h > H && ("bottom" === C ? o3 = "top" : S = H + c < g ? g - v : H - h), o3) {
            var M = z[o3];
            "top" === (C = o3) || "bottom" === C ? S = M.top : "left" !== C && "right" !== C || (D = M.left);
          }
          "top" === C ? (e3 = S + x, t3 = D) : "right" === C ? (e3 = S, t3 = D - x) : "left" === C ? (e3 = S, t3 = D + x) : (e3 = S - x, t3 = D);
          var U = "translate3d(".concat(parseInt(t3), "px, ").concat(parseInt(e3), "px, 0)");
          if (i.setStyle(this.$popperEle, "transform", U), i.setData(this.$popperEle, "fromLeft", t3), i.setData(this.$popperEle, "fromTop", e3), i.setData(this.$popperEle, "top", S), i.setData(this.$popperEle, "left", D), i.removeClass(this.$popperEle, n.join(" ")), i.addClass(this.$popperEle, "position-".concat(C)), this.hasArrow) {
            var B = 0, q = 0, N = D + d, W = S + c, K = this.$arrowEle.offsetWidth / 2, V = a[C] || "";
            "top" === C || "bottom" === C ? (B = y / 2 + E - N) < K ? B = K : B > p - K && (B = p - K) : "left" !== C && "right" !== C || ((q = m / 2 + g - W) < K ? q = K : q > h - K && (q = h - K)), i.setStyle(this.$arrowEle, "transform", "translate3d(".concat(parseInt(B), "px, ").concat(parseInt(q), "px, 0) ").concat(V));
          }
          i.hide(this.$popperEle);
        } }, { key: "resetPosition", value: function() {
          i.setStyle(this.$popperEle, "transform", "none"), this.setPosition();
        } }, { key: "show", value: function() {
          var e3 = this, t3 = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}, o3 = t3.resetPosition, r2 = t3.data;
          clearTimeout(this.exitDelayTimeout), clearTimeout(this.hideDurationTimeout), o3 && this.resetPosition(), this.enterDelayTimeout = setTimeout(function() {
            var t4 = i.getData(e3.$popperEle, "left"), o4 = i.getData(e3.$popperEle, "top"), n2 = "translate3d(".concat(parseInt(t4), "px, ").concat(parseInt(o4), "px, 0)"), a2 = e3.showDuration;
            i.show(e3.$popperEle, "inline-flex"), i.getCoords(e3.$popperEle), i.setStyle(e3.$popperEle, "transitionDuration", a2 + "ms"), i.setStyle(e3.$popperEle, "transform", n2), i.setStyle(e3.$popperEle, "opacity", 1), e3.showDurationTimeout = setTimeout(function() {
              "function" == typeof e3.afterShowCallback && e3.afterShowCallback(r2);
            }, a2);
          }, this.enterDelay);
        } }, { key: "hide", value: function() {
          var e3 = this, t3 = (arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {}).data;
          clearTimeout(this.enterDelayTimeout), clearTimeout(this.showDurationTimeout), this.exitDelayTimeout = setTimeout(function() {
            if (e3.$popperEle) {
              var o3 = parseInt(i.getData(e3.$popperEle, "fromLeft")), r2 = parseInt(i.getData(e3.$popperEle, "fromTop")), n2 = "translate3d(".concat(o3, "px, ").concat(r2, "px, 0)"), a2 = e3.hideDuration;
              i.setStyle(e3.$popperEle, "transitionDuration", a2 + "ms"), i.setStyle(e3.$popperEle, "transform", n2), i.setStyle(e3.$popperEle, "opacity", 0), e3.hideDurationTimeout = setTimeout(function() {
                i.hide(e3.$popperEle), "function" == typeof e3.afterHideCallback && e3.afterHideCallback(t3);
              }, a2);
            }
          }, this.exitDelay);
        } }, { key: "updatePosition", value: function() {
          i.setStyle(this.$popperEle, "transitionDuration", "0ms"), this.resetPosition();
          var e3 = parseInt(i.getData(this.$popperEle, "left")), t3 = parseInt(i.getData(this.$popperEle, "top"));
          i.show(this.$popperEle, "inline-flex"), i.setStyle(this.$popperEle, "transform", "translate3d(".concat(e3, "px, ").concat(t3, "px, 0)"));
        } }], o2 && r(t2.prototype, o2), e2;
      }();
      window.PopperComponent = s;
    }(), function() {
      function e(t2) {
        return e = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(e2) {
          return typeof e2;
        } : function(e2) {
          return e2 && "function" == typeof Symbol && e2.constructor === Symbol && e2 !== Symbol.prototype ? "symbol" : typeof e2;
        }, e(t2);
      }
      function t(e2, t2) {
        for (var i2 = 0; i2 < t2.length; i2++) {
          var r2 = t2[i2];
          r2.enumerable = r2.enumerable || false, r2.configurable = true, "value" in r2 && (r2.writable = true), Object.defineProperty(e2, o(r2.key), r2);
        }
      }
      function o(t2) {
        var o2 = function(t3, o3) {
          if ("object" != e(t3) || !t3) return t3;
          var i2 = t3[Symbol.toPrimitive];
          if (void 0 !== i2) {
            var r2 = i2.call(t3, o3);
            if ("object" != e(r2)) return r2;
            throw new TypeError("@@toPrimitive must return a primitive value.");
          }
          return String(t3);
        }(t2, "string");
        return "symbol" == e(o2) ? o2 : o2 + "";
      }
      var i = function() {
        return e2 = function e3() {
          !function(e4, t2) {
            if (!(e4 instanceof t2)) throw new TypeError("Cannot call a class as a function");
          }(this, e3);
        }, i2 = [{ key: "convertToBoolean", value: function(e3) {
          return e3 = true === e3 || "true" === e3 || false !== e3 && "false" !== e3 && arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        } }, { key: "removeArrayEmpty", value: function(e3) {
          return Array.isArray(e3) && e3.length ? e3.filter(function(e4) {
            return !!e4;
          }) : [];
        } }, { key: "throttle", value: function(e3, t2) {
          var o3, i3 = 0;
          return function() {
            for (var r2 = arguments.length, n2 = new Array(r2), a2 = 0; a2 < r2; a2++) n2[a2] = arguments[a2];
            var s2 = (/* @__PURE__ */ new Date()).getTime(), l2 = t2 - (s2 - i3);
            clearTimeout(o3), l2 <= 0 ? (i3 = s2, e3.apply(void 0, n2)) : o3 = setTimeout(function() {
              e3.apply(void 0, n2);
            }, l2);
          };
        } }], (o2 = null) && t(e2.prototype, o2), i2 && t(e2, i2), Object.defineProperty(e2, "prototype", { writable: false }), e2;
        var e2, o2, i2;
      }();
      function r(e2) {
        return r = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(e3) {
          return typeof e3;
        } : function(e3) {
          return e3 && "function" == typeof Symbol && e3.constructor === Symbol && e3 !== Symbol.prototype ? "symbol" : typeof e3;
        }, r(e2);
      }
      function n(e2) {
        return function(e3) {
          if (Array.isArray(e3)) return a(e3);
        }(e2) || function(e3) {
          if ("undefined" != typeof Symbol && null != e3[Symbol.iterator] || null != e3["@@iterator"]) return Array.from(e3);
        }(e2) || function(e3, t2) {
          if (e3) {
            if ("string" == typeof e3) return a(e3, t2);
            var o2 = Object.prototype.toString.call(e3).slice(8, -1);
            return "Object" === o2 && e3.constructor && (o2 = e3.constructor.name), "Map" === o2 || "Set" === o2 ? Array.from(e3) : "Arguments" === o2 || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(o2) ? a(e3, t2) : void 0;
          }
        }(e2) || function() {
          throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
        }();
      }
      function a(e2, t2) {
        (null == t2 || t2 > e2.length) && (t2 = e2.length);
        for (var o2 = 0, i2 = new Array(t2); o2 < t2; o2++) i2[o2] = e2[o2];
        return i2;
      }
      function s(e2, t2) {
        for (var o2 = 0; o2 < t2.length; o2++) {
          var i2 = t2[o2];
          i2.enumerable = i2.enumerable || false, i2.configurable = true, "value" in i2 && (i2.writable = true), Object.defineProperty(e2, l(i2.key), i2);
        }
      }
      function l(e2) {
        var t2 = function(e3, t3) {
          if ("object" != r(e3) || !e3) return e3;
          var o2 = e3[Symbol.toPrimitive];
          if (void 0 !== o2) {
            var i2 = o2.call(e3, t3);
            if ("object" != r(i2)) return i2;
            throw new TypeError("@@toPrimitive must return a primitive value.");
          }
          return String(e3);
        }(e2, "string");
        return "symbol" == r(t2) ? t2 : t2 + "";
      }
      var u = function() {
        function e2() {
          !function(e3, t3) {
            if (!(e3 instanceof t3)) throw new TypeError("Cannot call a class as a function");
          }(this, e2);
        }
        return t2 = e2, r2 = [{ key: "addClass", value: function(t3, o3) {
          t3 && (o3 = o3.split(" "), e2.getElements(t3).forEach(function(e3) {
            var t4;
            (t4 = e3.classList).add.apply(t4, n(o3));
          }));
        } }, { key: "removeClass", value: function(t3, o3) {
          t3 && (o3 = o3.split(" "), e2.getElements(t3).forEach(function(e3) {
            var t4;
            (t4 = e3.classList).remove.apply(t4, n(o3));
          }));
        } }, { key: "hasClass", value: function(e3, t3) {
          return !!e3 && e3.classList.contains(t3);
        } }, { key: "getElement", value: function(e3) {
          return e3 && ("string" == typeof e3 ? e3 = document.querySelector(e3) : void 0 !== e3.length && (e3 = e3[0])), e3 || null;
        } }, { key: "getElements", value: function(e3) {
          if (e3) return void 0 === e3.forEach && (e3 = [e3]), e3;
        } }, { key: "addEvent", value: function(t3, o3, i2) {
          e2.addOrRemoveEvent(t3, o3, i2, "add");
        } }, { key: "removeEvent", value: function(t3, o3, i2) {
          e2.addOrRemoveEvent(t3, o3, i2, "remove");
        } }, { key: "addOrRemoveEvent", value: function(t3, o3, r3, n2) {
          t3 && (o3 = i.removeArrayEmpty(o3.split(" "))).forEach(function(o4) {
            (t3 = e2.getElements(t3)).forEach(function(e3) {
              "add" === n2 ? e3.addEventListener(o4, r3) : e3.removeEventListener(o4, r3);
            });
          });
        } }, { key: "getScrollableParents", value: function(e3) {
          if (!e3) return [];
          for (var t3 = [window], o3 = e3.parentElement; o3; ) {
            var i2 = getComputedStyle(o3).overflow;
            -1 === i2.indexOf("scroll") && -1 === i2.indexOf("auto") || t3.push(o3), o3 = o3.parentElement;
          }
          return t3;
        } }, { key: "convertPropToDataAttr", value: function(e3) {
          return e3 ? "data-popover-".concat(e3).replace(/([A-Z])/g, "-$1").toLowerCase() : "";
        } }], (o2 = null) && s(t2.prototype, o2), r2 && s(t2, r2), Object.defineProperty(t2, "prototype", { writable: false }), t2;
        var t2, o2, r2;
      }();
      function p(e2) {
        return p = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(e3) {
          return typeof e3;
        } : function(e3) {
          return e3 && "function" == typeof Symbol && e3.constructor === Symbol && e3 !== Symbol.prototype ? "symbol" : typeof e3;
        }, p(e2);
      }
      function h(e2, t2) {
        for (var o2 = 0; o2 < t2.length; o2++) {
          var i2 = t2[o2];
          i2.enumerable = i2.enumerable || false, i2.configurable = true, "value" in i2 && (i2.writable = true), Object.defineProperty(e2, c(i2.key), i2);
        }
      }
      function c(e2) {
        var t2 = function(e3, t3) {
          if ("object" != p(e3) || !e3) return e3;
          var o2 = e3[Symbol.toPrimitive];
          if (void 0 !== o2) {
            var i2 = o2.call(e3, t3);
            if ("object" != p(i2)) return i2;
            throw new TypeError("@@toPrimitive must return a primitive value.");
          }
          return String(e3);
        }(e2, "string");
        return "symbol" == p(t2) ? t2 : t2 + "";
      }
      var f, v = { 27: "onEscPress" }, d = ["target", "position", "margin", "offset", "enterDelay", "exitDelay", "showDuration", "hideDuration", "transitionDistance", "updatePositionThrottle", "zIndex", "hideOnOuterClick", "showOnHover", "hideArrowIcon", "disableManualAction", "disableUpdatePosition"], y = function() {
        function e2(t3) {
          !function(e3, t4) {
            if (!(e3 instanceof t4)) throw new TypeError("Cannot call a class as a function");
          }(this, e2);
          try {
            this.setProps(t3), this.init();
          } catch (e3) {
            console.warn("Couldn't initiate Popover component"), console.error(e3);
          }
        }
        return t2 = e2, r2 = [{ key: "init", value: function(t3) {
          var o3 = t3.ele;
          if (o3) {
            var i2 = false;
            if ("string" == typeof o3) {
              if (!(o3 = document.querySelectorAll(o3))) return;
              1 === o3.length && (i2 = true);
            }
            void 0 === o3.length && (o3 = [o3], i2 = true);
            var r3 = [];
            return o3.forEach(function(o4) {
              t3.ele = o4, e2.destroy(o4), r3.push(new e2(t3));
            }), i2 ? r3[0] : r3;
          }
        } }, { key: "destroy", value: function(e3) {
          if (e3) {
            var t3 = e3.popComp;
            t3 && t3.destroy();
          }
        } }, { key: "showMethod", value: function() {
          this.popComp.show();
        } }, { key: "hideMethod", value: function() {
          this.popComp.hide();
        } }, { key: "updatePositionMethod", value: function() {
          this.popComp.popper.updatePosition();
        } }, { key: "getAttrProps", value: function() {
          var e3 = u.convertPropToDataAttr, t3 = {};
          return d.forEach(function(o3) {
            t3[e3(o3)] = o3;
          }), t3;
        } }], (o2 = [{ key: "init", value: function() {
          this.$popover && (this.setElementProps(), this.renderArrow(), this.initPopper(), this.addEvents());
        } }, { key: "getEvents", value: function() {
          var e3 = [{ $ele: document, event: "click", method: "onDocumentClick" }, { $ele: document, event: "keydown", method: "onDocumentKeyDown" }];
          return this.disableManualAction || (e3.push({ $ele: this.$ele, event: "click", method: "onTriggerEleClick" }), this.showOnHover && (e3.push({ $ele: this.$ele, event: "mouseenter", method: "onTriggerEleMouseEnter" }), e3.push({ $ele: this.$ele, event: "mouseleave", method: "onTriggerEleMouseLeave" }))), e3;
        } }, { key: "addOrRemoveEvents", value: function(e3) {
          var t3 = this;
          this.getEvents().forEach(function(o3) {
            t3.addOrRemoveEvent({ action: e3, $ele: o3.$ele, events: o3.event, method: o3.method });
          });
        } }, { key: "addEvents", value: function() {
          this.addOrRemoveEvents("add");
        } }, { key: "removeEvents", value: function() {
          this.addOrRemoveEvents("remove"), this.removeScrollEventListeners(), this.removeResizeEventListeners();
        } }, { key: "addOrRemoveEvent", value: function(e3) {
          var t3 = this, o3 = e3.action, r3 = e3.$ele, n2 = e3.events, a2 = e3.method, s2 = e3.throttle;
          r3 && (n2 = i.removeArrayEmpty(n2.split(" "))).forEach(function(e4) {
            var n3 = "".concat(a2, "-").concat(e4), l2 = t3.events[n3];
            l2 || (l2 = t3[a2].bind(t3), s2 && (l2 = i.throttle(l2, s2)), t3.events[n3] = l2), "add" === o3 ? u.addEvent(r3, e4, l2) : u.removeEvent(r3, e4, l2);
          });
        } }, { key: "addScrollEventListeners", value: function() {
          this.$scrollableElems = u.getScrollableParents(this.$ele), this.addOrRemoveEvent({ action: "add", $ele: this.$scrollableElems, events: "scroll", method: "onAnyParentScroll", throttle: this.updatePositionThrottle });
        } }, { key: "removeScrollEventListeners", value: function() {
          this.$scrollableElems && (this.addOrRemoveEvent({ action: "remove", $ele: this.$scrollableElems, events: "scroll", method: "onAnyParentScroll" }), this.$scrollableElems = null);
        } }, { key: "addResizeEventListeners", value: function() {
          this.addOrRemoveEvent({ action: "add", $ele: window, events: "resize", method: "onResize", throttle: this.updatePositionThrottle });
        } }, { key: "removeResizeEventListeners", value: function() {
          this.addOrRemoveEvent({ action: "remove", $ele: window, events: "resize", method: "onResize" });
        } }, { key: "onAnyParentScroll", value: function() {
          this.popper.updatePosition();
        } }, { key: "onResize", value: function() {
          this.popper.updatePosition();
        } }, { key: "onDocumentClick", value: function(e3) {
          var t3 = e3.target, o3 = t3.closest(".pop-comp-ele"), i2 = t3.closest(".pop-comp-wrapper");
          this.hideOnOuterClick && o3 !== this.$ele && i2 !== this.$popover && this.hide();
        } }, { key: "onDocumentKeyDown", value: function(e3) {
          var t3 = e3.which || e3.keyCode, o3 = v[t3];
          o3 && this[o3](e3);
        } }, { key: "onEscPress", value: function() {
          this.hideOnOuterClick && this.hide();
        } }, { key: "onTriggerEleClick", value: function() {
          this.toggle();
        } }, { key: "onTriggerEleMouseEnter", value: function() {
          this.show();
        } }, { key: "onTriggerEleMouseLeave", value: function() {
          this.hide();
        } }, { key: "setProps", value: function(e3) {
          e3 = this.setDefaultProps(e3), this.setPropsFromElementAttr(e3);
          var t3 = i.convertToBoolean;
          this.$ele = e3.ele, this.target = e3.target, this.position = e3.position, this.margin = parseFloat(e3.margin), this.offset = parseFloat(e3.offset), this.enterDelay = parseFloat(e3.enterDelay), this.exitDelay = parseFloat(e3.exitDelay), this.showDuration = parseFloat(e3.showDuration), this.hideDuration = parseFloat(e3.hideDuration), this.transitionDistance = parseFloat(e3.transitionDistance), this.updatePositionThrottle = parseFloat(e3.updatePositionThrottle), this.zIndex = parseFloat(e3.zIndex), this.hideOnOuterClick = t3(e3.hideOnOuterClick), this.showOnHover = t3(e3.showOnHover), this.hideArrowIcon = t3(e3.hideArrowIcon), this.disableManualAction = t3(e3.disableManualAction), this.disableUpdatePosition = t3(e3.disableUpdatePosition), this.beforeShowCallback = e3.beforeShow, this.afterShowCallback = e3.afterShow, this.beforeHideCallback = e3.beforeHide, this.afterHideCallback = e3.afterHide, this.events = {}, this.$popover = u.getElement(this.target);
        } }, { key: "setDefaultProps", value: function(e3) {
          return Object.assign({ position: "auto", margin: 8, offset: 5, enterDelay: 0, exitDelay: 0, showDuration: 300, hideDuration: 200, transitionDistance: 10, updatePositionThrottle: 100, zIndex: 1, hideOnOuterClick: true, showOnHover: false, hideArrowIcon: false, disableManualAction: false, disableUpdatePosition: false }, e3);
        } }, { key: "setPropsFromElementAttr", value: function(e3) {
          var t3 = e3.ele;
          for (var o3 in f) {
            var i2 = t3.getAttribute(o3);
            i2 && (e3[f[o3]] = i2);
          }
        } }, { key: "setElementProps", value: function() {
          var t3 = this.$ele;
          t3.popComp = this, t3.show = e2.showMethod, t3.hide = e2.hideMethod, t3.updatePosition = e2.updatePositionMethod, u.addClass(this.$ele, "pop-comp-ele"), u.addClass(this.$popover, "pop-comp-wrapper");
        } }, { key: "getOtherTriggerPopComp", value: function() {
          var e3, t3 = this.$popover.popComp;
          return t3 && t3.$ele !== this.$ele && (e3 = t3), e3;
        } }, { key: "initPopper", value: function() {
          var e3 = { $popperEle: this.$popover, $triggerEle: this.$ele, $arrowEle: this.$arrowEle, position: this.position, margin: this.margin, offset: this.offset, enterDelay: this.enterDelay, exitDelay: this.exitDelay, showDuration: this.showDuration, hideDuration: this.hideDuration, transitionDistance: this.transitionDistance, zIndex: this.zIndex, afterShow: this.afterShow.bind(this), afterHide: this.afterHide.bind(this) };
          this.popper = new PopperComponent(e3);
        } }, { key: "beforeShow", value: function() {
          "function" == typeof this.beforeShowCallback && this.beforeShowCallback(this);
        } }, { key: "beforeHide", value: function() {
          "function" == typeof this.beforeHideCallback && this.beforeHideCallback(this);
        } }, { key: "show", value: function() {
          this.isShown() || (this.isShownForOtherTrigger() ? this.showAfterOtherHide() : (u.addClass(this.$popover, "pop-comp-disable-events"), this.$popover.popComp = this, this.beforeShow(), this.popper.show({ resetPosition: true }), u.addClass(this.$ele, "pop-comp-active")));
        } }, { key: "hide", value: function() {
          this.isShown() && (this.beforeHide(), this.popper.hide(), this.removeScrollEventListeners(), this.removeResizeEventListeners());
        } }, { key: "toggle", value: function(e3) {
          void 0 === e3 && (e3 = !this.isShown()), e3 ? this.show() : this.hide();
        } }, { key: "isShown", value: function() {
          return u.hasClass(this.$ele, "pop-comp-active");
        } }, { key: "isShownForOtherTrigger", value: function() {
          var e3 = this.getOtherTriggerPopComp();
          return !!e3 && e3.isShown();
        } }, { key: "showAfterOtherHide", value: function() {
          var e3 = this, t3 = this.getOtherTriggerPopComp();
          if (t3) {
            var o3 = t3.exitDelay + t3.hideDuration + 100;
            setTimeout(function() {
              e3.show();
            }, o3);
          }
        } }, { key: "afterShow", value: function() {
          var e3 = this;
          this.showOnHover ? setTimeout(function() {
            u.removeClass(e3.$popover, "pop-comp-disable-events");
          }, 2e3) : u.removeClass(this.$popover, "pop-comp-disable-events"), this.disableUpdatePosition || (this.addScrollEventListeners(), this.addResizeEventListeners()), "function" == typeof this.afterShowCallback && this.afterShowCallback(this);
        } }, { key: "afterHide", value: function() {
          u.removeClass(this.$ele, "pop-comp-active"), "function" == typeof this.afterHideCallback && this.afterHideCallback(this);
        } }, { key: "renderArrow", value: function() {
          if (!this.hideArrowIcon) {
            var e3 = this.$popover.querySelector(".pop-comp-arrow");
            e3 || (this.$popover.insertAdjacentHTML("afterbegin", '<i class="pop-comp-arrow"></i>'), e3 = this.$popover.querySelector(".pop-comp-arrow")), this.$arrowEle = e3;
          }
        } }, { key: "destroy", value: function() {
          this.removeEvents();
        } }]) && h(t2.prototype, o2), r2 && h(t2, r2), Object.defineProperty(t2, "prototype", { writable: false }), t2;
        var t2, o2, r2;
      }();
      f = y.getAttrProps(), window.PopoverComponent = y;
    }();
  }();
})();
class LegacyBundleSelect {
  constructor() {
    this.header = document.querySelector("body > header");
    this.overlay = document.querySelector(".js-bundle-select-options-overlay");
    if (this.overlay == void 0 || window.screen.width > 768) return;
    this.closeBtn = this.overlay.querySelector("[data-close-button]");
    this.genderFiltersWrapper = this.overlay.querySelector("[data-gender-filters]");
    this.genderFilters = this.genderFiltersWrapper.querySelectorAll("[data-gender-filter]");
    this.fragranceWrappers = this.overlay.querySelectorAll("[data-fragrance-wrapper]");
    this.submitButtonWrapper = this.overlay.querySelector("[data-submit-button]");
    this.selectFields = document.querySelectorAll(".js-bundle-select");
    this.searchField = this.overlay.querySelector("[data-search-field]");
    this.searchButton = this.overlay.querySelector("[data-search-button]");
    this.selectBoxButtons = document.querySelectorAll("[data-search-box-replacement]");
    this.productLimit = 3;
    this.selectedProducts = [];
    this.initEventListeners();
  }
  initEventListeners() {
    this.submitButtonWrapper.querySelector("button").innerHTML = document.querySelector('[data-section-product__add] button[type="submit"]').innerHTML;
    this.selectBoxButtons.forEach((select) => {
      ["click"].forEach((eventType) => {
        select.addEventListener(eventType, (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.toggleOverlay(select, e);
        });
      });
    });
    this.selectFields.forEach((select) => {
      select.parentElement.querySelector(".js-bundle-select-search-icon").classList.remove("hidden");
      ["click"].forEach((eventType) => {
        select.addEventListener(eventType, (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.toggleOverlay(select, e);
        });
      });
    });
    this.closeBtn.addEventListener("click", (e) => {
      this.toggleOverlay();
    });
    this.genderFilters.forEach((filter) => {
      filter.addEventListener("click", (e) => {
        this.toggleGenderFilter(filter);
      });
    });
    this.fragranceWrappers.forEach((fragranceWrapper) => {
      fragranceWrapper.querySelector("button[data-quantity-add").addEventListener("click", (e) => {
        e.preventDefault();
        e.target.parentElement.querySelector("input").value++;
        this.updateSelectedProducts();
      });
      fragranceWrapper.querySelector("button[data-quantity-remove").addEventListener("click", (e) => {
        e.preventDefault();
        e.target.parentElement.querySelector("input").value--;
        this.updateSelectedProducts();
      });
      fragranceWrapper.querySelector('input[type="checkbox"]').addEventListener("click", (e) => {
        this.updateSelectedProducts();
      });
    });
    this.submitButtonWrapper.querySelector("button").addEventListener("click", (e) => {
      e.preventDefault();
      this.updateSelectOptions();
    });
    this.searchButton.addEventListener("click", (e) => {
      e.preventDefault();
      this.search();
    });
    this.searchField.addEventListener("input", (e) => {
      if (e.target.value.length === 0) {
        this.search();
      }
    });
    this.genderFilters[0].click();
  }
  updateSelectOptions() {
    this.selectFields.forEach((select, i) => {
      if (this.selectedProducts[i] !== void 0) {
        let selectedOption = this.selectedProducts[i].querySelector('input[type="checkbox"]').value;
        select.querySelectorAll("option").forEach((option) => {
          if (option.value === selectedOption) {
            option.selected = true;
          } else {
            option.selected = false;
          }
        });
      }
      this.selectBoxButtons[i].querySelector("[data-selected-product]").innerText = this.selectedProducts[i].querySelector('input[type="checkbox"]').value;
    });
    document.querySelector('[data-section-product__add] button[type="submit"]').click();
    this.toggleOverlay();
  }
  updateSelectedProducts() {
    this.selectedProducts = [];
    this.fragranceWrappers.forEach((fragranceWrapper) => {
      const checkbox = fragranceWrapper.querySelector('input[type="checkbox"]');
      let quantity = checkbox.parentElement.querySelector("[data-quantity-selector]").querySelector("input").value;
      if (checkbox.checked && quantity > 0) {
        checkbox.classList.add("hidden");
        checkbox.parentElement.querySelector("[data-quantity-selector]").classList.remove("hidden");
        console.log("QUANTITY: ", quantity);
        for (let i = 0; i < quantity; i++) {
          this.selectedProducts.push(fragranceWrapper);
        }
      } else {
        checkbox.checked = false;
        checkbox.classList.remove("hidden");
        checkbox.parentElement.querySelector("[data-quantity-selector]").classList.add("hidden");
        checkbox.parentElement.querySelector("[data-quantity-selector]").querySelector("input").value = 1;
      }
    });
    if (this.selectedProducts.length === this.productLimit) {
      this.fragranceWrappers.forEach((fragranceWrapper) => {
        const checkbox = fragranceWrapper.querySelector('input[type="checkbox"]');
        if (!checkbox.checked) {
          fragranceWrapper.classList.add("opacity-30", "pointer-events-none");
        }
        fragranceWrapper.querySelector("button[data-quantity-add").classList.add("hidden");
      });
      this.submitButtonWrapper.classList.remove("hidden");
    } else {
      this.fragranceWrappers.forEach((fragranceWrapper) => {
        fragranceWrapper.querySelector("button[data-quantity-add").classList.remove("hidden");
        fragranceWrapper.classList.remove("opacity-30", "pointer-events-none");
      });
      this.selectBoxButtons.forEach((button) => {
        button.classList.remove("hidden");
      });
      this.submitButtonWrapper.classList.add("hidden");
    }
  }
  toggleOverlay() {
    this.header.classList.toggle("sticky");
    this.overlay.classList.toggle("hidden");
  }
  toggleGenderFilter(filter) {
    this.genderFilters.forEach((genderFilter) => {
      genderFilter.classList.remove("bg-black", "text-white");
      genderFilter.classList.remove("bg-white", "text-black");
    });
    filter.classList.add("bg-black", "text-white");
    const selectedGender = filter.dataset.genderFilter.toLowerCase();
    this.fragranceWrappers.forEach((fragranceWrapper) => {
      const genders = fragranceWrapper.dataset.gender.toLowerCase().split(",").map((gender) => gender.trim());
      if (genders.includes(selectedGender)) {
        fragranceWrapper.classList.remove("hidden");
      } else {
        fragranceWrapper.classList.add("hidden");
      }
    });
  }
  search() {
    let searchValue = this.searchField.value.toLowerCase();
    if (searchValue.length === 0) {
      this.fragranceWrappers.forEach((fragranceWrapper) => {
        fragranceWrapper.classList.remove("hidden");
      });
      this.genderFiltersWrapper.classList.remove("hidden");
      return;
    }
    this.genderFiltersWrapper.classList.add("hidden");
    this.fragranceWrappers.forEach((fragranceWrapper) => {
      if (fragranceWrapper.dataset.fragrance.toLowerCase().includes(searchValue)) {
        fragranceWrapper.classList.remove("hidden");
      } else {
        fragranceWrapper.classList.add("hidden");
      }
    });
  }
}
document.addEventListener("DOMContentLoaded", () => {
  new LegacyBundleSelect();
});
module_default$3.plugin(module_default);
module_default$3.plugin(module_default$2);
module_default$3.plugin(module_default$1);
window.Alpine = module_default$3;
module_default$3.start();
